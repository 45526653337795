import React, { useState, useEffect } from 'react';
import InputField from '../../../../../../../components/InputFeild';
import Dropdown from '../../../../../../../components/Dropdown';
import Input from '../../../../../../../components/Input';
import { VehicleProfile } from '../../../../../../../utils/interfaces/masterVehicle';
import Button from '../../../../../../../components/Button';
import SingleSelectDropdown from '../../../../../../../components/SingleSelectDropdown';


interface ProfileDataProps {
  data?: VehicleProfile;
}

const ProfileTab: React.FC<ProfileDataProps> = ({ data }) => {
  // State for string values
  const [plateNumber, setPlateNumber] = useState<string>('');
  const [dvirTemplate, setDvirTemplate] = useState<string>('');
  const [purchaseAmount, setPurchaseAmount] = useState<string>('');
  const [weightCapacity, setWeightCapacity] = useState<string>('');
  const [gpsWarranty, setGpsWarranty] = useState<string>('');
  const [companyAverage, setCompanyAverage] = useState<string>('');
  const [registrationNumber, setRegistrationNumber] = useState<string>('');
  const [distanceFuelType, setDistanceFuelType] = useState<number | null>(null);
  const [distanceKm, setDistanceKm] = useState<string>('');
  const [distanceLiter, setDistanceLiter] = useState<string>('');
  const [durationKm, setDurationKm] = useState<string>('');
  const [durationTime, setDurationTime] = useState<number | null>(null);
  const [durationFuelType, setDurationFuelType] = useState<number | null>(null);
  const [durationLiter, setDurationLiter] = useState<string>('');
  const [fuel_idling_consumption, setfuel_idling_consumption] = useState({ dropdownValue: '', inputValue: '' });
  const [consumptionTolerance, setConsumptionTolerance] = useState<string>('');
  const [vinNumber, setVinNumber] = useState<string>('');
  const [engineNumber, setEngineNumber] = useState<string>('');
  const [odometer, setOdometer] = useState<string>('');
  const [lbsDetectionRadius, setLbsDetectionRadius] = useState<string>('');
  const [engineHour, setEngineHour] = useState<string>('');
  const [passengerSeats, setPassengerSeats] = useState<string>('');
  const [rfidTimeoutDuration, setRfidTimeoutDuration] = useState<string>('');
  const [sleepModeDuration, setSleepModeDuration] = useState<string>('');
  const [minimumWorkingHours, setMinimumWorkingHours] = useState<string>('');
  const [underweightTolerance, setUnderweightTolerance] = useState<string>('25');
  const [overweightTolerance, setOverweightTolerance] = useState<string>('10');
  const [loadingTolerance, setLoadingTolerance] = useState<string>('5');
  const [fuelSensor, setFuelSensor] = useState<string>('single');
  const [numberOfTanks, setNumberOfTanks] = useState<string>('');
  const [axisX, setAxisX] = useState<string>('');
  const [axisY, setAxisY] = useState<string>('');
  const [axisZ, setAxisZ] = useState<string>('');

  // State for boolean values
  const [isDistanceFuelChecked, setIsDistanceFuelChecked] = useState<boolean>(false);
  const [isDurationFuelChecked, setIsDurationFuelChecked] = useState<boolean>(false);
  const [weightSensor, setWeightSensor] = useState<boolean>(false);
  const [gSensor, setGSensor] = useState<boolean>(false);

  // State for array values
  const [selectedObjectTypes, setSelectedObjectTypes] = useState<string | null>(null);
  const [selectedPermits, setSelectedPermits] = useState<string | null>(null);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string | null>(null);

  // State for Date values
  const [manufactureDate, setManufactureDate] = useState<Date | null>(null);
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
  const [gpsInstallationDate, setGpsInstallationDate] = useState<Date | null>(null);
  const [installationDate, setInstallationDate] = useState<Date | null>(null);

  // State for complex types
  const [costCategory, setCostCategory] = useState({ distance: false, duration: false });


  useEffect(() => {
    if (data) {
      // console.log('data from profile', data);

      setPlateNumber(data?.plateNumber ?? '');
      setSelectedObjectTypes(data.selectedObjectTypes);
      setSelectedPermits(data.selectedPermits);
      setSelectedFuelTypes(data.selectedFuelTypes);
      setIsDistanceFuelChecked(data.isDistanceFuelChecked || false);
      setDistanceKm(data.distanceKm || '');
      setDistanceFuelType(data.distanceFuelType ? Number(data.distanceFuelType) : null);
      setDistanceLiter(data.distanceLiter || '');
      setDurationKm(data.durationKm || '');
      setDurationTime(data.durationTime || null);
      setDurationFuelType(data.durationFuelType || null);
      setDurationLiter(data.durationLiter || '');
      setDvirTemplate(data.dvirTemplate || '');
      setPurchaseAmount(data.purchaseAmount || '');
      setWeightCapacity(data.weightCapacity || '');
      setGpsWarranty(data.gpsWarranty || '');
      setCompanyAverage(data.companyAverage || '');
      setRegistrationNumber(data.registrationNumber || '');
      setIsDurationFuelChecked(data.isDurationFuelChecked || false);
      setDurationKm(data.durationKm || '');
      setfuel_idling_consumption(
        data.fuel_idling_consumption || { dropdownValue: '', inputValue: '' }
      );
      setConsumptionTolerance(data.consumptionTolerance || '');
      setVinNumber(data.vinNumber || '');
      setEngineNumber(data.engineNumber || '');
      setOdometer(data.odometer || '');
      setLbsDetectionRadius(data.lbsDetectionRadius || '');
      setEngineHour(data.engineHour || '');
      setPassengerSeats(data.passengerSeats || '');
      setRfidTimeoutDuration(data.rfidTimeoutDuration || '');
      setSleepModeDuration(data.sleepModeDuration || '');
      setMinimumWorkingHours(data.minimumWorkingHours || '');

      setCostCategory({
        distance: data.costCategory?.distance ?? false,
        duration: data.costCategory?.duration ?? false,
      });

      setWeightSensor(data.weightSensor || false);
      setUnderweightTolerance(data.underweightTolerance || '25');
      setOverweightTolerance(data.overweightTolerance || '10');
      setLoadingTolerance(data.loadingTolerance || '5');
      setFuelSensor(data.fuelSensor || 'single');
      setNumberOfTanks(data.numberOfTanks || '');
      setGSensor(data.gSensor || false);
      setAxisX(data.axisX || '');
      setAxisY(data.axisY || '');
      setAxisZ(data.axisZ || '');


      setManufactureDate(parseDate(data.manufactureDate));
      setPurchaseDate(parseDate(data.purchaseDate));
      setGpsInstallationDate(parseDate(data.gpsInstallationDate));
      setInstallationDate(parseDate(data.installationDate));

    }
  }, [data]);

  function parseDate(dateString: string | null): Date | null {
    return dateString ? new Date(dateString) : null;
  }


  const handlePlateNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlateNumber(e.target.value);
  };

  const handleDvirTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDvirTemplate(e.target.value);
  };


  const handlePurchaseAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseAmount(e.target.value);
  };

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeightCapacity(e.target.value);
  };

  const handleGpsWarrantyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGpsWarranty(e.target.value);
  };

  const handleCompanyAverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyAverage(e.target.value);
  };

  const handleRegistrationNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationNumber(e.target.value);
  };

  const handleDistanceFuelTypeChange = (selectedValue: string) => {
    setDistanceFuelType(parseInt(selectedValue));
  };


  const handleDurationTimeChange = (selectedValue: string) => {
    setDurationTime(parseInt(selectedValue));
  };

  const handleDurationFuelTypeChange = (selectedValue: string) => {
    setDurationFuelType(parseInt(selectedValue));
  };


  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDistanceFuelChecked(e.target.checked);
  };

  const handleCheckboxesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDurationFuelChecked(e.target.checked);
  };



  const handleConsumptionToleranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsumptionTolerance(e.target.value);
  };


  const handleVinNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber(e.target.value);
  };

  const handleEngineNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEngineNumber(e.target.value);
  };

  const handleOdometerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOdometer(e.target.value);
  };

  const handleLbsDetectionRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLbsDetectionRadius(e.target.value);
  };

  const handleEngineHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEngineHour(e.target.value);
  };

  const handlePassengerSeatsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassengerSeats(e.target.value);
  };

  const handleRfidTimeoutDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRfidTimeoutDuration(e.target.value);
  };

  const handleSleepModeDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSleepModeDuration(e.target.value);
  };

  const handleMinimumWorkingHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinimumWorkingHours(e.target.value);
  };


  const handleObjectTypeSelect = (selectedValue: string | null) => {
    setSelectedObjectTypes(selectedValue);
  };

  const handlePermitSelect = (selectedValue: string | null) => {
    setSelectedPermits(selectedValue);
  };

  const handleFuelTypeSelect = (selectedValue: string | null) => {
    setSelectedFuelTypes(selectedValue);
  };

  // Handle checkbox change for cost categories
  const handleCostCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCostCategory(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  // Handle checkbox change for weight sensor
  const handleWeightSensorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeightSensor(e.target.checked);
  };

  // Handle radio button change for fuel sensor
  const handleRadioChange = (value: string) => {
    setFuelSensor(value);
    if (value === 'single') {
      setNumberOfTanks('');
    }
  };

  // Handle text input change for number of tanks
  const handleNumberOfTanksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfTanks(e.target.value);
  };



  // Options for the multi-select dropdowns
  const objectTypeOptions = [
    { value: 'default', label: 'Default' },
    { value: 'type1', label: 'Type 1' },
    { value: 'type2', label: 'Type 2' },
    // Add other options here
  ];

  const permitOptions = [
    { value: 'permit1', label: 'Permit 1' },
    { value: 'permit2', label: 'Permit 2' },
    // Add other options here
  ];

  const fuelTypeOptions = [
    { value: 'diesel', label: 'Diesel' },
    { value: 'petrol', label: 'Petrol' },
    { value: 'electric', label: 'Electric' },
    // Add other options here
  ];

  // Define the options for the distance fuel type dropdown
  const distanceFuelOptions = [
    { value: '1', label: 'Liter' },
    { value: '2', label: 'Gallon' },
  ];

  const durationTimeOptions = [
    { value: '1', label: 'Hour' },
    { value: '2', label: 'Minute' },
  ];

  const durationFuelTypeOptions = [
    { value: '1', label: 'Liter' },
    { value: '2', label: 'Gallon' },
  ];



  type Option = {
    value: string;
    label: string;
  };


  const fuelIdlingConsumptionOptions: Option[] = [
    { value: 'liter', label: 'Liter' },
    { value: 'gallon', label: 'Gallon' }
  ];


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = {
      plateNumber,
      dvirTemplate,
      purchaseAmount,
      weightCapacity,
      gpsWarranty,
      companyAverage,
      registrationNumber,
      distanceFuelType,
      distanceKm,
      distanceLiter,
      durationKm,
      durationTime,
      durationFuelType,
      durationLiter,
      fuel_idling_consumption,
      consumptionTolerance,
      vinNumber,
      engineNumber,
      odometer,
      lbsDetectionRadius,
      engineHour,
      passengerSeats,
      rfidTimeoutDuration,
      sleepModeDuration,
      minimumWorkingHours,
      underweightTolerance,
      overweightTolerance,
      loadingTolerance,
      fuelSensor,
      numberOfTanks,
      axisX,
      axisY,
      axisZ

    };

    console.log('Form Data:', formData);

  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3"></div>
      <div className="w-full md:w-2/3">
        <form onSubmit={handleSubmit}>
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="plateNumber" className="w-1/3 font-semibold">Plate Number:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="plateNumber"
                value={plateNumber}
                onChange={handlePlateNumberChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="objectType" className="w-1/3 font-semibold">Object Type:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={objectTypeOptions}
                onSelect={handleObjectTypeSelect}
                selectedValue={selectedObjectTypes}
                placeholder="Select Object Type"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label className="w-1/3 font-semibold">Object Category:</label>
            <div className="w-2/3">
              <label className="mr-4">
                <input type="radio" name="objectCategory" value="movable" /> Movable
              </label>
              <label>
                <input type="radio" name="objectCategory" value="immovable" /> Immovable
              </label>
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="dvirTemplate" className="w-1/3 font-semibold">DVIR Template:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="dvirTemplate"
                value={dvirTemplate}
                onChange={handleDvirTemplateChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="manufactureDate" className="w-1/3 font-semibold">Manufacture Date:</label>
            <div className="w-2/3">
              <Input
                type="date"
                id="manufactureDate"
                value={manufactureDate}
                onChange={(date) => setManufactureDate(date as Date)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="purchaseDate" className="w-1/3 font-semibold">Purchase Date:</label>
            <div className="w-2/3">
              <Input
                type="date"
                id="purchaseDate"
                value={purchaseDate}
                onChange={(date) => setPurchaseDate(date as Date)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="purchaseAmount" className="w-1/3 font-semibold">Purchase Amount:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="purchaseAmount"
                name="purchaseAmount"
                value={purchaseAmount}
                onChange={handlePurchaseAmountChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="weightCapacity" className="w-1/3 font-semibold">Weight Capacity:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="weightCapacity"
                name="weightCapacity"
                value={weightCapacity}
                onChange={handleWeightChange}
                placeholder="Tonnes"
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="gpsInstallationDate" className="w-1/3 font-semibold">GPS Installation Date:</label>
            <div className="w-2/3">
              <Input
                type="date"
                id="gpsInstallationDate"
                value={gpsInstallationDate}
                onChange={(date) => setGpsInstallationDate(date as Date)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="gpsWarranty" className="w-1/3 font-semibold">GPS Warranty:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="gpsWarranty"
                name="gpsWarranty"
                value={gpsWarranty}
                onChange={handleGpsWarrantyChange}
                placeholder="In years"
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="companyAverage" className="w-1/3 font-semibold">Company Average:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="companyAverage"
                name="companyAverage"
                value={companyAverage}
                onChange={handleCompanyAverageChange}
                placeholder="Enter Company Claimed average"
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="permit" className="w-1/3 font-semibold">Permit:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={permitOptions}
                onSelect={handlePermitSelect}
                selectedValue={selectedPermits}
                placeholder="Select Permit"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="installationDate" className="w-1/3 font-semibold">Installation Date:</label>
            <div className="w-2/3">
              <Input
                type="date"
                id="installationDate"
                value={installationDate}
                onChange={(date) => setInstallationDate(date as Date)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="registrationNumber" className="w-1/3 font-semibold">Registration Number:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="registrationNumber"
                name="registrationNumber"
                value={registrationNumber}
                onChange={handleRegistrationNumberChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="fuelType" className="w-1/3 font-semibold">Fuel Type:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={fuelTypeOptions}
                onSelect={handleFuelTypeSelect}
                selectedValue={selectedFuelTypes}
                placeholder="Select Fuel Type"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          <div className="mb-3 flex items-start space-x-4">
            <label htmlFor="distanceBasedFuelConsumption" className="w-1/3 font-semibold">
              Distance based Fuel Consumption:
            </label>
            <div className="w-2/3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="chk_distance_fuel"
                  id="chk_distance_fuel"
                  onChange={handleCheckboxChange}
                  className="mr-2 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                />
                <InputField
                  type="text"
                  name="distance_km"
                  value={distanceKm}
                  onChange={(e) => setDistanceKm(e.target.value)}
                  style={{ width: '80px' }}
                  className="mr-2 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  placeholder="Kilometer"
                />
              </div>
              {isDistanceFuelChecked && (
                <div className="w-full mt-3">
                  <div className="flex flex-col space-y-2">
                    <Dropdown
                      options={distanceFuelOptions}
                      onSelect={handleDistanceFuelTypeChange}
                      selectedValue={distanceFuelType?.toString() || ''}
                      selectStyle={{ width: '16rem' }}
                    />
                    <InputField
                      type="text"
                      name="distance_liter"
                      value={distanceLiter}
                      onChange={(e) => setDistanceLiter(e.target.value)}
                      className="w-64 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      placeholder="Liter"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mb-2 flex items-start space-x-4">
            <label htmlFor="durationBasedFuelConsumption" className="w-1/3 font-semibold">
              Duration based Fuel Consumption:
            </label>
            <div className="w-2/3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="chk_duration_fuel"
                  id="chk_duration_fuel"
                  onChange={handleCheckboxesChange}
                  className="mr-2 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                />
                <InputField
                  type="text"
                  name="duration_km"
                  value={durationKm}
                  onChange={(e) => setDurationKm(e.target.value)}
                  style={{ width: '80px' }}
                  className="mr-1 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  placeholder="Kilometer"
                />
                <Dropdown
                  options={durationTimeOptions}
                  onSelect={handleDurationTimeChange}
                  selectedValue={durationTime?.toString() || ''}
                  selectStyle={{ width: '10rem' }}
                />
              </div>
              {isDurationFuelChecked && (
                <div className="mt-3 flex flex-col">
                  <Dropdown
                    options={durationFuelTypeOptions}
                    onSelect={handleDurationFuelTypeChange}
                    selectedValue={durationFuelType?.toString() || ''}
                    selectStyle={{ width: '16rem' }}
                  />
                  <InputField
                    type="text"
                    name="duration_liter"
                    value={durationLiter}
                    onChange={(e) => setDurationLiter(e.target.value)}
                    className="w-64 mt-1 px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                    placeholder="Liter"
                  />
                </div>
              )}
            </div>
          </div>




          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="fuelIdlingConsumption" className="w-1/3 font-semibold">
              Fuel Idling Consumption:
            </label>
            <div className="w-2/3 flex items-center space-x-1">
              <Dropdown
                options={fuelIdlingConsumptionOptions}
                onSelect={value => setfuel_idling_consumption(prev => ({ ...prev, dropdownValue: value }))}
                selectedValue={fuel_idling_consumption.dropdownValue}
                placeholder="Select Unit"
                selectStyle={{ width: 'auto', flexGrow: 1, minWidth: '120px' }}
              />
              <InputField
                type="number"
                id="fuelIdlingConsumption"
                name="fuel_idling_consumption"
                value={fuel_idling_consumption.inputValue}
                onChange={e => setfuel_idling_consumption(prev => ({ ...prev, inputValue: e.target.value }))}
                className="w-32 p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
              <span className="ml-2">{fuel_idling_consumption.dropdownValue}</span>
            </div>
          </div>


          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Consumption Tolerance [+/-]:
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="consumptionTolerance"
                name="consumptionTolerance"
                value={consumptionTolerance}
                onChange={handleConsumptionToleranceChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              VIN(Chassis) Number :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="vinNumber"
                value={vinNumber}
                onChange={handleVinNumberChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Engine Number :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="engineNumber"
                value={engineNumber}
                onChange={handleEngineNumberChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Odometer :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="odometer"
                name="odometer"
                value={odometer}
                onChange={handleOdometerChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              LBS Detection Radius:
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="lbsDetectionRadius"
                name="lbsDetectionRadius"
                value={lbsDetectionRadius}
                onChange={handleLbsDetectionRadiusChange}
                placeholder="Only numeric value"
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
              <span className="text-sm">(meter) Max Value up to 5000 meters</span>
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Engine Hour :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="engineHour"
                name="engineHour"
                value={engineHour}
                onChange={handleEngineHourChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              No of Passenger Seats :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="passengerSeats"
                name="passengerSeats"
                value={passengerSeats}
                onChange={handlePassengerSeatsChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label className="w-full md:w-1/3 font-semibold">Cost Based On :</label>
            <div className="w-2/3">
              <label className="mr-4 mb-2 md:mb-0">
                <input
                  type="checkbox"
                  name="distance"
                  checked={costCategory.distance}
                  onChange={handleCostCategoryChange}
                /> Distance
              </label>
              <label>
                <input
                  type="checkbox"
                  name="duration"
                  checked={costCategory.duration}
                  onChange={handleCostCategoryChange}
                /> Duration
              </label>
            </div>
          </div>

          {/* Distance */}
          {costCategory.distance && (
            <div className="mb-2">
              <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="distance" className="w-1/3 font-semibold">
                  Distance:
                </label>
                <div className="w-2/3">
                  <InputField
                    type="text"
                    id="distance"
                    value={axisX}
                    onChange={(e) => setAxisX(e.target.value)}
                    className="w-64 p-2 px-3  ml-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                    placeholder="Enter Distance"
                  />
                  <span className="mt-2 ml-2">
                    <label style={{ cursor: 'pointer' }}>$ / km</label>
                  </span>
                </div>
              </div>
            </div>
          )}


          {/* Duration */}
          {costCategory.duration && (
            <div className="mb-4 flex flex-col items-start md:flex-row md:items-center">
              <label htmlFor="duration" className="w-1/3 font-semibold">
                Duration:
              </label>
              <div className="w-2/3">
                <InputField
                  type="text"
                  id="duration"
                  value={axisY}
                  onChange={(e) => setAxisY(e.target.value)}
                  className="w-64 p-2 ml-3 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  placeholder="Enter Duration"
                />
                <div className="mt-2 md:mt-1 flex items-center">
                  <label className="md:ml-2 cursor-pointer">$/</label>
                  <select
                    name="cost_based_on_duration_time"
                    className="w-full mt-1 md:w-auto border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none ml-2"
                    style={{ width: '120px' }}
                  >
                    <option value="day">Day</option>
                    <option value="hour">Hour</option>
                  </select>
                </div>
              </div>
            </div>
          )}


          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              RFID Timeout Duration :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="rfidTimeoutDuration"
                name="rfidTimeoutDuration"
                value={rfidTimeoutDuration}
                onChange={handleRfidTimeoutDurationChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Sleep Mode Duration :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="sleepModeDuration"
                name="sleepModeDuration"
                value={sleepModeDuration}
                onChange={handleSleepModeDurationChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="consumptionTolerance" className="w-1/3 font-semibold">
              Minimum Working Hours :
            </label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="minimumWorkingHours"
                name="minimumWorkingHours"
                value={minimumWorkingHours}
                onChange={handleMinimumWorkingHoursChange}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>


          <div className="mb-4 flex items-center space-x-4">
            <label htmlFor="weightSensor" className="w-1/3 font-semibold">Weight Sensor :</label>
            <div className="flex items-center">
              <div className="w-2/3">
                <input
                  type="checkbox"
                  id="weightSensor"
                  checked={weightSensor}
                  onChange={handleWeightSensorChange}
                  className="mr-2"
                />
              </div>
            </div>
          </div>

          {weightSensor && (
            <>
              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="underweightTolerance" className="w-1/3 font-semibold">
                    Underweight Tolerance <span className="text-red-500">*</span>:
                  </label>
                  <div className="flex flex-col w-full md:w-auto">
                    <div className="w-2/3">
                      <InputField
                        type="text"
                        id="underweightTolerance"
                        value={underweightTolerance}
                        onChange={(e) => setUnderweightTolerance(e.target.value)}
                        className="w-64 p-2 ml-3 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      />
                    </div>
                    <span className="mt-2 ml-3">
                      <label style={{ cursor: 'pointer' }}>(%)</label>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="overweightTolerance" className="w-1/3 font-semibold">
                    Overweight Tolerance <span className="text-red-500">*</span>:
                  </label>
                  <div className="flex flex-col w-full md:w-auto">
                    <div className="w-2/3">
                      <InputField
                        type="text"
                        id="overweightTolerance"
                        value={overweightTolerance}
                        onChange={(e) => setOverweightTolerance(e.target.value)}
                        className="w-64 p-2 ml-3 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      />
                    </div>
                    <span className="mt-2 ml-3 ">
                      <label style={{ cursor: 'pointer' }}>(%)</label>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="loadingTolerance" className="w-1/3 font-semibold">
                    Loading/Unloading Tolerance <span className="text-red-500">*</span>:
                  </label>
                  <div className="flex flex-col w-full md:w-auto">
                    <div className="w-2/3">
                      <InputField
                        type="text"
                        id="loadingTolerance"
                        value={loadingTolerance}
                        onChange={(e) => setLoadingTolerance(e.target.value)}
                        className="w-64 p-2 px-3 ml-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      />
                    </div>
                    <span className="mt-2 ml-3">
                      <label style={{ cursor: 'pointer' }}>(%)</label>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}


          <div className="mb-4 flex items-center space-x-4">
            <label className="w-1/3 font-semibold">Fuel Sensor :</label>
            <div className="w-1/3 md:w-64 flex">
              <input
                type="radio"
                id="single"
                name="fuelSensor"
                checked={fuelSensor === 'single'}
                onChange={() => handleRadioChange('single')}
                className="mr-2"
              />
              <label htmlFor="single">Single</label>
              <input
                type="radio"
                id="multiple"
                name="fuelSensor"
                checked={fuelSensor === 'multiple'}
                onChange={() => handleRadioChange('multiple')}
                className="mr-2 "
              />
              <label htmlFor="multiple">Multiple</label>
            </div>
          </div>

          {/* Number Of Tanks */}
          {fuelSensor === 'multiple' && (
            <div className="mb-4 flex items-center">
              <label htmlFor="numberOfTanks" className="w-1/3 font-semibold">No of Tanks :</label>
              <div className="w-2/3">
                <InputField
                  type="text"
                  id="numberOfTanks"
                  value={numberOfTanks}
                  onChange={handleNumberOfTanksChange}
                  className="w-64 p-2 ml-4 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                />
              </div>
            </div>
          )}

          <div className="mb-4 flex items-center space-x-4">
            <label htmlFor="gSensor" className="w-1/3 font-semibold">G-Sensor :</label>
            <div className="flex items-center">
              <div className="w-2/3">
                <input
                  type="checkbox"
                  id="gSensor"
                  checked={gSensor}
                  onChange={(e) => setGSensor(e.target.checked)}
                  className="mr-2"
                />
              </div>
            </div>
          </div>
          {gSensor && (
            <>
              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="axisX" className="w-1/3 font-semibold">Axis X:</label>
                  <div className="flex flex-col w-1/3 md:w-auto">
                    <InputField
                      type="text"
                      id="axisX"
                      value={axisX}
                      onChange={(e) => setAxisX(e.target.value)}
                      className="w-64 p-2 px-3 ml-4  border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      placeholder="Enter Axis X"
                    />
                    <span className="mt-2 ml-4">
                      <label style={{ cursor: 'pointer' }}>(mG)</label>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="axisY" className="w-1/3 font-semibold">Axis Y:</label>
                  <div className="flex flex-col w-1/3 md:w-auto">
                    <InputField
                      type="text"
                      id="axisY"
                      value={axisY}
                      onChange={(e) => setAxisY(e.target.value)}
                      className="w-64 p-2 px-3  ml-4 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      placeholder="Enter Axis Y"
                    />
                    <span className="mt-2 ml-4">
                      <label style={{ cursor: 'pointer' }}>(mG)</label>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label htmlFor="axisZ" className="w-1/3 font-semibold">Axis Z:</label>
                  <div className="flex flex-col w-1/3 md:w-auto">
                    <InputField
                      type="text"
                      id="axisZ"
                      value={axisZ}
                      onChange={(e) => setAxisZ(e.target.value)}
                      className="w-64 p-2 px-3 ml-4 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                      placeholder="Enter Axis Z"
                    />
                    <span className="mt-2 ml-4">
                      <label style={{ cursor: 'pointer' }}>(mG)</label>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Right column (empty) */}
          <div className=""></div>
          <div className="flex justify-end mt-4">
            <Button
              type="submit"
              className="bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      <div className="w-full md:w-1/3"></div>
    </div>

  );
}

export default ProfileTab;

