import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrash} from 'react-icons/fa';
import Table from '../../../../../../components/table/Table';
// import { createMasterVehicleService } from '../../../../../../services/ServiceFactory';
import { MasterVehicleDataColumn } from '../../../../../../services/interfaces/IMasterVehicleService';
import { VehicleTableInterface } from '../../../../../../utils/interfaces/masterVehicle';
import useHttp from '../../../../../../hooks/useHttp';
import Button from '../../../../../../components/Button';
import { toast } from 'react-toastify';


interface ApiResponse {
  message: string;
  data: VehicleTableInterface[];
}

const VehicleTable: React.FC = () => {
  const [data, setData] = useState<VehicleTableInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { request } = useHttp();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await request({
          url: '/vehicles',
          method: 'GET'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch vehicles');
        }


        const data =await response.json() as ApiResponse;
        setData(data.data);
        // console.log("responsagadfasfe", await response.json());

      } catch (error) {
        if (error instanceof Error) {
          toast.error(`Error: ${error.message}`);
      } else {
          toast.error('An unexpected error occurred');
      }
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, []);

  console.log("uhasdjhsa",data);
  


  // Define actions for each row
  const actions = [
    { name: 'Edit', path: '/company/settings/master/vehicle/newVehicle/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-1' },
    { name: 'Delete', path: '/vehicles/', icon: FaTrash, class: 'text-red-700 text-2xl' },
  ];

  //  column for QR Code with a download button
  const columns = [
    ...MasterVehicleDataColumn,
    // {
    //   name: 'QR Code',
    //   cell: () => (
    //     // <a
    //     //   href={`path/to/qr-code-generator?vehicleId=${row.id}`} // Replace with actual QR code generation/download URL
    //     //   download={`QR_Code_${row.id}.png`} 
    //     //   className="text-blue-700 text-2xl"
    //     //   title="Download QR Code"
    //     // >
    //     <FaDownload />
    //   ),
    //   sortable: false,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    // }
  ];

  return (
    <div className="mt-4">
      <div className="mt-4 text-end">
        <Link to="/company/settings/master/vehicle/newVehicle">
          <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
            New Vehicle
          </Button>
        </Link>
      </div>
      <Table<VehicleTableInterface>
        columns={columns}
        data={data}
        loading={loading}
        tableRowActions={actions}
      />
    </div>
  );
}

export default VehicleTable;
