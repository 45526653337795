import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MultiSelectComponent from '../../../../../components/MultiSelectComponent';
import BackButton from '../../../../../components/BackButton';
import { MultiValue } from 'react-select';
import styles from './CreateDriverRating.module.css';
import { useParams, useLocation } from 'react-router-dom';
import { createDriverDriverRating } from '../../../../../services/ServiceFactory';
import Modal from './showcalculationmodel/ShowCalculationModel';
import { toast } from 'react-toastify';
// import { FormValues as FormValuesdata } from '../../../../../services/interfaces/IDriverRating';

type OptionType = {
    label: string;
    value: string;
};

const options: OptionType[] = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
    { label: 'Option 4', value: 'option4' },
    { label: 'Option 5', value: 'option5' },
    { label: 'Option 6', value: 'option6' },
    { label: 'Option 7', value: 'option7' },
    { label: 'Option 8', value: 'option8' },
    { label: 'Option 9', value: 'option9' },
    { label: 'Option 10', value: 'option10' }
];

type FormValues = {
    basedOn: string;
    object: MultiValue<OptionType>;
    driverRatingName: string;
    overspeedDuration: string;
    speedLimit: number;
    speedIncrease: {
        speedrange1: number;
        speedrange2: number;
        speedrange3: number;
        speedrange4: number;
    };
    harshAcceleration: { acceleration: number; time: number; distance: number };
    harshBraking: { braking: number; time: number; distance: number };
    idlingDuration: string;
    idlingPenalty: number;
};

const validationSchema = Yup.object({
    basedOn: Yup.string().required('Required'),
    // object: Yup.array().min(1, 'At least one object is required').required('Required'),
    // driverRatingName: Yup.string().required('Required'),
    // overspeedDuration: Yup.string().required('Required'),
    // speedLimit: Yup.number().required('Required'),
    // speedIncrease: Yup.object().shape({
    //   'speedrange1': Yup.number().min(0).max(100),
    //   'speedrange2': Yup.number().min(0).max(100),
    //   'speedrange3': Yup.number().min(0).max(100),
    //   'speedrange4': Yup.number().min(0).max(100),
    // }),
    // harshAcceleration: Yup.object().shape({
    //   time: Yup.number().min(0).max(200),
    //   distance: Yup.number().min(0).max(200),
    // }),
    // harshBraking: Yup.object().shape({
    //   time: Yup.number().min(0).max(200),
    //   distance: Yup.number().min(0).max(200),
    // }),
    // idlingDuration: Yup.string().required('Required'),
    // idlingPenalty: Yup.number().min(0).max(100).required('Required')
});

const CreateDriverRatingUI: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const openModal = (): void => {
        setIsModalOpen(true);
    };

    const closeModal = (): void => {
        setIsModalOpen(false);
    };
    const initialValuesForCreate: FormValues = {
        basedOn: 'object',
        object: [],
        driverRatingName: '',
        overspeedDuration: '',
        speedLimit: 0,
        speedIncrease: {
            speedrange1: 0,
            speedrange2: 0,
            speedrange3: 0,
            speedrange4: 0
        },
        harshAcceleration: { acceleration: 0, time: 0, distance: 0 },
        harshBraking: { braking: 0, time: 0, distance: 0 },
        idlingDuration: '',
        idlingPenalty: 0
    };

    const location = useLocation();
    const isEditing: boolean = location.pathname.includes('edit');
    const isCreating = location.pathname.includes('create');
    const isViewing = location.pathname.includes('view');
    const [initialValues, setInitialValues] = useState<FormValues>();
   
    const { id } = useParams<{ id: string }>();
   
    useEffect(() => {
        if (isCreating) {
          setInitialValues(initialValuesForCreate);
        } else if (id) {
          const numericId = Number(id);
          const driverRatingService = createDriverDriverRating();
      
          const fetchData = async () => {
            try {
              const data = await driverRatingService.getDriverRatingDataCreate(numericId);
              setInitialValues({
                basedOn: data[0].basedOn,
                object: data[0].object,
                driverRatingName: data[0].driverRatingName,
                overspeedDuration: data[0].overspeedDuration,
                speedLimit: data[0].speedLimit,
                speedIncrease: data[0].speedIncrease,
                harshAcceleration: data[0].harshAcceleration,
                harshBraking: data[0].harshBraking,
                idlingDuration: data[0].idlingDuration,
                idlingPenalty: data[0].idlingPenalty,
              });
              setSelectedOptions(data[0].object);
            } catch (error) {
              console.log(error);
            }
          };
      
         void  fetchData(); // Call the async function after it's defined
        }
      }, [isCreating, id, initialValuesForCreate]); // Added initialValuesForCreate to the dependency array
      

    const readOnlyCondition = isViewing;
    let  buttonname: string;
    if (isEditing) {
        buttonname='Update';
     }else if(isCreating){
        buttonname='Create';
     }else{
        buttonname='View';
     }
 
    const [activeTab, setActiveTab] = useState<'General' | 'Speeding' | 'Harsh Driving' | 'Idling'>('General');
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>(
        initialValues ? initialValues.object : []
    );

    const handleMultiSelectChange = (selected: MultiValue<OptionType>) => {
        setSelectedOptions(selected);
    };
    // values: FormValues
    const onSubmit = () => {
        toast.success('Add successfully');
        window.location.href='/company/settings/management/driver-rating';
    };
    if (!initialValues) {
        return <div>Loading...</div>; // or a spinner or any loading indicator
    }
   

    return (
        <div className='container mx-auto p-4'>
            <div className='w-full '>
                <div className='tabs flex justify-around border-b-2 mb-4 w-1/2'>
                    {['General', 'Speeding', 'Harsh Driving', 'Idling'].map(tab => (
                        <button
                            key={tab}
                            className={`py-2 px-4 ${activeTab === tab ? 'border-b-4 border-blue-500' : ''}`}
                            onClick={() => setActiveTab(tab as 'General' | 'Speeding' | 'Harsh Driving' | 'Idling')}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ isValid }) => (
                    <Form className='text-lg'>
                        <div className='mt-10 grid justify-items-center'>
                            {activeTab === 'General' && (
                                <div>
                                    <div className='form-group'>
                                        <label className='mr-16' htmlFor='basedOn'>
                                            Based on
                                        </label>
                                        <div className='ml-4'>
                                            <label className='mr-4'>
                                                <Field type='radio' name='basedOn' value='object' className='mr-2' />
                                                Object
                                            </label>
                                            <label>
                                                <Field type='radio' name='basedOn' value='option2' className='mr-2' />
                                                Option 2
                                            </label>
                                        </div>
                                        <ErrorMessage name='basedOn' component='div' className='text-red-500' />
                                    </div>

                                    <div className=' flex form-group mt-3'>
                                        <label className='mr-24' htmlFor='object'>
                                            Object*
                                        </label>
                                        <MultiSelectComponent
                                            options={options}
                                            selectedOptions={selectedOptions}
                                            onChange={handleMultiSelectChange}
                                            placeholder='Choose your options'
                                        />
                                        <ErrorMessage name='object' component='div' className={styles.error} />
                                    </div>
                                    <div></div>
                                    <div className='form-group mt-4'>
                                        <label className='mr-3' htmlFor='driverRatingName'>
                                            Driver Rating Name
                                        </label>
                                        <Field
                                            type='text'
                                            name='driverRatingName'
                                            className='form-control h-9 border border-gray-300 rounded w-64'
                                            readOnly={readOnlyCondition}
                                        />
                                        <ErrorMessage
                                            name='driverRatingName'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>
                                </div>
                            )}

                            {activeTab === 'Speeding' && (
                                <div>
                                    <div className='form-group mt-3'>
                                        <label className='mr-28 ' htmlFor='overspeedDuration'>
                                            Overspeed Duration:
                                        </label>
                                        <Field
                                            type='text'
                                            name='overspeedDuration'
                                            className='form-control h-[30px] '
                                            placeholder='HH:MM:SS'
                                            readOnly={readOnlyCondition}
                                        />
                                        <ErrorMessage
                                            name='overspeedDuration'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>
                                    <div className='form-group mt-3'>
                                        <label className='mr-40 ' htmlFor='speedLimit'>
                                            Speed Limit:
                                        </label>
                                        <Field
                                            type='number'
                                            name='speedLimit'
                                            className='form-control mx-4 mt-2 h-[30px]'
                                            readOnly={readOnlyCondition}
                                        />
                                        <ErrorMessage name='speedLimit' component='div' className={styles.error} />
                                    </div>
                                    <div className='form-group mt-3'>
                                        <label className='mr-10 text-xl'>Speed Increase by km/h</label>
                                        <label className='ml-14  text-xl'>Penalty Points</label>

                                        <div className='form-group mt-3'>
                                            <label className='mr-24'>50 - 70</label>
                                            <Field
                                                type='range'
                                                name='speedIncrease.speedrange1'
                                                min='1'
                                                max='100'
                                                className='ml-10 w-60'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='speedIncrease.speedrange1'
                                                min='1'
                                                max='100'
                                                className='ml-4 w-11'
                                                readOnly
                                            />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label className='mr-24'>70 - 80</label>
                                            <Field
                                                type='range'
                                                name='speedIncrease.speedrange2'
                                                min='1'
                                                max='100'
                                                className='ml-10 w-60'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='speedIncrease.speedrange2'
                                                min='1'
                                                max='100'
                                                className='ml-4 w-11'
                                                readOnly
                                            />

                                            <span></span>
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label className='mr-24'>80 -100</label>
                                            <Field
                                                type='range'
                                                name='speedIncrease.speedrange3'
                                                min='1'
                                                max='100'
                                                className='ml-10 w-60'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='speedIncrease.speedrange3'
                                                min='1'
                                                max='100'
                                                className='ml-2 w-11'
                                                readOnly
                                            />

                                            <span></span>
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label className='mr-20'>More Than 100</label>
                                            <Field
                                                type='range'
                                                name='speedIncrease.speedrange3'
                                                min='1'
                                                max='100'
                                                className='w-60'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='speedIncrease.speedrange4'
                                                min='1'
                                                max='100'
                                                className='ml-2 w-11'
                                                readOnly
                                            />

                                            <span></span>
                                        </div>
                                    </div>

                                   
                                </div>
                            )}

                            {activeTab === 'Harsh Driving' && (
                                <div>
                                    <div className='form-group flex mt-5'>
                                        <label className='mx-2'>Harsh Acceleration</label>
                                        <div className=''>
                                            <Field
                                                type='range'
                                                name='harshAcceleration.acceleration'
                                                min='0'
                                                max='200'
                                                className='form-control w-60 mx-4  h-[30px]'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='harshAcceleration.acceleration'
                                                placeholder=''
                                                className='form-control mx-4 w-12  h-[30px]'
                                                readOnly
                                            />
                                            <Field
                                                type='number'
                                                name='harshAcceleration.time'
                                                placeholder='second'
                                                className='form-control mx-4 w-13 h-[30px] '
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='harshAcceleration.distance'
                                                placeholder='km'
                                                className='form-control mx-4 h-[30px] '
                                                readOnly={readOnlyCondition}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group flex mt-5'>
                                        <label className='mx-2 mr-10'>Harsh Braking</label>
                                        <div className=''>
                                            <Field
                                                type='range'
                                                name='harshBraking.braking'
                                                min='0'
                                                max='200'
                                                className='form-control mx-4 w-60 h-[30px]'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='harshBraking.braking'
                                                placeholder=''
                                                className='form-control mx-4 w-12 h-[30px] '
                                                readOnly
                                            />
                                            <Field
                                                type='number'
                                                name='harshBraking.time'
                                                placeholder='second'
                                                className='form-control mx-4 w-13  h-[30px]'
                                                readOnly={readOnlyCondition}
                                            />
                                            <Field
                                                type='number'
                                                name='harshBraking.distance'
                                                placeholder='km'
                                                className='form-control mx-4 h-[30px]'
                                                readOnly={readOnlyCondition}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type='button'
                                        className={styles.showCalculationButton}
                                        onClick={openModal}
                                    >
                                        Show Calculation
                                    </button>
                                </div>
                            )}

                            {activeTab === 'Idling' && (
                                <div>
                                    <div className='form-group mt-3'>
                                        <label className='mx-2 mr-24' htmlFor='idlingDuration'>
                                            Idling Duration
                                        </label>
                                        <Field
                                            type='text'
                                            name='idlingDuration'
                                            className='form-control mx-4 mt-2 h-[30px]'
                                            placeholder='HH:MM:SS'
                                            readOnly={readOnlyCondition}
                                        />
                                        <ErrorMessage name='idlingDuration' component='div' className={styles.error} />
                                    </div>
                                    <span className='block text-center w-full text-xl mt-4'>Penalty Points</span>

                                    <div className='form-group mt-3'>
                                        <label className='mx-2' htmlFor='idlingPenalty'>
                                            Idling Penalty{' '}
                                        </label>
                                        <Field
                                            type='range'
                                            name='idlingPenalty'
                                            min='0'
                                            max='100'
                                            className='form-control mx-4 mt-2 h-[30px] w-60'
                                            readOnly={readOnlyCondition}
                                        />
                                        <Field type='number' name='idlingPenalty' className='w-10' readOnly />
                                        <ErrorMessage name='idlingPenalty' component='div' className={styles.error} />
                                    </div>
                                    <button
                                        type='button' name='showModel'
                                        className={styles.showCalculationButton}
                                        onClick={openModal}
                                    >
                                        Show Calculation
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className='absolute bottom-0.5 flex justify-between mt-4 w-5/6'>
                           <div> <BackButton label='Go Back' className='' /></div>
                           <div>
                            <button type='submit' className={`${styles.buttonClass} `} disabled={!isValid ||  isViewing}>
                               {buttonname}     </button>
                                    <div/>
                                    <div/>
                                    </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};


export default CreateDriverRatingUI;
