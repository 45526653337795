import React from 'react';
import GeofenceMap from './GeofenceMap';

import { AiFillDashboard } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const CreateGeofance: React.FC = () => {
    return (
        <>
            <div className='flex justify-between relative mx-5 '>
                <h1 className='text-2xl text-gray-800'>Vehicle Geofance</h1>
                <div className='flex  absolute right-1'>
                    <Link to='/company/dashboard' className='flex my-auto text-xl mx-2 text-center'>
                        {' '}
                        <AiFillDashboard className='mt-1 mx-2' />
                        <h1>Home</h1>
                    </Link>
                    <Link to='/company/settings/management/geofence' className='text-xl '>
                        Vehicle Geofance{' '}
                    </Link>
                </div>
            </div>
            <div className='container max-auto p-2 bg-white border border-t-[3px] border-t-blue-700 w-full m-auto '>
                <GeofenceMap />
            </div>
        </>
    );
};

export default CreateGeofance;
