import { IExpense, IExpenseService } from './interfaces/IExpense';
import { expenseData } from './dummyData/ExpenseData';

export class ExpenseService implements IExpenseService {
    getExpense(): Promise<IExpense[]> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(expenseData);
            }, 1000);
        });
    }
}
