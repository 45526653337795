import React, { useState, useEffect } from 'react';
import Dropdown from '../../../../../components/Dropdown';
import Button from '../../../../../components/Button';
import InputField from '../../../../../components/InputFeild';
import Input from '../../../../../components/Input';
import { Link } from 'react-router-dom';

interface DriverAdditionalinformation {
    additionalinfoAction: string;
    employeeDesignation: string;
    tagVia: string;
    shiftGroup: string;
    driverAge: string;
    dateOfJoining: string;
    dateOfLeaving: string;
    drivingExpireSince: string;
    licenceAvailabel: string;
    licenceToDrive: string;
    IFEinsurenceNO: string;
    lifeInsurenceExpireDate: string;
    medicalNo: string;
    medicalExpireDate: string;
}

interface DriverAdditionalinfoProps {
    data: DriverAdditionalinformation;
}

const options = [
    { value: 'Driver', label: 'Driver' },
    { value: 'Employee', label: 'Employee' },
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Superviser', label: 'Superviser' },
    { value: 'Mechanic', label: 'Mechanic' }
];
const options2 = [
    { value: 'Beacon', label: 'Beacon' },
    { value: 'RFID/Button', label: 'RFID/Button' },
    { value: 'Mobile Application', label: 'Mobile Application' }
];
const options3 = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
];
function DriverAdditionalinfo({ data }: DriverAdditionalinfoProps) {
    const [designation, setDesignation] = useState('');
    const [shift, setShift] = useState('');
    const [tag, setTag] = useState('');
    const [age, setAge] = useState('');
    const [joiningDate, setJoiningDate] = useState<Date | null>(null);
    const [leavingDate, setLeavingDate] = useState<Date | null>(null);
    const [drivingExpiry, setDrivingExpiry] = useState<Date | null>(null);
    const [licence, setLicence] = useState('');
    const [licenceToDrive, setLicenceToDrive] = useState('');
    const [ifeInsuranceNo, setIfeInsuranceNo] = useState('');
    const [lifeInsurenceExpireDate, setLifeInsuranceExpireDate] = useState<Date | null>(null);
    const [medicalNo, setMedicalNo] = useState('');
    const [medicalExpireDate, setMedicalExpireDate] = useState<Date | null>(null);
    const [action, setAction] = useState('');

    useEffect(() => {
        if (data) {
            setAction(data.additionalinfoAction);
            setDesignation(data.employeeDesignation);
            setShift(data.shiftGroup);
            setTag(data.tagVia);
            setAge(data.driverAge);
            setJoiningDate(data.dateOfJoining ? new Date(data.dateOfJoining) : null);
            setLeavingDate(data.dateOfLeaving ? new Date(data.dateOfLeaving) : null);
            setDrivingExpiry(data.drivingExpireSince ? new Date(data.drivingExpireSince) : null);
            setLicence(data.licenceAvailabel);
            setLicenceToDrive(data.licenceToDrive);
            setIfeInsuranceNo(data.IFEinsurenceNO);
            setLifeInsuranceExpireDate(data.lifeInsurenceExpireDate ? new Date(data.lifeInsurenceExpireDate) : null);
            setMedicalNo(data.medicalNo);
            setMedicalExpireDate(data.medicalExpireDate ? new Date(data.medicalExpireDate) : null);
        } else {
            setAction('');
            setDesignation('');
            setShift('');
            setTag('');
            setAge('');
            setJoiningDate(null);
            setLeavingDate(null);
            setDrivingExpiry(null);
            setLicence('');
            setLicenceToDrive('');
            setIfeInsuranceNo('');
            setLifeInsuranceExpireDate(null);
            setMedicalNo('');
            setMedicalExpireDate(null);
        }
    }, [data]);

    const handleJoiningDate = (date: Date | null) => {
        setJoiningDate(date);
    };
    const handleLeavingDate = (date: Date | null) => {
        setLeavingDate(date);
    };
    const handleDrivingExpiryDate = (date: Date | null) => {
        setDrivingExpiry(date);
    };
    const handleLifeInsuranceExpireDate = (date: Date | null) => {
        setLifeInsuranceExpireDate(date);
    };
    const handleMedicalExpireDate = (date: Date | null) => {
        setMedicalExpireDate(date);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simple validation logic
        if (
            !designation||
            !shift ||
            !tag||
            !age ||
            !joiningDate ||
            !leavingDate ||
            !drivingExpiry ||
            !licence||
            !licenceToDrive ||
            !ifeInsuranceNo ||
            !lifeInsurenceExpireDate ||
            !medicalNo ||
            !medicalExpireDate
        ) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            driverDesignation:designation,
            driverShift: shift,
            driverTag: tag,
            driverAge: age,
            driverJoiningDate: joiningDate,
            driverLeavingDate: leavingDate,
            driverExpiry: drivingExpiry,
            drivingLicence: licence,
            licenceToDrive: licenceToDrive,
            ifeInsuranceNo: ifeInsuranceNo,
            lifeInsurenceExpireDate: lifeInsurenceExpireDate,
            medicalNo: medicalNo,
            medicalExpireDate: medicalExpireDate
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 lg:w-[40%] w-[50%] mx-auto mt-2'>
                    <div>
                        <div className='text-sm mb-1 font-semibold'>
                            <label htmlFor='designation'>Select Employee Designation:</label>
                        </div>
                        <Dropdown
                            options={options}
                            onSelect={(selectedValue) => setDesignation(selectedValue)}
                            selectedValue={designation}
                            placeholder='Select Designation'
                            selectClass='rounded-none h-[38px]'
                            selectStyle={{
                                backgroundColor: 'white',
                                fontSize: '12px', // Decrease the font size
                                padding: '8px' // Adjust padding to make it smaller
                            }}
                            optionClass='my-custom-option-class'
                            optionStyle={{
                                fontSize: '12px', // Decrease the font size of options
                                padding: '6px' // Adjust padding of options
                            }}
                        />
                    </div>
                    <div className='mt-2'>
                        <div className='text-sm mb-1 font-semibold'>
                            <label htmlFor='tag'>Select Tag Via:</label>
                        </div>
                        <Dropdown
                            options={options2}
                            onSelect={(selectedValue) => setTag(selectedValue)}
                            selectedValue={tag}
                            placeholder='Select Tag'
                            selectClass='rounded-none h-[38px]'
                            selectStyle={{
                                backgroundColor: 'white',
                                fontSize: '12px', // Decrease the font size
                                padding: '8px' // Adjust padding to make it smaller
                            }}
                            optionClass='my-custom-option-class'
                            optionStyle={{
                                fontSize: '12px', // Decrease the font size of options
                                padding: '6px' // Adjust padding of options
                            }}
                        />
                    </div>

                    <div className='mt-2'>
                        <div className='text-sm mb-1 font-semibold'>
                            <label htmlFor='shiftGroup'>Shift Group:</label>
                        </div>

                        <InputField
                            id='shiftgroup'
                            type='text'
                            value={shift}
                            onChange={e => setShift(e.target.value)}
                            className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            disabled={action === 'view'}
                        />
                    </div>
                    <div className='mt-2'>
                        <div className='text-sm mb-1 font-semibold'>
                            <label htmlFor='age'>Age:</label>
                        </div>

                        <InputField
                            id='age'
                            value={age}
                            onChange={e => setAge(e.target.value)}
                            type='text'
                            className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            disabled={action === 'view'}
                        />
                    </div>

                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-3 mt-3'>
                        <div>
                            <Input
                                type='date'
                                id='joiningdate'
                                value={joiningDate}
                                onChange={e => (action === 'view' ? undefined : handleJoiningDate(e as Date | null))}
                                placeholder='Date of Joining:'
                                labelChildren='Date of Joining:'
                                error={false}
                                className='border border-gray-300 px-10 py-1.5'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <Input
                                type='date'
                                id='livingdate'
                                value={leavingDate}
                                onChange={e => (action === 'view' ? undefined : handleLeavingDate(e as Date | null))}
                                placeholder='Date of Leaving:'
                                labelChildren='Date of Leaving:'
                                error={false}
                                className='border border-gray-300 px-10 py-1.5'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <Input
                                type='date'
                                id='expiredate'
                                value={drivingExpiry}
                                onChange={e =>
                                    action === 'view' ? undefined : handleDrivingExpiryDate(e as Date | null)
                                }
                                placeholder='Driving Expire Since:'
                                labelChildren='Driving Expire Since:'
                                error={false}
                                className='border border-gray-300 px-10 py-1.5'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <div className='text-sm mb-1 font-semibold'>
                                <label htmlFor='licence'>Licence Available:</label>
                            </div>
                            <Dropdown
                                options={options3}
                                onSelect={(selectedValue) => setLicence(selectedValue)}
                                selectedValue={licence}
                                placeholder='Select Licence'
                                selectClass='rounded-none '
                                selectStyle={{
                                    backgroundColor: 'white',
                                    fontSize: '12px', // Decrease the font size
                                    padding: '10px' // Adjust padding to make it smaller
                                }}
                                optionClass='my-custom-option-class'
                                optionStyle={{
                                    fontSize: '12px', // Decrease the font size of options
                                    padding: '6px' // Adjust padding of options
                                }}
                            />
                        </div>
                    </div>

                    <div className='mt-2'>
                        <div className='text-sm mb-1 font-semibold'>
                            <label htmlFor='licenseToDrive'>Licence to Drive:</label>
                        </div>

                        <InputField
                            id='licencetodriver'
                            value={licenceToDrive}
                            onChange={e => setLicenceToDrive(e.target.value)}
                            type='text'
                            className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            disabled={action === 'view'}
                        />
                    </div>

                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-3 mt-3'>
                        <div>
                            <div className='text-sm mb-1 font-semibold'>
                                <label htmlFor='insuranceNumber'>IFE Insurance Number:</label>
                            </div>

                            <InputField
                                id='ifeinsurancenumber'
                                value={ifeInsuranceNo}
                                onChange={e => setIfeInsuranceNo(e.target.value)}
                                type='text'
                                className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <Input
                                type='date'
                                id='ensurencedate'
                                value={lifeInsurenceExpireDate}
                                onChange={e =>
                                    action === 'view' ? undefined : handleLifeInsuranceExpireDate(e as Date | null)
                                }
                                placeholder='Life Insurance Expire Date:'
                                labelChildren='Life Insurance Expire Date:'
                                error={false}
                                className='border border-gray-300 px-10 py-1.5'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <div className='text-sm mb-1 font-semibold'>
                                <label htmlFor='medicalNumber'>Medical Number:</label>
                            </div>

                            <InputField
                                id='medicalnumber'
                                value={medicalNo}
                                onChange={e => setMedicalNo(e.target.value)}
                                type='text'
                                className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                disabled={action === 'view'}
                            />
                        </div>
                        <div>
                            <Input
                                type='date'
                                id='mediexpiredate'
                                value={medicalExpireDate}
                                onChange={e =>
                                    action === 'view' ? undefined : handleMedicalExpireDate(e as Date | null)
                                }
                                placeholder='Medical Expire Date:'
                                labelChildren='Medical Expire Date:'
                                error={false}
                                className='border border-gray-300 p-1.5 px-10'
                                disabled={action === 'view'}
                            />
                        </div>
                    </div>
                    <div className='flex items-center space-x-2 mt-3'>
                        <input type='checkbox' className='w-4 h-4' />
                        <label htmlFor='active' className='mr-2 text-sm mb-1 font-semibold'>
                            Active
                        </label>
                    </div>
                </div>

                <div className='bg-white h-16 mt-4'>
                    <div className='bg-blue-700 h-[2px] rounded-tr-lg rounded-tl-lg'></div>
                    <div className='flex gap-4 justify-end mt-4 mr-2'>
                        <Link to={'/company/settings/master/driver'}>
                        <Button
                            type='button'
                            className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                        >
                            Back
                        </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DriverAdditionalinfo;
