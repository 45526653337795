import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Information from "./Information";
import Password from "./Password";

// import Information from "./Information";

function Profile() {


  const [value, setValue] = React.useState(0);


  
  return (
    <div>
      <div className="md:flex justify-between items-center">
        <div>
          <h1 className="font-semibold text-2xl text-gray-700">
            Profile Update
          </h1>
        </div>
        <div className="flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300">

        
          <ul>
            <li>
              <Link to="/company/dashboard" className="flex items-center space-x-2">
                <DashboardIcon />
                <span>Dashboard</span>
              </Link>
            </li>
          </ul>
          <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: "7px" }} />
          <p className="ml-2">Profile</p>
        </div>
      </div>
      <div className="bg-white w-[100%] h-[100%] rounded-t-md mt-6">
        <div className="flex gap-1">
          
            <button
              onClick={() => setValue(0)}
              className={`w-[100px] h-[40px] ${
                value === 0 
                  ? " border-t-blue-500 border-t-2 border-r border-r-gray-300"
                  : "hover:text-gray-500"
              }`}
            >
              Information
            </button>
          
          
            <button
              onClick={() => setValue(1)}
              className={`w-[100px] h-[40px] ${
                value === 1
                  ? " border-t-blue-500 border-t-2 border-r border-l  border-r-gray-300 border-l-gray-300"
                  : "hover:text-gray-500"
              }`}
            >
              Password
            </button>
          
        </div>
        <hr />
        {value === 0 && <Information/> }
        {value === 1 && <Password/> }

        
      </div>
    </div>
  );
}

export default Profile;
