import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import MultiselectDropdown from '../../../components/MutliselectDropdown';
import { Button, Grid } from '@mui/material';
import Input from '../../../components/Input';
import InputField from '../../../components/InputFeild';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';



interface Option {
    value: string;
    label: string;
}

interface DashboardModalProps {
    open: boolean;
    onClose: () => void;
}

const DashboardModal: React.FC<DashboardModalProps> = ({ open, onClose }) => {
    const [objectGroup, setObjectGroup] = useState<string[]>([]);
    const [objectType, setObjectType] = useState<string[]>([]);
    const [vehicle, setVehicle] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [search, setSearch] = useState<string>('');

    const objectGroupOptions: Option[] = [
        { value: 'group1', label: 'Group 1' },
        { value: 'group2', label: 'Group 2' },
    ];

    const objectTypeOptions: Option[] = [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
    ];

    const vehicleOptions: Option[] = [
        { value: 'vehicle1', label: 'Vehicle 1' },
        { value: 'vehicle2', label: 'Vehicle 2' },
    ];

    const handleSaveChanges = () => {
        console.log("Save Changes Button Clicked");
        console.log({
            objectGroup,
            objectType,
            vehicle,
            fromDate,
            toDate,
            search
        });
    };

    const handleDeleteChanges = () => {
        console.log("Delete Changes Button Clicked");
    };

    const handleApplyChanges = () => {
        console.log("Apply Changes Button Clicked");
        console.log({
            objectGroup,
            objectType,
            vehicle,
            fromDate,
            toDate,
            search
        });
    };

    const handleDateChange = (setter: React.Dispatch<React.SetStateAction<Date | null>>) =>
        (e: React.ChangeEvent<HTMLInputElement> | Date | null) => {
            if (e instanceof Date || e === null) {
                setter(e); // Handle direct Date objects (e.g., from custom date pickers)
            } else {
                const date = e.target.value ? new Date(e.target.value) : null;
                setter(date);
            }
        };

    return (
        <Modal isOpen={open} onClose={onClose} title="Filter Options">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div className="mb-1">
                        <label className="block text-gray-700 font-semibold text-sm">Object Group : </label>
                        <MultiselectDropdown
                            options={objectGroupOptions}
                            selectedValues={objectGroup}
                            onSelect={setObjectGroup}
                            placeholder="Select Object Group"
                            width='16rem'
                        />
                    </div>
                    <div className="mb-1">
                        <label className="block text-gray-700 font-semibold text-sm">Object Type : </label>
                        <MultiselectDropdown
                            options={objectTypeOptions}
                            selectedValues={objectType}
                            onSelect={setObjectType}
                            placeholder="Select Object Type"
                            width='16rem'
                        />
                    </div>
                    <div className="mb-1">
                        <label className="block text-gray-700 font-semibold text-sm">Vehicle :  </label>
                        <MultiselectDropdown
                            options={vehicleOptions}
                            selectedValues={vehicle}
                            onSelect={setVehicle}
                            placeholder="Select Vehicle"
                            width='16rem'
                        />
                    </div>
                    <Input
                        type="date"
                        id="fromDate"
                        placeholder="From Date"
                        value={fromDate ? fromDate.toISOString().split('T')[0] : ''}
                        onChange={handleDateChange(setFromDate)}
                        labelHtmlFor="fromDate"
                        labelClassName="block text-gray-700 font-semibold text-sm"
                        labelChildren="From Date"
                    />
                    <Input
                        type="date"
                        id="toDate"
                        placeholder="To Date"
                        value={toDate ? toDate.toISOString().split('T')[0] : ''}
                        onChange={handleDateChange(setToDate)}
                        labelHtmlFor="toDate"
                        labelClassName="block text-gray-700 font-semibold text-sm"
                        labelChildren="To Date"
                    />
                    <div className='mt-4'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                variant="contained"
                                style={{ width: '48%', fontSize: '0.875rem', backgroundColor: '#07163d', color: '#fff' }}
                                onClick={handleSaveChanges}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                style={{ width: '48%', fontSize: '0.875rem', backgroundColor: '#07163d', color: '#fff' }}
                                onClick={handleDeleteChanges}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', position: 'relative' }}>
                        <SearchOutlinedIcon className="absolute left-2 top-12 transform -translate-y-1/2 text-gray-400" />
                        <InputField
                            type="text"
                            placeholder="Search.."
                            value={search}
                            onChange={(e: React.ChangeEvent<HTMLInputElement> | Date | null) => {
                                if (e instanceof Date || e === null) return;
                                setSearch(e.target.value);
                            }}
                            style={{ flexGrow: 1, paddingLeft: '40px' }}
                            inputKey="search"
                            labelHtmlFor="search"
                            labelClassName="block w-96 text-gray-700 font-semibold mb-1 text-sm"
                            labelChildren=" Object Selection : "
                        />
                        <div
                            onClick={() => console.log('Cached icon clicked')}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '10px',
                                marginTop: '25px',
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                                borderRadius: '4px',
                                backgroundColor: '#07163d', 
                            }}
                        >
                            <CachedSharpIcon style={{ color: '#fff' }} />
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        style={{ marginTop: '70px', fontSize: '0.875rem', backgroundColor: '#07163d', color: '#fff' }}
                        onClick={handleApplyChanges}
                    >
                        Apply
                    </Button>
                </Grid>

            </Grid>
        </Modal>
    );
};

export default DashboardModal;
