import React, { useState } from 'react';
import Input from '../../../../../../../components/Input';
import Button from '../../../../../../../components/Button';

interface Row {
  id: number;
  validFrom: Date | null;
  validTo: Date | null;
  fromTime: string | null;
  toTime: string | null;
}

const PrivateModeComponent = () => {
  const [rows, setRows] = useState<Row[]>([
    {
      id: 1,
      validFrom: null,
      validTo: null,
      fromTime: null,
      toTime: null
    }
  ]);

  const addRow = () => {
    const newRow: Row = {
      id: rows.length + 1,
      validFrom: null,
      validTo: null,
      fromTime: null,
      toTime: null
    };
    setRows([...rows, newRow]);
  };

  const removeRow = (idToRemove: number) => {
    const updatedRows = rows.filter(row => row.id !== idToRemove);
    setRows(updatedRows);
  };

  const handleDateChange = (date: Date | null, field: keyof Omit<Row, 'fromTime' | 'toTime'>, id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [field]: date };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleTimeChange = (time: string | null, field: keyof Pick<Row, 'fromTime' | 'toTime'>, id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [field]: time };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    console.log('Form values:', rows);
    // Add logic for submitting the form data
  };

  return (
    <div className="container mx-auto p-1">
      <h3 className="text-xl font-bold mb-4">Private Mode</h3>
      <form onSubmit={handleSubmit}>
        <div className="container mt-4 overflow-x-auto">
          <table className="table-auto w-full mb-4">
            <thead>
              <tr>
                <th className="border px-4 py-2 w-1/5">Valid From</th>
                <th className="border px-4 py-2 w-1/5">Valid To</th>
                <th className="border px-4 py-2 w-1/5">From Time</th>
                <th className="border px-4 py-2 w-1/5">To Time</th>
                <th className="border px-4 py-2 w-1/5">Action</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                <td className="border px-4 py-2">
                  <Input
                    type="date"
                    id={`validFrom-${row.id}`}
                    value={row.validFrom}
                    onChange={(date) => handleDateChange(date as Date | null, 'validFrom', row.id)}
                    placeholder="DD/MM/YYYY"
                    className="form-control w-full border-2 border-gray-200 rounded-sm p-2 focus:border-sky-500 focus:outline-none"
                  />
                </td>
                <td className="border px-4 py-2">
                  <Input
                    type="date"
                    id={`validTo-${row.id}`}
                    value={row.validTo}
                    onChange={(date) => handleDateChange(date as Date | null, 'validTo', row.id)}
                    placeholder="DD/MM/YYYY"
                    className="form-control w-full border-2 border-gray-200 rounded-sm p-2 focus:border-sky-500 focus:outline-none"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="time"
                    id={`fromTime-${row.id}`}
                    value={row.fromTime || ''}
                    onChange={(e) => handleTimeChange(e.target.value, 'fromTime', row.id)}
                    className="form-control w-full border-2 border-gray-200 rounded-sm p-2 focus:border-sky-500 focus:outline-none"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="time"
                    id={`toTime-${row.id}`}
                    value={row.toTime || ''}
                    onChange={(e) => handleTimeChange(e.target.value, 'toTime', row.id)}
                    className="form-control w-full border-2 border-gray-200 rounded-sm p-2 focus:border-sky-500 focus:outline-none"
                  />
                </td>
                <td className="border px-4 py-2">
                  <Button
                    type="button"
                    className="btn btn-danger bg-red-500 text-white px-4 py-2 rounded w-full"
                    onClick={() => removeRow(row.id)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Button
          type="button"
          className="px-4 py-2 bg-blue-950 text-white rounded-sm hover:bg-blue-800"
          onClick={addRow}
        >
          Add Row
        </Button>
        <div className="flex justify-end mt-4 space-x-4">
          <Button
            type="submit"
            className="bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md  "
          >
            Submit
          </Button>
          <Button
            type="button"
            className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
          >
            Back
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PrivateModeComponent;

