import React from 'react';
import Table from '../../../../components/table/Table';
import { useState,useEffect } from 'react';
import {createObjectAlertReportService} from '../../../../services/ServiceFactory';
 import BackButton from '../../../../components/BackButton';
import { IObjectAlertReportSingle,ObjectAlertDataColumnSingle } from '../../../../services/interfaces/IObjectService';
const ObjectAlertSingleReportUI: React.FC = () => {

// send data for filter report
const [loading, setLoading] = useState(false);

  const [data,setData]=  useState<IObjectAlertReportSingle[]>([]);
  
  const geofanceReportService = createObjectAlertReportService();
  
  useEffect(() => {
    const fetchData=async()=>{
      try{
      const data = await geofanceReportService.getObjecteAlertSingle();
      setData(data);}
      catch(error){console.log(error);

      }finally{
        setLoading(false);
      }
      
    }
 void fetchData();
  }, []);
 
  return (
    <>
      <p> 
        <h1 className="text-black-700 text-xl">Object Alert Report</h1>
               <BackButton label="Go Back" className=" absolute float-end  right-9 top-3" />
    </p>
      <Table<IObjectAlertReportSingle>   data={data} loading={loading} columns={ObjectAlertDataColumnSingle}  excelExport={true} pdfExport={true} searchAble={true}/>
    </>
  );
};

export default ObjectAlertSingleReportUI;
