import {IDeriverRatingReport,IDeriverWorkHourReport}from "../interfaces/IDriverBehaviourReport";

export const driverRatingReports:IDeriverRatingReport[] = [
    {
        id: 1,
        company: "ABC Logistics",
        branch: "New York",
        employeeName: "John Doe",
        mobileNumber: "1234567890",
        employeeId: "EMP001",
        dateOfJoining: "2021-01-15",
        tagVia: "RFID",
        rfidBeaconTagNo: "RFID123456",
        designation: "Driver",
        shiftTime: "08:00 AM - 04:00 PM",
        distance: "150 km",
        running: "4 hours",
        idle: "1 hour",
        stop: "30 minutes",
        noOfOverSpeeding: 2,
        noOfHarshDriving: 1,
        totalViolations: 3,
        ratings: "A"
    },
    {
        id: 2,
        company: "XYZ Transport",
        branch: "Los Angeles",
        employeeName: "Jane Smith",
        mobileNumber: "2345678901",
        employeeId: "EMP002",
        dateOfJoining: "2020-05-20",
        tagVia: "Beacon",
        rfidBeaconTagNo: "BEACON234567",
        designation: "Driver",
        shiftTime: "10:00 AM - 06:00 PM",
        distance: "200 km",
        running: "5 hours",
        idle: "1.5 hours",
        stop: "20 minutes",
        noOfOverSpeeding: 0,
        noOfHarshDriving: 0,
        totalViolations: 0,
        ratings: "A+"
    },
    {
        id: 3,
        company: "DEF Freight",
        branch: "Chicago",
        employeeName: "Michael Johnson",
        mobileNumber: "3456789012",
        employeeId: "EMP003",
        dateOfJoining: "2019-09-10",
        tagVia: "RFID",
        rfidBeaconTagNo: "RFID345678",
        designation: "Driver",
        shiftTime: "12:00 PM - 08:00 PM",
        distance: "175 km",
        running: "4.5 hours",
        idle: "1 hour",
        stop: "45 minutes",
        noOfOverSpeeding: 1,
        noOfHarshDriving: 2,
        totalViolations: 3,
        ratings: "B"
    },
    {
        id: 4,
        company: "GHI Transport",
        branch: "Houston",
        employeeName: "Emily Davis",
        mobileNumber: "4567890123",
        employeeId: "EMP004",
        dateOfJoining: "2018-07-22",
        tagVia: "Beacon",
        rfidBeaconTagNo: "BEACON456789",
        designation: "Driver",
        shiftTime: "06:00 AM - 02:00 PM",
        distance: "220 km",
        running: "6 hours",
        idle: "30 minutes",
        stop: "15 minutes",
        noOfOverSpeeding: 3,
        noOfHarshDriving: 1,
        totalViolations: 4,
        ratings: "B+"
    },
    {
        id: 5,
        company: "JKL Freight",
        branch: "Phoenix",
        employeeName: "David Brown",
        mobileNumber: "5678901234",
        employeeId: "EMP005",
        dateOfJoining: "2022-03-18",
        tagVia: "RFID",
        rfidBeaconTagNo: "RFID567890",
        designation: "Driver",
        shiftTime: "02:00 PM - 10:00 PM",
        distance: "190 km",
        running: "5 hours",
        idle: "1.5 hours",
        stop: "10 minutes",
        noOfOverSpeeding: 1,
        noOfHarshDriving: 0,
        totalViolations: 1,
        ratings: "A"
    },
    {
        id: 6,
        company: "MNO Logistics",
        branch: "Philadelphia",
        employeeName: "Sophia Martinez",
        mobileNumber: "6789012345",
        employeeId: "EMP006",
        dateOfJoining: "2020-11-30",
        tagVia: "Beacon",
        rfidBeaconTagNo: "BEACON678901",
        designation: "Driver",
        shiftTime: "04:00 PM - 12:00 AM",
        distance: "210 km",
        running: "5.5 hours",
        idle: "1 hour",
        stop: "30 minutes",
        noOfOverSpeeding: 2,
        noOfHarshDriving: 1,
        totalViolations: 3,
        ratings: "B"
    },
    {
        id: 7,
        company: "PQR Transport",
        branch: "San Antonio",
        employeeName: "Chris Wilson",
        mobileNumber: "7890123456",
        employeeId: "EMP007",
        dateOfJoining: "2019-12-12",
        tagVia: "RFID",
        rfidBeaconTagNo: "RFID789012",
        designation: "Driver",
        shiftTime: "08:00 AM - 04:00 PM",
        distance: "160 km",
        running: "4 hours",
        idle: "2 hours",
        stop: "15 minutes",
        noOfOverSpeeding: 0,
        noOfHarshDriving: 0,
        totalViolations: 0,
        ratings: "A+"
    },
    {
        id: 8,
        company: "STU Logistics",
        branch: "San Diego",
        employeeName: "Olivia Garcia",
        mobileNumber: "8901234567",
        employeeId: "EMP008",
        dateOfJoining: "2021-08-25",
        tagVia: "Beacon",
        rfidBeaconTagNo: "BEACON890123",
        designation: "Driver",
        shiftTime: "06:00 PM - 02:00 AM",
        distance: "230 km",
        running: "5 hours",
        idle: "1.5 hours",
        stop: "10 minutes",
        noOfOverSpeeding: 1,
        noOfHarshDriving: 2,
        totalViolations: 3,
        ratings: "B+"
    },
    {
        id: 9,
        company: "VWX Freight",
        branch: "Dallas",
        employeeName: "Daniel Anderson",
        mobileNumber: "9012345678",
        employeeId: "EMP009",
        dateOfJoining: "2018-05-15",
        tagVia: "RFID",
        rfidBeaconTagNo: "RFID901234",
        designation: "Driver",
        shiftTime: "10:00 PM - 06:00 AM",
        distance: "180 km",
        running: "4.5 hours",
        idle: "1 hour",
        stop: "30 minutes",
        noOfOverSpeeding: 2,
        noOfHarshDriving: 1,
        totalViolations: 3,
        ratings: "B"
    },
    {
        id: 10,
        company: "YZA Transport",
        branch: "San Jose",
        employeeName: "Ava Thomas",
        mobileNumber: "9123456789",
        employeeId: "EMP010",
        dateOfJoining: "2023-02-10",
        tagVia: "Beacon",
        rfidBeaconTagNo: "BEACON912345",
        designation: "Driver",
        shiftTime: "12:00 AM - 08:00 AM",
        distance: "200 km",
        running: "5 hours",
        idle: "1.5 hours",
        stop: "20 minutes",
        noOfOverSpeeding: 3,
        noOfHarshDriving: 0,
        totalViolations: 3,
        ratings: "A"
    }
];

export const driverWorkHourReports:IDeriverWorkHourReport[] = [
    {
        id: 1,
        company: "ABC Logistics",
        branch: "New York",
        employeeName: "John Doe",
        mobileNumber: "1234567890",
        employeeId: "EMP001",
        dateOfJoining: "2021-01-15",
        designation: "Driver",
        shift: "Day",
        workHourDistance: 150, // in km
        workHourRunning: 4, // in hours
        workHourIdle: 1, // in hours
        nonWorkingHours: 19, // in hours
        shiftStartTime: "08:00 AM",
        shiftEndTime: "04:00 PM"
    },
    {
        id: 2,
        company: "XYZ Transport",
        branch: "Los Angeles",
        employeeName: "Jane Smith",
        mobileNumber: "2345678901",
        employeeId: "EMP002",
        dateOfJoining: "2020-05-20",
        designation: "Driver",
        shift: "Night",
        workHourDistance: 200, // in km
        workHourRunning: 5, // in hours
        workHourIdle: 1.5, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "10:00 PM",
        shiftEndTime: "06:00 AM"
    },
    {
        id: 3,
        company: "DEF Freight",
        branch: "Chicago",
        employeeName: "Michael Johnson",
        mobileNumber: "3456789012",
        employeeId: "EMP003",
        dateOfJoining: "2019-09-10",
        designation: "Driver",
        shift: "Day",
        workHourDistance: 175, // in km
        workHourRunning: 4.5, // in hours
        workHourIdle: 1, // in hours
        nonWorkingHours: 18.5, // in hours
        shiftStartTime: "06:00 AM",
        shiftEndTime: "02:00 PM"
    },
    {
        id: 4,
        company: "GHI Transport",
        branch: "Houston",
        employeeName: "Emily Davis",
        mobileNumber: "4567890123",
        employeeId: "EMP004",
        dateOfJoining: "2018-07-22",
        designation: "Driver",
        shift: "Night",
        workHourDistance: 220, // in km
        workHourRunning: 6, // in hours
        workHourIdle: 0.5, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "08:00 PM",
        shiftEndTime: "04:00 AM"
    },
    {
        id: 5,
        company: "JKL Freight",
        branch: "Phoenix",
        employeeName: "David Brown",
        mobileNumber: "5678901234",
        employeeId: "EMP005",
        dateOfJoining: "2022-03-18",
        designation: "Driver",
        shift: "Day",
        workHourDistance: 190, // in km
        workHourRunning: 5, // in hours
        workHourIdle: 1.5, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "07:00 AM",
        shiftEndTime: "03:00 PM"
    },
    {
        id: 6,
        company: "MNO Logistics",
        branch: "Philadelphia",
        employeeName: "Sophia Martinez",
        mobileNumber: "6789012345",
        employeeId: "EMP006",
        dateOfJoining: "2020-11-30",
        designation: "Driver",
        shift: "Night",
        workHourDistance: 210, // in km
        workHourRunning: 5.5, // in hours
        workHourIdle: 1, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "11:00 PM",
        shiftEndTime: "07:00 AM"
    },
    {
        id: 7,
        company: "PQR Transport",
        branch: "San Antonio",
        employeeName: "Chris Wilson",
        mobileNumber: "7890123456",
        employeeId: "EMP007",
        dateOfJoining: "2019-12-12",
        designation: "Driver",
        shift: "Day",
        workHourDistance: 160, // in km
        workHourRunning: 4, // in hours
        workHourIdle: 2, // in hours
        nonWorkingHours: 18, // in hours
        shiftStartTime: "06:00 AM",
        shiftEndTime: "02:00 PM"
    },
    {
        id: 8,
        company: "STU Logistics",
        branch: "San Diego",
        employeeName: "Olivia Garcia",
        mobileNumber: "8901234567",
        employeeId: "EMP008",
        dateOfJoining: "2021-08-25",
        designation: "Driver",
        shift: "Night",
        workHourDistance: 230, // in km
        workHourRunning: 5, // in hours
        workHourIdle: 1.5, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "10:00 PM",
        shiftEndTime: "06:00 AM"
    },
    {
        id: 9,
        company: "VWX Freight",
        branch: "Dallas",
        employeeName: "Daniel Anderson",
        mobileNumber: "9012345678",
        employeeId: "EMP009",
        dateOfJoining: "2018-05-15",
        designation: "Driver",
        shift: "Day",
        workHourDistance: 180, // in km
        workHourRunning: 4.5, // in hours
        workHourIdle: 1, // in hours
        nonWorkingHours: 18.5, // in hours
        shiftStartTime: "08:00 AM",
        shiftEndTime: "04:00 PM"
    },
    {
        id: 10,
        company: "YZA Transport",
        branch: "San Jose",
        employeeName: "Ava Thomas",
        mobileNumber: "9123456789",
        employeeId: "EMP010",
        dateOfJoining: "2023-02-10",
        designation: "Driver",
        shift: "Night",
        workHourDistance: 200, // in km
        workHourRunning: 5, // in hours
        workHourIdle: 1.5, // in hours
        nonWorkingHours: 17.5, // in hours
        shiftStartTime: "11:00 PM",
        shiftEndTime: "07:00 AM"
    }
];

