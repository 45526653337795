import React from 'react';
import Table from '../../../../../components/table/Table';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createDriverDriverRating } from '../../../../../services/ServiceFactory';
import { IDriverRating, IDriverRatingColumn } from '../../../../../services/interfaces/IDriverRating';
import Button from '../../../../../components/Button';
const DriverRatingUI: React.FC = () => {
    // send data for filter report
    const [data, setData] = useState<IDriverRating[]>([]);

    const driverRatingService = createDriverDriverRating();
    useEffect(() => {
        const fetchdata = async () => {
            try {
                const data = await driverRatingService.getDriverRatingData();
                setData(data);
            } catch {
                console.log('Error fetching data');
            }
        };
        void fetchdata();
    }, []);

    const actions = [
        {
            name: 'view',
            path: '/company/settings/management/driver-rating/view',
            icon: FaEye,
            class: 'text-blue-700 text-2xl mx-3'
        },
        {
            name: 'Edit',
            path: '/company/settings/management/driver-rating/edit',
            icon: FaEdit,
            class: 'text-green-700 text-2xl'
        },
        {
            name: 'Delete',
            path: '/company/report/vehicle-activity/IdleReportForVehicle',
            icon: FaTrash,
            class: 'text-red-700 text-2xl mx-3'
        }
    ];
    console.log(data);
    return (
        <>
            <div className=' flex justify-end mt-5'>
                <Link to={'/company/settings/management/driver-rating/create'}>
                    <Button className='bg-blue-950 hover:bg-blue-600  text-white w-[160px] h-[35px] rounded-md'>
                        New Driver Rating
                    </Button>
                </Link>
            </div>
            <Table<IDriverRating>
                data={data}
                columns={IDriverRatingColumn}
                tableRowActions={actions}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
            />
        </>
    );
};

export default DriverRatingUI;
