import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  onSelect: (selectedValue: string) => void;
  selectedValue: string;
  placeholder?: string;
  selectClass?: string;
  selectStyle?: React.CSSProperties;
  optionClass?: string;
  optionStyle?: React.CSSProperties;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  selectedValue,
  placeholder,
  selectClass,
  selectStyle,
  optionClass,
  optionStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (selectedValue: string) => {
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onSelect(selectedOption.value);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`block w-full px-4 py-2 border rounded-md relative cursor-pointer ${isOpen ? 'border-blue-500' : 'border-gray-300'
          } ${selectClass}`}
        style={selectStyle}
        onClick={handleToggleDropdown}
      >
        {selectedValue ? (
          <div className="text-gray-800">
            {options.find(option => option.value === selectedValue)?.label}
          </div>
        ) : (
          <div className="text-gray-400">{placeholder || 'Select Option'}</div>
        )}
        {isOpen ? (
          <KeyboardArrowUpIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-700 pointer-events-none" />
        ) : (
          <KeyboardArrowDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-700 pointer-events-none" />
        )}

      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg" style={{ top: '100%', ...selectStyle }} >
          <div className="max-h-60 overflow-y-auto">
            {options.map(option => (
              <div
                key={option.value}
                className={`block px-4 py-2 cursor-pointer ${option.value === selectedValue ? 'bg-blue-100 font-bold' : 'hover:bg-gray-100'
                  } ${optionClass}`}
                style={optionStyle}
                onClick={() => handleSelectOption(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
