import React, { CSSProperties, ButtonHTMLAttributes, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  onClick,
  className,
  style,
  children,
  disabled,
  ...rest
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={className}
      style={style}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
