import React, { useEffect, useState } from 'react';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import NavTabs from '../../../../components/NavTabs';
import tabs from './Tab';
import { FaEye } from 'react-icons/fa';
import { IVehicleMovementReport, MovementDataColumn } from '../../../../services/interfaces/IVehicleService';
import useHttp from '../../../../hooks/useHttp';
import { VehicleTableInterface } from '../../../../utils/interfaces/masterVehicle';

interface VehicleApiResponse {
    message: string;
    data: VehicleTableInterface[];
}

const MovementReportUI: React.FC = () => {
    const Report_type = 'Movement';
    const { request } = useHttp();
    const [data, setData] = useState<IVehicleMovementReport[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState<CustomFormData | null>(null);
    const [vehicle, setVehicle] = useState<VehicleTableInterface[]>([]);
    const [matchingVehicleIds, setMatchingVehicleIds] = useState<number[]>([]);



    const handleSelectedData = (datafilter: CustomFormData) => {
        
       
        const fullDataNumbers = datafilter.multiSelect?.map((item) => item.value);

        if (Array.isArray(vehicle)) {
            const matchedIds = vehicle.filter((v) => fullDataNumbers?.includes(v.number))
                .map((v) => v.id);

            setMatchingVehicleIds(matchedIds);
            setSelectedData(datafilter);
        } else {
            console.error('Vehicle is not an array:', vehicle);
        }
    };

    const fetchVehicle = async () => {
        setLoading(true);
        try {
            const response = await request({ url: '/vehicles', method: 'GET' });
            if (!response.ok) throw new Error('Failed to fetch vehicles');

            const responseData = await response.json() as VehicleApiResponse;

            if (Array.isArray(responseData.data)) {
                setVehicle(responseData.data);
            } else {
                console.error('Fetched data is not an array:', responseData.data);
                setVehicle([]);
            }
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        if (selectedData) {
            const sdate = selectedData.startDateTime
            ? selectedData.startDateTime.toISOString()
            : '';
            const endDate = selectedData.endDateTime
            ? selectedData.endDateTime.toISOString()
            : '';
        
          const intervalTime:string=selectedData.singleSelect?selectedData.singleSelect?.value:'5m';
            const params = new URLSearchParams({
                start: sdate,
                stop:endDate,
                aggregateWindow: intervalTime,
                page: "1",
                pageSize: "100",
                vehicleIds: `${matchingVehicleIds.join(",")}`
            });
            const url = `/report/movement?${params.toString()}`;
            console.log(url);
            setLoading(true);

            try {
                const response = await request({
                    method: 'GET',
                    url: url,
                    
                });

                console.log(`response`, response);

                if (!response.ok) throw new Error('Failed to fetch data');
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                const reportData = (await response.json()).data as IVehicleMovementReport[];

                setData(reportData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        void fetchVehicle();
    }, []);

    useEffect(() => {
        void fetchData();
    }, [selectedData]);

    const actions = [
        {
            name: 'View',
            path: '/company/report/vehicle-activity/MovementReportForVehicle',
            icon: FaEye,
            class: 'text-blue-700 text-2xl',
        },
    ];


    const vehilcleList = vehicle.map((e) => e.number );
   


    return (
        <>
            <NavTabs tabs={tabs} />
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={vehilcleList}
                    singleselect={['5m', '10m', '15m', '20m']}
                    field_type={['Vehicle', 'Interval', 'From', 'To']}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table<IVehicleMovementReport>
                columns={MovementDataColumn}
                data={data}
                loading={loading}
                tableRowActions={actions}
                excelExport={true}
                searchAble={true}
                pdfExport={true}
            />
        </>
    );
};

export default MovementReportUI;
