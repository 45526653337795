// CreateGeofence.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MapContainer, TileLayer, Polyline, useMapEvents } from 'react-leaflet';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ColorPicker from '../createGeofance/ColorPicker'; 
import useHttp from '../../../../../../hooks/useHttp';
import { FaMapMarkerAlt } from 'react-icons/fa'; 
import SearchLocation from './SearchLocation';
interface Apiresponce {
  message: string;
  data: IGeofenceAttributes;
}
interface IGeofenceAttributes {
  id: number;
  name: string;
  enabled?: boolean;
  coordinates: [number, number][];
  companyId?: number | null;
  userId?: number | null;
  geofenceType?: string;
}

interface GeofenceFormValues {
  name: string;
  selectedColor: string;
  coordinates: [number, number][]; // Coordinates as tuples
}
interface ILocation{
  latitude: string;
  longitude: string;
}

const CreateGeofence: React.FC = () => {
  const [coordinates, setCoordinates] = useState<[number, number][]>([]);
  const [selectedColor, setSelectedColor] = useState<string>('#2196F3');
  const [isDrawingComplete, setIsDrawingComplete] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<GeofenceFormValues | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false); // Track if search is focused
  const defaultLocation: ILocation = { latitude: '20.5937', longitude: '78.9629' };
  const [center, setCenter] = useState<ILocation>(defaultLocation);
  const { request } = useHttp();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const isEditing = location.pathname.includes('edit');
  const isViewing = location.pathname.includes('view');

  const numericId = Number(id);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      const defaultValues: GeofenceFormValues = {
        name: '',
        selectedColor: '#2196F3',
        coordinates: [],
      };
      setInitialValues(defaultValues);
      setLoading(false);
    } else if (numericId) {
      const fetchData = async () => {
        try {
          const response = await request({ url: '/geofence/' + numericId });
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data = (await response.json()) as Apiresponce;
          console.log(data.data.coordinates);
          const coordinates = data.data.coordinates[0];
          console.log(coordinates);
            const latitude = coordinates[0];
            const longitude = coordinates[1];
          setCenter({ latitude: latitude.toString(), longitude: longitude.toString() });

          const formattedCoordinates = data.data.coordinates.map((coord) => {
            if (Array.isArray(coord) && coord.length === 2) {
              return [coord[0], coord[1]] as [number, number];
            }
            return [123456, 123456] as [number, number];
          });
          setCoordinates(formattedCoordinates);
          const initialValuesEditView: GeofenceFormValues = {
            name: data.data.name,
            selectedColor: '#2196F3',
            coordinates: formattedCoordinates,
          };
          setInitialValues(initialValuesEditView);
          setLoading(false);
        } catch (error) {
          console.error(error);
          toast.error('Failed to load geofence data');
        }
      };

      void fetchData();
    }
  }, [numericId, request, location.pathname]);

  const MapEvents = () => {
    useMapEvents({
      click: (event) => {
        if (isDrawingComplete || isSearchFocused) return; // Prevent drawing if search is focused
        const newCoordinate: [number, number] = [event.latlng.lat, event.latlng.lng];
        setCoordinates((prev) => [...prev, newCoordinate]);
      },
    });
    return null;
  };

  const handleSubmit = async (values: GeofenceFormValues, { resetForm }: FormikHelpers<GeofenceFormValues>) => {
    const geofenceData = {
      ...values,
      coordinates,
      selectedColor,
    };

    if (!isViewing) {
      try {
        const response = await request({
          url: isEditing ? `/geofence/${numericId}` : '/geofence',
          method: isEditing ? 'PUT' : 'POST',
          body: { name: geofenceData.name, coordinates: geofenceData.coordinates },
        });

        if (!response.ok) {
          throw new Error('Error occurred while saving the geofence');
        }

        toast.success(isEditing ? 'Geofence updated successfully' : 'Geofence created successfully');
        window.location.href = '/osmGeofence';

        resetForm();
        setCoordinates([]);
        setIsDrawingComplete(false);
      } catch (error) {
        toast.error('Failed to save geofence');
      }
    }
  };

  const handleClear = () => {
    setCoordinates([]);
    setIsDrawingComplete(false);
  };

  const handleLocationSearch = (lat: number, lon: number) => {
    setCenter({ latitude: lat.toString(), longitude: lon.toString() });
 
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className='text-xl font-bold'>{isEditing ? 'Edit Geofence' : 'Create Geofence'}</h2>
      <Formik
        initialValues={initialValues as GeofenceFormValues}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className='grid sm:grid-cols-1 md:grid-cols-12 bg-white border border-t-blue-800 border-t-4px'>
              <div className='md:col-span-4 m-3'>
                <div>
                  <label htmlFor='name' className='block mb-1 font-semibold'>Geofence Name</label>
                  <Field name='name' type='text' className={`border ${errors.name && touched.name ? 'border-red-500' : 'border-gray-300'} p-2 w-full`} />
                  {errors.name && touched.name && <div className='text-red-500'>{errors.name}</div>}
                </div>

                <ColorPicker selectedColor={selectedColor} onColorChange={setSelectedColor} />

                <div className='flex justify-end mt-4'>
                  <button type='button' onClick={handleClear} className='bg-cyan-950 text-white px-2 h-8 rounded hover:bg-blue-800 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'>Clear</button>
                  <button type='submit' className='bg-cyan-950 text-white px-2 h-8 rounded hover:bg-blue-800 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mx-2' disabled={isViewing}>Submit</button>
                </div>
              </div>

              <div className='my-4 md:col-span-8 m-2'>
                <MapContainer center={[parseFloat(center.latitude), parseFloat(center.longitude)]} zoom={8} style={{ height: '400px', width: '100%' }}>
                  <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; OpenStreetMap contributors' />
                  <MapEvents />
                  <FaMapMarkerAlt className="absolute top-2 right-2 text-xl text-cyan-950 cursor-pointer" title="Create Corridor" onClick={() => { /* Logic for corridor creation */ }} />
                  <Polyline positions={coordinates} pathOptions={{ color: selectedColor, weight: 5 }} />
                  <SearchLocation onSearch={handleLocationSearch} setIsSearchFocused={setIsSearchFocused} />
                </MapContainer>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateGeofence;
