import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import Table from '../../../components/table/Table';
import useHttp from '../../../hooks/useHttp';
import { ICompany, CompanyDataColumns } from '../../../services/interfaces/IManageCompany';
import { toast } from 'react-toastify';


interface ApiResponce {
    message: string;
    data: ICompany[];
}
const ManageCompanyUI: React.FC = () => {
    // State to store fetched data
    const [data, setData] = useState<ICompany[]>([]);
    const { request } = useHttp();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request({
                    url: '/companies',
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch companies');
                    toast.error(response.arrayBuffer.toString());
                }
                const data = await response.json() as ApiResponce;
                setData(data.data);
            } catch (error) {
                toast.error(' Could not Found');
            }
        };

        void fetchData();
    }, []);

    // Actions for table row
    const actions = [
        { name: 'View', path: '/systemAdmin/manageCompany/view', icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: '/systemAdmin/manageCompany/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-2' },
        {
            name: 'Delete',
            path: '/companies/',
            icon: FaTrash,
            class: 'text-red-700 text-2xl'
        }
    ];

    return (
        <>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl text-gray-800'>All Company</h1>
                <Link to='/company/dashboard' className='flex items-center text-xl hover:bg-blue-500 mb-[30px]'>
                    <AiFillDashboard className='mx-3 ' /> Home
                </Link>
            </div>

            <div className='relative mb-2 mt-[-10px] bg-green-400'>
                <Link
                    to='/systemAdmin/manageCompany/create'
                    className='absolute right-2 top-[-20px] bg-blue-950 text-white px-5 border border-green-300 rounded'
                >
                    Add New Company
                </Link>
            </div>

            <div className=''>
                <Table<ICompany> data={data} columns={CompanyDataColumns} tableRowActions={actions} searchAble={true} />
            </div>
        </>
    );
};

export default ManageCompanyUI;
