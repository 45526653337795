import React, { useState, useEffect } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/table/Table';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { createExpenseService } from '../../../../../services/ServiceFactory';
import SingleSelectDropdown from '../../../../../components/SingleSelectDropdown';
import Dropdown from '../../../../../components/Dropdown';
import { IExpense, IExpenseDataColumn } from '../../../../../services/interfaces/IExpense';
function Expense() {
    const options = [
        { value: 'W-Cement', label: 'W Cement' },
        { value: 'GROUP', label: 'GROUP' }
    ];
    const options1 = [
        { value: 'Demo-A', label: 'Demo A' },
        { value: 'Demo-B', label: 'Demo B' },
        { value: 'Demo-C', label: 'Demo C' }
    ];
    const options2 = [
        { value: 'Variable', label: 'Variable' },
        { value: 'Fix', label: 'Fix' }
    ];
    const options3 = [
        { value: 'Expense-A', label: 'Expense A' },
        { value: 'Expense-B', label: 'Expense B' },
        { value: 'Expense-C', label: 'Expense C' }
    ];

    const [showContent, setShowContent] = useState<boolean>(true);
    const [selectCompany, setSelectCompany] = useState<string | null>(null);
    const [selectBranch, setSelectBranch] = useState<string | null>(null);
    const [selectExpenseType, setSelectExpenseType] = useState<string | null>(null);
    const [selectCategory, setSelectCategory] = useState('');
    const [data, setData] = useState<IExpense[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const ExpenseService = createExpenseService();

    const toggleContent = () => {
        setShowContent(prev => !prev);
    };

    const handleComapny = (value: string | null) => {
        setSelectCompany(value);
    };
    const handleBranch = (value: string | null) => {
        setSelectBranch(value);
    };
    const handleExpenseType = (value: string | null) => {
        setSelectExpenseType(value);
    };

    const actions = [
        { name: 'View', path: '/company/settings/management/expense/view', icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: '/company/settings/management/expense/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-1' },
        { name: 'Delete', path: '/delete', icon: FaTrash, class: 'text-red-700 text-2xl' }
    ];
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ExpenseService.getExpense();
                setData(data);
            } catch (error) {
                console.log(error);
            }finally {
                setLoading(false);
              }
        };
        void fetchData();
    }, [ExpenseService]);
    console.log("table data", data);
    

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectCompany || !selectBranch || !selectExpenseType || !selectCategory) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            selectCompany: selectCompany,
            selectBranch: selectBranch,
            selectCategory: selectCategory,
            selectExpenseType: selectExpenseType
        });
    };

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-semibold text-2xl text-black'>Expense</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Expense</p>
                </div>
            </div>

            {/* Filter Options Section */}
            <div className='bg-white w-full  mt-4 rounded'>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <h1 className='text-blue-950 font-bold p-2 ml-2 cursor-pointer' onClick={toggleContent}>
                    Filter Options
                </h1>
                <hr />
                {showContent && (
                    <div className='w-full lg:h-[160px] h-[400px]'>
                        <form onSubmit={handleSubmit}>
                            <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-8 gap-2 p-4'>
                                <div className=' mt-1'>
                                    <div className='mb-1'>
                                        <label className='text-black font-semibold text-sm' htmlFor=''>
                                            Company
                                        </label>
                                    </div>
                                    <SingleSelectDropdown
                                        options={options}
                                        selectedValue={selectCompany}
                                        onSelect={handleComapny}
                                        placeholder='Select Company'
                                        width='100%'
                                    />
                                </div>
                                <div className=' mt-1'>
                                    <div className='mb-1'>
                                        <label className='text-black font-semibold text-sm' htmlFor=''>
                                            Branch
                                        </label>
                                    </div>
                                    <SingleSelectDropdown
                                        options={options1}
                                        selectedValue={selectBranch}
                                        onSelect={handleBranch}
                                        placeholder='Select Branch'
                                        width='100%'
                                    />
                                </div>

                                <div className='mt-2'>
                                    <div className='text-sm mb-1 font-semibold'>
                                        <label htmlFor=''>Category</label>
                                    </div>
                                    <Dropdown
                                        options={options2}
                                        onSelect={selectedValue => setSelectCategory(selectedValue)}
                                        selectedValue={selectCategory}
                                        placeholder='Select Category'
                                        selectClass='rounded-none h-[33px] w-full'
                                        selectStyle={{
                                            backgroundColor: 'white',
                                            fontSize: '15px', 
                                            padding: '5px' 
                                        }}
                                        optionClass='my-custom-option-class'
                                        optionStyle={{
                                            fontSize: '15px',  
                                            padding: '8px' 
                                        }}
                                    />
                                </div>

                                <div className=' mt-1'>
                                    <div className='mb-1'>
                                        <label className='text-black font-semibold text-sm' htmlFor=''>
                                            Expense Type
                                        </label>
                                    </div>
                                    <SingleSelectDropdown
                                        options={options3}
                                        selectedValue={selectExpenseType}
                                        onSelect={handleExpenseType}
                                        placeholder='Select Expense Type'
                                        width='100%'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-4 mt-2 justify-end mr-4'>
                                <Button
                                    type='submit'
                                    className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                                >
                                    Check
                                </Button>
                                <Button className='bg-gray-100 hover:bg-gray-200 text-black w-[90px] h-[35px] rounded-md border border-black'>
                                    Clear
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
            </div>

            <div className=' flex justify-end mt-5'>
                <Link to={'/company/settings/management/new-expense'}>
                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                        New Expense
                    </Button>
                </Link>
            </div>
            <Table
                excelExport={true}
                loading={loading}
                pdfExport={true}
                searchAble={true}
                columns={IExpenseDataColumn}
                data={data}
                tableRowActions={actions}
            />
        </div>
    );
}

export default Expense;
