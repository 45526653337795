import React, { useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

interface DoughnutProps {
  width?: number;
  height?: number;
  data?: { name: string; value: number }[];
  colors?: string[];
}

const defaultColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];


const Doughnut: React.FC<DoughnutProps> = ({ width = 300, height = 200, data = [], colors = defaultColors }) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([]);

  const handleLabelClick = (index: number) => {
    setActiveIndex(prevIndex =>
      prevIndex.includes(index) ? prevIndex.filter(i => i !== index) : [...prevIndex, index]
    );
  };

  // Filter out data based on activeIndex
  const filteredData = data.filter((_, index) => !activeIndex.includes(index));
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center ">
      <PieChart width={width} height={height}>
        <Pie
          data={filteredData}
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={60}
          fill="#82ca9d"
          dataKey="value"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div className="mt-4 sm:mt-0 sm:ml-4">
        <ul>
          {data.map((entry, index) => (
            <li key={`legend-${index}`} className="flex items-center mb-2"  onClick={() => handleLabelClick(index)}
            style={{ textDecoration: activeIndex.includes(index) ? 'line-through' : 'none', cursor: 'pointer' }}>
              <span
                className="inline-block w-4 h-4 mr-2"
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
              <span className="text-black">{entry.name} ({entry.value})</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Doughnut;
