import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import ColorPicker from './ColorPicker';
import axios from 'axios';

const GeofenceMap: React.FC = () => {
    const mapRef = useRef<HTMLDivElement>(null);
    const googleMapRef = useRef<google.maps.Map | null>(null);
    const [selectedColor, setSelectedColor] = useState<string>('#2196F3');
    const [geofenceName, setGeofenceName] = useState<string>('');
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
    const [cancel, setCancel] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const REACT_APP_GOOGLE_MAPS_API_KEY = '';

    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const existingScript = document.getElementById('googleMapsScript');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=drawing,places`;
                script.id = 'googleMapsScript';
                script.async = true;
                script.defer = true;
                script.onload = () => initializeMap();
                document.head.appendChild(script);
            } else {
                initializeMap();
            }
        };

        const initializeMap = () => {
            if (!mapRef.current || !window.google) return;

            googleMapRef.current = new window.google.maps.Map(mapRef.current, {
                center: { lat: 26.88948, lng: 75.73655 },
                zoom: 12,
            });

            const drawingManager = new window.google.maps.drawing.DrawingManager({
                drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        window.google.maps.drawing.OverlayType.POLYGON,
                        window.google.maps.drawing.OverlayType.RECTANGLE,
                        window.google.maps.drawing.OverlayType.CIRCLE,
                        window.google.maps.drawing.OverlayType.MARKER,
                    ],
                },
                polygonOptions: {
                    fillColor: selectedColor,
                    fillOpacity: 0.4,
                    strokeColor: selectedColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    clickable: false,
                    editable: true,
                    zIndex: 1,
                },
            });

            drawingManager.setMap(googleMapRef.current);

            google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
                const coordinates: google.maps.LatLngLiteral[] = polygon
                    .getPath()
                    .getArray()
                    .map((latLng: google.maps.LatLng) => ({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                    }));

                console.log(coordinates);

                setPolygon(polygon);
                polygon.setEditable(false);
                drawingManager.setDrawingMode(null);
                drawingManager.setMap(null);
                polygon.setMap(googleMapRef.current);
            });
        };

        loadGoogleMapsScript();

        return () => {
            const script = document.getElementById('googleMapsScript');
            if (script) {
                script.remove();
            }
        };
    }, [selectedColor, cancel]);

    const handleSave = async () => {
        if (!polygon) return;

        const coordinates = polygon
            .getPath()
            .getArray()
            .map(latLng => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
            }));

        const geofenceData = {
            name: geofenceName,
            coordinates,
            color: selectedColor,
        };

        try {
            await axios.post('/api/geofences', geofenceData);
            alert('Geofence saved successfully!');
        } catch (error) {
            console.error('Error saving geofence:', error);
            alert('Failed to save geofence.');
        }
    };

    const handleSaveClick = () => {
        void handleSave();
    };

    const handleSearch = async () => {
        if (!searchQuery) return;

        const service = new window.google.maps.places.AutocompleteService();

        try {
            const predictions = await new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
             void   service.getPlacePredictions({ input: searchQuery }, (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                        resolve(predictions);
                    } else {
                        reject(new Error('No locations found'));
                    }
                });
            });

            setSuggestions(predictions);
        } catch (error) {
            alert('No locations found');
        }
    };

    const handleSuggestionClick = (suggestion: google.maps.places.AutocompletePrediction) => {
        const service = new window.google.maps.places.PlacesService(googleMapRef.current!);
        service.getDetails({ placeId: suggestion.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
                const location = place.geometry.location;
                if (googleMapRef.current) {
                    googleMapRef.current.setCenter(location);
                    googleMapRef.current.setZoom(15);
                    new window.google.maps.Marker({
                        position: location,
                        map: googleMapRef.current,
                        title: place.name,
                    });
                    setSuggestions([]);
                }
            } else {
                alert('Location details not found');
            }
        });
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            void handleSearch();
        }
    };

    return (
        <div className='grid grid-cols-12'>
            <div className='col-span-12 sm:col-span-12 md:col-span-2'>
                <h1 className='text-center text-lg text-orange-600'>Geofence Details</h1>
                <div className='form mt-5'>
                    <div className='form-group'>
                        <label htmlFor='Geofencename' className='block text-sm font-medium text-gray-700'>
                            Enter Geofence Name <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type='text'
                            placeholder='Geofence Name'
                            name='Geofencename'
                            value={geofenceName}
                            onChange={e => setGeofenceName(e.target.value)}
                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            required
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='searchlocation' className='block text-sm font-medium text-gray-700'>
                            Search location
                        </label>
                        <input
                            type='text'
                            placeholder='Search Location'
                            value={searchQuery}
                            name='searchlocation'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        />
                        <button
                            onClick={() => void handleSearch()}
                            className='bg-blue-600 text-white p-2 border border-green-500'
                        >
                            Search
                        </button>
                        {suggestions.length > 0 && (
                            <ul>
                                {suggestions.map(suggestion => (
                                    <li
                                        key={suggestion.place_id}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        style={{ cursor: 'pointer', padding: '5px' }}
                                    >
                                        {suggestion.description}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div>
                        <ColorPicker selectedColor={selectedColor} onColorChange={setSelectedColor} />
                    </div>
                    <div className='flex justify-end space-x-4 px-2'>
                        <button
                            onClick={handleSaveClick}
                            className='btn btn-primary mb-2 p-2 px-2 bg-blue-950 text-white border border-green-600 rounded'
                        >
                            Save
                        </button>
                        <button
                            onClick={() => {
                                setPolygon(null);
                                setCancel(!cancel);
                            }}
                            className='btn btn-secondary bg-gray-950 text-white mb-2 px-2 border border-gray-200 rounded'
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div className='col-span-12 sm:col-span-12 md:col-span-10'>
                <div ref={mapRef} style={{ height: '400px', width: '100%' }} />
            </div>
        </div>
    );
};

export default GeofenceMap;
