import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import DynamicRoute from './routes/DynamicRoute';
import Layout from './modules/mainLayout/Layout';
import Login from './modules/company/login/Login';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './modules/company/Dashboard/Dashboard';
import { LiveLocationProvider } from './context/LiveLocationContext';
import { MultitrackLocationProvider } from './context/MultiTrackContext';
import ErrorBoundary from './ErrorBoundary';

const App: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const authtoken = !!localStorage.getItem('authToken');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [authtoken, navigate]);

    return (
        <>
            <ToastContainer />
            <Routes>
                {isAuthenticated ? (
                    <Route path='/' element={<Layout />}>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/login' element={<Dashboard />} />

                        <Route path='*' element={<DynamicRoute />} />
                    </Route>
                ) : (
                    <Route path='/login' element={<Login />} />
                )}
            </Routes>
        </>
    );
};

const WrappedApp = () => (
    <AuthProvider>
        <MultitrackLocationProvider>
            <LiveLocationProvider>
                <Router>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </Router>
            </LiveLocationProvider>
        </MultitrackLocationProvider>
    </AuthProvider>
);

export default WrappedApp;
