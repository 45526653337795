import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string; // Added title prop
  children: React.ReactNode; // Added children prop for dynamic content
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <div style={{ backgroundColor: '#07163d', padding: '8px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle style={{ color: 'white', padding: 0, margin: 0 }}>{title}</DialogTitle>
        <IconButton style={{ color: 'white' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
