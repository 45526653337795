import {IGeofanceEntryexitone,IGeofanceEntryexit,IGeofancetogeofance,IGeoToGeoParticularVehicle} from "../interfaces/IGeofanceService";

export const geofenceEntriesone: IGeofanceEntryexitone[] = [
    {
      id: 1,
      company: "ABC Logistics",
      vehicleName: "Truck 001",
      vehicleBrand: "Ford",
      vehicleModel: "F-150",
      driver: "John Doe",
      imeiNumber: "123456789012345",
      geofanceName: "Warehouse 1",
      fanceEntrytime: "2023-07-01T08:30:00Z",
      etryCoordinates: "40.712776, -74.005974",
      entryPosition: "Entrance Gate 1",
      durationinFences: 120,
      traveldastination: 50,
      fanceExittime: "2023-07-01T10:30:00Z",
      exitCoordinates: "40.712776, -74.005974",
      exitpositions: "Exit Gate 1"
    },
    {
      id: 2,
      company: "XYZ Transport",
      vehicleName: "Van 002",
      vehicleBrand: "Mercedes-Benz",
      vehicleModel: "Sprinter",
      driver: "Jane Smith",
      imeiNumber: "987654321098765",
      geofanceName: "Depot A",
      fanceEntrytime: "2023-07-01T09:00:00Z",
      etryCoordinates: "34.052235, -118.243683",
      entryPosition: "Main Entrance",
      durationinFences: 90,
      traveldastination: 30,
      fanceExittime: "2023-07-01T10:30:00Z",
      exitCoordinates: "34.052235, -118.243683",
      exitpositions: "Main Exit"
    },
    {
      id: 3,
      company: "LMN Services",
      vehicleName: "Car 003",
      vehicleBrand: "Toyota",
      vehicleModel: "Corolla",
      driver: "Jim Beam",
      imeiNumber: "123498765432109",
      geofanceName: "Client B",
      fanceEntrytime: "2023-07-01T11:00:00Z",
      etryCoordinates: "41.878113, -87.629799",
      entryPosition: "North Gate",
      durationinFences: 45,
      traveldastination: 20,
      fanceExittime: "2023-07-01T11:45:00Z",
      exitCoordinates: "41.878113, -87.629799",
      exitpositions: "North Gate"
    },
    {
      id: 4,
      company: "DEF Corp",
      vehicleName: "SUV 004",
      vehicleBrand: "Chevrolet",
      vehicleModel: "Tahoe",
      driver: "Alice Johnson",
      imeiNumber: "234567890123456",
      geofanceName: "Office HQ",
      fanceEntrytime: "2023-07-01T12:00:00Z",
      etryCoordinates: "29.760427, -95.369804",
      entryPosition: "South Entrance",
      durationinFences: 60,
      traveldastination: 40,
      fanceExittime: "2023-07-01T13:00:00Z",
      exitCoordinates: "29.760427, -95.369804",
      exitpositions: "South Exit"
    },
    {
      id: 5,
      company: "GHI Logistics",
      vehicleName: "Truck 005",
      vehicleBrand: "Ram",
      vehicleModel: "1500",
      driver: "Bob Brown",
      imeiNumber: "345678901234567",
      geofanceName: "Warehouse 2",
      fanceEntrytime: "2023-07-01T14:00:00Z",
      etryCoordinates: "33.448376, -112.074036",
      entryPosition: "Loading Bay",
      durationinFences: 75,
      traveldastination: 35,
      fanceExittime: "2023-07-01T15:15:00Z",
      exitCoordinates: "33.448376, -112.074036",
      exitpositions: "Loading Bay"
    },
    {
      id: 6,
      company: "JKL Transport",
      vehicleName: "Van 006",
      vehicleBrand: "Ford",
      vehicleModel: "Transit",
      driver: "Charlie Davis",
      imeiNumber: "456789012345678",
      geofanceName: "Client C",
      fanceEntrytime: "2023-07-01T16:00:00Z",
      etryCoordinates: "39.739236, -104.990251",
      entryPosition: "Service Gate",
      durationinFences: 50,
      traveldastination: 25,
      fanceExittime: "2023-07-01T16:50:00Z",
      exitCoordinates: "39.739236, -104.990251",
      exitpositions: "Service Gate"
    },
    {
      id: 7,
      company: "MNO Services",
      vehicleName: "Car 007",
      vehicleBrand: "Honda",
      vehicleModel: "Civic",
      driver: "Dana Miller",
      imeiNumber: "567890123456789",
      geofanceName: "Depot B",
      fanceEntrytime: "2023-07-01T17:00:00Z",
      etryCoordinates: "32.715736, -117.161087",
      entryPosition: "West Entrance",
      durationinFences: 100,
      traveldastination: 45,
      fanceExittime: "2023-07-01T18:40:00Z",
      exitCoordinates: "32.715736, -117.161087",
      exitpositions: "West Exit"
    },
    {
      id: 8,
      company: "PQR Corp",
      vehicleName: "SUV 008",
      vehicleBrand: "GMC",
      vehicleModel: "Yukon",
      driver: "Evan White",
      imeiNumber: "678901234567890",
      geofanceName: "Office Branch",
      fanceEntrytime: "2023-07-01T19:00:00Z",
      etryCoordinates: "37.774929, -122.419416",
      entryPosition: "East Gate",
      durationinFences: 80,
      traveldastination: 55,
      fanceExittime: "2023-07-01T20:20:00Z",
      exitCoordinates: "37.774929, -122.419416",
      exitpositions: "East Gate"
    }];

export const geofenceEntries: IGeofanceEntryexit[] =[

];
export const geofenceToGeofenceTrips: IGeofancetogeofance[] = [
    {
      id: 1,
      company: "ABC Logistics",
      branch: "New York",
      vehicleName: "Truck 001",
      vehicleBrand: "Ford",
      vehicleModel: "F-150",
      driver: "John Doe",
      imei: "123456789012345",
      tripStartgeofance: "Warehouse 1",
      tripStarttime: "2023-07-01T08:00:00Z",
      tripEndgeofance: "Depot A",
      tripEndtime: "2023-07-01T10:00:00Z",
      noOftrips: 1,
      distance: 100,
      running: "01:30:00",
      idle: "00:10:00",
      stop: 1,
      inactive: "00:20:00",
      durations: 120
    },
    {
      id: 2,
      company: "XYZ Transport",
      branch: "Los Angeles",
      vehicleName: "Van 002",
      vehicleBrand: "Mercedes-Benz",
      vehicleModel: "Sprinter",
      driver: "Jane Smith",
      imei: "987654321098765",
      tripStartgeofance: "Depot A",
      tripStarttime: "2023-07-01T09:00:00Z",
      tripEndgeofance: "Client B",
      tripEndtime: "2023-07-01T10:30:00Z",
      noOftrips: 1,
      distance: 50,
      running: "01:00:00",
      idle: "00:20:00",
      stop: 2,
      inactive: "00:10:00",
      durations: 90
    },
    {
      id: 3,
      company: "LMN Services",
      branch: "Chicago",
      vehicleName: "Car 003",
      vehicleBrand: "Toyota",
      vehicleModel: "Corolla",
      driver: "Jim Beam",
      imei: "123498765432109",
      tripStartgeofance: "Client B",
      tripStarttime: "2023-07-01T11:00:00Z",
      tripEndgeofance: "Warehouse 2",
      tripEndtime: "2023-07-01T12:00:00Z",
      noOftrips: 2,
      distance: 30,
      running: "00:45:00",
      idle: "00:15:00",
      stop: 1,
      inactive: "00:05:00",
      durations: 60
    },
    {
      id: 4,
      company: "DEF Corp",
      branch: "Houston",
      vehicleName: "SUV 004",
      vehicleBrand: "Chevrolet",
      vehicleModel: "Tahoe",
      driver: "Alice Johnson",
      imei: "234567890123456",
      tripStartgeofance: "Office HQ",
      tripStarttime: "2023-07-01T13:00:00Z",
      tripEndgeofance: "Client C",
      tripEndtime: "2023-07-01T14:30:00Z",
      noOftrips: 1,
      distance: 60,
      running: "01:15:00",
      idle: "00:10:00",
      stop: 2,
      inactive: "00:05:00",
      durations: 90
    },
    {
      id: 5,
      company: "GHI Logistics",
      branch: "Phoenix",
      vehicleName: "Truck 005",
      vehicleBrand: "Ram",
      vehicleModel: "1500",
      driver: "Bob Brown",
      imei: "345678901234567",
      tripStartgeofance: "Warehouse 2",
      tripStarttime: "2023-07-01T15:00:00Z",
      tripEndgeofance: "Client D",
      tripEndtime: "2023-07-01T17:00:00Z",
      noOftrips: 1,
      distance: 80,
      running: "01:45:00",
      idle: "00:10:00",
      stop: 1,
      inactive: "00:05:00",
      durations: 120
    },
    {
      id: 6,
      company: "JKL Transport",
      branch: "Philadelphia",
      vehicleName: "Van 006",
      vehicleBrand: "Ford",
      vehicleModel: "Transit",
      driver: "Charlie Davis",
      imei: "456789012345678",
      tripStartgeofance: "Client C",
      tripStarttime: "2023-07-01T16:00:00Z",
      tripEndgeofance: "Depot B",
      tripEndtime: "2023-07-01T17:30:00Z",
      noOftrips: 1,
      distance: 55,
      running: "01:00:00",
      idle: "00:25:00",
      stop: 2,
      inactive: "00:05:00",
      durations: 90
    },
    {
      id: 7,
      company: "MNO Services",
      branch: "San Antonio",
      vehicleName: "Car 007",
      vehicleBrand: "Honda",
      vehicleModel: "Civic",
      driver: "Dana Miller",
      imei: "567890123456789",
      tripStartgeofance: "Depot B",
      tripStarttime: "2023-07-01T18:00:00Z",
      tripEndgeofance: "Client E",
      tripEndtime: "2023-07-01T19:30:00Z",
      noOftrips: 1,
      distance: 45,
      running: "01:15:00",
      idle: "00:10:00",
      stop: 1,
      inactive: "00:05:00",
      durations: 90
    },
    {
      id: 8,
      company: "PQR Corp",
      branch: "San Diego",
      vehicleName: "SUV 008",
      vehicleBrand: "GMC",
      vehicleModel: "Yukon",
      driver: "Evan White",
      imei: "678901234567890",
      tripStartgeofance: "Office Branch",
      tripStarttime: "2023-07-01T20:00:00Z",
      tripEndgeofance: "Client F",
      tripEndtime: "2023-07-01T21:45:00Z",
      noOftrips: 1,
      distance: 65,
      running: "01:30:00",
      idle: "00:10:00",
      stop: 2,
      inactive: "00:05:00",
      durations: 105
    },
    {
      id: 9,
      company: "STU Logistics",
      branch: "Dallas",
      vehicleName: "Truck 009",
      vehicleBrand: "Nissan",
      vehicleModel: "Titan",
      driver: "Fiona Black",
      imei: "789012345678901",
      tripStartgeofance: "Client D",
      tripStarttime: "2023-07-01T22:00:00Z",
      tripEndgeofance: "Warehouse 3",
      tripEndtime: "2023-07-02T00:00:00Z",
      noOftrips: 1,
      distance: 85,
      running: "01:45:00",
      idle: "00:05:00",
      stop: 1,
      inactive: "00:05:00",
      durations: 120
    },
    {
      id: 10,
      company: "VWX Transport",
      branch: "San Jose",
      vehicleName: "Van 010",
      vehicleBrand: "Mercedes-Benz",
      vehicleModel: "Sprinter",
      driver: "George Green",
      imei: "890123456789012",
      tripStartgeofance: "Warehouse 3",
      tripStarttime: "2023-07-01T23:00:00Z",
      tripEndgeofance: "Client G",
      tripEndtime: "2023-07-02T01:00:00Z",
      noOftrips: 1,
      distance: 70,
      running: "01:30:00",
      idle: "00:10:00",
      stop: 1,
      inactive: "00:05:00",
      durations: 120
    }
  ];

 export  const geoToGeoParticularVehicleData :IGeoToGeoParticularVehicle[]= [
    {
      id: 1,
      company: "ABC Logistics",
      branch: "North Branch",
      vehicleName: "Truck 101",
      vehicleBrand: "Ford",
      vehicleModel: "F-150",
      driver: "John Doe",
      imei: "123456789012345",
      tripStartgeofance: "Warehouse A",
      tripStarttime: "2024-07-24T08:00:00Z",
      tripEndgeofance: "Distribution Center",
      tripEndtime: "2024-07-24T09:30:00Z",
      distance: 50,
      running: "1h 30m",
      idle: "15m",
      stop: 3,
      inactive: "5m",
      durations: 5700,
    },
    {
      id: 2,
      company: "XYZ Freight",
      branch: "East Branch",
      vehicleName: "Van 202",
      vehicleBrand: "Mercedes",
      vehicleModel: "Sprinter",
      driver: "Jane Smith",
      imei: "987654321098765",
      tripStartgeofance: "Depot B",
      tripStarttime: "2024-07-24T09:00:00Z",
      tripEndgeofance: "Client Site 1",
      tripEndtime: "2024-07-24T10:15:00Z",
      distance: 30,
      running: "1h 15m",
      idle: "10m",
      stop: 2,
      inactive: "3m",
      durations: 4500,
    },
    {
      id: 3,
      company: "Global Transport",
      branch: "West Branch",
      vehicleName: "Bus 303",
      vehicleBrand: "Volvo",
      vehicleModel: "VNL",
      driver: "Mike Johnson",
      imei: "123498765432109",
      tripStartgeofance: "Station C",
      tripStarttime: "2024-07-24T07:30:00Z",
      tripEndgeofance: "Terminal 1",
      tripEndtime: "2024-07-24T08:45:00Z",
      distance: 45,
      running: "1h 15m",
      idle: "5m",
      stop: 1,
      inactive: "2m",
      durations: 4500,
    },
    {
      id: 4,
      company: "Speedy Delivery",
      branch: "Central Branch",
      vehicleName: "Bike 404",
      vehicleBrand: "Yamaha",
      vehicleModel: "YZF-R3",
      driver: "Alice Brown",
      imei: "456789012345678",
      tripStartgeofance: "Hub D",
      tripStarttime: "2024-07-24T11:00:00Z",
      tripEndgeofance: "Client Site 2",
      tripEndtime: "2024-07-24T11:45:00Z",
      distance: 20,
      running: "45m",
      idle: "5m",
      stop: 1,
      inactive: "1m",
      durations: 2700,
    },
    {
      id: 5,
      company: "Green Transit",
      branch: "South Branch",
      vehicleName: "Car 505",
      vehicleBrand: "Tesla",
      vehicleModel: "Model S",
      driver: "David White",
      imei: "567890123456789",
      tripStartgeofance: "Office E",
      tripStarttime: "2024-07-24T08:30:00Z",
      tripEndgeofance: "Client Site 3",
      tripEndtime: "2024-07-24T09:30:00Z",
      distance: 40,
      running: "1h",
      idle: "10m",
      stop: 1,
      inactive: "2m",
      durations: 3600,
    },
    {
      id: 6,
      company: "Blue Sky Couriers",
      branch: "West Branch",
      vehicleName: "Truck 606",
      vehicleBrand: "Chevrolet",
      vehicleModel: "Silverado",
      driver: "Emma Green",
      imei: "678901234567890",
      tripStartgeofance: "Warehouse F",
      tripStarttime: "2024-07-24T06:00:00Z",
      tripEndgeofance: "Distribution Center",
      tripEndtime: "2024-07-24T07:30:00Z",
      distance: 60,
      running: "1h 30m",
      idle: "15m",
      stop: 2,
      inactive: "3m",
      durations: 5400,
    },
    {
      id: 7,
      company: "Red Line Logistics",
      branch: "North Branch",
      vehicleName: "Van 707",
      vehicleBrand: "Fiat",
      vehicleModel: "Ducato",
      driver: "Oliver Blue",
      imei: "789012345678901",
      tripStartgeofance: "Depot G",
      tripStarttime: "2024-07-24T10:00:00Z",
      tripEndgeofance: "Client Site 4",
      tripEndtime: "2024-07-24T11:15:00Z",
      distance: 35,
      running: "1h 15m",
      idle: "12m",
      stop: 1,
      inactive: "4m",
      durations: 4500,
    },
    {
      id: 8,
      company: "Yellow Star Transport",
      branch: "East Branch",
      vehicleName: "Bus 808",
      vehicleBrand: "Scania",
      vehicleModel: "Citywide",
      driver: "Sophia Silver",
      imei: "890123456789012",
      tripStartgeofance: "Station H",
      tripStarttime: "2024-07-24T07:00:00Z",
      tripEndgeofance: "Terminal 2",
      tripEndtime: "2024-07-24T08:30:00Z",
      distance: 55,
      running: "1h 30m",
      idle: "8m",
      stop: 2,
      inactive: "2m",
      durations: 5400,
    },
    {
      id: 9,
      company: "Urban Express",
      branch: "Central Branch",
      vehicleName: "Bike 909",
      vehicleBrand: "Honda",
      vehicleModel: "CBR500R",
      driver: "Liam Gold",
      imei: "901234567890123",
      tripStartgeofance: "Hub I",
      tripStarttime: "2024-07-24T12:00:00Z",
      tripEndgeofance: "Client Site 5",
      tripEndtime: "2024-07-24T12:40:00Z",
      distance: 15,
      running: "40m",
      idle: "5m",
      stop: 1,
      inactive: "1m",
      durations: 2400,
    },
    {
      id: 10,
      company: "Purple Path Logistics",
      branch: "South Branch",
      vehicleName: "Car 1010",
      vehicleBrand: "BMW",
      vehicleModel: "X5",
      driver: "Charlotte Red",
      imei: "012345678901234",
      tripStartgeofance: "Office J",
      tripStarttime: "2024-07-24T08:00:00Z",
      tripEndgeofance: "Client Site 6",
      tripEndtime: "2024-07-24T09:15:00Z",
      distance: 25,
      running: "1h 15m",
      idle: "10m",
      stop: 1,
      inactive: "3m",
      durations: 4500,
    }
  ];
  