import { IViolations } from '../interfaces/IViolation';

export const violationData: IViolations[] = [
    {
        Date: '2024-01-10',
        Route_Number: 'R123',
        Route_Details: 'Downtown to Uptown',
        Vehicle_Number: 'V001',
        Driver_Name: 'John Doe',
        Violation_Start_Time: '2024-01-10T08:00:00',
        Violation_End_Time: '2024-01-10T08:30:00',
        Violation_Distance: '15.2',
        G_Map: 'https://maps.google.com/?q=40.712776,-74.005974'
    },
    {
        Date: '2024-01-12',
        Route_Number: 'R456',
        Route_Details: 'Airport to City Center',
        Vehicle_Number: 'V002',
        Driver_Name: 'Jane Smith',
        Violation_Start_Time: '2024-01-12T09:15:00',
        Violation_End_Time: '2024-01-12T09:45:00',
        Violation_Distance: '20.5',
        G_Map: 'https://maps.google.com/?q=34.052235,-118.243683'
    },
    {
        Date: '2024-01-15',
        Route_Number: 'R789',
        Route_Details: 'Northside to Southside',
        Vehicle_Number: 'V003',
        Driver_Name: 'Emily Johnson',
        Violation_Start_Time: '2024-01-15T11:00:00',
        Violation_End_Time: '2024-01-15T11:30:00',
        Violation_Distance: '18.7',
        G_Map: 'https://maps.google.com/?q=37.774929,-122.419418'
    },
    {
        Date: '2024-01-18',
        Route_Number: 'R101',
        Route_Details: 'Eastside to Westside',
        Vehicle_Number: 'V004',
        Driver_Name: 'Michael Brown',
        Violation_Start_Time: '2024-01-18T14:00:00',
        Violation_End_Time: '2024-01-18T14:45:00',
        Violation_Distance: '22.3',
        G_Map: 'https://maps.google.com/?q=40.730610,-73.935242'
    },
    {
        Date: '2024-01-20',
        Route_Number: 'R202',
        Route_Details: 'Suburban to Downtown',
        Vehicle_Number: 'V005',
        Driver_Name: 'Laura Green',
        Violation_Start_Time: '2024-01-20T16:00:00',
        Violation_End_Time: '2024-01-20T16:30:00',
        Violation_Distance: '12.9',
        G_Map: 'https://maps.google.com/?q=51.507351,-0.127758'
    }
];
