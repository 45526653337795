import { TableColumn } from 'react-data-table-component';

export interface IViolationService {
    getViolation(): Promise<IViolations[]>;
}

export type IViolations = {
    Date: string;
    Route_Number: string;
    Route_Details: string;
    Vehicle_Number: string;
    Driver_Name: string;
    Violation_Start_Time: string;
    Violation_End_Time: string;
    Violation_Distance: string;
    G_Map: string;
};

export const IViolationsDataColumn: TableColumn<IViolations>[] = [
    { name: 'Date', selector: (row: IViolations) => row.Date, sortable: true },
    { name: 'Route Number', selector: (row: IViolations) => row.Route_Number, sortable: true },
    { name: 'Route Details', selector: (row: IViolations) => row.Route_Details, sortable: true },
    { name: 'Vehicle Number', selector: (row: IViolations) => row.Vehicle_Number, sortable: true },
    { name: 'Driver Name', selector: (row: IViolations) => row.Driver_Name, sortable: true },
    { name: 'Violation Start Time', selector: (row: IViolations) => row.Violation_Start_Time, sortable: true },
    { name: 'Violation End Time', selector: (row: IViolations) => row.Violation_End_Time, sortable: true },
    { name: 'Violation Distance(KMs)', selector: (row: IViolations) => row.Violation_Distance, sortable: true },
    { name: 'G-Map', selector: (row: IViolations) => row.G_Map, sortable: true }
];
