import {IObjectAlertReport,IObjectAlertReportSingle} from "../interfaces/IObjectService";

export const objectAlertReportsData: IObjectAlertReport[] = [
    {
        id: 1,
        branch: "New York",
        objectName: "Vehicle A",
        imeiNumber: 123456789012345,
        alertType: "Speeding",
        alertDuration: 15,
        numberOfAlerts: 3,
    },
    {
        id: 2,
        branch: "Los Angeles",
        objectName: "Vehicle B",
        imeiNumber: 234567890123456,
        alertType: "Harsh Braking",
        alertDuration: 10,
        numberOfAlerts: 5,
    },
    {
        id: 3,
        branch: "Chicago",
        objectName: "Vehicle C",
        imeiNumber: 345678901234567,
        alertType: "Idling",
        alertDuration: 25,
        numberOfAlerts: 2,
    },
    {
        id: 4,
        branch: "Houston",
        objectName: "Vehicle D",
        imeiNumber: 456789012345678,
        alertType: "Geofence Breach",
        alertDuration: 20,
        numberOfAlerts: 4,
    },
    {
        id: 5,
        branch: "Phoenix",
        objectName: "Vehicle E",
        imeiNumber: 567890123456789,
        alertType: "Harsh Acceleration",
        alertDuration: 12,
        numberOfAlerts: 1,
    },
    {
        id: 6,
        branch: "Philadelphia",
        objectName: "Vehicle F",
        imeiNumber: 678901234567890,
        alertType: "Speeding",
        alertDuration: 18,
        numberOfAlerts: 6,
    },
    {
        id: 7,
        branch: "San Antonio",
        objectName: "Vehicle G",
        imeiNumber: 789012345678901,
        alertType: "Harsh Braking",
        alertDuration: 8,
        numberOfAlerts: 3,
    },
    {
        id: 8,
        branch: "San Diego",
        objectName: "Vehicle H",
        imeiNumber: 890123456789012,
        alertType: "Idling",
        alertDuration: 22,
        numberOfAlerts: 2,
    },
    {
        id: 9,
        branch: "Dallas",
        objectName: "Vehicle I",
        imeiNumber: 901234567890123,
        alertType: "Geofence Breach",
        alertDuration: 30,
        numberOfAlerts: 7,
    },
    {
        id: 10,
        branch: "San Jose",
        objectName: "Vehicle J",
        imeiNumber: 123456789012346,
        alertType: "Harsh Acceleration",
        alertDuration: 14,
        numberOfAlerts: 4,
    }
];

export const objectAlertReportSingles: IObjectAlertReportSingle[] = [
    {
        id: 1,
        branch: "New York",
        object: "Vehicle A",
        imeiNumber: 123456789012345,
        geofenceName: "Geofence 1",
        eventStartTime: "2024-08-15T08:00:00Z",
        eventEndTime: "2024-08-15T08:15:00Z",
        speed: "65 km/h",
        location: "40.7128° N, 74.0060° W",
        coordinates: "40.7128, -74.0060",
        driver: "John Doe",
        alertType: "Speeding",
        alertInformations: "Exceeded speed limit by 15 km/h",
        alertDuration: 15,
    },
    {
        id: 2,
        branch: "Los Angeles",
        object: "Vehicle B",
        imeiNumber: 234567890123456,
        geofenceName: "Geofence 2",
        eventStartTime: "2024-08-15T09:00:00Z",
        eventEndTime: "2024-08-15T09:20:00Z",
        speed: "80 km/h",
        location: "34.0522° N, 118.2437° W",
        coordinates: "34.0522, -118.2437",
        driver: "Jane Smith",
        alertType: "Harsh Braking",
        alertInformations: "Sudden brake applied",
        alertDuration: 20,
    },
    {
        id: 3,
        branch: "Chicago",
        object: "Vehicle C",
        imeiNumber: 345678901234567,
        geofenceName: "Geofence 3",
        eventStartTime: "2024-08-15T10:00:00Z",
        eventEndTime: "2024-08-15T10:25:00Z",
        speed: "50 km/h",
        location: "41.8781° N, 87.6298° W",
        coordinates: "41.8781, -87.6298",
        driver: "Alice Johnson",
        alertType: "Idling",
        alertInformations: "Vehicle idling for more than 10 minutes",
        alertDuration: 25,
    },
    {
        id: 4,
        branch: "Houston",
        object: "Vehicle D",
        imeiNumber: 456789012345678,
        geofenceName: "Geofence 4",
        eventStartTime: "2024-08-15T11:00:00Z",
        eventEndTime: "2024-08-15T11:10:00Z",
        speed: "70 km/h",
        location: "29.7604° N, 95.3698° W",
        coordinates: "29.7604, -95.3698",
        driver: "Bob Brown",
        alertType: "Geofence Breach",
        alertInformations: "Exited geofence area",
        alertDuration: 10,
    },
    {
        id: 5,
        branch: "Phoenix",
        object: "Vehicle E",
        imeiNumber: 567890123456789,
        geofenceName: "Geofence 5",
        eventStartTime: "2024-08-15T12:00:00Z",
        eventEndTime: "2024-08-15T12:15:00Z",
        speed: "90 km/h",
        location: "33.4484° N, 112.0740° W",
        coordinates: "33.4484, -112.0740",
        driver: "Charlie Davis",
        alertType: "Harsh Acceleration",
        alertInformations: "Rapid acceleration detected",
        alertDuration: 15,
    },
    {
        id: 6,
        branch: "Philadelphia",
        object: "Vehicle F",
        imeiNumber: 678901234567890,
        geofenceName: "Geofence 6",
        eventStartTime: "2024-08-15T13:00:00Z",
        eventEndTime: "2024-08-15T13:18:00Z",
        speed: "75 km/h",
        location: "39.9526° N, 75.1652° W",
        coordinates: "39.9526, -75.1652",
        driver: "David Wilson",
        alertType: "Speeding",
        alertInformations: "Exceeded speed limit by 10 km/h",
        alertDuration: 18,
    },
    {
        id: 7,
        branch: "San Antonio",
        object: "Vehicle G",
        imeiNumber: 789012345678901,
        geofenceName: "Geofence 7",
        eventStartTime: "2024-08-15T14:00:00Z",
        eventEndTime: "2024-08-15T14:08:00Z",
        speed: "60 km/h",
        location: "29.4241° N, 98.4936° W",
        coordinates: "29.4241, -98.4936",
        driver: "Eve Green",
        alertType: "Harsh Braking",
        alertInformations: "Sudden brake applied",
        alertDuration: 8,
    },
    {
        id: 8,
        branch: "San Diego",
        object: "Vehicle H",
        imeiNumber: 890123456789012,
        geofenceName: "Geofence 8",
        eventStartTime: "2024-08-15T15:00:00Z",
        eventEndTime: "2024-08-15T15:22:00Z",
        speed: "85 km/h",
        location: "32.7157° N, 117.1611° W",
        coordinates: "32.7157, -117.1611",
        driver: "Frank Hall",
        alertType: "Idling",
        alertInformations: "Vehicle idling for more than 15 minutes",
        alertDuration: 22,
    },
    {
        id: 9,
        branch: "Dallas",
        object: "Vehicle I",
        imeiNumber: 901234567890123,
        geofenceName: "Geofence 9",
        eventStartTime: "2024-08-15T16:00:00Z",
        eventEndTime: "2024-08-15T16:30:00Z",
        speed: "55 km/h",
        location: "32.7767° N, 96.7970° W",
        coordinates: "32.7767, -96.7970",
        driver: "Grace Lee",
        alertType: "Geofence Breach",
        alertInformations: "Entered restricted geofence area",
        alertDuration: 30,
    },
    {
        id: 10,
        branch: "San Jose",
        object: "Vehicle J",
        imeiNumber: 123456789012346,
        geofenceName: "Geofence 10",
        eventStartTime: "2024-08-15T17:00:00Z",
        eventEndTime: "2024-08-15T17:14:00Z",
        speed: "78 km/h",
        location: "37.3382° N, 121.8863° W",
        coordinates: "37.3382, -121.8863",
        driver: "Hank Miller",
        alertType: "Harsh Acceleration",
        alertInformations: "Quick acceleration detected",
        alertDuration: 14,
    }
];