import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import Table from '../../../components/table/Table';
import useHttp from '../../../hooks/useHttp';
import { IDeviceType, DeviceTypeDataColumn } from '../../../services/interfaces/IDviceType';



// 
interface ApiResponce{
    message:string;
    data: IDeviceType[];
}

interface ICompany_id{
    companyId?:string;
}
const DeviceTypeUI: React.FC <ICompany_id> = ({companyId}) => {
    const [data, setData] = useState<IDeviceType[]>([]);
    const { request } = useHttp();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request({
                    url: '/device-type',
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch Device Type');
                }
                const data=await response.json() as ApiResponce;
                setData(data.data);
            } catch (error) {
                console.error('Error fetching Device data:', error);
            }
        };

        void fetchData();
    }, []); 

    const actions = [
        { name: 'View', path: '/systemAdmin/manageDeviceType/view', icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: '/systemAdmin/manageDeviceType/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-2' },
        {
            name: 'Delete',
            path: '/device-type/',
            icon: FaTrash,
            class: 'text-red-700 text-2xl'
        }
    ];
console.log(companyId);
    return (
        <>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl text-gray-800'>All Device</h1>
                <Link to='/company/dashboard' className='flex items-center text-xl hover:bg-blue-500 mb-[30px]'>
                    <AiFillDashboard className='mx-3 ' /> Home
                </Link>
            </div>

            <div className='relative mb-2 mt-[-10px] bg-green-400'>
                <Link
                    to={`/systemAdmin/manageDeviceType/create`}
                    className='absolute right-2 top-[-20px] bg-blue-950 text-white px-5 border border-green-300 rounded'
                                    >
                    Add New Device Type
                </Link>
            </div>

            <div className=''>
                <Table<IDeviceType>
                    data={data}
                    columns={DeviceTypeDataColumn}
                    tableRowActions={actions}
                    searchAble={true}
                />
            </div>
        </>
    );
};

export default DeviceTypeUI;
