import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from '../../../../../../components/table/Table';
import { createPreventiveMaintainence } from '../../../../../../services/ServiceFactory';
import { IPreventiveMaintainence, PreventiveMaintainenceDataColumn } from '../../../../../../services/interfaces/IPreventiveMaintainenceService';
import Button from '../../../../../../components/Button';

const Preventive_MaintainenceTable: React.FC = () => {
  const [data, setData] = useState<IPreventiveMaintainence[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const preventiveMaintainenceService = createPreventiveMaintainence();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: IPreventiveMaintainence[] = await preventiveMaintainenceService.getPreventiveMaintainence();
        setData(data);
      } catch (error) {
        console.error('Error fetching preventive maintenance data:', error);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [preventiveMaintainenceService]);

  const actions = [
    { 
      name: 'Edit', 
      icon: FaEdit, 
      path:'/company/settings/management/Preventive_Maintainence/addPreventive_Maintainence/edit',
      class: 'text-green-700 text-2xl mx-1', 
    },
    { 
      name: 'Delete', 
      icon: FaTrash, 
      class: 'text-red-700 text-2xl' 
    },
  ];

  return (
    <div className="mt-4">
      <div className="mt-4 text-end">
        <Link   to="/company/settings/management/Preventive_Maintainence/addPreventive_Maintainence" >
        <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
            Add
          </Button>
        </Link>
      </div>
      <Table<IPreventiveMaintainence>
        columns={PreventiveMaintainenceDataColumn}  
        data={data}
        loading={loading}
        tableRowActions={actions}
        excelExport={false}
        searchAble={true}
        pdfExport={false}
      />
    </div>
  );
};

export default Preventive_MaintainenceTable;
