import React, { ReactNode } from 'react';
import styles from './Showcalculation.module.css';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children?: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.main}>
            <div className={styles.subMain}>
                <div className='w-full bg-sky-950 text-white'>
                    Driver Rating Calculation
                    <button onClick={onClose} className='absolute top-1 right-2 text-white'>
                        &#x2715;
                    </button>
                </div>
                <div className='border borderlack-400 w-5/6 m-auto '>
                    <form action='' className='mb-5 mt-3  '>
                        <div className=''>
                            <label htmlFor='overspeed' className='mr-10'>
                                Over Speed
                            </label>
                            <input type='text' name='overspeed' className='border border-gray-400' />
                        </div>
                        <div className='mt-2'>
                            <label htmlFor='distance' className='mr-16'>
                                Distance
                            </label>

                            <input type='text' name='distance' className='border border-gray-400' />
                        </div>
                    </form>
                    <table className={styles.table}>
                    <caption className={styles.caption}>Overspeed Range</caption>
                    <thead className=' '>
                            <tr>
                            <th className={styles.th}>
                                    
                                    <p>Palanty Range</p>
                                    <p className={styles.textredsmall}>
                                        Range:(Overspeed(260)+ start-range)to (overspeed(260)+ end-range)
                                    </p>
                                </th>
                                <th  className={styles.th}>Panlanty point</th>
                                <th className={styles.th}>
                                    Duration
                                    <p className={styles.textredsmall}>Minute(5)</p>
                                </th>
                                <th className={styles.th}>
                                    Total Panalty{' '}
                                    <p className={styles.textredsmall}>
                                        Penalty Point *Duration/Configration duration(1 Minute(s))
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            <tr>
                                <td className={styles.td}>
                                    260 to 280
                                    <br />
                                    <span className={styles.textredsmall}>+0 to +20</span>
                                </td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>
                                    280 to 290 <br /> <span className={styles.textredsmall}>+20 to +30</span>
                                </td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>
                                    290 to 310 <br /> <span className={styles.textredsmall}>+30 to +50</span>
                                </td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>
                                    More than 310 <br /> <span className={styles.textredsmall}>More than 50</span>
                                </td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Total Penalty Points</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td} ></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className={styles.table}>
                    <caption className={styles.caption}>Harsh Driving</caption>
                    <tbody>
                            <tr className=''>
                                <th className={styles.th}>Type</th>
                                <th className={styles.th}>Penalty Point</th>
                                <th className={styles.th}>Number of Violations</th>
                                <th className={styles.th}>Total Penalty</th>
                            </tr>
                            <tr>
                                <td className={styles.td}>Harsh Acceleration</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Harsh Braking</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Harsh Cornering</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Harsh Acceleration in Cornering</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Harsh Braking in Cornering</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>Total Penalty Points</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className={styles.table}>
                    <caption className={styles.caption}>Idling</caption>
                        <tbody>
                            <tr className='bg-gray-200'>
                                <th className='p-2 text-left'>
                                    Duration <br />
                                    <span className={styles.textredsmall}>(Minute(s))</span>
                                </th>
                                <th className='p-2 text-left'>
                                    Total Penalty <br />
                                    <span className={styles.textredsmall}>
                                        Penalty Point (5) x Duration / Configuration duration (5 Minute(s))
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <td className={styles.td}>1</td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>2</td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>3</td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>4</td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className='p-2 font-bold'>Total Penalty Points</td>
                                <td className='p-2'></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className={styles.table}>
                        <tbody>
                            <tr className=''>
                                <th className={styles.th}>Range</th>
                                <th className={styles.th}>Rating</th>
                                <th className={styles.th}>Driver Behaviour</th>
                            </tr>
                            <tr>
                                <td className={styles.td}>100 - 80</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>80 - 60</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>60 - 40</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className={styles.td}>40 - 20</td>
                                <td className={styles.td}></td>
                                <td className={styles.td}></td>
                            </tr>
                            <tr>
                                <td className='p-2'>Below 20</td>
                                <td className='p-2'></td>
                                <td className='p-2'></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Modal;
