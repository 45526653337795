import React from 'react';

function Footer() {
    return (
        <div className='bg-white w-full h-[50px]  border-t border-gray-400'>
            <div className='flex justify-between items-center content-center '>
                <div>
                <p className='mt-4 ml-4 text-sm'> <span className='font-bold text-gray-700'>Copyright</span> &copy; <span className='font-bold text-gray-700'>2024</span> All rights reserved.</p>
                </div>
                <div>
                    <p className='mt-4 mr-4 text-sm'><span className='font-bold text-gray-700'>Version</span> 1.0</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
