import React, { useState, useEffect,useRef  } from 'react';
import InputField from '../../../components/InputFeild';
import Button from '../../../components/Button';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';

interface UserInformation {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    profilePhoto: File | null;
}

interface ApiResponse {
    message: string,
    data: UserResponse,
}

interface UserResponse {

    id: number;
    email: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    userType: string;
    companyId: number;
    role: string;
    roleId: number;
}


function Information() {
    const { request } = useHttp();
    const isFetched = useRef(false);

    const [formData, setFormData] = useState<UserInformation>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        profilePhoto: null,
    });


    const fetchCurrentUser = async () => {
        try {
            const response = await request({
                url: '/current-user',
                method: 'GET',
            });
            const data = (await response.json()) as ApiResponse;

            if (data && data.data) {
                setFormData({
                    firstName: data.data.firstName || '',
                    lastName: data.data.lastName || '',
                    email: data.data.email || '',
                    phone: data.data.phone || '',
                    profilePhoto: null,
                });
            }
        } catch {
            console.error('Error fetching user data');
        }
    };

    useEffect(() => {
        if (!isFetched.current) {
            isFetched.current = true;
            void fetchCurrentUser();
        }
    }, []);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value, files } = e.target;
        setFormData({
            ...formData,
            [id]: files && files[0] ? files[0] : value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Updated data:', formData);
    
        try {
            const response = await request({
                url: '/update-profile',
                method: 'PUT',
                body: formData,
            });
    
            const responseData = (await response.json()) as { status: string; message: string };
    
            if (response.ok) {
                toast.success(responseData.message);
            } else {
                toast.error(`Error: ${responseData.message}`);
            }
        } catch (error) { // Removed type annotation
            if (error instanceof Error) {
                toast.error(`Request failed: ${error.message}`);
            } else {
                toast.error('An unexpected error occurred');
            }
        }
    };
    

    return (
        <div>
            <div className='w-full p-4 bg-white rounded-b shadow-md'>


                <form className='md:ml-20' onSubmit={(e) => { void handleSubmit(e); }}>
                    <div className='md:flex gap-x-6'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='firstName'>First Name</label>
                        </div>
                        <div className='grid md:grid-cols-2 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <div>
                                <InputField
                                    type='text'
                                    id='firstName'
                                    placeholder='Enter first name'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className='block w-full p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none'
                                />
                            </div>
                            <div>
                                <InputField
                                    type='text'
                                    id='lastName'
                                    placeholder='Enter last name'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className='block w-full p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none mt-3 md:mt-0'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='email'>Email</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <div>
                                <InputField
                                    type='text'
                                    id='email'
                                    placeholder='Enter email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    className='block w-full p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='phone'>Phone Number</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <div>
                                <InputField
                                    type='text'
                                    id='phone'
                                    placeholder='Enter phone number'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className='block w-full p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='profilePhoto'>Profile Photo</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <div>
                                <input
                                    type='file'
                                    id='profilePhoto'
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 md:ml-[150px]'>
                        <Button type='submit' className='w-[80px] h-[35px] bg-red-500 text-white rounded'>
                            Update
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Information;
