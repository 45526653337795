import { TableColumn } from 'react-data-table-component';

export interface IAllGeofanceService {
    getAllgeofance(): Promise<IAllGeofance[]>;
}
// 
export type IAllGeofance = {
    id: number;
    geofanceName: string;
    geofancetype: string;
    created_at: string;
    updated_at: string;
};

export const AllGeofanceDataColumn: TableColumn<IAllGeofance>[] = [
    { name: 'Id', selector: (row: IAllGeofance) => row.id, sortable: true },
    { name: 'Geofence Name', selector: (row: IAllGeofance) => row.geofanceName, sortable: true },
    { name: 'Geofence Type', selector: (row: IAllGeofance) => row.geofancetype, sortable: true },
    { name: 'Created At', selector: (row: IAllGeofance) => row.created_at, sortable: true },
    { name: 'Updated At', selector: (row: IAllGeofance) => row.updated_at, sortable: true }
];
// export type IGeofence = {
//     id: number;
//     name: string;
//     geofenceType?: string;
//     enabled?: boolean;
// }

export type IGeofence = {
    id: number;
    name: string;
    enabled: boolean;
    coordinates: [number, number][];
    companyId: number;
    userId: number ;
    geofenceType: string; 
  }
//coordinates: [number, number][]; 

export const GeofenceTableColumns: TableColumn<IGeofence>[] = [
    {
        name: 'ID',
        selector: (row: IGeofence) => row.id,
        sortable: true
    },
    {
        name: 'Geofence Name',
        selector: (row: IGeofence) => row.name,
        sortable: true
    },
    {
        name: 'Geofence Type',
        selector: (row: IGeofence) => row.geofenceType ?? 'N/A',  // Handle optional value
        sortable: true
    },
    {
        name: 'Enabled',
        selector: (row: IGeofence) => (row.enabled ? 'Yes' : 'No'),  // Custom text for boolean value
        sortable: true
    },
   
];