import React, { SyntheticEvent, useEffect, useState } from 'react';
import InputField from '../../../../../../../components/InputFeild';
import Dropdown from '../../../../../../../components/Dropdown';
import { VehicleGeneral } from '../../../../../../../utils/interfaces/masterVehicle';
import { useNavigate } from 'react-router-dom';
import useHttp from '../../../../../../../hooks/useHttp';
import { toast } from 'react-toastify';
import Button from '../../../../../../../components/Button';
import SingleSelectDropdown from '../../../../../../../components/SingleSelectDropdown';

interface Option {
  value: string;
  label: string;
}

interface ApiResponce{
  message:string,
  data:Device[]
}

interface GeneralTabProps {
  data?: VehicleGeneral;
  id?: string;
}

interface createVehicleApi {
  name: string;
  number: string;
  deviceId: string;
  enabled?: boolean;
}

interface Device {
  companyId: number;
  createdAt: string;
  deviceTypeId: number;
  enabled: boolean;
  id: number;
  imei: string;
  name: string;
  updatedAt: string;
  userId: number;
}

const GeneralTab: React.FC<GeneralTabProps> = ({ data, id }) => {
  const [vehicleName, setvehicleName] = useState('');
  const [imeiNumber, setImeiNumber] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [deviceOptions, setDeviceOptions] = useState<{ value: string; label: string }[]>([]);
  const [gpsDeviceType, setGpsDeviceType] = useState<string | null>(null);  
  const [ignoreHeartBeatData, setIgnoreHeartBeatData] = useState(false);
  const [copyFrom, setCopyFrom] = useState<string | null>(null);
  const [serverAddress, setServerAddress] = useState('');
  const [simNumber, setSimNumber] = useState('');
  const [secondarySimNumber, setSecondarySimNumber] = useState('');
  const [timezone, setTimezone] = useState<number | null>(null);
  const [distanceCounter, setDistanceCounter] = useState<string | null>(null);
  const [unitOfDistance, setUnitOfDistance] = useState<string | null>(null);
  const [speedDetection, setSpeedDetection] = useState<string | null>(null);
  const [distanceVariation, setDistanceVariation] = useState({ dropdownValue: '', inputValue: '' });
  const [deviceAccuracyTolerance, setDeviceAccuracyTolerance] = useState('');
  const [shiftGroup, setShiftGroup] = useState('');
  const [shiftName, setShiftName] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [vehicleNameError, setVehicleNameError] = useState('');
  const [imeiNumberError, setImeiNumberError] = useState('');
  const [status, setStatus] = useState<boolean>(true);
  const { request } = useHttp();

  const handleDeviceSelect = (value: string) => {
    setSelectedDevice(value);
  };
  const fetchDevice = async () => {
    try {
      const response = await request({
        url: "/device",
        method: "GET",
      });

      if (!response.ok) {
        throw new Error('Failed to fetch vehicles');
      }


      const devices: ApiResponce = await response.json() as ApiResponce;


      const options = devices.data.map((device) => ({
        value: device.id.toString(),
        label: device.name + ', ' + device.imei,
      }));

      setDeviceOptions(options);
    }
    catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  }

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        await fetchDevice();
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };
    void fetchDeviceData();

    if (data) {
      setvehicleName(data.vehicleName || '');
      setImeiNumber(data?.imeiNumber || '');
      setSelectedDevice(data?.selectedDevice || '');
      setGpsDeviceType(data.gpsDeviceType ? data.gpsDeviceType[0] : null);
      setIgnoreHeartBeatData(data?.ignoreHeartBeatData || false);
      setCopyFrom(data?.copyFrom ? data.copyFrom[0] : null);
      setServerAddress(data.serverAddress || '');
      setSimNumber(data.simNumber || '');
      setSecondarySimNumber(data.secondarySimNumber || '');
      setTimezone(data.timezone ? Number(data.timezone) : null);
      setDistanceCounter(data.distanceCounter && Array.isArray(data.distanceCounter) && data.distanceCounter.length > 0 
  ? data.distanceCounter[0] as string 
  : null);
      setUnitOfDistance(data.unitOfDistance ? data.unitOfDistance[0] : null);
      setSpeedDetection(data.speedDetection ? data.speedDetection[0] : null);
      setDistanceVariation(data.distanceVariation || { dropdownValue: '', inputValue: '' });
      setDeviceAccuracyTolerance(data.deviceAccuracyTolerance || '');
      setShiftGroup(data.shiftGroup || '');
      setShiftName(data.shiftName || '');
      setQrCode(data.qrCode || '');
      setStatus(typeof data.enabled === 'boolean' ? data.enabled : true);


    }
  }, [data]);

  const validateForm = () => {
    let isValid = true;

    if (vehicleName.trim() === '') {
      setVehicleNameError('Vehicle Name is required');
      isValid = false;
    } else {
      setVehicleNameError('');
    }

    if (imeiNumber.trim() === '' || !/^\d{15}$/.test(imeiNumber)) {
      setImeiNumberError('IMEI Number must be required');
      isValid = false;
    } else {
      setImeiNumberError('');
    }

    return isValid;
  };


  const handleSelect = (selectedValue: string | null) => {
    setGpsDeviceType(selectedValue);
  };
  const handleCopyFromSelect = (selectedValue: string | null) => {
    setCopyFrom(selectedValue);
  };

  const handleUnitOfDistanceSelect = (selectedValue: string | null) => {
    setUnitOfDistance(selectedValue);
  };

  const handleSpeedDetectionSelect = (selectedValue: string | null) => {
    setSpeedDetection(selectedValue);
  };

  const handleDistanceCounterSelect = (selectedValue: string | null) => {
    setDistanceCounter(selectedValue);
  };

  const handleSelectChange = (selectedValue: string, name: string) => {
    const newValue = Number(selectedValue);
    if (name === 'timezone') {
      setTimezone(newValue);
    } 
  };

  const navigate = useNavigate();

  const submitVehicle = async (data: createVehicleApi, id?: number) => {
    try {
      const url = id ? `/vehicles/${id}` : `/vehicles`;
      const method = id ? 'PUT' : 'POST';

      const response = await request({
        url,
        method,
        body: data,
      });
      if (method === 'POST') {
        toast.success("Vehicle Created Successfully");
      } else {
        toast.success("Vehicle Updated Successfully");
      }

      navigate('/company/settings/master/vehicle');

      console.log("response", response);
    } catch (error) {
      console.error("Error in submitVehicle:", error);
    }
  };


  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    const data: createVehicleApi = {
      name: vehicleName,
      number: imeiNumber,
      deviceId: selectedDevice,
      enabled: status,
    };

    const parsedId = id ? parseInt(id, 10) : undefined;

    submitVehicle(data, parsedId)
      .catch(error => {
        console.error("Error submitting vehicle:", error);
      });

    if (validateForm()) {
      const formData = {
        vehicleName,
        selectedDevice,
        imeiNumber,
        gpsDeviceType,
        ignoreHeartBeatData,
        copyFrom,
        serverAddress,
        simNumber,
        secondarySimNumber,
        timezone,
        distanceCounter,
        unitOfDistance,
        speedDetection,
        distanceVariation,
        deviceAccuracyTolerance,
        shiftGroup,
        shiftName,
        qrCode,
      };
      console.log('Form Data:', formData);
    }
  };


  const handleBack = () => {
    navigate('/company/settings/master/vehicle');
  };

  const deviceTypeOptions: Option[] = [
    { label: 'Device 1', value: 'device1' },
    { label: 'Device 2', value: 'device2' },
    { label: 'Device 3', value: 'device3' },
  ];


  const copyFromOptions: Option[] = [
    { value: 'copy1', label: 'Copy 1' },
    { value: 'copy2', label: 'Copy 2' },
    { value: 'copy3', label: 'Copy 3' }
  ];


  const unitOfDistanceOptions: Option[] = [
    { value: 'unit1', label: 'Unit 1' },
    { value: 'unit2', label: 'Unit 2' },
    { value: 'unit3', label: 'Unit 3' }
  ];

  const speedDetectionOptions: Option[] = [
    { value: 'speed1', label: 'Speed 1' },
    { value: 'speed2', label: 'Speed 2' },
    { value: 'speed3', label: 'Speed 3' }
  ];

  const deviceTimezoneOptions = [
    { value: '1', label: 'Timezone 1' },
    { value: '2', label: 'Timezone 2' },
    { value: '3', label: 'Timezone 3' }
  ];

  const distanceCounterOptions = [
    { value: 'Counter 1', label: 'Counter 1' },
    { value: 'Counter 2', label: 'Counter 2' },
    { value: 'Counter 3', label: 'Counter 3' }
  ];

  const distanceVariationOptions: Option[] = [
    { value: 'variation1', label: 'Variation 1' },
    { value: 'variation2', label: 'Variation 2' },
    { value: 'variation3', label: 'Variation 3' }
  ];

  // Status options for the dropdown
  const statusOptions = [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' },
  ];

  // Handler for status change
  const handleStatusChange = (selectedValue: string) => {
    const newStatus = selectedValue === 'true';
    setStatus(newStatus);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3"></div>
      <div className="w-full md:w-2/3">
        <form onSubmit={handleSubmit}>
          {/* Name */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="name" className="w-1/3 font-semibold">Name: <span className="text-red-500">*</span></label>
            <div className="w-2/3">
              <InputField
                type="text"
                value={vehicleName}
                onChange={e => setvehicleName(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
              {vehicleNameError && <p className="text-red-500 text-sm mt-1">{vehicleNameError}</p>}
            </div>
          </div>

          {/* IMEI Number */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="imeiNumber" className="w-1/3 font-semibold">Vehicle Number: <span className="text-red-500">*</span></label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="imeiNumber"
                name="imeiNumber"
                value={imeiNumber}
                onChange={e => setImeiNumber(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
              {imeiNumberError && <p className="text-red-500 text-sm mt-1">{imeiNumberError}</p>}
            </div>
          </div>
          {/* device */}

          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="device" className="w-1/3 font-semibold">Device: <span className="text-red-500">*</span></label>
            <div className="w-2/3">
              <Dropdown
                options={deviceOptions}
                onSelect={handleDeviceSelect}
                selectedValue={selectedDevice}
                placeholder="Select Device"
                selectStyle={{ width: '16rem', minHeight: '2.5rem' }}
              />
            </div>
          </div>

          {/* Status */}
          {id && (
            <div className="mb-2 flex items-center space-x-4">
              <label htmlFor="status" className="w-1/3 font-semibold">
                Status:
              </label>
              <div className="w-2/3">
                <Dropdown
                  options={statusOptions}
                  onSelect={handleStatusChange}
                  selectedValue={status.toString()}
                  placeholder="Select Status"
                  selectStyle={{ width: '16rem', minHeight: '2.5rem' }}
                />
              </div>
            </div>
          )}

          {/* Device Type */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="deviceType" className="w-1/3 font-semibold">Device Type: <span className="text-red-500">*</span></label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={deviceTypeOptions}
                onSelect={handleSelect}
                selectedValue={gpsDeviceType}
                placeholder="Select Device Type"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
                />
            </div>
          </div>

          {/* Ignore Heart Beat Data */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="ignoreHeartBeatData" className="w-1/3 font-semibold">Ignore Heart Beat Data:</label>
            <div className="w-2/3">
              <input
                type="checkbox"
                id="ignoreHeartBeatData"
                name="ignoreHeartBeatData"
                checked={ignoreHeartBeatData}
                onChange={e => setIgnoreHeartBeatData(e.target.checked)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Copy From */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="copyFrom" className="w-1/3 font-semibold">Copy From:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={copyFromOptions}
                onSelect={handleCopyFromSelect} 
                selectedValue={copyFrom}
                placeholder="Select Copy From"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          {/* Server Address */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="serverAddress" className="w-1/3 font-semibold">Server Address:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="serverAddress"
                name="serverAddress"
                value={serverAddress}
                onChange={e => setServerAddress(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* SIM Number */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="simNumber" className="w-1/3 font-semibold">SIM Number:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="simNumber"
                name="simNumber"
                value={simNumber}
                onChange={e => setSimNumber(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Secondary SIM Number */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="secondarySimNumber" className="w-1/3 font-semibold">Secondary SIM Number:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="secondarySimNumber"
                name="secondarySimNumber"
                value={secondarySimNumber}
                onChange={e => setSecondarySimNumber(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Device Timezone */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="deviceTimezone" className="w-1/3 font-semibold">Device Timezone:</label>
            <div className="w-2/3">
              <Dropdown
                options={deviceTimezoneOptions}
                onSelect={value => handleSelectChange(value, 'timezone')}
                selectedValue={timezone?.toString() || ''}
                selectClass="p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                selectStyle={{ width: '16rem' }}
              />
            </div>
          </div>

          {/* Distance Counter */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="distanceCounter" className="w-1/3 font-semibold">Distance Counter:</label>
            <div className="w-2/3">
            <SingleSelectDropdown
            options={distanceCounterOptions} 
            onSelect={handleDistanceCounterSelect}
            selectedValue={distanceCounter}
            placeholder="Select Distance Counter"
            selectClass="border-gray-300 p-2 rounded"
            selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
            optionStyle={{width: '16rem'}}
          />
            </div>
          </div>


          {/* Unit of Distance */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="unitOfDistance" className="w-1/3 font-semibold">Unit of Distance:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={unitOfDistanceOptions}
                onSelect={handleUnitOfDistanceSelect} 
                selectedValue={unitOfDistance}
                placeholder="Select Unit of Distance"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          {/* Speed Detection */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="speedDetection" className="w-1/3 font-semibold">Speed Detection:</label>
            <div className="w-2/3">
              <SingleSelectDropdown
                options={speedDetectionOptions}
                onSelect={handleSpeedDetectionSelect} 
                selectedValue={speedDetection}
                placeholder="Select Speed Detection"
                selectClass="border-gray-300 p-2 rounded"
                selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
                optionStyle={{width: '16rem'}}
              />
            </div>
          </div>

          {/* Distance Variation */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="distanceVariation" className="w-1/3 font-semibold">
              Distance Variation:
            </label>
            <div className="w-2/3">
              <div className="flex items-center ">
                <Dropdown
                  options={distanceVariationOptions}
                  onSelect={value => setDistanceVariation(prev => ({ ...prev, dropdownValue: value }))}
                  selectedValue={distanceVariation.dropdownValue}
                  placeholder="+"
                  selectStyle={{ width: '8rem' }}
                />
                <InputField
                  type="number"
                  id="distanceVariation"
                  name="distanceVariation"
                  value={distanceVariation.inputValue}
                  onChange={e => setDistanceVariation(prev => ({ ...prev, inputValue: e.target.value }))}
                  className="w-24 ml-2 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                />
                <span className="ml-2">%</span>
              </div>
            </div>
          </div>

          {/* Device Accuracy Tolerance */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="deviceAccuracyTolerance" className="w-1/3 font-semibold">Device Accuracy Tolerance:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="deviceAccuracyTolerance"
                name="deviceAccuracyTolerance"
                value={deviceAccuracyTolerance}
                onChange={e => setDeviceAccuracyTolerance(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Shift Group */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="shiftGroup" className="w-1/3 font-semibold">Shift Group:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="shiftGroup"
                name="shiftGroup"
                value={shiftGroup}
                onChange={e => setShiftGroup(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Shift Name */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="shiftName" className="w-1/3 font-semibold">Shift Name:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="shiftName"
                name="shiftName"
                value={shiftName}
                onChange={e => setShiftName(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* QR Code */}
          <div className="mb-2 flex items-center space-x-4">
            <label htmlFor="qrCode" className="w-1/3 font-semibold">QR Code:</label>
            <div className="w-2/3">
              <InputField
                type="text"
                id="qrCode"
                name="qrCode"
                value={qrCode}
                onChange={e => setQrCode(e.target.value)}
                className="w-64 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Right column (empty) */}
          <div className=""></div>
          <div className="mt-4 flex justify-end space-x-4">
            <Button
              type="submit"
              className="bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md"
            >
              {id ? 'Update' : 'Submit'}
            </Button>
            <Button
              onClick={handleBack}
              className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
            >
              Back
            </Button>
          </div>
        </form>
      </div>
      <div className="w-full md:w-1/3"></div>
    </div>
  );
}

export default GeneralTab;
