import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import { IDevices, IDevicesDataColumn } from '../../../services/interfaces/IDevices';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useHttp from '../../../hooks/useHttp';
import Table from '../../../components/table/Table';
import { toast } from 'react-toastify';


interface ApiResponse {
    message: string;
    data: IDevices[];
}

function DevicesUI() {
    const [data, setData] = useState<IDevices[]>([]);
    const { request } = useHttp();

    const actions = [
        { name: 'View', path: `/companyAdmin/add_devices/view`, icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: `/companyAdmin/add_devices/edit`, icon: FaEdit, class: 'text-green-700 text-2xl mx-1' },
        { name: 'Delete', path: '/device/', icon: FaTrash, class: 'text-red-700 text-2xl' }
    ];

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await request({
                    url: '/device',
                    method: 'GET'
                });
                const data1 = (await response.json()) as ApiResponse;
                // toast.success(data1.message);
                setData(data1.data);
            } catch(error) {
                if (error instanceof Error) {
                    toast.error(`Error: ${error.message}`);
                } else {
                    toast.error('An unexpected error occurred');
                }
            }
        };
        void fetchdata();
    }, []);
    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Devices</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Devices</p>
                </div>
            </div>
            <div className='mt-5 flex justify-end'>
                <Link to={'/companyAdmin/add_devices'}>
                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                        New Devices
                    </Button>
                </Link>
            </div>

            <Table<IDevices> searchAble={true} columns={IDevicesDataColumn} data={data} tableRowActions={actions} />
        </div>
    );
}

export default DevicesUI;
