import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from '../../../../../components/table/Table';
import { IAlertType, IAlertTypeDataColumn } from '../../../../../services/interfaces/IAlertType';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import useHttp from '../../../../../hooks/useHttp';


interface ApiResponce{
    message:string;
    data:IAlertType[];
}

function AlertTypeUI() {
    const [data, setData] = useState<IAlertType[]>([]);
    const { request } = useHttp();

    const actions = [
        { name: 'View', path: `/systemAdmin/new-alertType/view`, icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: `/systemAdmin/new-alertType/edit`, icon: FaEdit, class: 'text-green-700 text-2xl mx-1' },
        { name: 'Delete', path: '/alert-types/', icon: FaTrash, class: 'text-red-700 text-2xl' }
    ];

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await request({
                    url: '/alert-types',
                    method: 'GET'
                });
                const data1 = (await response.json()) as ApiResponce;

                setData(data1.data);
            } catch {
                console.log('Error fetching data');
            }
        };
        void fetchdata();
    }, []);
    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>All Alert Types</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Alert Type</p>
                </div>
            </div>
            <div className='mt-5 flex justify-end'>
                <Link to={'/systemAdmin/new-alertType'}>
                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                        New Alert Type
                    </Button>
                </Link>
            </div>

            <Table<IAlertType>
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                columns={IAlertTypeDataColumn}
                data={data}
                tableRowActions={actions}
            />
        </div>
    );
}

export default AlertTypeUI;
