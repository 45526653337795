import React from 'react';

interface InputProps {
  type: string;
  id?: string;
  placeholder?: string;
  value: string | number | Date | null; // Allow Date or null
  onChange: (e: React.ChangeEvent<HTMLInputElement> | Date | null) => void; // Allow Date or null
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  errorMessage?: string;
  maxLength?: number;
  inputKey?: string | number;
  disabled?: boolean;
  labelHtmlFor?: string;
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
  labelChildren?: React.ReactNode;
}

const Input: React.FC<InputProps> = ({
  type,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  className,
  style,
  name,
  errorMessage,
  inputKey,
  maxLength,
  disabled = false,
  labelHtmlFor,
  labelClassName,
  labelStyle,
  labelChildren,
  ...rest
}) => {
  // Handler to convert input value to Date if necessary
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    onChange(date);
  };

  // Render input based on type
  const renderInput = () => {
    if (type === 'date') {
      return (
        <input
          key={inputKey}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value instanceof Date ? value.toISOString().split('T')[0] : value ?? ''} // Convert Date to string if necessary
          onChange={handleDateChange} // Use handleDateChange directly
          onBlur={onBlur}
          className={className || 'px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'}
          style={style}
          maxLength={maxLength}
          {...rest}
        />
      );
    } else {
      return (
        <input
          key={inputKey}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          value={typeof value === 'string' || typeof value === 'number' ? value : ''} // Ensure value is string or number
          onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void} // Use type assertion only if needed
          onBlur={onBlur}
          className={className || 'px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'}
          style={style}
          maxLength={maxLength}
          disabled={disabled}
          {...rest}
        />
      );
    }
  };

  return (
    <div className="flex flex-col space-y-1">
      {labelChildren && (
        <label
          htmlFor={labelHtmlFor}
          className={labelClassName || ' text-sm font-semibold'}
          style={labelStyle}
        >
          {labelChildren}
        </label>
      )}
      {renderInput()}
      {error && onBlur && (
        <span className="text-red-500 text-xs mt-1">{errorMessage}</span>
      )}
    </div>
  );
};

export default Input;
