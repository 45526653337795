import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SingleSelectDropdown from '../../../../../components/SingleSelectDropdown';
import InputField from '../../../../../components/InputFeild';
import Input from '../../../../../components/Input';
import { createExpenseService } from '../../../../../services/ServiceFactory';
import Button from '../../../../../components/Button';

interface FormData {
    object_id: string;
    category: 'Variable' | 'Fix';
    job_selected: boolean;
    job_allocation: 'In-Progress' | 'Completed';
    completed_till: string;
    job_id: string;
    expense_type_id: string;
    expense_date: string;
    from_date: string;
    to_date: string;
    amount: string;
    reference_number: string;
    odometer: string;
    work_hour: string;
    bill_upload: File | null;
    description: string;
}

const NewExpense: React.FC = () => {
    const { id, action } = useParams<{ id: string; action: string }>();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
        object_id: '',
        category: 'Variable',
        job_selected: false,
        job_allocation: 'In-Progress',
        completed_till: '',
        job_id: '',
        expense_type_id: '',
        expense_date: '',
        from_date: '',
        to_date: '',
        amount: '',
        reference_number: '',
        odometer: '',
        work_hour: '',
        bill_upload: null,
        description: ''
    });

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [expenseDate, setExpenseDate] = useState<Date | null>(null);
    const [completed_till, setCompletedTill] = useState<Date | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (action === 'view') return;

        const { name, type, value, checked } = e.target as HTMLInputElement;

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (value) {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleSelect = (name: keyof FormData) => (selectedValue: string | null) => {
        if (action === 'view') return;

        setFormData(prevData => ({
            ...prevData,
            [name]: selectedValue ?? ''
        }));

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (selectedValue) {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    function parseDate(dateString: string | null): Date | null {
        return dateString ? new Date(dateString) : null;
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (action === 'view') return;
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : null
        });
    };

    useEffect(() => {
        setCompletedTill(parseDate(formData.completed_till));
        setExpenseDate(parseDate(formData.expense_date));
        setFromDate(parseDate(formData.from_date));
        setToDate(parseDate(formData.to_date));
    })

    useEffect(() => {

        if (id) {
            const fetchExpense = async () => {
                const expenseService = createExpenseService();
                try {
                    const expenses = await expenseService.getExpense();
                    const expenseToEdit = expenses.find((expense) => expense.id === parseInt(id, 10));
                    console.log("dummy data", expenseToEdit);
                    console.log(expenseToEdit);

                    if (expenseToEdit) {
                        setFormData({
                            object_id: expenseToEdit.Object,
                            category: expenseToEdit.Category === 'Variable' || expenseToEdit.Category === 'Fix' ? expenseToEdit.Category : 'Variable',
                            job_selected: expenseToEdit.Consider_Job || false,
                            job_allocation: 'In-Progress',
                            completed_till: expenseToEdit.Completed_Till || '',
                            job_id: expenseToEdit.Job_ID || '',
                            expense_type_id: expenseToEdit.Expense_Type_ID || '',
                            expense_date: expenseToEdit.Expense_Date || '',
                            from_date: expenseToEdit.Expense_From || '',
                            to_date: expenseToEdit.Expense_To || '',
                            amount: expenseToEdit.Amount.toString(),
                            reference_number: expenseToEdit.ReferenceNo,
                            odometer: expenseToEdit.Odometer || '',
                            work_hour: expenseToEdit.Work_Hour || '',
                            bill_upload: null,
                            description: expenseToEdit.Description
                        });
                    }
                } catch (error) {
                    console.error('Error fetching expense:', error);
                }
            };
            void fetchExpense();
            console.log(formData);
        }
    }, [id]);


    const validate = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        if (!formData.object_id) newErrors.object_id = 'Required';
        if (!formData.expense_type_id) newErrors.expense_type_id = 'Required';
        if (!formData.amount) newErrors.amount = 'Required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form submitted:', formData);
            navigate('/company/settings/management/expense');
        }
    };

    const handleBack = () => {
        navigate('/company/settings/management/expense');
    };

    const objectOptions = [
        { value: 'Object A', label: 'Object A' },
        { value: 'Object B', label: 'Object B' },
        { value: 'Object C', label: 'Object C' }
    ];

    const jobOptions = [
        { value: 'Job A', label: 'Job A' },
        { value: 'Job B', label: 'Job B' },
        { value: 'Job C', label: 'Job C' }
    ];

    const expenseTypeOptions = [
        { value: 'Expense A', label: 'Expense A' },
        { value: 'Expense B', label: 'Expense B' },
        { value: 'Expense C', label: 'Expense C' }
    ];

    return (
        <div className="p-1">
            <div className='md:flex justify-between items-center'>
                <h1 className="text-2xl font-semibold mb-4"> Expense</h1>
                <div className='flex items-center bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Home</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
                    <p> Expense</p>
                </div>
            </div>
            <div className="bg-white text-black py-2 px-6 mb-4 mt-2 border-t-4 border-blue-950">
                <div className="flex">
                    <div className="w-1/3"></div>
                    <div className="w-2/3">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="object_id">
                                    Object <span className="text-red-500">*</span>
                                </label>
                                <div className="w-2/3">
                                    <SingleSelectDropdown
                                        options={objectOptions}
                                        onSelect={handleSelect('object_id')}
                                        selectedValue={formData.object_id}
                                        placeholder="Select Object"
                                        selectClass="border-gray-300 p-2 rounded"
                                        selectStyle={{ width: '22rem' , minHeight: '2.5rem' }}
                                        optionStyle={{width: '22rem'}}
                                        disabled={action === 'view'}
                                    />
                                    {errors.object_id && <div className="text-red-500 text-sm mt-1">{errors.object_id}</div>}
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="category">
                                    Category <span className="text-red-500">*</span>
                                </label>
                                <div className="w-2/3 flex gap-2">
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="category"
                                            value="Variable"
                                            checked={formData.category === 'Variable'}
                                            onChange={handleChange}
                                            className="mr-1"
                                        />
                                        Variable
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="category"
                                            value="Fix"
                                            checked={formData.category === 'Fix'}
                                            onChange={handleChange}
                                            className="mr-1"
                                        />
                                        Fix
                                    </label>
                                </div>
                            </div>

                            {formData.category === 'Variable' && (
                                <>
                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="job_selected">
                                            Consider Job
                                        </label>
                                        <div className="w-2/3 flex items-center">
                                            <input
                                                type="checkbox"
                                                id="job_selected"
                                                name="job_selected"
                                                className="mr-2"
                                                checked={formData.job_selected}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {formData.job_selected && (
                                        <>
                                            <div className="mb-4 flex items-center space-x-4">
                                                <label className="block text-sm font-medium w-1/3" htmlFor="job_allocation">
                                                    Job Allocation <span className="text-red-500">*</span>
                                                </label>
                                                <div className="w-2/3 flex gap-2">
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="job_allocation"
                                                            value="In-Progress"
                                                            checked={formData.job_allocation === 'In-Progress'}
                                                            onChange={handleChange}
                                                            className="mr-1"
                                                        />
                                                        In-Progress
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="job_allocation"
                                                            value="Completed"
                                                            checked={formData.job_allocation === 'Completed'}
                                                            onChange={handleChange}
                                                            className="mr-1"
                                                        />
                                                        Completed
                                                    </label>
                                                </div>
                                            </div>

                                            {formData.job_allocation === 'Completed' && (
                                                <div className="mb-4 flex items-center space-x-4">
                                                    <label className="block text-sm font-medium w-1/3" htmlFor="completed_till">
                                                        Completed Till
                                                    </label>
                                                    <div className="w-2/3">
                                                        <Input
                                                            type="date"
                                                            id="completed_till"
                                                            placeholder="Completed Till"
                                                            value={completed_till}
                                                            onChange={(date) => setCompletedTill(date as Date)}
                                                            className="p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                                            disabled={action === 'view'}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <div className="mb-4 flex items-center space-x-4">
                                                <label className="block text-sm font-medium w-1/3" htmlFor="job_id">
                                                    Job <span className="text-red-500">*</span>
                                                </label>
                                                <div className="w-2/3">
                                                    <SingleSelectDropdown
                                                        options={jobOptions}
                                                        onSelect={handleSelect('job_id')}
                                                        selectedValue={formData.job_id}
                                                        placeholder="Select Job"
                                                        selectClass="border-gray-300 p-2 rounded "
                                                        selectStyle={{ width: '22rem' , minHeight: '2.5rem' }}
                                        optionStyle={{width: '22rem'}}
                                                        disabled={action === 'view'}

                                                    />
                                                    {errors.job_id && <div className="text-red-500 text-sm mt-1">{errors.job_id}</div>}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="expense_type_id">
                                            Expense Type <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-2/3">
                                            <SingleSelectDropdown
                                                options={expenseTypeOptions}
                                                onSelect={handleSelect('expense_type_id')}
                                                selectedValue={formData.expense_type_id}
                                                placeholder="Select Expense Type"
                                                selectClass="border-gray-300 p-2 rounded"
                                                selectStyle={{ width: '22rem' , minHeight: '2.5rem' }}
                                        optionStyle={{width: '22rem'}}
                                                disabled={action === 'view'}

                                            />
                                            {errors.expense_type_id && <div className="text-red-500 text-sm mt-1">{errors.expense_type_id}</div>}
                                        </div>
                                    </div>

                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="expense_date">
                                            Expense Date <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-2/3">
                                            <Input
                                                type="date"
                                                id="expenseDate"
                                                placeholder="Expense Date"
                                                value={expenseDate}
                                                onChange={(date) => setExpenseDate(date as Date)}
                                                className="p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                                disabled={action === 'view'}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {formData.category === 'Fix' && (
                                <>
                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="expense_type_id">
                                            Expense Type <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-2/3">
                                            <SingleSelectDropdown
                                                options={expenseTypeOptions}
                                                onSelect={handleSelect('expense_type_id')}
                                                selectedValue={formData.expense_type_id}
                                                placeholder="Select Expense Type"
                                                selectClass="border-gray-300 p-2 rounded"
                                                selectStyle={{ width: '22rem' , minHeight: '2.5rem' }}
                                        optionStyle={{width: '22rem'}}
                                                disabled={action === 'view'}

                                            />
                                            {errors.expense_type_id && <div className="text-red-500 text-sm mt-1">{errors.expense_type_id}</div>}
                                        </div>
                                    </div>
                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="from_date">
                                            From Date <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-2/3">
                                            <Input
                                                type="date"
                                                id="fromDate"
                                                placeholder="From Date"
                                                value={fromDate}
                                                onChange={(date) => setFromDate(date as Date)}
                                                className="p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-4 flex items-center space-x-4">
                                        <label className="block text-sm font-medium w-1/3" htmlFor="to_date">
                                            To Date <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-2/3">
                                            <Input
                                                type="date"
                                                id="toDate"
                                                placeholder="To Date"
                                                value={toDate}
                                                onChange={(date) => setToDate(date as Date)}
                                                className="p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="amount">
                                    Amount <span className="text-red-500">*</span>
                                </label>
                                <div className="w-2/3">
                                    <InputField
                                        type="number"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        placeholder="Expense Amount"
                                        className="p-2 px-3 w-full border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        disabled={action === 'view'}
                                    />
                                    {errors.amount && <div className="text-red-500 text-sm mt-1">{errors.amount}</div>}
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="reference_number">
                                    Reference Number
                                </label>
                                <div className="w-2/3">
                                    <InputField
                                        type="text"
                                        name="reference_number"
                                        value={formData.reference_number}
                                        onChange={handleChange}
                                        className="p-2 px-3 w-full border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        placeholder="Reference Number"
                                        disabled={action === 'view'}
                                    />
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="odometer">
                                    Odometer
                                </label>
                                <div className="w-2/3">
                                    <InputField
                                        type="text"
                                        name="odometer"
                                        value={formData.odometer}
                                        onChange={handleChange}
                                        placeholder="Odometer"
                                        className="p-2 px-3 w-full border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        disabled={action === 'view'}
                                    />
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="work_hour">
                                    Work Hour
                                </label>
                                <div className="w-2/3">
                                    <InputField
                                        type="text"
                                        name="work_hour"
                                        value={formData.work_hour}
                                        onChange={handleChange}
                                        placeholder="Work Hour"
                                        className="p-2 px-3 w-full border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        disabled={action === 'view'}
                                    />
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="bill_upload">
                                    Bill Upload
                                </label>
                                <div className="w-2/3">
                                    <input
                                        type="file"
                                        id="bill_upload"
                                        name="bill_upload"
                                        onChange={handleFileChange}
                                        className="w-full p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        disabled={action === 'view'}
                                    />
                                </div>
                            </div>

                            <div className="mb-4 flex items-center space-x-4">
                                <label className="block text-sm font-medium w-1/3" htmlFor="description">
                                    Description
                                </label>
                                <div className="w-2/3">
                                    <InputField
                                        type="text"
                                        name="description"
                                        className="w-full p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                                        value={formData.description}
                                        onChange={handleChange}
                                        disabled={action === 'view'}
                                    />
                                </div>
                            </div>


                            <div className="flex justify-end space-x-4">
                                {action !== 'view' && (
                                    <Button
                                        type="submit"
                                        className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md '
                                    >
                                        {action === 'edit' ? 'Update' : 'Submit'}
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    onClick={handleBack}
                                    className="bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md"
                                >
                                    Back
                                </Button>
                            </div>

                        </form>
                    </div>
                    <div className="w-1/3"></div>
                </div>
            </div>
        </div>
    );
};

export default NewExpense;
