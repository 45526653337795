import { useState } from 'react';
import Dropdown from '../../../../../../../components/Dropdown';
import InputField from '../../../../../../../components/InputFeild';
import Button from '../../../../../../../components/Button';

const SensorsTab = () => {
  const [selectedConnectedSensors, setSelectedConnectedSensors] = useState<string[]>(Array(4).fill(''));
  const [selectedReadingTypes, setSelectedReadingTypes] = useState<string[]>(Array(4).fill(''));

  const handleConnectedSensorChange = (index: number, value: string) => {
    const updatedSensors = [...selectedConnectedSensors];
    updatedSensors[index] = value;
    setSelectedConnectedSensors(updatedSensors);
  };

  const handleReadingTypeChange = (index: number, value: string) => {
    const updatedReadingTypes = [...selectedReadingTypes];
    updatedReadingTypes[index] = value;
    setSelectedReadingTypes(updatedReadingTypes);
  };

  const connectedSensorOptions = [
    { value: 'ignition_acc', label: 'Ignition / ACC' },
    { value: 'air_condition', label: 'Air Condition' },
    { value: 'door', label: 'Door' },
    { value: 'power', label: 'Power' }
  ];

  const readingTypeOptions = [
    { value: 'direct', label: 'Direct' },
    { value: 'inverse', label: 'Inverse' }
  ];

  return (
    <div id="sensors-container"  >
      <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-4">Sensors</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full border-gray-300 text-sm lg:text-base">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="py-2 px-4 sm:px-4 border-r">Active</th>
              <th className="py-2 px-4 sm:px-4 border-r">Connection Type</th>
              <th className="py-2 px-4 sm:px-4 border-r">Connected Sensor</th>
              <th className="py-2 px-4 sm:px-4 border-r">Reading Type</th>
              <th className="py-2 px-4 sm:px-4 border-r">Work Hour Calculation</th>
              <th className="py-2 px-4 sm:px-4">Calibration</th>
            </tr>
          </thead>
          <tbody>
            {[0, 1, 2, 3].map((index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4 sm:px-4 border-r text-center">
                  <input type="checkbox" className="form-check-input active-checkbox" name={`active[${index}]`} />
                </td>
                <td className="py-2 px-4 border-r">
                  <InputField
                    type="text"
                    name={`connection_type[${index}]`}
                    value={getValueByIndex(index, 'connection_type')}
                    onChange={() => {}}
                    readOnly
                    className="w-full px-3 py-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  />
                </td>
                <td className="py-2 px-4 sm:px-4 border-r">
                  <Dropdown
                    options={connectedSensorOptions}
                    onSelect={(value) => handleConnectedSensorChange(index, value)}
                    selectedValue={selectedConnectedSensors[index]}
                    placeholder="Select Sensor"
                    selectStyle={{ width: '10rem' }}
                  />
                </td>
                <td className="py-2 px-4 border-r">
                  <Dropdown
                    options={readingTypeOptions}
                    onSelect={(value) => handleReadingTypeChange(index, value)}
                    selectedValue={selectedReadingTypes[index]}
                    placeholder="Select Reading Type"
                    selectStyle={{ width: '12rem' }}
                  />
                </td>
                <td className="py-2 px-4 border-r text-center">
                  <input type="checkbox" className="form-check-input work-hour-checkbox" name={`work_hour_calculation[${index}]`} disabled />
                </td>
                <td className="py-2 px-4 text-center">--</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end mt-4">
        <Button
          type="submit"
          className="bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md"
        >
          Submit
        </Button>
      </div>
    </div>
  );

  function getValueByIndex(index: number, key: 'connection_type'): string {
    const values = {
      connection_type: ['ACC', 'Air condition', 'Door status', 'Power']
    };
    return values[key][index];
  }
  
};

export default SensorsTab;

