import { TableColumn } from "react-data-table-component";
export interface IObjectService {
    getObjecteAlert(): Promise<IObjectAlertReport[]>;
    getObjecteAlertSingle(): Promise<IObjectAlertReportSingle[]>;

   
}
export type IObjectAlertReport = {
    id: number;
    branch: string;
    objectName: string;
    imeiNumber: number;
    alertType: string;
    alertDuration: number;
    numberOfAlerts: number;
};

export const ObjectAlertReportData:TableColumn<IObjectAlertReport>[] = [
    { name: 'ID', selector: (row: IObjectAlertReport) => row.id, sortable: true, },
    { name: 'Branch', selector: (row: IObjectAlertReport) => row.branch, sortable: true },
    { name: 'Object Name', selector: (row: IObjectAlertReport) => row.objectName, sortable: true },
    { name: 'IMEI Number', selector: (row: IObjectAlertReport) => row.imeiNumber, sortable: true },
    { name: 'Alert Type ', selector: (row: IObjectAlertReport) => row.alertType, sortable: true },
    { name: 'Alert Duration', selector: (row: IObjectAlertReport) => row.alertDuration, sortable: true },
    { name: 'Number Of Alerts', selector: (row: IObjectAlertReport) => row.numberOfAlerts, sortable: true }  
];

// single ObjectAlertReport
export type IObjectAlertReportSingle = {
    id: number;
    branch: string;
    object: string;
    imeiNumber: number;
    geofenceName: string;
    eventStartTime:string;
    eventEndTime: string;
    speed:string;
    location:string;
    coordinates:string;
    driver: string;
    alertType:string;
    alertInformations:string;
    alertDuration: number;

}
export const ObjectAlertDataColumnSingle: TableColumn<IObjectAlertReportSingle>[] = [
    { name: 'ID', selector: (row: IObjectAlertReportSingle) => row.id, sortable: true },
    { name: 'Branch', selector: (row: IObjectAlertReportSingle) => row.branch, sortable: true },
    { name: 'Object', selector: (row: IObjectAlertReportSingle) => row.object, sortable: true },
    { name: 'IMEI Number', selector: (row: IObjectAlertReportSingle) => row.imeiNumber, sortable: true },
    { name: 'Geofence Name', selector: (row: IObjectAlertReportSingle) => row.geofenceName, sortable: true },
    { name: 'Event Start Time', selector: (row: IObjectAlertReportSingle) => row.eventStartTime, sortable: true },
    { name: 'Event End Time', selector: (row: IObjectAlertReportSingle) => row.eventEndTime, sortable: true },
    { name: 'Speed', selector: (row: IObjectAlertReportSingle) => row.speed, sortable: true },
    { name: 'Location', selector: (row: IObjectAlertReportSingle) => row.location, sortable: true },
    { name: 'Coordinates', selector: (row: IObjectAlertReportSingle) => row.coordinates, sortable: true },
    { name: 'Driver', selector: (row: IObjectAlertReportSingle) => row.driver, sortable: true },
    { name: 'Alert Type', selector: (row: IObjectAlertReportSingle) => row.alertType, sortable: true },
    { name: 'Alert Information', selector: (row: IObjectAlertReportSingle) => row.alertInformations, sortable: true },
    { name: 'Alert Duration', selector: (row: IObjectAlertReportSingle) => row.alertDuration, sortable: true },
];