import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useParams, useLocation  ,useNavigate} from 'react-router-dom';
import useHttp from '../../../hooks/useHttp';

import * as Yup from 'yup';

const CreateValue: FormValues = { email: '', password: '' };

interface FormValues {
  email: string;
  password: string;
}

const IndividualuserCurd: React.FC = () => {
  const location = useLocation();
  const isEditing: boolean = location.pathname.includes('edit');
  const isCreating = location.pathname.includes('create');
  const isViewing = location.pathname.includes('view');
  const { request } = useHttp();
  const navigate = useNavigate();

  console.log(isEditing, isViewing, isCreating);

  const [initialValues, setInitialValues] = useState<FormValues>();

  const { id } = useParams<{ id: string }>();
  const numericId = Number(id);
  console.log(numericId);

  useEffect(() => {
    if (isCreating) {
      setInitialValues(CreateValue);

    } else if (isEditing || isViewing) {
 
   void   fetchData(numericId).then((data) => {
       setInitialValues(data);
      });
      }
  }, [numericId]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  const handleSubmit =async (values: FormValues) => {
        const response = await request({
            url: '/add-user',
            method: 'POST',
            body: {  email:values.email, password:values.password}
        });

        if (!response.ok) {
            throw new Error(response.arrayBuffer.toString());
        }
    alert('Add successfully');
     navigate('/systemAdmin/manageindividualuser/indivividualuser');
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-4 text-center">Add New IndividualUser</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isValid }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                Email
              </label>
              <Field
                type="email"
                name="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-2" />
            </div>

            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
              <Field
                type="password"
                name="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-2" />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                disabled={!isValid}
              >
                Create
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const fetchData = async (id: number): Promise<FormValues> => {
  console.log(id);
  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({ email: 'example@example.com', password: 'password123' });
    }, 1000);
  });
};

export default IndividualuserCurd;
