import React, { createContext, useState, useContext, ReactNode } from 'react';
import { LoadScript } from '@react-google-maps/api';

const MapLoaderContext = createContext<{ isLoaded: boolean }>({ isLoaded: false });

const MapLoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <MapLoaderContext.Provider value={{ isLoaded }}>
      <LoadScript
        googleMapsApiKey="AIzaSyDrKlRcFNEBhPzIWnSr5EqW5pGkIvq4HGs"
        onLoad={handleLoad}
        loadingElement={<div>Loading...</div>}
      >
        {isLoaded && children}
      </LoadScript>
    </MapLoaderContext.Provider>
  );
};

export const useMapLoader = () => useContext(MapLoaderContext);
export default MapLoaderProvider;
