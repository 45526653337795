// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import MultiselectDropdown from '../../../../../../components/MutliselectDropdown';
// import InputField from '../../../../../../components/InputFeild';
// import { createAlerts } from '../../../../../../services/ServiceFactory';
// import Dropdown from '../../../../../../components/Dropdown';
// import Button from '../../../../../../components/Button';
// import SingleSelectDropdown from '../../../../../../components/SingleSelectDropdown';

// interface FormData {
//   basedOn: 'object' | 'objectGroup' | 'objectType';
//   object: string[];
//   alertName: string;
//   alertType: string;
//   text: string;
//   validDays: 'everyday' | 'custom';
//   validTimeFrom: string;
//   validTimeTo: string;
//   timezone: string;
//   remindUsing: {
//     notification: boolean;
//     sms: boolean;
//     email: boolean;
//   };
//   customValidDays: {
//     sun: boolean;
//     mon: boolean;
//     tue: boolean;
//     wed: boolean;
//     thu: boolean;
//     fri: boolean;
//     sat: boolean;
//   };
//   smsText: string;
//   emailText: string;
// }

// const AddAlerts: React.FC = () => {
//   const { id } = useParams<{ id: string }>();
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState<FormData>({
//     basedOn: 'object',
//     object: [],
//     alertName: '',
//     alertType: "",
//     text: '',
//     validDays: 'everyday',
//     validTimeFrom: '',
//     validTimeTo: '',
//     timezone: 'UTC+05:30 - Asia/Kolkata',
//     remindUsing: {
//       notification: false,
//       sms: false,
//       email: false,
//     },
//     customValidDays: {
//       sun: false,
//       mon: false,
//       tue: false,
//       wed: false,
//       thu: false,
//       fri: false,
//       sat: false,
//     },
//     smsText: '',
//     emailText: '',
//   });

//   const [selectedNotification, setSelectedNotification] = useState<string>('');
//   const [errors, setErrors] = useState<{ [key: string]: string }>({});
//   const [alertType, setAlertType] = useState<string | null>(null);

//   useEffect(() => {
//     if (id) {
//       const fetchAlert = async () => {
//         const alertsService = createAlerts();
//         try {
//           const alerts = await alertsService.getAlerts();
//           console.log("All alerts:", alerts);
//           const alertToEdit = alerts.find((alert) => alert.id === parseInt(id, 10));
//           console.log("Alert to Edit:", alertToEdit);
//           if (alertToEdit) {
//             const {
//               alertType ,
//               id: alertId,
//               companyId,
//               alertName,
//               createdAt,
//               updatedAt,
//               basedOn,
//               object,
//               text,
//               validDays,
//               validTimeFrom,
//               validTimeTo,
//               emailText,
//               smsText,
//               remindUsing,
//             } = alertToEdit;

//             setFormData((prev) => ({
//               ...prev,
//               alertType,
//               id: alertId,
//               companyId,
//               alertName,
//               createdAt,
//               updatedAt,
//               basedOn,
//               object,
//               text,
//               validDays,
//               validTimeFrom,
//               validTimeTo,
//               emailText,
//               smsText,
//               remindUsing,
//             }));

//             const notification = remindUsing?.notification;
//             setSelectedNotification(notification ? 'notification1' : '');
//           }
//         } catch (error) {
//           console.error('Error fetching alert:', error);
//         }
//       };

//       void fetchAlert();
//     }
//   }, [id]);

//   const validateForm = () => {
//     const newErrors: { [key: string]: string } = {};
//     if (!formData.alertName) newErrors.alertName = 'The alert name field is required.';
//     if (formData.alertType.length === 0) newErrors.alertType = 'The alert type field is required.';
//     if (!formData.text) newErrors.text = 'The alert text field is required.';
//     if (!formData.validTimeFrom) newErrors.validTimeFrom = 'The valid time from field is required.';
//     if (!formData.validTimeTo) newErrors.validTimeTo = 'The valid time to field is required.';
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     if (validateForm()) {
//       console.log(formData);
//       navigate('/company/settings/master/alerts');
//     }
//   };

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
//     const { name, value, type } = e.target;
//     if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
//       if (name.startsWith('customValidDays')) {
//         setFormData((prev) => ({
//           ...prev,
//           customValidDays: {
//             ...prev.customValidDays,
//             [name.split('.')[1] as keyof typeof formData.customValidDays]: !prev.customValidDays[name.split('.')[1] as keyof typeof formData.customValidDays],
//           },
//         }));
//       } else {
//         setFormData((prev) => ({
//           ...prev,
//           remindUsing: {
//             ...prev.remindUsing,
//             [name.split('.')[1]]: (e.target as HTMLInputElement).checked,
//           },
//         }));
//       }
//     } else {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: '',
//       }));

//       setFormData((prev) => ({ ...prev, [name]: value }));
//     }
//   };

//   const handleSelectAlertType = (selectedValue: string | null) => {
//     setAlertType(selectedValue);

//     setFormData((prev) => ({
//       ...prev,
//       alertType: selectedValue || '',
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       alertType: '',
//     }));
//   };

//   const handleSelectObjects = (selectedValues: string[]) => {
//     setFormData((prev) => ({
//       ...prev,
//       object: selectedValues,
//     }));
//   };

//   const handleSelectNotification = (selectedValue: string) => {
//     setSelectedNotification(selectedValue);
//     setFormData((prev) => ({
//       ...prev,
//       remindUsing: {
//         ...prev.remindUsing,
//         notification: selectedValue !== '',
//       },
//     }));
//   };

//   const handleSelectTimezone = (selectedValue: string) => {
//     setFormData((prev) => ({
//       ...prev,
//       timezone: selectedValue,
//     }));
//   };

//   const objectOptions = [
//     { value: 'object1', label: 'Object 1' },
//     { value: 'object2', label: 'Object 2' },
//     { value: 'object3', label: 'Object 3' },
//   ];

//   const alertTypeOptions = [
//     { value: 'alert1', label: 'Alert 1' },
//     { value: 'alert2', label: 'Alert 2' },
//     { value: 'alert3', label: 'Alert 3' },
//   ];

//   const notificationOptions = [
//     { value: 'notification1', label: 'Notification 1' },
//     { value: 'notification2', label: 'Notification 2' },
//     { value: 'notification3', label: 'Notification 3' },
//   ];

//   const timezoneOptions = [
//     { value: 'UTC+05:30 - Asia/Kolkata', label: 'UTC+05:30 - Asia/Kolkata' },
//     { value: 'UTC+00:00 - UTC', label: 'UTC+00:00 - UTC' },
//     { value: 'UTC-05:00 - America/New_York', label: 'UTC-05:00 - America/New_York' },
//   ];

//   const handleBack = () => {
//     navigate('/company/settings/master/alerts');
//   };

//   return (
//     <div className="">
//       <div className="bg-white text-black py-1 px-2  border-t-4 border-blue-950">
//         <h2 className="text-lg font-bold">
//           {id ? 'Edit Alert' : 'Alert Detail'}
//         </h2>
//       </div>
//       <div className="w-full mx-auto mt-5 p-6 bg-white border rounded-lg shadow">
//         <div className="flex flex-col lg:flex-row">
//           {/* Left column */}
//           <div className="lg:w-1/3"></div>

//           {/* Middle form column */}
//           <form onSubmit={handleSubmit} className="lg:w-2/3">
//             <div className="mb-4 flex flex-col sm:flex-row sm:items-center">
//               <label className="block text-gray-700 font-semibold  sm:w-1/3">
//                 Based On <span className="text-red-500">*</span>
//               </label>
//               <div className="flex ">
//                 <label className="flex items-center  ">
//                   <input
//                     type="radio"
//                     name="basedOn"
//                     value="object"
//                     className="ml-1"
//                     checked={formData.basedOn === 'object'}
//                     onChange={handleChange}
//                   />
//                   Object
//                 </label>
//                 <label className="flex items-center ">
//                   <input
//                     type="radio"
//                     name="basedOn"
//                     value="objectGroup"
//                     className="ml-1"
//                     checked={formData.basedOn === 'objectGroup'}
//                     onChange={handleChange}
//                   />
//                   Object Group
//                 </label>
//                 <label className="flex items-center  ">
//                   <input
//                     type="radio"
//                     name="basedOn"
//                     value="objectType"
//                     className="ml-1"
//                     checked={formData.basedOn === 'objectType'}
//                     onChange={handleChange}
//                   />
//                   Object Type
//                 </label>
//               </div>
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Object <span className="text-red-500">*</span>
//               </label>
//               <MultiselectDropdown
//                 options={objectOptions}
//                 onSelect={handleSelectObjects}
//                 selectedValues={formData.object}
//                 placeholder="Select Objects"
//                 selectClass="block md:w-64 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                 width='16rem'
//               />
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Alert Name<span className="text-red-500">*</span>
//               </label>
//               <div>
//                 <InputField
//                   type="text"
//                   name="alertName"
//                   value={formData.alertName}
//                   onChange={handleChange}
//                   className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none "
//                   style={{ width: '16rem' }}
//                 />
//                 {errors.alertName && <p className="text-red-500 text-sm mt-1">{errors.alertName}</p>}
//               </div>
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Alert Type <span className="text-red-500">*</span>
//               </label>
//               <div>
//                 <SingleSelectDropdown
//                   options={alertTypeOptions}
//                   onSelect={handleSelectAlertType}
//                   selectedValue={alertType}
//                   // selectedValue={formData.alertType}
//                   placeholder="Select Alert Type"
//                   selectStyle={{ width: '16rem' , minHeight: '2.5rem' }}
//                   optionStyle={{width: '16rem'}}
//                 />
//                 {errors.alertType && <p className="text-red-500 text-sm mt-1">{errors.alertType}</p>}
//               </div>
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Text <span className="text-red-500">*</span>
//               </label>
//               <div>
//                 <InputField
//                   type="text"
//                   name="text"
//                   value={formData.text}
//                   onChange={handleChange}
//                   className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                   style={{ width: '16rem' }}
//                 />
//                 {errors.text && <p className="text-red-500 text-sm mt-1">{errors.text}</p>}
//               </div>
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Valid Day(s) <span className="text-red-500">*</span>
//               </label>
//               <div className="flex items-center space-x-2">
//                 <label className="flex items-center">
//                   <input
//                     type="radio"
//                     name="validDays"
//                     value="everyday"
//                     className="mr-1"
//                     checked={formData.validDays === 'everyday'}
//                     onChange={handleChange}
//                   />
//                   Every day
//                 </label>
//                 <label className="flex items-center">
//                   <input
//                     type="radio"
//                     name="validDays"
//                     value="custom"
//                     className="mr-1"
//                     checked={formData.validDays === 'custom'}
//                     onChange={handleChange}
//                   />
//                   Custom
//                 </label>
//               </div>
//             </div>

//             {formData.validDays === 'custom' && (
//               <div className="mb-2 flex items-center">
//                 <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                   Week Days <span className="text-red-500">*</span>
//                 </label>
//                 <div className="flex items-center space-x-2">
//                   {Object.keys(formData.customValidDays).map((day) => (
//                     <label key={day} className="flex items-center">
//                       <input
//                         type="checkbox"
//                         name={`customValidDays.${day}`}
//                         checked={!!formData.customValidDays[day as keyof typeof formData.customValidDays]}
//                         onChange={handleChange}
//                       />
//                       {day}
//                     </label>
//                   ))}
//                 </div>
//               </div>
//             )}

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Valid Time From <span className="text-red-500">*</span>
//               </label>
//               <div>
//                 <div className="flex items-center space-x-1">
//                   <input
//                     type="time"
//                     className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                     name="validTimeFrom"
//                     value={formData.validTimeFrom}
//                     onChange={handleChange}
//                   />
//                   <span>TO</span>
//                   <input
//                     type="time"
//                     className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                     name="validTimeTo"
//                     value={formData.validTimeTo}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 {errors.validTimeFrom && <p className="text-red-500 text-sm mt-1">{errors.validTimeFrom}</p>}
//                 {errors.validTimeTo && <p className="text-red-500 text-sm mt-1">{errors.validTimeTo}</p>}

//               </div>
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                 Timezone <span className="text-red-500">*</span>
//               </label>
//               <Dropdown
//                 options={timezoneOptions}
//                 onSelect={handleSelectTimezone}
//                 selectedValue={formData.timezone}
//                 placeholder="Select Timezone"
//                 selectStyle={{ width: '16rem' }}
//               />
//             </div>

//             <div className="mb-2 flex items-center">
//               <label className="block text-gray-700 font-semibold mb-1 w-1/3">Remind Using</label>
//               <div className="flex items-center space-x-2">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     name="remindUsing.notification"
//                     checked={formData.remindUsing.notification}
//                     onChange={handleChange}
//                     className="mr-1"
//                   />
//                   Notification
//                 </label>
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     name="remindUsing.sms"
//                     checked={formData.remindUsing.sms}
//                     onChange={handleChange}
//                     className="mr-1"
//                   />
//                   SMS
//                 </label>
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     name="remindUsing.email"
//                     checked={formData.remindUsing.email}
//                     onChange={handleChange}
//                     className="mr-1"
//                   />
//                   Email
//                 </label>
//               </div>
//             </div>

//             {/* Conditionally render Notification Dropdown */}
//             {formData.remindUsing.notification && (
//               <div className="mb-2 flex items-center">
//                 <label className="block text-gray-700 font-semibold mb-1 w-1/3">Notification</label>
//                 <Dropdown
//                   options={notificationOptions}
//                   onSelect={handleSelectNotification}
//                   selectedValue={selectedNotification}
//                   placeholder="Select Notification"
//                   selectStyle={{ width: '16rem' }}
//                 />
//               </div>
//             )}

//             {formData.remindUsing.sms && (
//               <div className="mb-2 flex items-center">
//                 <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                   SMS
//                 </label>
//                 <textarea
//                   className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                   name="smsText"
//                   value={formData.smsText}
//                   onChange={handleChange}
//                 />
//               </div>
//             )}

//             {formData.remindUsing.email && (
//               <div className="mb-2 flex items-center">
//                 <label className="block text-gray-700 font-semibold mb-1 w-1/3">
//                   Email
//                 </label>
//                 <textarea
//                   className="block w-full md:w-1/2 px-2 py-1 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
//                   name="emailText"
//                   value={formData.emailText}
//                   onChange={handleChange}
//                 />
//               </div>
//             )}

//             <div className="flex justify-between mt-4">
//               <Button
//                 type="button"
//                 onClick={handleBack}
//                 className="bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md"
//               >
//                 Back
//               </Button>

//               <Button
//                 type="submit"
//                 className="bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md "
//               >
//                 {id ? 'Update' : 'Submit'}
//               </Button>
//             </div>
//           </form>
//           {/* Right column */}
//           <div className="lg:w-1/3"></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddAlerts;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputField from '../../../../../../components/InputFeild';
import SingleSelectDropdown from '../../../../../../components/SingleSelectDropdown';
import Button from '../../../../../../components/Button';
import useHttp from '../../../../../../hooks/useHttp';
import { toast } from 'react-toastify';

interface Alert {
    alertType: string;
    threshold: string;
    status: string;
}

interface AlertType {
    id: number;
    name: string;
    enabled: boolean;
}
interface Option {
    value: string;
    label: string;
}

interface responseForAlert {
    enabled: boolean;
    id: number;
    alert_type_id: number;
    threshold: string;
    companyId: number;
    updatedAt: string;
    createdAt: string;
}
function AlertsAdd() {
    const { id, action } = useParams<{ id: string; action: string }>();
    const { request } = useHttp();
    const [options, setOptions] = useState<Option[]>([]);
    const [errors, setErrors] = useState({ alert_type_id: '', threshold: '' });
    const navigate = useNavigate();

    const [formData, setFormData] = useState<Alert>({
        alertType: '',
        status: '',
        threshold: ''
    });

    const option1 = [
        { value: 'true', label: 'Active' },
        { value: 'false', label: 'Inactive' }
    ];

    const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const validationErrors = { alert_type_id: '', threshold: '' };
        if (!formData.alertType) validationErrors.alert_type_id = 'AlertType Id is required';
        if (!formData.threshold) validationErrors.threshold = 'Threshold is required';

        setErrors(validationErrors);

        const isValid = !validationErrors.alert_type_id && !validationErrors.threshold;

        if (!isValid) {
            toast.error('Please fill in all required fields');
            return;
        }

        const data = {
            alert_type_id: formData.alertType,
            enabled: formData.status === 'true' ? true : false,
            threshold: formData.threshold
        };

        if (id) {
            const response = await request({
                url: '/alert/' + id,
                method: 'PUT',
                body: data
            });
            void toast.success('Alert Updated Successfully');
            navigate('/company/settings/master/alerts');

            if (!response.ok) {
                throw new Error(response.arrayBuffer.toString());
            }
        } else {
            try {
                const response = await request({
                    url: '/add-alert',
                    method: 'POST',
                    body: data
                });
                void toast.success('Alert Created Successfully');

                navigate('/company/settings/master/alerts');

                if (response.status == 400) {
                    throw new Error(response.arrayBuffer.toString());
                }
            } catch (error) {
                console.error(error);
            }
        }

        console.log(formData);
    };

    const handleSelect = (selectedValue: string | null) => {
        setFormData(prevData => ({
            ...prevData,
            alertType: selectedValue !== null ? selectedValue : prevData.alertType
        }));
    };

    const handleSelect1 = (selectedValue: string | null) => {
        setFormData(prevData => ({
            ...prevData,
            status: selectedValue !== null ? selectedValue : prevData.status
        }));
    };

    useEffect(() => {
        const fetchAlertType = async () => {
            try {
                const response = await request({
                    url: '/alert-types',
                    method: 'GET'
                });
                const data = (await response.json()) as AlertType[];
                const mappedOptions: Option[] = data.map(alert => ({
                    value: alert.id.toString(),
                    label: alert.name
                }));
                setOptions(mappedOptions);
            } catch (error) {
                console.error('Error fetching alert type:', error);
            }
        };
        void fetchAlertType();

        if (id) {
            const fetchAlerts = async () => {
                try {
                    const response = await request({
                        url: `/alert/${id}`,
                        method: 'GET'
                    });
                    if (!response.ok) {
                        throw new Error(response.arrayBuffer.toString());
                    }
                    const data = (await response.json()) as responseForAlert;
                    console.log("response data",data);
                    

                    const mappedFormData: Alert = {
                        alertType: data.alert_type_id.toString(),
                        status: data.enabled === true ? 'true' : 'false',
                        threshold: data.threshold
                    };
                    setFormData(mappedFormData);
                } catch (error) {
                    console.error('Error fetching users:', error);
                }
            };

            void fetchAlerts();
        }
    }, [id]);

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Add Alert</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Add Alert</p>
                </div>
            </div>

            <form>
                <div className='bg-white   border-t-2 border-blue-800 rounded mt-5'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <div className='p-4'>
                            <div className='mb-1'>
                                <label className='text-black font-semibold text-sm' htmlFor=''>
                                    Select Alert Type
                                </label>
                            </div>
                            <SingleSelectDropdown
                                options={options}
                                selectedValue={formData.alertType}
                                onSelect={handleSelect}
                                placeholder='Select Alert Type'
                                selectClass='w-full'
                                optionClass='w-full'
                                disabled={action === 'view'}
                            />
                            {errors.alert_type_id && <p className='text-red-500 text-sm '>{errors.alert_type_id}</p>}
                        </div>

                        {id && (
                            <div className='p-4'>
                                <div className='mb-1'>
                                    <label className='text-black font-semibold text-sm' htmlFor=''>
                                        Select Alert Status
                                    </label>
                                </div>
                                <SingleSelectDropdown
                                    options={option1}
                                    selectedValue={formData.status}
                                    onSelect={handleSelect1}
                                    placeholder='Select Deviec Type'
                                    selectClass='w-full'
                                    optionClass='w-full'
                                    disabled={action === 'view'}
                                />
                            </div>
                        )}

                        <div className='p-4'>
                            <div className='ml-4'>
                                <label htmlFor='name' className='text-sm font-semibold'>
                                    Enter Threshold
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='threshold'
                                placeholder='Enter Threshold'
                                className='w-full border h-9 pl-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
                                value={formData.threshold}
                                onChange={e => setFormData({ ...formData, threshold: e.target.value })}
                                disabled={action === 'view'}
                            />
                            {errors.threshold && <p className='text-red-500 text-sm ml-4'>{errors.threshold}</p>}
                        </div>
                    </div>
                    <div className='flex justify-between mt-4 mr-2 p-4'>
                        <Link to='/company/settings/master/alerts'>
                            <Button
                                type='button'
                                className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                            >
                                Back
                            </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                                onClick={event => {
                                    void handleButtonClick(event);
                                }}
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AlertsAdd;
