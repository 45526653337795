// SearchLocation.tsx
import React, { useState } from 'react';
import { useMap } from 'react-leaflet';

interface Location {
    lat: string;
    lon: string;
}

interface SearchLocationProps {
    onSearch: (lat: number, lon: number) => void; // Add prop for handling search results
    setIsSearchFocused: (isFocused: boolean) => void; // Add prop to set search focus state
}

const SearchLocation: React.FC<SearchLocationProps> = ({ onSearch, setIsSearchFocused }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const map = useMap();

    const handleSearch = async (): Promise<void> => {
        if (!searchTerm) return;

        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchTerm)}`
            );
            const data: Location[] = (await response.json()) as Location[];

            if (data && data.length > 0) {
                const location = data[0];
                const { lat, lon } = location;
                console.log(`Found location: ${lat}, ${lon}`);
                onSearch(parseFloat(lat), parseFloat(lon));
                map.setView([parseFloat(lat), parseFloat(lon)], 10);
            } else {
                alert('Location not found');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
            alert('Error fetching location data');
        }
    };

    return (
        <div className='flex mb-4'>
            <input
                type='text'
                placeholder='Search for a location...'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearchFocused(true)} // Set search focus on input focus
                onBlur={() => setIsSearchFocused(false)} 
                className='border p-2 '
                style={{ zIndex: 500 }}
            />
            <button
             onFocus={() => setIsSearchFocused(true)} 
             onBlur={() => setIsSearchFocused(false)} 
                type='button'
                onClick={() => {
                    void handleSearch();
                }}
                className='bg-cyan-950 text-white px-2 ml-2 rounded hover:bg-blue-800'
                style={{ zIndex: 500 }}
            >
                Search
            </button>
        </div>
    );
};

export default SearchLocation;
