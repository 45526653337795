import React, { useEffect, useState } from 'react';
import Table from '../../../../components/table/Table';
import {createVehicleService} from '../../../../services/ServiceFactory'
import { IVehicleMovementReportSingle, MovementDataColumnSingle } from '../../../../services/interfaces/IVehicleService';
import BackButton from '../../../../components/BackButton';

const MovementReportForVehicleUI: React.FC = () => {
  const [data, setData] = useState<IVehicleMovementReportSingle[]>([]);
  const [loading, setLoading] = useState(false);
  // send data for filter report
  
  const vehicleService = createVehicleService();
 

  useEffect(() => {
    const fetchdata= async() =>{
      setLoading(true);
      try{
        const result=await vehicleService.getMovementReportSingle()
          setData(result);
       }catch{
        console.error("Error fetching data");
      }finally{
        setLoading(false);
      }
    }
  void  fetchdata();
  }, []);

 
  return (
    <>
      <p> 
        <h1 className="text-black-700 text-xl">Movement Report For Vehicle</h1>
               <BackButton label="Go Back" className=" absolute float-end  right-9 top-3" />
    </p>
      
      <Table<IVehicleMovementReportSingle>  
        columns={MovementDataColumnSingle}
        data={data}
        loading={loading}
        excelExport={true}
        searchAble={true}
        pdfExport={true}
      />
    </>
  );
}

export default MovementReportForVehicleUI;
