import React, { useState } from 'react';
import Cards from '../../../components/Card';
import SmallCards from '../../../components/ContentCard';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Doughnut from '../../../components/charts/DoughnutChart';
import HorizontalBarChart from '../../../components/charts/HorizontalBarChart';
import CustomAreaChart from '../../../components/charts/CustomAreaChart';
// import FilterListIcon from '@mui/icons-material/FilterList';  
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DashboardModal from './DashboardModal';
 import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Dropdown from '../../../components/Dropdown';
import FleetStatus from './FleetStatus';

const Dashboard = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('today');

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    console.log('Selected:', value);
  };

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const mapStyles = {
    height: "90vh",
    width: "100%"
  };

  const defaultCenter = {
    lat: 41.3851,
    lng: 2.1734
  };

  // Data and colors for Card 5
  const dataForCard5 = [
    { name: 'Outside of Geofence', value: 150 },
    { name: 'Overspeed', value: 200 },
    { name: 'Entering Geofence', value: 100 },
    { name: 'Idle', value: 300 }
  ];
  const colorsForCard5 = ['#FF5733', '#FFC300', '#36A2EB', '#FF6384'];

  // Data for CustomAreaChart
  const areaChartData = [
    { duration: 0, distance: 0 },
    { duration: 5, distance: 100 },
    { duration: 10, distance: 200 },
    { duration: 15, distance: 300 }
  ];

  // Data for HorizontalBarChart
  const horizontalBarChartData = [
    {
      name: "OverSpeed",
      votes: 1.2,
    },
    {
      name: "HarshDriver",
      votes: 0.5,
    },
    {
      name: "Idling",
      votes: 1.8,
    },
    {
      name: "Emergency",
      votes: 0.7,
    },
  ];

  const options = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'this-week', label: 'This Week' },
    { value: 'last-week', label: 'Last Week' },
    { value: 'this-month', label: 'This Month' },
    { value: 'last-month', label: 'Last Month' },
    { value: 'custom', label: 'Custom' },
  ];

  return (
    <div className="min-h-screen bg-gray-100 overflow-hidden">
      <div className="bg-blue-950 text-white py-1 px-2 mb-1 flex items-center justify-between">
        <h2 className="text-lg font-bold">Dashboard</h2>
        {/* Filter Button */}
        <button 
          type="button" 
          className="p-2"
          aria-label="Filter"
          onClick={handleOpenFilterModal} 
        >
          <FilterAltIcon style={{ color: 'white' }} />
        </button>
      </div>

      <div className="container mx-auto">
        {/* Main Cards */}
        <div className="flex flex-wrap -m-2">
          <FleetStatus/>

          <div className="w-full md:w-1/2 lg:w-1/3 p-2">
            <Cards title="Fleet Usage" height="h-72" width="w-full">
              <div className="grid grid-cols-2 gap-2 -mt-4">
                <div>
                  <b className="block text-sm">Total Fleet Usage</b>
                  <span id="totalFleetUsage" className="text-blue-500 text-sm">km</span>
                  <br />
                  <b className="block text-sm">Avg. Distance / Vehicle</b>
                  <span id="avgDistancePerObject" className="text-blue-500 text-sm">Calculating...</span>
                </div>
                <div>
                  <b className="block text-sm">Top Fleet Distances</b>
                  <span id="topFleetDistances" className="text-blue-500 text-sm">Calculating...</span>
                  <br />
                  <b className="block text-sm">Bottom Fleet Distances</b>
                  <span id="bottomFleetDistances" className="text-blue-500 text-sm">Calculating...</span>
                </div>
              </div>
              <div className="mt-1">
                <CustomAreaChart 
                  data={areaChartData}
                  xAxisLabel="Duration"
                  yAxisLabel="Distance"
                  dataKey="distance"
                />
              </div>
            </Cards>
          </div>

          <div className="w-full lg:w-1/3 p-2 lg:block ">
            <div className="flex flex-wrap -m-1">
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Fleet Idle" content="Small Content 1" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Trip" content="Small Content 2" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Work Efficiency " content="Small Content 3" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Average Driver" content="Small Content 4" className="h-36" />
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/3 p-2">
            <Cards
              title="Driver With Most Alerts"
              height="h-72"
              width="w-full"
              headerRight={
                <Dropdown
                  options={options}
                  onSelect={handleSelect}
                  selectedValue={selectedValue}
                  placeholder="Select Time Range"
                  selectStyle={{ width: '8rem',padding:'4px',fontSize:'12px' }}
                  optionStyle={{padding:'4px'}}
                />
              }
            >
              <HorizontalBarChart
                data={horizontalBarChartData}
                dataKey="votes"
                barColor="#87cefa"
              />
            </Cards>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/3 p-2">
            <Cards
              title="Objects With Most Alerts"
              height="h-72"
              width="w-full"
              headerRight={
                <Dropdown
                  options={options}
                  onSelect={handleSelect}
                  selectedValue={selectedValue}
                  placeholder="Select Time Range"
                  selectStyle={{ width: '8rem',padding:'4px',fontSize:'12px' }}
                  optionStyle={{padding:'4px'}}
                />
              }
            >
              <div className="flex items-center justify-center h-full">
                <Doughnut width={200} height={200} data={dataForCard5} colors={colorsForCard5} />
              </div>
            </Cards>
          </div>

          <div className="w-full lg:w-1/3 p-2 lg:block">
            <div className="flex flex-wrap -m-1">
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Renewal reminder" content="Small Content 5" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Alert" content="Small Content 6" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Maintenance reminder" content="Small Content 7" className="h-36" />
              </div>
              <div className="w-full sm:w-1/2 p-1">
                <SmallCards title="Vehicle" content="Small Content 8" className="h-36" />
              </div>
            </div>
          </div>
        </div>

        {/* Google Map */}
        <div className="w-full p-2 mt-4">
          <LoadScript googleMapsApiKey='AIzaSyDrKlRcFNEBhPzIWnSr5EqW5pGkIvq4HGs'>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={defaultCenter}
            />
          </LoadScript>
        </div>
      </div>

      {/* Filter Modal */}
      <DashboardModal 
        open={isFilterModalOpen} 
        onClose={handleCloseFilterModal}
      />
    </div>
  );
};

export default Dashboard;
