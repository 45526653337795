import React, { useEffect, useState } from 'react';
import SingleSelectDropdown from '../../../components/SingleSelectDropdown';
import Button from '../../../components/Button';
import InputField from '../../../components/InputFeild';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';

interface DeviceInfo {
    id: string;
    name: string;
    status: string;
    imei: string;
}

interface DeviceType {
    id: number;
    name: string;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
}

interface Option {
    value: string;
    label: string;
}

interface DeviceForResponse {
    id: number;
    imei: string;
    name: string;
    enabled: boolean;
    deviceTypeId: number;
    companyId: number;
    userId: number | null;
    createdAt: string;
    updatedAt: string;
}

interface ApiResponce{
    message:string,
    data:DeviceType[]
}

function AddDevices() {
    const { id, action } = useParams<{ id: string; action: string }>();
    const { request } = useHttp();
    const navigate = useNavigate();
    const [options, setOptions] = useState<Option[]>([]);

    const [formData, setFormData] = useState<DeviceInfo>({
        id: '',
        name: '',
        status: '',
        imei: ''
    });

    const [errors, setErrors] = useState({ deviceTypeId: '', name: '', imei: '' });
    const option1 = [
        { value: 'true', label: 'Active' },
        { value: 'false', label: 'Inactive' }
    ];

    const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const validationErrors = { deviceTypeId: '', name: '', imei: '' };
        if (!formData.id) validationErrors.deviceTypeId = 'DeviceType Id is required';
        if (!formData.name) validationErrors.name = 'Name is required';
        if (!formData.imei) validationErrors.imei = 'Imei is required';

        setErrors(validationErrors);

        const isValid = !validationErrors.deviceTypeId && !validationErrors.name && !validationErrors.imei;

        if (!isValid) {
            toast.error('Please fill in all required fields');
            return;
        }

        const data = {
            deviceTypeId: formData.id,
            imei: formData.imei,
            enabled: formData.status === 'true' ? true : false,
            name: formData.name
        };

        if (id) {
            const response = await request({
                url: '/device/' + id,
                method: 'PUT',
                body: data
            });
            void toast.success('Device Updated Successfully');
            navigate('/companyAdmin/devices');

            if (!response.ok) {
                throw new Error(response.arrayBuffer.toString());
            }
        } else {
            try {
                const response = await request({
                    url: '/device',
                    method: 'POST',
                    body: data
                });
                void toast.success('Device Created Successfully');

                navigate('/companyAdmin/devices');

                if (response.status == 400) {
                    throw new Error(response.arrayBuffer.toString());
                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    useEffect(() => {
        const fetchDeviceType = async () => {
            try {
                const response = await request({
                    url: '/device-type',
                    method: 'GET'
                });

                const data = (await response.json()) as ApiResponce;
                
                const mappedOptions: Option[] = data.data.map(device => ({
                    value: device.id.toString(),
                    label: device.name
                }));
                toast.success(data.message);
                setOptions(mappedOptions);
            } catch (error) {
                console.error('Error fetching device type:', error);
            }
        };
        void fetchDeviceType();
        if (id) {
            const fetchDevices = async () => {
                try {
                    const response = await request({
                        url: `/device/${id}`,
                        method: 'GET'
                    });
                    if (!response.ok) {
                        throw new Error(response.arrayBuffer.toString());
                    }
                    const data = (await response.json()) as DeviceForResponse;

                    const mappedFormData: DeviceInfo = {
                        id: data.deviceTypeId.toString(),
                        status: data.enabled === true ? 'true' : 'false',
                        name: data.name,
                        imei: data.imei
                    };
                    setFormData(mappedFormData);
                } catch (error) {
                    console.error('Error fetching users:', error);
                }
            };

            void fetchDevices();
        }
    }, [id]);

    const handleSelect = (selectedValue: string | null) => {
        setFormData(prevData => ({
            ...prevData,
            id: selectedValue !== null ? selectedValue : prevData.id
        }));
    };

    const handleSelect1 = (selectedValue: string | null) => {
        setFormData(prevData => ({
            ...prevData,
            status: selectedValue !== null ? selectedValue : prevData.status
        }));
    };

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Add Devices</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Add Devices</p>
                </div>
            </div>
            <form>
                <div className='bg-white   border-t-2 border-blue-800 rounded mt-5'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4'>
                        <div className='p-4'>
                            <div className='mb-1'>
                                <label className='text-black font-semibold text-sm' htmlFor=''>
                                    Select Device Type
                                </label>
                            </div>
                            <SingleSelectDropdown
                                options={options}
                                selectedValue={formData.id}
                                onSelect={handleSelect}
                                placeholder='Select Deviec Type'
                                selectClass='w-full'
                                disabled={action === 'view'}
                            />
                            {errors.deviceTypeId && <p className='text-red-500 text-sm '>{errors.deviceTypeId}</p>}
                        </div>
                        {id && (
                            <div className='p-4'>
                                <div className='mb-1'>
                                    <label className='text-black font-semibold text-sm' htmlFor=''>
                                        Select Device Status
                                    </label>
                                </div>
                                <SingleSelectDropdown
                                    options={option1}
                                    selectedValue={formData.status}
                                    onSelect={handleSelect1}
                                    placeholder='Select Deviec Type'
                                    selectClass='w-full'
                                    disabled={action === 'view'}
                                />
                            </div>
                        )}

                        <div className='p-4 mt-1'>
                            <div className=''>
                                <label htmlFor='name' className='text-sm font-semibold'>
                                    Full Name
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='name'
                                placeholder='Full Name'
                                className='w-full border h-9 pl-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
                                value={formData.name}
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                disabled={action === 'view'}
                            />
                            {errors.name && <p className='text-red-500 text-sm ml-4'>{errors.name}</p>}
                        </div>
                        <div className='p-4 mt-1'>
                            <div className=''>
                                <label htmlFor='name' className='text-sm font-semibold'>
                                    Imie Number
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='imie'
                                placeholder='imie number'
                                className='w-full border h-9 pl-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
                                value={formData.imei}
                                onChange={e => setFormData({ ...formData, imei: e.target.value })}
                                disabled={action === 'view'}
                            />
                            {errors.imei && <p className='text-red-500 text-sm ml-4'>{errors.imei}</p>}
                        </div>
                    </div>
                    <div className='flex justify-between mt-4 mr-2 p-4'>
                        <Link to='/companyAdmin/devices'>
                            <Button
                                type='button'
                                className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                            >
                                Back
                            </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                                onClick={event => {
                                    void handleButtonClick(event);
                                }}
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AddDevices;
