import React from 'react';

interface SmallCardsProps {
  title?: string;
  content?: string;
  className?: string; 
}

const SmallCards: React.FC<SmallCardsProps> = ({
  title = 'Small Card Title',
  content = 'Small Card Content',
  className = '', 
}) => {
  return (
    <div className={`rounded-lg border border-gray-300 p-2 bg-white ${className}`}>
      <p className="text-sm font-medium mb-1">{title}</p>
      <hr className="border-gray-200" />
      <p className="text-xs">{content}</p>
    </div>
  );
};

export default SmallCards;
