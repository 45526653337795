import { TableColumn } from "react-data-table-component";
import { VehicleTableInterface } from "../../utils/interfaces/masterVehicle";



export const MasterVehicleDataColumn: TableColumn<VehicleTableInterface>[] = [
    { name: 'ID', selector: (row: VehicleTableInterface) => row.id, sortable: true },
    { name: 'Vehicle Name', selector: (row: VehicleTableInterface) => row.name, sortable: true },
    { name: 'IMEI Number', selector: (row: VehicleTableInterface) => row.number, sortable: true },
    { name: 'Status', selector: (row: VehicleTableInterface) =>row.enabled ? "Active" : "Inactive" ,sortable: true },
    // { name: 'Device', selector: (row: VehicleTableInterface) => row.deviceName, sortable: true },

];
