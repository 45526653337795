import { IAllGeofanceService,IAllGeofance } from "./interfaces/IAllGeofanceService";
import {geofencesdata} from "./dummyData/AllGeofancedata";

export class AllGeofanceService implements IAllGeofanceService{
    getAllgeofance(): Promise<IAllGeofance[]>{
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(geofencesdata);
            }, 1000);
        });
    }}