import { TableColumn } from 'react-data-table-component';

export type IDevices = {
    id: number;
    deviceType: string;
    name: string;
    imei: string;
    enabled:boolean;
};
export const IDevicesDataColumn: TableColumn<IDevices>[] = [
    { name: 'ID', selector: (row: IDevices) => row.id, sortable: true },
    { name: 'DeviceType', selector: (row: IDevices) => row.deviceType, sortable: true },
    { name: 'Device Name', selector: (row: IDevices) => row.name, sortable: true },
    { name: 'IMEI Number', selector: (row: IDevices) => row.imei, sortable: true },
    { name: 'Status', selector: (row: IDevices) => row.enabled ? "Active" : "Inactive" , sortable: true }

];
