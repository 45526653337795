import React, { ReactNode } from 'react';

interface CardsProps {
  title?: string;
  titleColor?:string;
  background?: string;
  headerRight?: ReactNode; 
  className?: string;
  children?: ReactNode; 
  height?: string;
  width?: string;
}

const Cards: React.FC<CardsProps> = ({
  title = 'Default Title',
  titleColor='',
  headerRight, 
  background = 'bg-white',
  className = '',
  children,
  height = 'h-auto',
  width = 'w-full',
}) => {
  return (
    <div className={`rounded-lg border border-gray-300 p-4 ${background} ${className} ${height} ${width}`}>
      <div className="flex justify-between items-center pb-2">
        <p className={`text-base font-small ${titleColor}`}>{title}</p>
        {headerRight && <div>{headerRight}</div>}
      </div>
      <hr className="border-gray-200" />
      <div className="mt-4">
        {children} 
      </div>
    </div>
  );
};

export default Cards;


