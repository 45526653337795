import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

interface Option {
  value: string;
  label: string;
}

interface MultiselectDropdownProps {
  options: Option[];
  onSelect: (selectedValues: string[]) => void;
  selectedValues: string[];
  placeholder?: string;
  selectClass?: string;
  selectStyle?: React.CSSProperties;
  optionClass?: string;
  optionStyle?: React.CSSProperties;
  width?: string;
  isDisabled?: boolean; // Added isDisabled prop
}

const MultiselectDropdown: React.FC<MultiselectDropdownProps> = ({
  options,
  onSelect,
  selectedValues,
  placeholder,
  selectClass,
  selectStyle,
  optionClass,
  optionStyle,
  width = '12rem',
  isDisabled = false, // Default to not disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
      setSearchTerm('');
    }
  };

  const handleSelectOption = (selectedValue: string, event: React.MouseEvent) => {
    event.stopPropagation();
    if (isDisabled) return;

    const alreadySelected = selectedValues.includes(selectedValue);
    if (alreadySelected) {
      const newSelectedValues = selectedValues.filter(value => value !== selectedValue);
      onSelect(newSelectedValues);
    } else {
      onSelect([...selectedValues, selectedValue]);
    }
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef} style={{ width }}>
      <div
        className={`block w-full px-4 py-2 border rounded-md relative cursor-pointer ${
          isOpen ? 'border-blue-500' : 'border-gray-300'
        } ${selectClass} ${isDisabled ? 'cursor-not-allowed bg-gray-100' : ''}`} // Add styles when disabled
        style={{ ...selectStyle }}
        onClick={handleToggleDropdown}
      >
        {selectedValues.length > 0 ? (
          <div className="text-gray-800 flex flex-wrap">
            {selectedValues.map((value, index) => (
              <div key={index} className="flex items-center mr-1 mb-1">
                {options.find(option => option.value === value)?.label}
                {!isDisabled && (
                  <CloseIcon
                    className="ml-1 cursor-pointer"
                    onClick={(event) => handleSelectOption(value, event)}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-400">{placeholder || 'Select Option'}</div>
        )}
        <KeyboardArrowDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-700 pointer-events-none" />
      </div>
      {isOpen && !isDisabled && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <input
            type="text"
            className="block w-full px-4 py-2 border-b border-gray-300 focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="max-h-60 overflow-y-auto">
            {filteredOptions.map(option => (
              <div
                key={option.value}
                className={`block px-4 py-2 cursor-pointer ${
                  selectedValues.includes(option.value) ? 'bg-blue-100 font-bold' : 'hover:bg-gray-100'
                } ${optionClass}`}
                style={optionStyle}
                onClick={(event) => handleSelectOption(option.value, event)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectDropdown;
