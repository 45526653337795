import React, { useState, useEffect } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/table/Table';
import Input from '../../../../../components/Input';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { createDriverService } from '../../../../../services/ServiceFactory';
import { IDriverDataColumn, IDriver } from '../../../../../services/interfaces/IDriver';
function Driver() {
    // State to manage filter options visibility
    const [showContent, setShowContent] = useState<boolean>(true);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [data, setData] = useState<IDriver[]>([]);
    const DriverService = createDriverService();

    // Function to toggle content visibility
    const toggleContent = () => {
        setShowContent(prev => !prev);
    };
    console.log('selected Start', selectedStartDate, selectedEndDate);

    const actions = [
        { name: 'View', path: `/company/settings/master/newdriver/view`, icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: `/company/settings/master/newdriver/edit`, icon: FaEdit, class: 'text-green-700 text-2xl mx-1' },
        { name: 'Delete', path: '/delete', icon: FaTrash, class: 'text-red-700 text-2xl' }
    ];

   
    useEffect(() => {

        const fetchdata =async ()=>{
          try{
            const data= await DriverService.getDriver();
          setData(data);
          }catch{
            console.log('Error fetching data');
          }
          
        }
       void  fetchdata();
      
      }, []);

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Driver</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Driver</p>
                </div>
            </div>

            {/* Filter Options Section */}
            <div className='bg-white w-full  mt-4 rounded'>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <h1 className='text-blue-950 font-bold p-2 ml-2 cursor-pointer' onClick={toggleContent}>
                    Filter Options
                </h1>
                <hr />
                {showContent && (
                    <div className='w-full h-[230px]'>
                        <form>
                            <div className='flex items-start lg:flex-row flex-col  justify-between p-4'>
                                <div className='flex items-center lg:gap-6 gap-2'>
                                    <div>
                                        <Input
                                            type='date'
                                            id='date_from'
                                            value={selectedStartDate}
                                            onChange={date => setSelectedStartDate(date as Date | null)}
                                            placeholder='From'
                                            labelChildren='From'
                                            error={false}
                                            className='w-[80%] lg:w-full border border-gray-300 px-7 lg:px-10 py-1'
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            type='date'
                                            id='date_to'
                                            value={selectedEndDate}
                                            onChange={date => setSelectedEndDate(date as Date | null)}
                                            placeholder='Select a date'
                                            labelChildren='To'
                                            error={false}
                                            className='w-[80%] lg:w-full border border-gray-300 px-7 lg:px-10  py-1'
                                        />
                                    </div>

                                    <div className='flex gap-4 mt-4'>
                                        <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'>
                                            Check
                                        </Button>
                                        <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'>
                                            Clear
                                        </Button>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <Link to={'/company/settings/master/newdriver'}>
                                        <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                                            New Devices
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <div className='flex lg:gap-10 gap-5 ml-4'>
                                <div className='flex flex-col items-start'>
                                    <label htmlFor='date-input-from' className='text-sm font-semibold mb-1'>
                                        Import File Here
                                    </label>
                                    <input
                                        type='file'
                                        id='file'
                                        className='file:text-sm file:text-center file:mt-1 file:ml-1 lg:w-[400px] w-[250px] h-9 border border-gray-300 shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                                    />
                                </div>
                                <div className='flex gap-4 mt-5'>
                                    {' '}
                                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'>
                                        Import
                                    </Button>
                                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[130px] h-[35px] rounded-md'>
                                        Sample to CSV
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <Table
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                columns={IDriverDataColumn}
                data={data}
                tableRowActions={actions}
            />
        </div>
    );
}

export default Driver;
