// Importing interfaces and dummy data
import {
    IVehicleMovementReport,
    IVehicleService,
    IVehicleParkedReport,
    IVehicleWorkhourReport,
    IVehicleIdleReport,
    IVehicleOfflineReport,
    IVehicleNewdeviceReport,
    IVehicleParkedReportSingle,
    IVehicleWorkhourReportSingle,
    IVehicleIdleReportSingle,
    IVehicleMovementReportSingle,
  } from './interfaces/IVehicleService';
  
  import {
    vehicleMovementReports,
    vehicleParkedReports,
    vehicleIdleReports,
    vehicleOfflineReports,
    vehicleNewdeviceReports,
    vehicleWorkhourReports,
    vehicleMovementReportsSingleData,
    vehicleParkedReportsSingleData,
    vehicleIdleReportsSingleData,
    vehicleWorkhourReportsSingleData
  } from './dummyData/VehicleReportsData';
  
  // Implementing the VehicleService class
  export class VehicleService implements IVehicleService {
    getMovementReport(): Promise<IVehicleMovementReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleMovementReports);
        }, 1000);
      });
    }
  
    getParkedReport(): Promise<IVehicleParkedReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleParkedReports);
        }, 1000);
      });
    }
  
    getIdleReport(): Promise<IVehicleIdleReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleIdleReports);
        }, 1000);
      });
    }
  
    getNewdeviceReport(): Promise<IVehicleNewdeviceReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleNewdeviceReports);
        }, 1000);
      });
    }
  
    getWorkhourReport(): Promise<IVehicleWorkhourReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleWorkhourReports);
        }, 1000);
      });
    }
  
    getOfflineReport(): Promise<IVehicleOfflineReport[]> {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(vehicleOfflineReports);
        }, 1000);
      });
    }

// single vehicle reports
getMovementReportSingle(): Promise<IVehicleMovementReportSingle[]> {
  return new Promise<IVehicleMovementReportSingle[]>((resolve) => {
    setTimeout(() => {
      resolve(vehicleMovementReportsSingleData);
    }, 1000);
  });
}

getParkedReportSingle(): Promise<IVehicleParkedReportSingle[]> {
  return new Promise<IVehicleParkedReportSingle[]>((resolve) => {
    setTimeout(() => {
      resolve(vehicleParkedReportsSingleData);
    }, 1000);
  });
}

getIdleReportSingle(): Promise<IVehicleIdleReportSingle[]> {
  return new Promise<IVehicleIdleReportSingle[]>((resolve) => {
    setTimeout(() => {
      resolve(vehicleIdleReportsSingleData);
    }, 1000);
  });
}

getWorkhourReportSingle(): Promise<IVehicleWorkhourReportSingle[]> {
  return new Promise<IVehicleWorkhourReportSingle[]>((resolve) => {
    setTimeout(() => {
      resolve(vehicleWorkhourReportsSingleData);
    }, 1000);
  });
}

  }
  