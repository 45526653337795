import { IEmergenctAlert, IEmergencyAlertService } from './interfaces/IEmergenctAlert';
import { emergencyAlertData } from './dummyData/EmergencyAlertData';

export class EmergencyAlertService implements IEmergencyAlertService {
    getEmergencyAlert(): Promise<IEmergenctAlert[]> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(emergencyAlertData);
            }, 1000);
        });
    }
}
