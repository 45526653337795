import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import Footer from '../footer/Footer';

const Layout: React.FC = () => {
  return (
    <div className="h-screen overflow-auto">
      <div className="flex min-h-full">
        <Sidebar />
        <main className="flex flex-col flex-1 bg-gray-300 overflow-y-auto">
          <div className="flex-grow">
            <Outlet />
          </div>
          <Footer/>
        </main>
      </div>
    </div>
  );
};

export default Layout;
