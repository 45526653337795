import { IEmergenctAlert } from '../interfaces/IEmergenctAlert';

export const emergencyAlertData: IEmergenctAlert[] = [
    {
        VehicleNumber: 'ABC123',
        Title: 'Engine Overheat',
        Messege: 'The engine temperature is exceeding normal limits.',
        DriverName: 'John Doe',
        EmployeeName: 'Jane Smith',
        EmployeeId: 101,
        Department: 'Maintenance',
        Plant: 'Plant A',
        G_Map: 'Point A1',
        IssueRaised: '2024-08-21T14:30:00Z'
    },
    {
        VehicleNumber: 'XYZ789',
        Title: 'Brake Failure',
        Messege: 'Brakes are not responding as expected.',
        DriverName: 'Alice Johnson',
        EmployeeName: 'Bob Brown',
        EmployeeId: 102,
        Department: 'Logistics',
        Plant: 'Plant B',
        G_Map: 'Point B2',
        IssueRaised: '2024-08-22T09:15:00Z'
    },
    {
        VehicleNumber: 'LMN456',
        Title: 'Tire Pressure Warning',
        Messege: 'Tire pressure is below the recommended level.',
        DriverName: 'Chris Lee',
        EmployeeName: 'Sarah Davis',
        EmployeeId: 103,
        Department: 'Quality Control',
        Plant: 'Plant C',
        G_Map: 'Point C3',
        IssueRaised: '2024-08-23T11:00:00Z'
    },
    {
        VehicleNumber: 'JKL012',
        Title: 'Oil Leak',
        Messege: 'There is a significant oil leak detected.',
        DriverName: 'Emma Wilson',
        EmployeeName: 'Michael Brown',
        EmployeeId: 104,
        Department: 'Repair',
        Plant: 'Plant D',
        G_Map: 'Point D4',
        IssueRaised: '2024-08-24T16:45:00Z'
    },
    {
        VehicleNumber: 'GHI345',
        Title: 'Headlight Outage',
        Messege: 'One of the headlights is not functioning.',
        DriverName: 'Oliver Green',
        EmployeeName: 'Sophia Turner',
        EmployeeId: 105,
        Department: 'Inspection',
        Plant: 'Plant E',
        G_Map: 'Point E5',
        IssueRaised: '2024-08-25T08:30:00Z'
    }
];
