import { IDriverRating,IDriverRatingService ,FormValues} from "./interfaces/IDriverRating";
import { driverRatings ,formValuesData } from "./dummyData/DriverRatingData";
export class IDriverRatingServiceClass implements IDriverRatingService {
    getDriverRatingData(): Promise<IDriverRating[]>{
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(driverRatings);
            }, 1000);
        });
    }
    getDriverRatingDataCreate(id: number): Promise<FormValues[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(formValuesData.filter(item => item.id === id));
            }, 1000);
        });
    }


}
