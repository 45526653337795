import React from 'react';
import { FaEye } from 'react-icons/fa';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import { useState, useEffect } from 'react';
import { createDriverBehaviorService } from '../../../../services/ServiceFactory';
import { IDeriverRatingReport, DriverRatingReportColumn } from '../../../../services/interfaces/IDriverBehaviourReport';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const DriverRatingReportUI: React.FC = () => {
    const Report_type = 'Filter Options';

    // send data for filter report
    const options: string[] = ['all', 'krishana', 'ram', 'rakehs', 'meash'];
    const singleselect: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    const field_type: string[] = ['Branch', 'Work Type', 'From', 'To'];
    const [selecteddata, setSelecteddata] = useState<CustomFormData>();
    const [data, setData] = useState<IDeriverRatingReport[]>([]);
    const handleSelectedData = (data: CustomFormData) => {
        setSelecteddata(data);
    };
    console.log(selecteddata);
    const geofanceReportService = createDriverBehaviorService();

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const data = await geofanceReportService.getDriverRatingReport();
                setData(data);
            } catch (error) {
                console.log(error);
            }
        };
        void fetchdata();
    }, []);

    const actions = [
        { name: 'View', path: '/company/report/geo-fence/entry-exit', icon: FaEye, class: 'text-blue-700 text-2xl' }
    ];
    return (
        <>
            <div className='md:flex justify-between items-center'>
                <h1 className='text-xl font-bold mb-4'>Driver Rating</h1>
                <div className='flex items-center bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Home</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
                    <p> Driver Rating</p>
                </div>
            </div>
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={options}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table
                data={data}
                columns={DriverRatingReportColumn}
                tableRowActions={actions}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
            />
        </>
    );
};

export default DriverRatingReportUI;
