import { TableColumn } from "react-data-table-component";
export interface IGeofanceReportService {
    getGeofanceEntryexitoneReport(): Promise<IGeofanceEntryexitone[]>;
    getGeofanceEntryexitReport(): Promise<IGeofanceEntryexit[]>;
    getGeofanceToGeofenceReport(): Promise<IGeofancetogeofance[]>;
    getGeofanceToGeofenceparticulerVReport(): Promise<IGeoToGeoParticularVehicle[]>;

}
 export type IGeofanceEntryexitone = {
    id: number;
    company: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imeiNumber: string;
    geofanceName: string;
    fanceEntrytime: string;
    etryCoordinates: string;
    entryPosition: string;
    durationinFences:number;
    traveldastination:number;
    fanceExittime: string;
    exitCoordinates: string;
    exitpositions: string;
}
export const GeofanceEntryexitoneDataColumn:TableColumn<IGeofanceEntryexitone>[] = [
    { name: 'ID', selector: (row: IGeofanceEntryexitone) => row.id, sortable: true, },
    { name: 'Company', selector: (row: IGeofanceEntryexitone) => row.company, sortable: true },
    { name: 'Vehicle Name', selector: (row: IGeofanceEntryexitone) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IGeofanceEntryexitone) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IGeofanceEntryexitone) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IGeofanceEntryexitone) => row.driver, sortable: true },
    { name: 'IMEI Number', selector: (row: IGeofanceEntryexitone) => row.imeiNumber, sortable: true },
    { name: 'GeofanceName', selector: (row: IGeofanceEntryexitone) => row.geofanceName, sortable: true },
    { name: 'FanceEntrytime', selector: (row: IGeofanceEntryexitone) => row.fanceEntrytime, sortable: true },
    { name: 'EtryCoordinates', selector: (row: IGeofanceEntryexitone) => row.etryCoordinates, sortable: true },
    { name: 'EntryPosition', selector: (row: IGeofanceEntryexitone) => row.entryPosition, sortable: true },
    { name: 'DurationinFences', selector: (row: IGeofanceEntryexitone) => row.durationinFences, sortable: true },
    { name: 'Traveldastination', selector: (row: IGeofanceEntryexitone) => row.traveldastination, sortable: true },
    { name: 'FanceExittime', selector: (row: IGeofanceEntryexitone) => row.fanceExittime, sortable: true },
    { name: 'ExitCoordinates', selector: (row: IGeofanceEntryexitone) => row.exitCoordinates, sortable: true },
    { name: 'Exitpositions', selector: (row: IGeofanceEntryexitone) => row.exitpositions, sortable: true },
   
];


// export type IGeofanceEntryexit = {
//     id: number;
//     company: string;
//     branch:string;
//     vehicleName: string;
//     vehicleBrand: string;
//     vehicleModel: string;
//     driver: string;
//     imei: string;
//     geofanceName: string;
//     firstVisittime: string;
//     lastVisittime: string;
//     noOfvisits:number;
// }
// export const GeofenceEntryExitColumns = [
//     { name: 'ID', selector: (row: IGeofanceEntryexit) => row.id, sortable: true },
//     { name: 'Company', selector: (row: IGeofanceEntryexit) => row.company, sortable: true },
//     { name: 'Branch', selector: (row: IGeofanceEntryexit) => row.branch, sortable: true },
//     { name: 'Vehicle Name', selector: (row: IGeofanceEntryexit) => row.vehicleName, sortable: true },
//     { name: 'Vehicle Brand', selector: (row: IGeofanceEntryexit) => row.vehicleBrand, sortable: true },
//     { name: 'Vehicle Model', selector: (row: IGeofanceEntryexit) => row.vehicleModel, sortable: true },
//     { name: 'Driver', selector: (row: IGeofanceEntryexit) => row.driver, sortable: true },
//     { name: 'IMEI', selector: (row: IGeofanceEntryexit) => row.imei, sortable: true },
//     { name: 'IGeofence Name', selector: (row: IGeofanceEntryexit) => row.geofanceName, sortable: true },
//     { name: 'First Visit Time', selector: (row: IGeofanceEntryexit) => row.firstVisittime, sortable: true },
//     { name: 'Last Visit Time', selector: (row: IGeofanceEntryexit) => row.lastVisittime, sortable: true },
//     { name: 'Number of Visits', selector: (row: IGeofanceEntryexit) => row.noOfvisits, sortable: true },
//   ];

  
  
  
  
  interface Vehicle {
    id: number;
    name: string;
    companyId: number;
    company: {name:string};
    device: { imei: string};
     }
  export interface IGeofanceEntryexit {
    vehicleNumber: string;
    firstVisit: string;
    lastVisit: string;
    totalVisits: string;
    vehicle: Vehicle;
    geofence: {name:string};
    [key:string]: string|Vehicle|object;
  }
  
  export const GeofenceEntryExitColumns: TableColumn<IGeofanceEntryexit>[] = [
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicleNumber,
      sortable: true,
    },
    {
      name: "First Visit",
      selector: (row) => new Date(row.firstVisit).toLocaleString(),
      sortable: true,
    },
    {
      name: "Last Visit",
      selector: (row) => new Date(row.lastVisit).toLocaleString(),
      sortable: true,
    },
    {
      name: "Total Visits",
      selector: (row) => row.totalVisits,
      sortable: true,
    },
    {
      name: "Vehicle Name",
      selector: (row) => row.vehicle.name,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.vehicle.company.name,
      sortable: true,
    },
    {
      name: "Device IMEI",
      selector: (row) => row.vehicle.device.imei,
      sortable: true,
    },
    {
      name: "Geofence",
      selector: (row) => row.geofence.name,
      sortable: true,
    },
  ];
  export type IGeofancetogeofance = {
    id: number;
    company: string;
    branch:string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imei: string;
    tripStartgeofance: string;
    tripStarttime: string;
    tripEndgeofance: string;
    tripEndtime: string;
    noOftrips: number; 
    distance: number;
    running: string;
    idle: string;
    stop:number;
    inactive: string;
    durations: number;
}
export const IGeofenceTogeofenceColumns:TableColumn<IGeofancetogeofance>[] = [
    { name: 'ID', selector: (row: IGeofancetogeofance) => row.id, sortable: true },
    { name: 'Company', selector: (row: IGeofancetogeofance) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IGeofancetogeofance) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IGeofancetogeofance) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IGeofancetogeofance) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IGeofancetogeofance) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IGeofancetogeofance) => row.driver, sortable: true },
    { name: 'IMEI', selector: (row: IGeofancetogeofance) => row.imei, sortable: true },
    { name: 'Trip Start Geofence', selector: (row: IGeofancetogeofance) => row.tripStartgeofance, sortable: true },
    { name: 'Trip Start Time', selector: (row: IGeofancetogeofance) => row.tripStarttime, sortable: true },
    { name: 'Trip End Geofence', selector: (row: IGeofancetogeofance) => row.tripEndgeofance, sortable: true },
    { name: 'Trip End Time', selector: (row: IGeofancetogeofance) => row.tripEndtime, sortable: true },
    { name: 'Number of Trips', selector: (row: IGeofancetogeofance) => row.noOftrips, sortable: true },
    { name: 'Distance', selector: (row: IGeofancetogeofance) => row.distance, sortable: true },
    { name: 'Running', selector: (row: IGeofancetogeofance) => row.running, sortable: true },
    { name: 'Idle', selector: (row: IGeofancetogeofance) => row.idle, sortable: true },
    { name: 'Stop', selector: (row: IGeofancetogeofance) => row.stop, sortable: true },
    { name: 'Inactive', selector: (row: IGeofancetogeofance) => row.inactive, sortable: true },
    { name: 'Durations', selector: (row: IGeofancetogeofance) => row.durations, sortable: true }
  ];
// geofance to geofance for particuler vehicle
export type IGeoToGeoParticularVehicle = {
    id: number;
    company: string;
    branch:string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imei: string;
    tripStartgeofance: string;
    tripStarttime: string;
    tripEndgeofance: string;
    tripEndtime: string;
    distance: number;
    running: string;
    idle: string;
    stop:number;
    inactive: string;
    durations: number;
}
export const geoToGeoParticularVehicleDataColumn:TableColumn<IGeoToGeoParticularVehicle>[]  = [
    { name: 'ID', selector: (row: IGeoToGeoParticularVehicle) => row.id, sortable: true },
    { name: 'Company', selector: (row: IGeoToGeoParticularVehicle) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IGeoToGeoParticularVehicle) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IGeoToGeoParticularVehicle) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IGeoToGeoParticularVehicle) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IGeoToGeoParticularVehicle) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IGeoToGeoParticularVehicle) => row.driver, sortable: true },
    { name: 'IMEI', selector: (row: IGeoToGeoParticularVehicle) => row.imei, sortable: true },
    { name: 'Trip Start Geofence', selector: (row: IGeoToGeoParticularVehicle) => row.tripStartgeofance, sortable: true },
    { name: 'Trip Start Time', selector: (row: IGeoToGeoParticularVehicle) => row.tripStarttime, sortable: true },
    { name: 'Trip End Geofence', selector: (row: IGeoToGeoParticularVehicle) => row.tripEndgeofance, sortable: true },
    { name: 'Trip End Time', selector: (row: IGeoToGeoParticularVehicle) => row.tripEndtime, sortable: true },
    { name: 'Distance', selector: (row: IGeoToGeoParticularVehicle) => row.distance, sortable: true },
    { name: 'Running', selector: (row: IGeoToGeoParticularVehicle) => row.running, sortable: true },
    { name: 'Idle', selector: (row: IGeoToGeoParticularVehicle) => row.idle, sortable: true },
    { name: 'Stop', selector: (row: IGeoToGeoParticularVehicle) => row.stop, sortable: true },
    { name: 'Inactive', selector: (row: IGeoToGeoParticularVehicle) => row.inactive, sortable: true },
    { name: 'Durations', selector: (row: IGeoToGeoParticularVehicle) => row.durations, sortable: true }
  ];
