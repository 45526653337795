import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VehicleDetails from './VehicleDetails';

interface Device {
    id?: number;
    name?: string;
    date: string;
    color?: string;
    speed: number;
    status: string;
    dname: string;
    lat: number;
    lng: number;
}

interface SelectedDevicePanelProps {
    selectedDevice: Device | null;
    onClose: () => void;
}

const SelectedDevicePanel: React.FC<SelectedDevicePanelProps> = ({ selectedDevice, onClose }) => {
    if (!selectedDevice) return null;

    return (
        <div className='absolute top-20 right-0  w-[240px] lg:w-[270px] z-[999] shadow-lg rounded-md' style={{ backgroundColor: "transparent" }}>
            {/* absolute top-20 right-0 bg-white w-[240px] lg:w-[270px] z-[999] shadow-lg rounded-md */}
            <div>
                <button
                    onClick={onClose}
                    className='absolute top-5 left-[-32px] h-8 w-8 bg-blue-800 rounded-tl-md rounded-bl-md'
                >
                    <ArrowForwardIosIcon className='text-white mb-1' style={{ fontSize: '15px' }} />
                </button>
            </div>
            <VehicleDetails selectedDevice={selectedDevice} />
        </div>
    );
};

export default SelectedDevicePanel;
