import React from 'react';
import { FaEye } from 'react-icons/fa';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import { useState, useEffect } from 'react';
import { createGeofanceReportService } from '../../../../services/ServiceFactory';
import { IGeofancetogeofance, IGeofenceTogeofenceColumns } from '../../../../services/interfaces/IGeofanceService';
const GeofanceTogefanceReportUI: React.FC = () => {
    const Report_type = 'Geo-fence To Geo-fence';

    // send data for filter report
    const options: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    const singleselect: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    const field_type: string[] = ['Vehicle', 'Select GeoFence Type', 'From', 'To'];
    const [loading, setLoading] = useState(false);

    const [selecteddata, setSelecteddata] = useState<CustomFormData>();
    const [data, setData] = useState<IGeofancetogeofance[]>([]);
    const handleSelectedData = (data: CustomFormData) => {
        setSelecteddata(data);
    };
    console.log(selecteddata);
    const geofanceReportService = createGeofanceReportService();
    useEffect(() => {
        setLoading(true);
        const fetchdata = async () => {
            try {
                const data = await geofanceReportService.getGeofanceToGeofenceReport();
                setData(data);
            } catch {
                console.log('Error fetching geofence to geofence report data');
            } finally {
                setLoading(false);
            }
        };

        void fetchdata();
    }, []);
    const actions = [
        {
            name: 'View',
            path: '/company/report/geofanceReports/geofance-to-geofance',
            icon: FaEye,
            class: 'text-blue-700 text-2xl'
        }
    ];

    return (
        <>
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={options}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table
                data={data}
                columns={IGeofenceTogeofenceColumns}
                loading={loading}
                tableRowActions={actions}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
            />
        </>
    );
};

export default GeofanceTogefanceReportUI;
