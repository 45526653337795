import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LoadingSpinner from '../LoadingSpinner';
import styles from './Table.module.css';
import useHttp from '../../hooks/useHttp';
import { toast } from 'react-toastify';

interface RowData {
    id?: number;
    [key: string]: string | number | boolean | undefined | string[] | object;
}

export interface ITableRowAction {
    name?: string;
    path?: string;
    icon?: React.ComponentType;
    class?: string;
}

interface ITableProps<T> {
    tableRowActions?: ITableRowAction[] | undefined;
    columns?: TableColumn<T>[];
    loading?: boolean;
    data?: T[];
    searchAble?: boolean;
    excelExport?: boolean;
    pdfExport?: boolean;
}

const Table = <T extends RowData>(props: ITableProps<T>) => {
    const { tableRowActions, columns, data, excelExport, searchAble, pdfExport, loading } = props;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [combinedColumns, setCombinedColumns] = useState<TableColumn<T>[]>([]);
    const navigate = useNavigate();
    const { request } = useHttp();
    useEffect(() => {
        if (tableRowActions && tableRowActions.length > 0) {
            const actionColumn: TableColumn<T> = {
                name: 'Actions',
                cell: (row: T) => (
                    <div>
                        {tableRowActions.map((action, index) => {
                            const Icon = action.icon;
                            if (action.name === 'Delete' || action.icon === FaTrash) {
                                return (
                                    <button
                                        key={index}
                                        className={action.class}
                                        onClick={() => {
                                            const fetchData = async () => {
                                                try {
                                                    const response = await request({
                                                        url: action.path ? action.path + row.id : '',
                                                        method: 'DELETE'
                                                    });

                                                    if (response.ok) {
                                                        confirm('Do you want to delete');
                                                        void toast.success('Data Deleted Successfully');
                                                    }
                                                    if (!response.ok) {
                                                        throw new Error('Failed to fetch companies');
                                                    }
                                                } catch (error) {
                                                    console.error('Error fetching geofence data:', error);
                                                    alert(action.path ? action.path + row.id : '');
                                                }
                                            };

                                            void fetchData();
                                        }}
                                    >
                                        {Icon ? <Icon /> : ''}
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        key={index}
                                        className={action.class}
                                        onClick={() => navigate(`${action.path}/${row.id}`)}
                                    >
                                        {Icon ? <Icon /> : ''}
                                    </button>
                                );
                            }
                        })}
                    </div>
                )
            };

            setCombinedColumns(columns ? [...columns, actionColumn] : [actionColumn]);
        } else {
            setCombinedColumns(columns || []);
        }
    }, [tableRowActions, columns, navigate]);

    const exportToExcel = () => {
        if (!data) return;
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'data.xlsx');
    };

    const exportToPDF = () => {
        if (!data || !combinedColumns) return;
        const doc = new jsPDF();
        const columnsForPDF = combinedColumns.map(column => column.name);
        const rowsForPDF = data.map(row =>
            combinedColumns.map(column => {
                const selector = column.selector as (row: T) => unknown;
                if (selector) {
                    return selector(row);
                } else if (column.name && typeof column.name === 'string') {
                    const key = column.name.toLowerCase();
                    return row[key as keyof T];
                }
                return '';
            })
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
        (doc as any).autoTable({
            head: [columnsForPDF],
            body: rowsForPDF
        });

        doc.save('table.pdf');
    };

    const filteredData = data
        ? data.filter(item =>
              combinedColumns.some(column => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const selector = column.selector as (row: T) => any;
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const value = selector
                      ? selector(item)
                      : column.name && typeof column.name === 'string'
                        ? item[column.name.toLowerCase() as keyof T]
                        : '';
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                  return value && value.toString().toLowerCase().includes(searchQuery.toLowerCase());
              })
          )
        : [];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px'
            }
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px'
            }
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '8px'
            }
        },
        pagination: {
            style: {
                fontSize: '16px' // Optional styling
            },
            pageButtonsStyle: {
                fontSize: '18px',
                width: '40px',
                fontWeight: 'light',
                color: 'black-700',
                cursor: 'pointer',
                margin: '2 8 2 8',
                padding: '3px',
                border: '1px ',
                borderRadius: '2px'
            }
        }
    };

    return (
        <div className='mt-4 border-2 bg-white border-t-blue-900 border-t-[4px]'>
            {(excelExport ? excelExport : false) && (
                <button className={styles.excelExportButton} onClick={exportToExcel}>
                    Excel
                </button>
            )}

            {(pdfExport ? pdfExport : false) && (
                <button className={styles.pdfExportButton} onClick={exportToPDF}>
                    PDF
                </button>
            )}
            <div className='text-end'>
                {(searchAble ? searchAble : false) && (
                    <input
                        type='text'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className='border-2 p-2 rounded mx-2 mt-2'
                    />
                )}
            </div>
            {loading ? (
                <LoadingSpinner size={20} color='border-blue-500' />
            ) : (
                <DataTable
                    columns={combinedColumns}
                    data={filteredData}
                    customStyles={customStyles}
                    pagination
                    paginationIconNext='Next'
                    paginationIconPrevious='Previous'
                    paginationIconFirstPage={null}
                    paginationIconLastPage={null}
                />
            )}
        </div>
    );
};

export default Table;
