import React from "react";
import Table from "../../../../components/table/Table";
import { useState, useEffect } from "react";
import BackButton from "../../../../components/BackButton";
import { createGeofanceReportService } from "../../../../services/ServiceFactory";
import {IGeoToGeoParticularVehicle,geoToGeoParticularVehicleDataColumn} from "../../../../services/interfaces/IGeofanceService";

const GeofanceTogeofanceReportparticularvehicleUI: React.FC = () => {
  const [data, setData] = useState<IGeoToGeoParticularVehicle[]>([]);
  const [loading, setLoading] = useState(false);

  const geofanceReportService = createGeofanceReportService();
  
  useEffect(() => {
    setLoading(true);
    const fetchdata = async ()=>{
      try{
        const  data= await geofanceReportService.getGeofanceToGeofenceparticulerVReport();
        setData(data);
      }catch{
        console.log('Error fetching geofence to geofence report data');
      }finally{
        setLoading(false);

      }
    }
    
   void fetchdata();
  }, []);

  return (
    <>
    <p> 
        <h1 className="text-black-700 text-xl">Geofance To Geofance Particuler Vehicle Report</h1>
               <BackButton label="Go Back" className=" absolute float-end  right-9 top-3" />
    </p>
      <Table data={data} columns={geoToGeoParticularVehicleDataColumn} loading={loading} excelExport={true} pdfExport={true}/>
    </>
  );
};

export default GeofanceTogeofanceReportparticularvehicleUI;
