import React from 'react';

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 24, color = 'border-gray-500' }) => {
  const sizeClass = `h-${size} w-${size}`;
  return (
    <div className="flex justify-center items-center">
      <div
        className={`animate-spin rounded-full ${sizeClass} border-t-2 border-b-2 ${color}`}
        style={{ borderTopColor: 'transparent' }}
      ></div>
    </div>
  );
};

export default LoadingSpinner;
