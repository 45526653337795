import Preventive_MaintainenceTable from './components/Preventive_MaintainenceTable';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Preventive_Maintainence() {
  return (
    <div>
      <div className='md:flex justify-between items-center'>
        <div className="text-2xl">Preventive Maintainence</div>
        <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
          <ul>
            <li>
              <Link to='/company/dashboard' className='flex items-center space-x-2'>
                <DashboardIcon />
                <span>Dashboard</span>
              </Link>
            </li>
          </ul>
          <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
          <p className='ml-2 text-sm'>Preventive Maintainence</p>
        </div>
      </div>
      <Preventive_MaintainenceTable />
    </div>
  );
}

export default Preventive_Maintainence;
