import React from 'react';

interface ColorPickerProps {
  selectedColor: string;
  onColorChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ selectedColor, onColorChange }) => {
  const colors = ['#FF0000', '#00FF00', '#0000FF', '#000000', '#FFFFFF'];

  return (
    <div className=' '>
      <span>Pick a color:</span>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        {colors.map(color => (
          <div
            key={color}
            onClick={() => onColorChange(color)}
            style={{
              backgroundColor: color,
              width: '30px',
              height: '30px',
              margin: '0 5px',
              borderRadius: '50%',
              cursor: 'pointer',
              border: selectedColor === color ? '2px solid black' : 'none'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
