import { TableColumn } from 'react-data-table-component';

export type IAlerts = {
    id: number;
    alertType: {
        name: string; // Adjusted to match the nested structure
    };
    enabled: boolean;
    threshold: string;
};

export const AlertsDataColumn: TableColumn<IAlerts>[] = [
    { name: 'ID', selector: (row: IAlerts) => row.id, sortable: true },
    { name: 'Alert Type', selector: (row: IAlerts) => row.alertType.name, sortable: true },
    { name: 'Status', selector: (row: IAlerts) => (row.enabled ? 'Active' : 'Inactive'), sortable: true },
    { name: 'Threshold', selector: (row: IAlerts) => row.threshold, sortable: true }
];
