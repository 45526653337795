import { TableColumn } from "react-data-table-component";

export interface IPreventiveMaintainenceService {
    getPreventiveMaintainence(): Promise<IPreventiveMaintainence[]>;
}

export type IPreventiveMaintainence = {
    id: number;
    reseller_id: number;
    company_id: number;
    reminder_name: string;
    reminder_type_id: number;
    reminder_month: number;
    notify_days: number;
    notify_distance: number;
    engine_hour: number;
    notify_engine: number;
    reminder_notify_to: string;
    reminder_mobile_number: string;
    reminder_email: string;
};

export const PreventiveMaintainenceDataColumn: TableColumn<IPreventiveMaintainence>[] = [
    { name: 'ID', selector: (row: IPreventiveMaintainence) => row.id, sortable: true },
    { name: 'Reseller ID', selector: (row: IPreventiveMaintainence) => row.reseller_id, sortable: true },
    { name: 'Company ID', selector: (row: IPreventiveMaintainence) => row.company_id, sortable: true },
    { name: 'Reminder Name', selector: (row: IPreventiveMaintainence) => row.reminder_name, sortable: true },
    { name: 'Reminder Type ID', selector: (row: IPreventiveMaintainence) => row.reminder_type_id, sortable: true },
    { name: 'Reminder Month', selector: (row: IPreventiveMaintainence) => row.reminder_month, sortable: true },
    { name: 'Notify Days', selector: (row: IPreventiveMaintainence) => row.notify_days, sortable: true },
    { name: 'Notify Distance', selector: (row: IPreventiveMaintainence) => row.notify_distance, sortable: true },
    { name: 'Engine Hour', selector: (row: IPreventiveMaintainence) => row.engine_hour, sortable: true },
    { name: 'Notify Engine', selector: (row: IPreventiveMaintainence) => row.notify_engine, sortable: true },
    { name: 'Reminder Notify To', selector: (row: IPreventiveMaintainence) => row.reminder_notify_to, sortable: true },
    { name: 'Reminder Mobile Number', selector: (row: IPreventiveMaintainence) => row.reminder_mobile_number, sortable: true },
    { name: 'Reminder Email', selector: (row: IPreventiveMaintainence) => row.reminder_email, sortable: true },
];
