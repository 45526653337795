import React from 'react';
import Table from '../../../../components/table/Table';
import NavTabs from '../../../../components/NavTabs';
import tabs from './Tab';
import { useState, useEffect } from 'react';
import { createVehicleService } from '../../../../services/ServiceFactory';
import { IVehicleNewdeviceReport, NewdeviceDataColumn } from '../../../../services/interfaces/IVehicleService';

const NewdeviceReportUI: React.FC = () => {
    const [data, setData] = useState<IVehicleNewdeviceReport[]>([]);
    const [loading, setLoading] = useState(false);

    const vehicleservices = createVehicleService();
    useEffect(() => {
        setLoading(true);
        void vehicleservices
            .getNewdeviceReport()
            .then(data => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <NavTabs tabs={tabs} />
            <div className='container p-4 bg-white  w-[100%] border-t-blue-900 border-t-[4px]'>
                <p className='text-black-800 text-lg'>New Device</p>
            </div>

            <Table<IVehicleNewdeviceReport>
                columns={NewdeviceDataColumn}
                data={data}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                loading={loading}
            />
        </>
    );
};

export default NewdeviceReportUI;
