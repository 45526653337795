import React, { useState, useEffect } from 'react';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import NavTabs from '../../../../components/NavTabs';
import tabs from './Tab';
import { IVehicleIdleReport, IdledDataColumn } from '../../../../services/interfaces/IVehicleService';
import { FaEye } from 'react-icons/fa';
import useHttp from '../../../../hooks/useHttp';
import { VehicleTableInterface } from '../../../../utils/interfaces/masterVehicle';

interface VehicleApiResponse {
    message: string;
    data: VehicleTableInterface[];
}

const IdleReportUI: React.FC = () => {
    const { request } = useHttp();
    const [vehicle, setVehicle] = useState<VehicleTableInterface[]>([]);
    const Report_type = 'Idle';
    const field_type: string[] = ['Vehicle', 'Interval', 'From', 'To'];
    const [selectedData, setSelectedData] = useState<CustomFormData>();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IVehicleIdleReport[]>([]);
    const [matchingVehicleIds, setMatchingVehicleIds] = useState<number[]>([]);

    useEffect(() => {
        void fetchVehicle();
        if(selectedData){
            void fetchData();
        }
    }, [selectedData]);

    const fetchData = async () => {
        if (selectedData) {
            const startdate = selectedData.startDateTime
            ? selectedData.startDateTime.toISOString()
            : '';
            const enddate = selectedData.endDateTime
            ? selectedData.endDateTime.toISOString()
            : '';
          const intervalTime:string=selectedData.singleSelect?selectedData.singleSelect?.value:'5m';
            const params = new URLSearchParams({
                start: startdate,
                stop:enddate ,
                aggregateWindow: intervalTime,
                page: "1",
                pageSize: "100",
                vehicleIds: `${matchingVehicleIds.join(",")}`
            });
              const url = `/report/idle?${params.toString()}`;
            setLoading(true);

            try {
                const response = await request({
                    method: 'GET',
                    url: url,
                });


                if (!response.ok) throw new Error('Failed to fetch data');
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                const reportData = (await response.json()).data as IVehicleIdleReport[];

                setData(reportData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };


    const fetchVehicle = async () => {
        setLoading(true);
        try {
            const response = await request({ url: '/vehicles', method: 'GET' });
            if (!response.ok) throw new Error('Failed to fetch vehicles');

            const responseData = await response.json() as VehicleApiResponse;

            if (Array.isArray(responseData.data)) {
                setVehicle(responseData.data);
            } else {
                console.error('Fetched data is not an array:', responseData.data);
                setVehicle([]);
            }
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        } finally {
            setLoading(false);
        }
    };

    const vehicleList = vehicle.map((d) => d.number);

    const handleSelectedData = (datafilter: CustomFormData) => {
        const fullDataNumbers = datafilter.multiSelect?.map((item) => item.value);

        if (Array.isArray(vehicle)) {
            const matchedIds = vehicle.filter((v) => fullDataNumbers?.includes(v.number))
                .map((v) => v.id);

            setMatchingVehicleIds(matchedIds);
            setSelectedData(datafilter);
        } else {
            console.error('Vehicle is not an array:', vehicle);
        }
    };


    const actions = [
        {
            name: 'View',
            path: '/company/report/vehicle-activity/IdleReportForVehicle',
            icon: FaEye,
            class: 'text-blue-700 text-2xl'
        }
    ];
    return (
        <>
            <NavTabs tabs={tabs} />
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={vehicleList}
                    singleselect={['5m', '10m', '15m', '20m']}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>

            <Table<IVehicleIdleReport>
                columns={IdledDataColumn}
                data={data}
                loading={loading}
                tableRowActions={actions}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
            />
        </>
    );
};

export default IdleReportUI;
