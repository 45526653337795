import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface AddressProps {
  latitude: string;
  longitude: string;
  border?:boolean;
  title?:boolean;
}

interface NominatimResponse {
  display_name: string;
}

const FindAddress: React.FC<AddressProps> = ({ latitude, longitude,border = false, title = false}) => {
  const [address, setAddress] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get<NominatimResponse>(
          `https://nominatim.openstreetmap.org/reverse`,
          {
            params: {
              lat: latitude,
              lon: longitude,
              format: 'json',
            },
          }
        );

        if (response.data.display_name) {
          setAddress(response.data.display_name);
        } else {
          setError("Couldn't find an address for these coordinates.");
        }
      } catch (err) {
        setError("Error fetching the address. Please try again.");
      }
    };

    void fetchAddress();
  }, [latitude, longitude]);

  return (
    <div className={`p-3 ${border ? 'border border-gray-300 bg-white rounded' : ''}`}>
      {title && <h2 className='text-sm font-bold'>Location</h2>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
       {/* <p className='text-sm font-light'>{address}</p> */}
       <p className="text-sm font-light ">
  {address}
</p>
    </div>

  );
};

export default FindAddress;
