import { TableColumn } from "react-data-table-component";

export type IIndividualUser={
    id: number;
    name: string;
    email: string;  
}

export const IndividualUserDatacolumn: TableColumn<IIndividualUser>[] = [
    { name: 'ID', selector: (row: IIndividualUser) => row.id, sortable: true },
    { name: 'name ', selector: (row: IIndividualUser) => row.name, sortable: true },
    { name: 'Email', selector: (row: IIndividualUser) => row.email, sortable: true },
   
];