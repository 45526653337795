import React from 'react';
import { FaEye } from 'react-icons/fa';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import { useState, useEffect } from 'react';
import { IGeofanceEntryexit, GeofenceEntryExitColumns } from '../../../../services/interfaces/IGeofanceService';
import { VehicleTableInterface } from '../../../../utils/interfaces/masterVehicle';
import useHttp from '../../../../hooks/useHttp';
interface VehicleApiResponse {
    message: string;
    data: VehicleTableInterface[];
}
interface IgeofenceEnteryExitResponse {
    message: string;
    data: IGeofanceEntryexit[];
}
const GeofenceEntryExitReport: React.FC = () => {
    const Report_type = 'GeoFence Entry-Exit';
    const [loading, setLoading] = useState(false);
    // send data for filter report
    const singleselect: string[] = ['5m', '10m', '15m', '20m'];
    const field_type: string[] = ['Vehicle', 'Select GeoFence Type', 'From', 'To'];
    const [selectedData, setSelectedData] = useState<CustomFormData>();
    const [data, setData] = useState<IGeofanceEntryexit[]>([]);
    const [vehicle, setVehicle] = useState<VehicleTableInterface[]>([]);
    const [matchingVehicleIds, setMatchingVehicleIds] = useState<number[]>([]);
    const handleSelectedData = (datafilter: CustomFormData) => {
        
       
        const fullDataNumbers = datafilter.multiSelect?.map((item) => item.value);

        if (Array.isArray(vehicle)) {
            const matchedIds = vehicle.filter((v) => fullDataNumbers?.includes(v.number))
                .map((v) => v.id);

            setMatchingVehicleIds(matchedIds);
            setSelectedData(datafilter);
        } else {
            console.error('Vehicle is not an array:', vehicle);
        }
    };
    const { request } = useHttp();
    console.log(matchingVehicleIds,selectedData);

    const fetchVehicle = async () => {
        setLoading(true);
        try {
            const response = await request({ url: '/vehicles', method: 'GET' });
            if (!response.ok) throw new Error('Failed to fetch vehicles');

            const responseData = await response.json() as VehicleApiResponse;

            if (Array.isArray(responseData.data)) {
                setVehicle(responseData.data);
            } else {
                console.error('Fetched data is not an array:', responseData.data);
                setVehicle([]);
            }
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        void fetchVehicle();
    }, []);
    const fetchData = async () => {
        if (selectedData) {
            const sdate = selectedData.startDateTime
            ? selectedData.startDateTime.toISOString()
            : '';
            const endDate = selectedData.endDateTime
            ? selectedData.endDateTime.toISOString()
            : '';
        
          const intervalTime:string=selectedData.singleSelect?selectedData.singleSelect?.value:'5m';
            const params = new URLSearchParams({
                start: sdate,
                stop:endDate,
                aggregateWindow: intervalTime,
                page: "1",
                pageSize: "100",
                vehicleIds: `${matchingVehicleIds.join(",")}`
            });
            const url = `/report/geoFenceEntryExit?${params.toString()}`;
            console.log(url);
            setLoading(true);

            try {
                const response = await request({
                    method: 'GET',
                    url: url,
                    
                });

                console.log(`response`, response);

                if (!response.ok) throw new Error('Failed to fetch data');
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                const reportData = (await response.json()) as IgeofenceEnteryExitResponse;

                setData(reportData.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        void fetchVehicle();
    }, []);

    useEffect(() => {
        void fetchData();
    }, [selectedData]);


   

    const actions = [
        { name: 'View', path: '/company/report/geo-fence/entry-exit', icon: FaEye, class: 'text-blue-700 text-2xl' }
    ];
    const vehilcleList = vehicle.map((e) => e.number );

    return (
        <>
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={vehilcleList}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table
                data={data}
                columns={GeofenceEntryExitColumns}
                tableRowActions={actions}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                loading={loading}
            />
        </>
    );
};

export default GeofenceEntryExitReport;
