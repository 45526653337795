import { useEffect, useState } from 'react';
import Cards from '../../../components/Card';
import Doughnut from '../../../components/charts/DoughnutChart';
import { useLiveLocation } from '../../../context/LiveLocationContext';

interface LocationData {
  imei: string;
  state?: string;
}

interface GroupedData {
  [key: string]: number;
}

const initialStatus: GroupedData = {
  "Running": 0,
  "Idle": 0,
  "Parked": 0,
  "Offline": 0,
  "New Devices": 0,
};

const stateMapping: { [key: string]: string } = {
  "moving": "Running",
  "idle": "Idle",
  "parked": "Parked",
  "offline": "Offline",
  "new_device": "New Devices",
};

const colorsForCard1 = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#808080'];

const FleetStatus = () => {
  const { locations } = useLiveLocation();
  
  const [groupedData, setGroupedData] = useState<GroupedData>(initialStatus);

  useEffect(() => {
    if (!locations || Object.keys(locations).length === 0) return;

    const grouped = Object.values(locations).reduce((acc: GroupedData, location: LocationData) => {
      const deviceState = location.state || "unknown";
      const key = stateMapping[deviceState.toLowerCase()] || "unknown";

      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, { ...initialStatus });

    const isDifferent = Object.keys(grouped).some(key => grouped[key] !== groupedData[key]);

    if (isDifferent) {
      setGroupedData(grouped);
    }

  }, [locations, groupedData]);

  const chartData = Object.entries(groupedData).map(([key, count]) => ({
    name: key,
    value: count,
  }));

  return (
    <div className='w-full md:w-1/2 lg:w-1/3 p-2'>
      <Cards title='Current Status of the Fleet' height='h-72' width='w-full'>
        <div className='flex items-center justify-center h-full'>
          <Doughnut width={200} height={200} data={chartData} colors={colorsForCard1} />
        </div>
      </Cards>
    </div>
  );
};

export default FleetStatus;
