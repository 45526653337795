import React, { useEffect, useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useParams } from 'react-router-dom';
import DriverProfile from '../DriverProfile';
import DriverAdditionalinfo from '../DriverAdditionalinfo';
import DriverDocuments from '../DriverDocuments';
import { driversData } from '../../../../../../services/dummyData/DriverData';

export interface IDriver {
    id: number;
    driverName: string;
    punchID: string;
    driverEmail: string;
    driverPhoneNumber: string;
    driverDOB: string;
    drivingLicenceNo: string;
    drivingLicenceIssueDate: string;
    drivingLicenceExpireDate: string;
    driverAddress: string;
    employeeDesignation: string;
    tagVia: string;
    shiftGroup: string;
    driverAge: string;
    dateOfJoining: string;
    dateOfLeaving: string;
    drivingExpireSince: string;
    licenceAvailabel: string;
    licenceToDrive: string;
    IFEinsurenceNO: string;
    lifeInsurenceExpireDate: string;
    medicalNo: string;
    medicalExpireDate: string;
    documentName: string;
    issueDate: string;
    expireDate: string;
    status: string;
    lastModified: string;
}

function NewDriver() {
    const [value, setValue] = React.useState(0);
    const [driversProfile, setDriversProfile] = useState({
        profileAction: '',
        driverName: '',
        punchID: '',
        driverEmail: '',
        driverPhoneNumber: '',
        driverDOB: '',
        drivingLicenceNo: '',
        drivingLicenceIssueDate: '',
        drivingLicenceExpireDate: '',
        driverAddress: ''
    });
    const [driverAdditionalInfo, setDriverAdditionalInfo] = useState({
        additionalinfoAction: '',
        employeeDesignation:'',
        shiftGroup: '',
        tagVia:'',
        driverAge:'',
        dateOfJoining:'',
        dateOfLeaving: '',
        drivingExpireSince: '',
        licenceAvailabel: '',
        licenceToDrive: '',
        IFEinsurenceNO: '',
        lifeInsurenceExpireDate: '',
        medicalNo: '',
        medicalExpireDate: '',

    });

    const [driverDocuments, setDriverDocuments] = useState({
        documentAction:'',
        documentName: '',
        issueDate: '',
        expireDate: '',
    });
    const { id } = useParams<{ id: string }>();
    const { action } = useParams<{action : string}>();
    useEffect(() => {
        if (id) {
            const deriverFullDeatils = driversData.find((driver: IDriver) => driver.id === parseInt(id));
            if (deriverFullDeatils) {
                const dummyDriverProfile = {
                    profileAction: action ? action : "",
                    driverName: deriverFullDeatils.driverName,
                    punchID: deriverFullDeatils.punchID,
                    driverEmail: deriverFullDeatils.driverEmail,
                    driverPhoneNumber: deriverFullDeatils.driverPhoneNumber,
                    driverDOB: deriverFullDeatils.driverDOB,
                    drivingLicenceNo: deriverFullDeatils.drivingLicenceNo,
                    drivingLicenceIssueDate: deriverFullDeatils.drivingLicenceIssueDate,
                    drivingLicenceExpireDate: deriverFullDeatils.drivingLicenceExpireDate,
                    driverAddress: deriverFullDeatils.driverAddress
                };

                const dummyDriverAdditionalInfo = {
                    additionalinfoAction: action ? action: "",
                    employeeDesignation: deriverFullDeatils.employeeDesignation,
                    shiftGroup: deriverFullDeatils.shiftGroup,
                    tagVia: deriverFullDeatils.tagVia,
                    driverAge: deriverFullDeatils.driverAge,
                    dateOfJoining:deriverFullDeatils.dateOfJoining,
                    dateOfLeaving: deriverFullDeatils.dateOfLeaving,
                    drivingExpireSince: deriverFullDeatils.drivingExpireSince,
                    licenceAvailabel: deriverFullDeatils.licenceAvailabel,
                    licenceToDrive: deriverFullDeatils.licenceToDrive,
                    IFEinsurenceNO: deriverFullDeatils.IFEinsurenceNO,
                    lifeInsurenceExpireDate: deriverFullDeatils.lifeInsurenceExpireDate,
                    medicalNo: deriverFullDeatils.medicalNo,
                    medicalExpireDate: deriverFullDeatils.medicalExpireDate,

                };

                const dummyDriverDocuments = {
                    documentAction: action ? action: "",
                    documentName: deriverFullDeatils.documentName,
                    issueDate: deriverFullDeatils.issueDate,
                    expireDate: deriverFullDeatils.expireDate,
                };
                // console.log('Driver Profile data', dummyDriverProfile);
                // console.log('Driver Additional data',dummyDriverAdditionalInfo );
                // console.log('Driver Documents data', dummyDriverDocuments);
                

                setDriversProfile(dummyDriverProfile);
                setDriverAdditionalInfo(dummyDriverAdditionalInfo);
                setDriverDocuments(dummyDriverDocuments);
            }
            // if(deriverProfile){
            //     // setName(deriverProfile.driverName)
            // }
        } else {
            // console.log('Creating a new driver');
            // setName("")
        }
    }, [id]);

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Driver</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
                    <p className='ml-2 text-sm'>Driver</p>
                    <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
                    <p className='ml-2 text-sm'>Details</p>
                </div>
            </div>
            <div className='text-blue-500 w-[100%] h-[100%] rounded-t-md mt-6'>
                <div className='flex gap-5'>
                    <button
                        onClick={() => setValue(0)}
                        className={`w-[100px] h-[40px] ${
                            value === 0 ? ' text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
                        }`}
                    >
                        Profile
                    </button>

                    <button
                        onClick={() => setValue(1)}
                        className={`w-[180px] h-[40px] ${
                            value === 1 ? ' text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
                        }`}
                    >
                        Additional Information
                    </button>

                    <button
                        onClick={() => setValue(2)}
                        className={`w-[100px] h-[40px] ${
                            value === 2 ? ' text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
                        }`}
                    >
                        Documents
                    </button>
                </div>
                <hr />
            </div>
            <hr className='border-0 h-px bg-gray-400' />
            {value === 0 && <DriverProfile data={driversProfile} />}
            {value === 1 && <DriverAdditionalinfo data={driverAdditionalInfo} />}
            {value === 2 && <DriverDocuments data ={driverDocuments} />}
        </div>
    );
}

export default NewDriver;
