import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../modules/company/Dashboard/Dashboard';
import Multitrack from '../modules/company/Multi-track/Multitrack';
import Reports from '../modules/company/Reports/Reports';
import Settings from '../modules/company/Settings/Settings';
import Preventive_Maintainence from '../modules/company/Settings/management/Preventive Maintainence/Preventive_Maintainence';
import Preventive_MaintainenceAdd from '../modules/company/Settings/management/Preventive Maintainence/components/Preventive_MaintainenceAdd';
import Alerts from '../modules/company/Settings/master/alerts/Alerts';
import AlertsAdd from '../modules/company/Settings/master/alerts/components/AlertsAdd';
import GeofanceEnteryexitReport from '../modules/company/Reports/geofanceReports/GeofanceEnteryexitReportUI';
import GeofanceEntryexitReportparticularvehicleUI from '../modules/company/Reports/geofanceReports/GeofanceEntryexitReportparticularvehicleUI';
import GeofanceTogeofanceReportUI from '../modules/company/Reports/geofanceReports/GeofanceTogefanceReportUI';
import GeofanceTogeofanceReportparticularvehicleUI from '../modules/company/Reports/geofanceReports/GeofanceTogeofanceReportparticularvehicleUI';
import ParkedReportUI from '../modules/company/Reports/Vechile_activity_report/ParkedReportUI';
import IdleReportUI from '../modules/company/Reports/Vechile_activity_report/IdleReportUI';
import NewdeviceReportUI from '../modules/company/Reports/Vechile_activity_report/NewdeviceReportUI';
import OfflineReportUI from '../modules/company/Reports/Vechile_activity_report/OfflineReportUI';
import MovementReportUI from '../modules/company/Reports/Vechile_activity_report/MovementReportUI';
import WorkHourReportUI from '../modules/company/Reports/Vechile_activity_report/WorkhourReportUI';
import IdleReportForVehicleUI from '../modules/company/Reports/Vechile_activity_report/IdleReportForVehicleUI';
import MovementReportForVehicleUI from '../modules/company/Reports/Vechile_activity_report/MovementReportForVehicleUI';
import ParkedReportForVehicleUI from '../modules/company/Reports/Vechile_activity_report/ParkedReportForVehicleUI';
import WorkHourReportForVehicleUI from '../modules/company/Reports/Vechile_activity_report/WorkHourReportForVehicleUI';
import DriverWorkHourReportUI from '../modules/company/Reports/driverBehaviourReport/DriverWorkHourReportUI';
import DriverRatingReportUI from '../modules/company/Reports/driverBehaviourReport/DriverRatingReportUI';
import ObjectAlertReportUI from '../modules/company/Reports/objectAlertReport/ObjectAlertReportUI';
import ObjectAlertSingleReportUI from '../modules/company/Reports/objectAlertReport/ObjectAlertSingleReportUI';
import DriverRatingUI from '../modules/company/Settings/management/driverRating/DriverRatingUI';
import CreateDriverRatingUI from '../modules/company/Settings/management/driverRating/CreateDriverRatingUI';
import Vehicle from '../modules/company/Settings/master/vehicle/Vehicle';
import VehicleAdd from '../modules/company/Settings/master/vehicle/components/VehicleAdd';
import EmergencyAlerte from '../modules/company/Alert-Notification/EmergencyAlerte';
import GeofenceViolations from '../modules/company/Alert-Notification/GeofenceViolations';
import Expense from '../modules/company/Settings/management/Expense/Expense';
import NewExpense from '../modules/company/Settings/management/Expense/NewExpense';
import Profile from '../modules/company/Profile/Profile';
import Driver from '../modules/company/Settings/master/Driver/Driver';
import NewDriver from '../modules/company/Settings/master/Driver/components/NewDriver';

import ManageCompanyUI from '../modules/systemAdmin/managecompany/ManageCompanyUI';
import CompanyCurd from '../modules/systemAdmin/managecompany/CompanyCurd';
import { useAuth } from '../context/AuthContext';
import AdminCurd from '../modules/systemAdmin/manageAdmin/AdminCurd';
import AllGeofenceUI from '../modules/company/Settings/management/geofance/createGeofance/AllGeofenceUI';
import CreateGeofance from '../modules/company/Settings/management/geofance/createGeofance/CreateGeofance';
import DevicesUI from '../modules/company/Add_Devices/DevicesUI';
import AddDevices from '../modules/company/Add_Devices/AddDevices';
import MultiTrackosm from '../modules/company/Multi-track/MultiTrackosm';
import DeviceTypeUI from '../modules/systemAdmin/manageDeviceType/DeviceTypeUI';
import IndividualUserUI from '../modules/systemAdmin/manageindividualuser/IndividualUserUI';
import IndividualuserCurd from '../modules/systemAdmin/manageindividualuser/IndividualuserCurd';
import DeviceType from '../modules/systemAdmin/manageDeviceType/DeviceType';
import CompanyUserCurdFormUI from '../modules/company/Settings/master/companyUser/CompanyUserCurd';
import CompanyUserUI from '../modules/company/Settings/master//companyUser/CompanyUserUI';
import OsmCreateGeofence from '../modules/company/Settings/management/geofance/osmGeofence/OsmCreateGeofence';
import OsmGeofenceUI from '../modules/company/Settings/management/geofance/osmGeofence/OsmGeofenceUI';
import AlertTypes from '../modules/company/Settings/master/alert_types/AlertTypes';
import AlertTypeUI from '../modules/company/Settings/master/alert_types/AlertTypeUI';
import Playback from '../modules/company/playback/Playback';

const DynamicRoute: React.FC = () => {
    const { role } = useAuth();

    return (
        <Routes>
            {/* System Admin Routes */}

            {role === 'system_admin' && (
                <>
                    <Route path='/systemAdmin/manageCompany/company' element={<ManageCompanyUI />} />
                    <Route path='/systemAdmin/manageCompany/create' element={<CompanyCurd />} />
                    <Route path='/systemAdmin/manageCompany/edit/:id' element={<CompanyCurd />} />
                    <Route path='/systemAdmin/manageCompany/view/:id' element={<CompanyCurd />} />
                    <Route path='/systemAdmin/manageAdmin/create/:id' element={<AdminCurd />} />
                    <Route path='/systemAdmin/manageAdmin/adminview/:id' element={<AdminCurd />} />

                    <Route path='/systemAdmin/manageAdmin/adminedit/:id' element={<AdminCurd />} />

                    <Route path='/systemAdmin/manageDeviceType' element={<DeviceTypeUI />} />

                    <Route path='/systemAdmin/manageDeviceType/create' element={<DeviceType />} />
                    <Route path='/systemAdmin/manageDeviceType/view/:id' element={<DeviceType />} />
                    <Route path='/systemAdmin/manageDeviceType/edit/:id' element={<DeviceType />} />

                    <Route path='/systemAdmin/new-alertType/:action?/:id?' element={<AlertTypes />} />
                    <Route path='/systemAdmin/alertType' element={<AlertTypeUI />} />

                    <Route path='/systemAdmin/manageindividualuser/indivividualuser' element={<IndividualUserUI />} />

                    <Route path='/indivividualuser/create' element={<IndividualuserCurd />} />
                </>
            )}
            {role === 'company_admin' && (
                <>
                    <Route path='/companyUser/create' element={<CompanyUserCurdFormUI />} />
                    <Route path='/companyUser/edit/:id' element={<CompanyUserCurdFormUI />} />
                    <Route path='/companyUser/view/:id' element={<CompanyUserCurdFormUI />} />
                </>
            )}
            <Route path='/companyUser' element={<CompanyUserUI />} />

            <Route path='/companyUser/create' element={<CompanyUserCurdFormUI />} />
            <Route path='/companyUser/edit/:id' element={<CompanyUserCurdFormUI />} />
            <Route path='/companyUser/view/:id' element={<CompanyUserCurdFormUI />} />

            {/* Company Routes */}
            <Route path='/company/dashboard' element={<Dashboard />} />
            <Route path='/company/multitrack' element={<Multitrack />} />
            <Route path='/company/reports' element={<Reports />} />
            <Route path='/company/settings' element={<Settings />} />

            <Route path='/companyAdmin/devices' element={<DevicesUI />} />
            <Route path='/companyAdmin/add_devices/:action?/:id?' element={<AddDevices />} />

            {/* Preventive Maintainence */}
            <Route path='/company/settings/management/Preventive_Maintainence' element={<Preventive_Maintainence />} />
            <Route
                path='/company/settings/management/Preventive_Maintainence/addPreventive_Maintainence/:action?/:id?'
                element={<Preventive_MaintainenceAdd />}
            />

            {/* Vehicle Settings */}
            <Route path='/company/settings/master/vehicle' element={<Vehicle />} />
            <Route path='/company/settings/master/vehicle/newVehicle/:action?/:id?' element={<VehicleAdd />} />

            {/* Alerts */}
            <Route path='/company/settings/master/alerts' element={<Alerts />} />
            <Route path='/company/settings/master/alerts/addAlerts/:action?/:id?' element={<AlertsAdd />} />

            {/* Geofence Reports */}
            <Route path='/company/report/geo-fence/entry-exit' element={<GeofanceEnteryexitReport />} />
            <Route
                path='/company/report/geo-fence/entry-exit/:id'
                element={<GeofanceEntryexitReportparticularvehicleUI />}
            />
            <Route path='/company/report/geo-fence/geo-fence-to-geo-fence' element={<GeofanceTogeofanceReportUI />} />
            <Route
                path='/company/report/geofanceReports/geofance-to-geofance/:id'
                element={<GeofanceTogeofanceReportparticularvehicleUI />}
            />

            {/* Vehicle Activity Reports */}
            <Route path='/company/report/vehicle-activity/movement' element={<MovementReportUI />} />
            <Route path='/company/report/vehicle-activity/parked' element={<ParkedReportUI />} />
            <Route path='/company/report/vehicle-activity/idle' element={<IdleReportUI />} />
            <Route path='/company/report/vehicle-activity/work-hour' element={<WorkHourReportUI />} />
            <Route path='/company/report/vehicle-activity/offline' element={<OfflineReportUI />} />
            <Route path='/company/report/vehicle-activity/new-device' element={<NewdeviceReportUI />} />
            <Route
                path='/company/report/vehicle-activity/IdleReportForVehicle/:id'
                element={<IdleReportForVehicleUI />}
            />
            <Route
                path='/company/report/vehicle-activity/MovementReportForVehicle/:id'
                element={<MovementReportForVehicleUI />}
            />
            <Route
                path='/company/report/vehicle-activity/ParkedReportForVehicle/:id'
                element={<ParkedReportForVehicleUI />}
            />
            <Route
                path='/company/report/vehicle-activity/WorkHourReportForVehicle/:id'
                element={<WorkHourReportForVehicleUI />}
            />

            {/* Object Alert Reports */}
            <Route path='/company/report/object-alert' element={<ObjectAlertReportUI />} />
            <Route path='/company/report/object-alertsingle/:id' element={<ObjectAlertSingleReportUI />} />

            {/* Driver Behaviour Reports */}
            <Route path='/company/report/driver-behaviour/driver-work-hour' element={<DriverWorkHourReportUI />} />
            <Route path='/company/report/driver-behaviour/driver-rating' element={<DriverRatingReportUI />} />

            {/* Driver Rating Settings */}
            <Route path='/company/settings/management/driver-rating' element={<DriverRatingUI />} />
            <Route path='/company/settings/management/driver-rating/create' element={<CreateDriverRatingUI />} />
            <Route path='/company/settings/management/driver-rating/edit/:id' element={<CreateDriverRatingUI />} />
            <Route path='/company/settings/management/driver-rating/view/:id' element={<CreateDriverRatingUI />} />

            {/* Profile and Other Settings */}
            <Route path='/profile' element={<Profile />} />
            <Route path='/company/settings/master/driver' element={<Driver />} />
            <Route path='/company/settings/master/newdriver/:action?/:id?' element={<NewDriver />} />

            {/* Expense Settings */}
            <Route path='/company/settings/management/expense' element={<Expense />} />
            <Route path='/company/settings/management/new-expense' element={<NewExpense />} />
            <Route path='/company/settings/management/expense/:action?/:id?' element={<NewExpense />} />
            {/* Emergency and Geofence Alerts */}
            <Route path='/company/emergency-alert' element={<EmergencyAlerte />} />
            <Route path='/company/geofence-violaion' element={<GeofenceViolations />} />

            {/* Geofence Settings */}
            <Route path='/company/settings/management/geofence/edit/:id?' element={<CreateGeofance />} />
            <Route path='/company/settings/management/geofence' element={<AllGeofenceUI />} />
            <Route path='/company/settings/management/geofence/create' element={<CreateGeofance />} />
            <Route path='/company/MultiTrackosm' element={<MultiTrackosm />} />
            {/* <Route path="/playback" element={<Playback/>} /> */}
            <Route path="/playback/:vehiclenumber/:imei" element={<Playback/>} />

            <Route path='/osmGeofence/create' element={<OsmCreateGeofence />} />
            <Route path='/osmGeofence/view/:id' element={<OsmCreateGeofence />} />

            <Route path='/osmGeofence/edit/:id' element={<OsmCreateGeofence />} />
            <Route path='/osmGeofence/delete/:id' element={<OsmCreateGeofence />} />

            <Route path='/osmGeofence' element={<OsmGeofenceUI />} />
        </Routes>
    );
};

export default DynamicRoute;
