// src/components/MultiSelect.tsx
import React from 'react';
import Select, { MultiValue, OptionsOrGroups, GroupBase } from 'react-select';
import { CSSObjectWithLabel } from 'react-select';

type OptionType = {
  label: string;
  value: string;
};

type MultiSelectProps = {
    name?: string;
  options?: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  selectedOptions?: MultiValue<OptionType>;
  onChange?: (selected: MultiValue<OptionType>) => void;
  placeholder?: string;
};

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderColor: 'rgb(229 231 235)', // Tailwind color for border-gray-300
    '&:hover': {
      borderColor: 'rgb(209 213 219)', // Tailwind color for border-gray-400
    },
    boxShadow: 'none',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'rgb(229 231 235)', // Tailwind color for bg-gray-300
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: 'rgb(55 65 81)', // Tailwind color for text-gray-700
  }),
  multiValueRemove: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: 'rgb(107 114 128)', // Tailwind color for text-gray-500
    '&:hover': {
      backgroundColor: 'rgb(209 213 219)', // Tailwind color for bg-gray-400
      color: 'rgb(55 65 81)', // Tailwind color for text-gray-700
    },
  }),
};

const MultiSelectComponent: React.FC<MultiSelectProps> = ({ options, selectedOptions, onChange,name, placeholder }) => {
  return (
    <Select
      isMulti
      name={name}
      options={options}
      value={selectedOptions}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder || 'Select options...'}
      className="mt-1 block w-full rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  );
};

export default MultiSelectComponent;
