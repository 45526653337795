import React, { useState, useEffect } from "react";
import BackButton from "../../../../components/BackButton";
import Table from "../../../../components/table/Table";
import { createGeofanceReportService } from "../../../../services/ServiceFactory";
import { IGeofanceEntryexitone, GeofanceEntryexitoneDataColumn } from "../../../../services/interfaces/IGeofanceService";

const GeofanceEntryexitReportparticularvehicleUI: React.FC = () => {
  const [data, setData] = useState<IGeofanceEntryexitone[]>([]);

  // Explicitly type the service instance
  const geofanceReportService = createGeofanceReportService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure the method returns the expected type
        const result: IGeofanceEntryexitone[] = await geofanceReportService.getGeofanceEntryexitoneReport();
        setData(result);
      } catch (error) {
        console.error("Failed to fetch data", error);
        // Optionally handle the error state here
      }
    };

    void fetchData();
  }, [geofanceReportService]);

  return (
    <>
      <div>
        <h1 className="text-black-700 text-xl">Geofance Entry Exit Report Particular Vehicle</h1>
        <BackButton label="Go Back" className="absolute float-end right-9 top-3" />
      </div>
      <Table data={data} columns={GeofanceEntryexitoneDataColumn} excelExport={true} pdfExport={true} />
    </>
  );
};

export default GeofanceEntryexitReportparticularvehicleUI;
