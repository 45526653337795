import {IDeriverRatingReport,IDeriverWorkHourReport,IDriverBehaviourService}from "./interfaces/IDriverBehaviourReport";
import {driverRatingReports,driverWorkHourReports} from "./dummyData/DriverBehaviourData";

export class DriverBehaviourService implements IDriverBehaviourService{
    getDriverRatingReport(): Promise<IDeriverRatingReport[]>{
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(driverRatingReports);
            }, 1000);
        });
    }
    getDriverWorkReport(): Promise<IDeriverWorkHourReport[]>{
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(driverWorkHourReports);
            }, 1000);
        });
    }

}
