import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useParams, useLocation } from 'react-router-dom';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons for eye and eye-slash
import * as Yup from 'yup';

interface FormValues {
    email: string;
    password: string;
}

interface ErrorResponse {
    status: string;
    message: string;
}
const CreateValue: FormValues = { email: '', password: '' };

const AdminCurd: React.FC = () => {
    const location = useLocation();
    const isEditing: boolean = location.pathname.includes('adminedit');
    const isCreating = location.pathname.includes('create');
    const isViewing = location.pathname.includes('adminview');
    const { request } = useHttp();
    let buttonname = '';
    if (isViewing) {
        buttonname = 'View';
    } else if (isCreating) {
        buttonname = 'Create';
    } else {
        buttonname = 'Update';
    }

    const [initialValues, setInitialValues] = useState<FormValues>();
    const { id } = useParams<{ id: string }>();
    const numericId = Number(id);
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    useEffect(() => {
        if (isCreating) {
            setInitialValues(CreateValue);
        } else if (isViewing) {
            void fetchData().then(data => {
                setInitialValues(data);
            });
        } else if (isEditing) {
            void fetchData().then(data => {
                setInitialValues(data);
            });
        }
    }, [numericId]);

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required')
    });

    const handleSubmit = async (values: FormValues) => {
        try {
            const response = await request({
                url: '/add-company-admin',
                method: 'POST',
                body: { companyId: id, email: values.email, password: values.password }
            });

            if (!response.ok) {
                const errorData = (await response.json()) as ErrorResponse;
                const errorMessage = errorData.message || 'An error occurred';
                throw new Error(errorMessage);
            }

            toast.success('Added successfully');
            window.location.href = `/systemAdmin/manageCompany/view/${id}`;
        } catch (error) {
            let message = 'An unexpected error occurred. Please try again.';

            if (error instanceof Error) {
                message = error.message;
            }

            toast.error(message);
        }
    };

    if (!initialValues) {
        return <div>Loading...</div>;
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='max-w-md mx-auto mt-10'>
            <h1 className='text-2xl font-bold mb-4 text-center'>Add New Admin</h1>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ isValid }) => (
                    <Form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-gray-700 text-sm font-bold mb-2'>
                                Email
                            </label>
                            <Field
                                type='email'
                                name='email'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            />
                            <ErrorMessage name='email' component='div' className='text-red-500 text-xs mt-2' />
                        </div>

                        <div className='mb-6 relative'>
                            <label htmlFor='password' className='block text-gray-700 text-sm font-bold mb-2'>
                                Password
                            </label>
                            <div className='relative w-full'>
                                <Field
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                />
                                <div
                                    className='absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer'
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>

                            <ErrorMessage name='password' component='div' className='text-red-500 text-xs mt-2' />
                        </div>

                        <div className='flex items-center justify-between'>
                            <button
                                type='submit'
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                disabled={!isValid && isViewing}
                            >
                                {buttonname}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const fetchData = async (): Promise<FormValues> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({ email: 'example@example.com', password: 'password123' });
        }, 1000);
    });
};

export default AdminCurd;
