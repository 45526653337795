import React, { useState, useEffect } from 'react';
import Table from '../../../../components/table/Table';
import BackButton from '../../../../components/BackButton';
import { createVehicleService } from '../../../../services/ServiceFactory';
import { IVehicleIdleReportSingle, IdledDataColumnSingle } from '../../../../services/interfaces/IVehicleService';

const IdleReportForVehicleUI: React.FC = () => {
  // State for loading and data
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IVehicleIdleReportSingle[]>([]);

  // Create an instance of the vehicle service
  const vehicleService = createVehicleService();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await vehicleService.getIdleReportSingle();
        setData(result);
      } catch (error) {
        console.error('Error fetching idle report:', error);
      } finally {
        setLoading(false);
      }
    };

  void   fetchData();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div>
        <h1 className="text-black-700 text-xl">Idle Report For Vehicle</h1>
        <BackButton label="Go Back" className="absolute float-end right-9 top-3" />
      </div>

      <Table<IVehicleIdleReportSingle>
        columns={IdledDataColumnSingle}
        excelExport={true}
        searchAble={true}
        pdfExport={true}
        data={data}
        loading={loading}
      />
    </>
  );
};

export default IdleReportForVehicleUI;
