import { IVehicleService } from './interfaces/IVehicleService';
import { VehicleService } from './VehicleService';
import { IGeofanceReportService } from './interfaces/IGeofanceService';
import { GeofanceReportService } from './GeofanceService';
import { IObjectService } from './interfaces/IObjectService';
import { DriverBehaviourService } from './DriverBehaviourService';
import { IDriverBehaviourService } from './interfaces/IDriverBehaviourReport';
import { IDriverRatingServiceClass } from './DriverRatingService';
import { IDriverRatingService } from './interfaces/IDriverRating';


import { ObjectAlertService } from './ObjectAlertReportService';
import { PreventiveMaintainenceService } from './PreventiveMaintainenceService';
import { IPreventiveMaintainenceService } from './interfaces/IPreventiveMaintainenceService';
// import { IMasterVehicleService } from './interfaces/IMasterVehicleService';
// import { MasterVehicleService } from './MasterVehicleService';
import { DriverService } from './DriverService';
import { IDriverService } from './interfaces/IDriver';
import { IAllGeofanceService } from "./interfaces/IAllGeofanceService";
import { AllGeofanceService } from "./AllGeofanceService";
import { LoginService } from "./LoginService";
import { ILoginService } from "./interfaces/ILogin";
import { IManageCompanyService } from "./interfaces/IManageCompany";
import { ManageCompanyService } from "./ManageCompanyService";
import { EmergencyAlertService } from './EmergencyAlertService';
import { IEmergencyAlertService } from './interfaces/IEmergenctAlert';
import { ExpenseService } from './ExpenseService';
import { IExpenseService } from './interfaces/IExpense';
import { ViolationService } from './ViolationService';
import { IViolationService } from './interfaces/IViolation';




export const createGeofanceReportService = (): IGeofanceReportService => new GeofanceReportService();
export const createVehicleService = (): IVehicleService => new VehicleService();
export const createObjectAlertReportService = (): IObjectService => new ObjectAlertService();
export const createDriverBehaviorService = (): IDriverBehaviourService => new DriverBehaviourService();
export const createDriverDriverRating = (): IDriverRatingService => new IDriverRatingServiceClass();

export const createPreventiveMaintainence = (): IPreventiveMaintainenceService => new PreventiveMaintainenceService();
// export const createMasterVehicleService = (): IMasterVehicleService => new MasterVehicleService();
export const createDriverService = (): IDriverService => new DriverService();
export const createAllGeofanceService = (): IAllGeofanceService => new AllGeofanceService();
export const createLoginService = (): ILoginService => new LoginService();
export const createCompanyService = (): IManageCompanyService => new ManageCompanyService();
export const createEmergencyAlertService = (): IEmergencyAlertService => new EmergencyAlertService();
export const createExpenseService = (): IExpenseService => new ExpenseService();
export const createViolationService = (): IViolationService => new ViolationService();



