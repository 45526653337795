import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MultiTreackStyle.css';
import useHttp from '../../../hooks/useHttp';
import ReactDOMServer from 'react-dom/server';
import DirectionsBusFilledRoundedIcon from '@mui/icons-material/DirectionsBusFilledRounded';
import { Location } from '../../../services/interfaces/ILocation';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useLiveLocation } from '../../../context/LiveLocationContext';
import SideTab from './components/SideTab';
import SelectedDevicePanel from './components/SelectedDevicePanel';
import MarkerClusterGroup from 'react-leaflet-cluster';
const createBusIcon = (zoomLevel: number, color: string) => {
    const iconSize = Math.max(20, zoomLevel * 2); // Adjust the multiplier as needed for scaling
    return L.divIcon({
        html: `
        <div style="display: flex; justify-content: center; align-items: center;">
            ${ReactDOMServer.renderToString(<DirectionsBusFilledRoundedIcon style={{ color: color, fontSize: `${iconSize}px` }} />)}
        </div>
      `,
        className: ''
    });
};
const createNumberedIcon = () =>
    L.divIcon({
        html: `<div style="font-size: 20px; color: red;  
     padding: 5px; text-align: center;"></div>`,
        className: ''
    });

export interface Device {
    id?: number;
    imei: string;
    name?: string;
    date: string;
    color?: string;
    speed: number;
    status: string;
    dname: string;
    lat: number;
    lng: number;
}

const containerStyle = {
    width: '100vw',
    height: '100vh'
};

// shrawan
interface IVehicle {
    id: number;
    name: string;
    number: string;
    deviceId?: number | null;
    companyId?: number | null;
    userId?: number | null;
    enabled?: boolean | null;
    device?: Device;
}
const Multitrackosm: React.FC = () => {

    const defaultLocation: Location = { latitude: '20.5937', longitude: '78.9629' };
    const [center, setCenter] = useState<Location>(defaultLocation);
    const [isLoading, setIsLoading] = useState(true);
    const [zoomLevel, setZoomLevel] = useState<number>(1); // Track the zoom level
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const { locations } = useLiveLocation();
    const handleDeviceClick = (device: Device) => {
        setSelectedDevice(device);
    };
    const { request } = useHttp();
    //vehicles
    useEffect(() => {

        //call url: /multitrack 


        const fetchData = async () => {
            try {
                const response = await request({
                    url: '/company/location',
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch company location');
                }

                const location = (await response.json()) as Location;
                if (location.latitude && location.longitude) setCenter(location);
            } catch (error) {
                console.error('Error fetching company location:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchVehicles = async () => {
            try {
                const response = await request({
                    url: '/vehicles',
                    method: 'GET'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch vehicles');
                }

                const vehicles = (await response.json()) as IVehicle[];
                console.log(vehicles);
            } catch (error) {
                console.error('Error fetching vehicles:', error);
            } finally {
                setIsLoading(false);

            }
        };

        void fetchVehicles();
        void fetchData();


    }, []);

    if (isLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }
    function ZoomHandler() {
        const map = useMapEvents({
            zoomend: () => {
                setZoomLevel(map.getZoom());
            }
        });
        return null;
    }

    console.log('this company cordinate', center);
    return (
        <>
            <div className='relative flex justify-start items-start'>
                <MapContainer
                    center={[parseFloat(center.latitude), parseFloat(center.longitude)]}
                    zoom={5}
                    style={containerStyle}
                >
                   <div className='bg-slate-600'>
                   <TileLayer className=''
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                   </div>
                    <ZoomHandler />

                    <MarkerClusterGroup chunkedLoading>
                        {Object.values(locations).map((location,) =>
                            location.latitude && location.longitude ? (
                                <Marker
                                    key={location.imei}
                                    position={[location.latitude, location.longitude]}
                                    icon={
                                        zoomLevel > 2
                                            ? createBusIcon(zoomLevel, location.color ? location.color : 'black')
                                            : createNumberedIcon()
                                    }
                                    eventHandlers={{
                                        click: () =>
                                            handleDeviceClick({
                                                id: 1, // or other unique id
                                                imei: location.imei,
                                                name: location.vehicleNumber, // or relevant vehicle name
                                                date: new Date(location.timestamp).toLocaleString(),
                                                color: location.color, // or any relevant color
                                                speed: location.speed, // assuming speed is available
                                                status: location.state || 'Running', // map your statuses
                                                dname: '', // device name if available
                                                lat: location.latitude,
                                                lng: location.longitude
                                            })
                                    }}
                                >
                                    <Popup>
                                        <div>
                                            VehicleNumber {location.vehicleNumber}
                                            <br />
                                            Driver Name :rakesh <br />
                                            Date & Time {location.timestamp} <br />
                                            Speed {location.speed}
                                            state {location.state}
                                        </div>
                                    </Popup>
                                </Marker>
                            ) : null
                        )}
                    </MarkerClusterGroup>

                </MapContainer>
                <SideTab handleDeviceClick={handleDeviceClick} />
                <SelectedDevicePanel
                    selectedDevice={selectedDevice}
                    onClose={() => setSelectedDevice(null)}
                />

            </div>
        </>

    );
};

export default Multitrackosm;
