import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import ReportIcon from '@mui/icons-material/Report';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SettingsIcon from '@mui/icons-material/Settings';
// import MultitrackIcon from '@mui/icons-material/ViewList';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FaBuildingColumns } from 'react-icons/fa6';
//import { RiAdminFill } from "react-icons/ri";
//import { RiAdminFill } from 'react-icons/ri';
import './Sidebar.css';
import AlertNotificationPage from '../company/Alert-Notification/AlertNotificationPage';
import { useAuth } from '../../context/AuthContext';

const Sidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { logout, role } = useAuth();
    const [showProfile, setShowProfile] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    const navigate = useNavigate();
    const handleProfileToggle = () => {
        setShowProfile(prev => !prev);
    };
    const popupRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setShowProfile(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='sidebar w-24 '>
            <ul>
                <li className='sidebar-item no-hover'>
                    <div className=' bg-black w-[80px] h-[80px] mt-3 ml-[6px]  rounded-[50%] flex items-center justify-center'>
                        <h1 className='text-white text-center text-sm font-bold'>GENTRAX</h1>
                    </div>
                </li>
                <li className='sidebar-item flex items-center'>
                    <div className='flex items-center'>
                        <span className='nav-link flex items-center p-2 cursor-pointer' onClick={handleProfileToggle}>
                            <AdminPanelSettingsIcon
                                style={{ fontSize: '30px', cursor: 'pointer', zIndex: '10', marginTop: '8px' }}
                            />
                            {/* <span className="text-xs ml-2">Profile</span> */}
                            {showProfile && (
                                <div
                                    ref={popupRef}
                                    className='absolute top-full left-0 mt-1 w-44 bg-[#cbcaca] rounded-sm shadow-lg z-[999]'
                                >
                                    <Link to='/profile' className='block px-4 py-4 hover:bg-[#fafcfd] text-black'>
                                        Profile Update
                                    </Link>
                                    <button
                                        onClick={() => {
                                            logout();
                                            navigate('/login');
                                        }}
                                        className='block px-4 py-4 hover:bg-[#fafcfd] text-black w-full text-left'
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </span>
                        <span className='nav-link flex items-center p-2 cursor-pointer'>
                            <CampaignIcon
                                style={{ fontSize: '35px', cursor: 'pointer', zIndex: '10', marginTop: '8px' }}
                                onClick={togglePopup}
                            />
                            {isOpen && <AlertNotificationPage togglePopup={togglePopup} />}
                        </span>
                    </div>
                </li>

                <li className='sidebar-item'>
                    <Link to='/company/dashboard' className='nav-link '>
                        <DashboardIcon />
                        <span className='text-xs mt-1'>Dashboard</span>
                    </Link>
                </li>
                <li className='sidebar-item'>
                    <Link to='/company/MultiTrackosm' className='nav-link'>
                        <ShareLocationIcon />
                        <span className='text-xs mt-1'>Multi track</span>
                    </Link>
                </li>
                <li className='sidebar-item hoverable'>
                    <div className='nav-link'>
                        <FindInPageIcon />
                        <span className='text-xs mt-1'>Reports</span>
                    </div>
                    <ReportsSubMenu />
                </li>
                <li className='sidebar-item hoverable'>
                    <div className='nav-link'>
                        <SettingsIcon />
                        <span className='text-xs mt-1'>Settings</span>
                    </div>
                    <SettingsSubMenu role={role} />
                </li>
                {role === 'system_admin' && (
                    <>
                        <li className='sidebar-item hoverable'>
                            {/* <Link to="/systemAdmin/manageCompany/company" className='text-center nav-link '>
                                <span className='text-sm'> Manage </span>
                            </Link> */}
                            <div className='nav-link'>
                                <FaBuildingColumns />
                                <span className='text-xs mt-1'>Manage</span>
                            </div>
                            <ManageSubmenu role={role} />
                        </li>

                        {/* <li className="sidebar-item hoverable">
                            <Link to="/systemAdmin/manageindividualuser/indivividualuser" className='text-center nav-link '> <RiAdminFill />
                                <span className='text-sm'> Individual User</span>
                            </Link>
                        </li> */}
                    </>
                )}
                {/* {role === 'company_admin' && (
                    <>
                        <li className='sidebar-item hoverable'>
                            <Link to='/manageAdmin/companyUser' className='text-center nav-link '>
                                {' '}
                                <RiAdminFill />
                                <span className='text-sm'>User</span>
                            </Link>
                        </li>

                    
                    </>
                )} */}
            </ul>
        </div>
    );
};

const ReportsSubMenu: React.FC = () => (
    <ul className='sub-menu block'>
        <li className='sub-menu-item'>
            <div className='nav-link vehicle-activity-link'>
                Vehicle Activity <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </div>
            <ul className='nested-sub-menu'>
                <li>
                    <Link to='/company/report/vehicle-activity/movement' className='nav-link'>
                        Movement
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/vehicle-activity/parked' className='nav-link'>
                        Parked
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/vehicle-activity/idle' className='nav-link'>
                        Idle
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/vehicle-activity/offline' className='nav-link'>
                        Offline
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/vehicle-activity/new-device' className='nav-link'>
                        New Device
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/vehicle-activity/work-hour' className='nav-link'>
                        Work Hour
                    </Link>
                </li>
            </ul>
        </li>
        <li className='sub-menu-item'>
            <div className='nav-link vehicle-activity-link'>
                Geo Fence <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </div>
            <ul className='nested-sub-menu'>
                <li>
                    <Link to='/company/report/geo-fence/entry-exit' className='nav-link'>
                        Geo Fence Entry Exit
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/geo-fence/geo-fence-to-geo-fence' className='nav-link'>
                        Geo Fence to Geo Fence
                    </Link>
                </li>
            </ul>
        </li>
        <li>
            <Link to='/company/report/object-alert' className='nav-link vehicle-activity-link'>
                Object Alert
            </Link>
        </li>
        <li className='sub-menu-item'>
            <div className='nav-link vehicle-activity-link'>
                Driver Behaviour <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </div>
            <ul className='nested-sub-menu'>
                <li>
                    <Link to='/company/report/driver-behaviour/driver-work-hour' className='nav-link'>
                        Driver Work Hour
                    </Link>
                </li>
                <li>
                    <Link to='/company/report/driver-behaviour/driver-rating' className='nav-link'>
                        Driver Rating
                    </Link>
                </li>
            </ul>
        </li>
    </ul>
);

interface settingpropes {
    role: string | null;
}
const SettingsSubMenu: React.FC<settingpropes> = ({ role }) => (
    <ul className='sub-menu block'>
        <li className='sub-menu-item'>
            <div className='nav-link vehicle-activity-link'>
                Master <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </div>
            <ul className='nested-sub-menu'>
                <li>
                    <Link to='/companyAdmin/devices' className='nav-link'>
                        Device
                    </Link>
                </li>
                <li>
                    <Link to='/company/settings/master/driver' className='nav-link'>
                        Driver
                    </Link>
                </li>
                {role === 'company_admin' && (
                    <>
                        <li>
                            <Link to='/company/settings/master/vehicle' className='nav-link'>
                                Vehicle
                            </Link>
                        </li>
                        <li>
                            <Link to='/companyUser' className='nav-link'>
                                CompanyUser
                            </Link>
                        </li>
                    </>
                )}

                {role === 'system_admin' && (
                    <>
                        <li>
                            <Link to='/systemAdmin/alertType' className='nav-link'>
                                Alert Types
                            </Link>
                        </li>
                    </>
                )}

                <li>
                    <Link to='/company/settings/master/alerts' className='nav-link'>
                        Alerts
                    </Link>
                </li>
            </ul>
        </li>
        <li className='sub-menu-item'>
            <div className='nav-link vehicle-activity-link'>
                Management <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </div>
            <ul className='nested-sub-menu'>
                <li>
                    <Link to='osmGeofence' className='nav-link'>
                        Geo Fence
                    </Link>
                </li>
                <li>
                    <Link to='/company/settings/management/expense' className='nav-link'>
                        Expense
                    </Link>
                </li>
                <li>
                    <Link to='/company/settings/management/Preventive_Maintainence' className='nav-link'>
                        Preventive Maintainence
                    </Link>
                </li>
                <li>
                    <Link to='/company/settings/management/driver-rating' className='nav-link'>
                        Driver Rating
                    </Link>
                </li>
            </ul>
        </li>
    </ul>
);

const ManageSubmenu: React.FC<settingpropes> = ({ role }) => (
    <ul className='sub-menu block'>
        <li className='sub-menu-item'>
            <Link to='/systemAdmin/manageCompany/company' className='nav-link vehicle-activity-link'>
                Company
            </Link>
        </li>
        <li className='sub-menu-item'>
            <Link to='/company/settings/master' className='nav-link vehicle-activity-link'>
                Device
            </Link>
        </li>
        <li className='sub-menu-item'>
            <Link to='/company/settings/master/vehicle' className='nav-link vehicle-activity-link'>
                Vehicle
            </Link>
        </li>
        {(role==='system_admin') && (<>  <li  className="sub-menu-item">
        <Link to="/systemAdmin/manageDeviceType" className="nav-link vehicle-activity-link">
                Devicetype
            </Link>
        </li> </>)}
    </ul>
);
export default Sidebar;
