import React from 'react';
import Table from '../../../../components/table/Table';

import { useState,useEffect } from 'react';
import {createVehicleService} from '../../../../services/ServiceFactory'
import { IVehicleWorkhourReportSingle,WorkhourDataColumnSingle} from '../../../../services/interfaces/IVehicleService';
import BackButton from '../../../../components/BackButton';


const WorkhourReportForVehicle: React.FC =()=>{
const [data,setData]=useState<IVehicleWorkhourReportSingle[]>([]);
const [loading,setLoading] = useState(false);
  // send data for filter report
  
    const vehicleService= createVehicleService();
  
    useEffect(() => {
      setLoading(true);

      const fetchdata= async ()=>{
        try {
          const data= await  vehicleService.getWorkhourReportSingle();
          setData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
   void  fetchdata();
},[]);
   
    
    return (
      <>  
               <p> 
        <h1 className="text-black-700 text-xl">Work-Hour Report For Vehicle</h1>
               <BackButton label="Go Back" className=" absolute float-end  right-9 top-3" />
    </p>
        <Table columns={WorkhourDataColumnSingle} data={data} 
         excelExport={true}
         searchAble={true}
         pdfExport={true} loading={loading}  />
      </>
    );
}


 
export default WorkhourReportForVehicle;