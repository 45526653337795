import { useEffect, useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GeneralTab from './Tabs/GeneralTab';
import ProfileTab from './Tabs/ProfileTab';
import SensorsTab from './Tabs/SensorsTab';
import DocumentTab from './Tabs/DocumentTab';
import PrivateModeTab from './Tabs/PrivateModeTab';
import { Link, useParams } from 'react-router-dom';
import { IMasterVehicle, VehicleProfile } from '../../../../../../utils/interfaces/masterVehicle';
import useHttp from '../../../../../../hooks/useHttp';

interface VehicleResponseDetailsInterface {
  id: number;
  name: string;
  number: string;
  companyId: number;
  userId: number;
  deviceId: number,
  enabled?: boolean;
  createdAt: string;
  updatedAt: string;
}

function VehicleAdd() {
  const [value, setValue] = useState(0);
  const { request } = useHttp();

  // State for vehicle general information
  const [vehicleGeneral, setVehicleGeneral] = useState({
    vehicleName: '',
    gpsDeviceType: '',
    imeiNumber: '',
    enabled: true, 
    selectedDevice: '',
    ignoreHeartBeatData: false,
    copyFrom: '',
    serverAddress: '',
    simNumber: '',
    secondarySimNumber: '',
    timezone: 0,
    distanceCounter: '',
    unitOfDistance: '',
    speedDetection: '',
    distanceVariation: {
      dropdownValue: '',
      inputValue: ''
    },
    deviceAccuracyTolerance: '',
    shiftGroup: '',
    shiftName: '',
    qrCode: '',
    

  });

  // State for vehicle profile information
  const [vehicleProfile, setVehicleProfile] = useState<VehicleProfile>({
    plateNumber: '',
    selectedObjectTypes: "",
    dvirTemplate: '',
    manufactureDate: '',
    purchaseDate: '',
    purchaseAmount: '',
    weightCapacity: '',
    gpsInstallationDate: '',
    gpsWarranty: '',
    companyAverage: '',
    selectedPermits: "",
    installationDate: '',
    registrationNumber: '',
    selectedFuelTypes: "",
    isDistanceFuelChecked: false,
    isDurationFuelChecked: false,
    distanceFuelType: 0,
    distanceKm: '',
    distanceLiter: '',
    durationKm: '',
    durationTime: 0,
    durationFuelType: 0,
    durationLiter: '',
    fuel_idling_consumption: {
      dropdownValue: '',
      inputValue: ''
    },
    consumptionTolerance: '',
    vinNumber: '',
    engineNumber: '',
    odometer: '',
    lbsDetectionRadius: '',
    engineHour: '',
    passengerSeats: '',
    rfidTimeoutDuration: '',
    sleepModeDuration: '',
    minimumWorkingHours: '',
    costCategory: {
      distance: false,
      duration: false
    },
    weightSensor: false,
    underweightTolerance: '',
    overweightTolerance: '',
    loadingTolerance: '',
    fuelSensor: '',
    numberOfTanks: '',
    gSensor: false,
    axisX: '',
    axisY: '',
    axisZ: ''
  });

  const { id } = useParams<{ id: string }>();

  const mapResponseToMasterVehicle = (data: VehicleResponseDetailsInterface): IMasterVehicle => {
    return {
      id: data.id,
      vehicleName: data.name || "",
      imeiNumber: data.number || "",
      enabled: typeof data.enabled === 'boolean' ? data.enabled : true,
      selectedDevice: data.deviceId?.toString() || "",
      gpsDeviceType: '',
      ignoreHeartBeatData: false,
      copyFrom: "",
      serverAddress: "",
      simNumber: "",
      secondarySimNumber: "",
      timezone: 0,
      distanceCounter: "",
      unitOfDistance: "",
      speedDetection: "",
      distanceVariation: { dropdownValue: '', inputValue: '' },
      deviceAccuracyTolerance: "",
      shiftGroup: "",
      shiftName: "",
      qrCode: "",
      
      plateNumber: "",
      selectedObjectTypes: "",
      dvirTemplate: "",
      manufactureDate: "",
      purchaseDate: "",
      purchaseAmount: "",
      weightCapacity: "",
      gpsInstallationDate: "",
      gpsWarranty: "",
      companyAverage: "",
      selectedPermits: "",
      installationDate: "",
      registrationNumber: "",
      selectedFuelTypes: "",
      isDistanceFuelChecked: false,
      isDurationFuelChecked: false,
      distanceFuelType: 0,
      distanceKm: "",
      distanceLiter: "",
      durationKm: "",
      durationTime: 0,
      durationFuelType: 0,
      durationLiter: "",
      fuel_idling_consumption: {
        dropdownValue: "",
        inputValue: ""
      },
      FuelIdlingconsumptionFuelTypeChange: "",
      consumptionTolerance: "",
      vinNumber: "",
      engineNumber: "",
      odometer: "",
      lbsDetectionRadius: "",
      engineHour: "",
      passengerSeats: "",
      rfidTimeoutDuration: "",
      sleepModeDuration: "",
      minimumWorkingHours: "",
      costCategory: {
        distance: false,
        duration: false
      },
      weightSensor: false,
      underweightTolerance: "",
      overweightTolerance: "",
      loadingTolerance: "",
      fuelSensor: "",
      numberOfTanks: "",
      gSensor: false,
      axisX: "",
      axisY: "",
      axisZ: "",
    };
  };

  useEffect(() => {
    if (id) {
      const fetchVehicleData = async () => {
        try {
          const response = await request({
            url: `/vehicles/${id}`,
            method: 'GET'
          });

          if (!response.ok) {
            throw new Error('Failed to fetch vehicles');
          }

          const data = await response.json() as VehicleResponseDetailsInterface;
          const vehicleDetails: IMasterVehicle = mapResponseToMasterVehicle(data);
          console.log("from main", data);
          
          

          if (vehicleDetails) {
            setVehicleGeneral({
              vehicleName: vehicleDetails.vehicleName,
              gpsDeviceType: vehicleDetails.gpsDeviceType,
              enabled: typeof vehicleDetails.enabled === 'boolean' ? vehicleDetails.enabled : false,
              imeiNumber: vehicleDetails.imeiNumber,
              selectedDevice: vehicleDetails.selectedDevice,
              ignoreHeartBeatData: vehicleDetails.ignoreHeartBeatData,
              copyFrom: vehicleDetails.copyFrom,
              serverAddress: vehicleDetails.serverAddress,
              simNumber: vehicleDetails.simNumber,
              secondarySimNumber: vehicleDetails.secondarySimNumber,
              timezone: vehicleDetails.timezone,
              distanceCounter: vehicleDetails.distanceCounter,
              unitOfDistance: vehicleDetails.unitOfDistance,
              speedDetection: vehicleDetails.speedDetection,
              distanceVariation: vehicleDetails.distanceVariation,
              deviceAccuracyTolerance: vehicleDetails.deviceAccuracyTolerance,
              shiftGroup: vehicleDetails.shiftGroup,
              shiftName: vehicleDetails.shiftName,
              qrCode: vehicleDetails.qrCode,
            });
          
            setVehicleProfile({
              plateNumber: vehicleDetails.plateNumber,
              selectedObjectTypes: vehicleDetails.selectedObjectTypes,
              dvirTemplate: vehicleDetails.dvirTemplate,
              manufactureDate: vehicleDetails.manufactureDate,
              purchaseDate: vehicleDetails.purchaseDate,
              purchaseAmount: vehicleDetails.purchaseAmount,
              weightCapacity: vehicleDetails.weightCapacity,
              gpsInstallationDate: vehicleDetails.gpsInstallationDate,
              gpsWarranty: vehicleDetails.gpsWarranty,
              companyAverage: vehicleDetails.companyAverage,
              selectedPermits: vehicleDetails.selectedPermits,
              installationDate: vehicleDetails.installationDate,
              registrationNumber: vehicleDetails.registrationNumber,
              selectedFuelTypes: vehicleDetails.selectedFuelTypes,
              isDistanceFuelChecked: vehicleDetails.isDistanceFuelChecked,
              isDurationFuelChecked: vehicleDetails.isDurationFuelChecked,
              distanceFuelType: vehicleDetails.distanceFuelType,
              distanceKm: vehicleDetails.distanceKm,
              distanceLiter: vehicleDetails.distanceLiter,
              durationKm: vehicleDetails.durationKm,
              durationTime: vehicleDetails.durationTime,
              durationFuelType: vehicleDetails.durationFuelType,
              durationLiter: vehicleDetails.durationLiter,
              fuel_idling_consumption: vehicleDetails.fuel_idling_consumption,
              consumptionTolerance: vehicleDetails.consumptionTolerance,
              vinNumber: vehicleDetails.vinNumber,
              engineNumber: vehicleDetails.engineNumber,
              odometer: vehicleDetails.odometer,
              lbsDetectionRadius: vehicleDetails.lbsDetectionRadius,
              engineHour: vehicleDetails.engineHour,
              passengerSeats: vehicleDetails.passengerSeats,
              rfidTimeoutDuration: vehicleDetails.rfidTimeoutDuration,
              sleepModeDuration: vehicleDetails.sleepModeDuration,
              minimumWorkingHours: vehicleDetails.minimumWorkingHours,
              costCategory: vehicleDetails.costCategory ?? { distance: false, duration: false },
              weightSensor: vehicleDetails.weightSensor ?? false,
              underweightTolerance: vehicleDetails.underweightTolerance ?? "",
              overweightTolerance: vehicleDetails.overweightTolerance ?? "",
              loadingTolerance: vehicleDetails.loadingTolerance ?? "",
              fuelSensor: vehicleDetails.fuelSensor ?? "",
              numberOfTanks: vehicleDetails.numberOfTanks ?? "",
              gSensor: vehicleDetails.gSensor ?? false,
              axisX: vehicleDetails.axisX ?? "",
              axisY: vehicleDetails.axisY ?? "",
              axisZ: vehicleDetails.axisZ ?? "",
            });
          }
          else {
            console.error('Vehicle record not found.');
          }
        } catch (error) {
          console.error('Error fetching vehicle data:', error as Error);
        }
      };

      void fetchVehicleData();
    }
  }, [id]);



  return (
    <div>
      <div className='md:flex justify-between items-center'>
        <div>
          <h1 className='font-bold text-2xl text-blue-950'>
            {id ? 'Edit Vehicle' : 'Create Vehicle'}
          </h1>
        </div>
        <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
          <ul>
            <li>
              <Link to='/company/dashboard' className='flex items-center space-x-2'>
                <DashboardIcon />
                <span>Dashboard</span>
              </Link>
            </li>
          </ul>
          <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
          <p className='ml-2 text-sm'>Vehicle</p>
          <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
          <p className='ml-2 text-sm'>Create</p>
        </div>
      </div>
      <div className='text-blue-500 w-[100%] h-[100%] rounded-t-md mt-6'>
        <div className='flex gap-5'>
          <button
            onClick={() => setValue(0)}
            className={`w-[100px] h-[40px] ${value === 0 ? 'text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
              }`}
          >
            General
          </button>

          <button
            onClick={() => setValue(1)}
            className={`w-[100px] h-[40px] ${value === 1 ? 'text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
              }`}
          >
            Profile
          </button>

          <button
            onClick={() => setValue(2)}
            className={`w-[100px] h-[40px] ${value === 2 ? 'text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
              }`}
          >
            Sensors
          </button>

          <button
            onClick={() => setValue(3)}
            className={`w-[100px] h-[40px] ${value === 3 ? 'text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
              }`}
          >
            Document
          </button>

          <button
            onClick={() => setValue(4)}
            className={`w-[120px] h-[40px] ${value === 4 ? 'text-black bg-gray-200 rounded-tr-md rounded-tl-md' : 'hover:text-gray-500'
              } ml-4`}
          >
            Private Mode
          </button>
        </div>
        <hr />
      </div>
      <hr className='border-0 h-px bg-gray-400' />
      <div className="w-full mx-auto p-6 bg-white border rounded-lg shadow">
        {value === 0 && <GeneralTab data={vehicleGeneral} id={id} />}
        {value === 1 && <ProfileTab data={vehicleProfile} />}
        {value === 2 && <SensorsTab />}
        {value === 3 && <DocumentTab />}
        {value === 4 && <PrivateModeTab />}

      </div>
    </div>
  );
}

export default VehicleAdd;
