// NavTabs.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Tab {
  name: string;
  path: string;
}

interface NavTabsProps {
  tabs: Tab[];
}

const NavTabs: React.FC<NavTabsProps> = ({ tabs }) => {
  return (
    <nav className="border-b border-gray-200 mb-4 bg-white">
      <div className="flex">
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            to={tab.path}
            className={({ isActive }) =>
              isActive
                ? "text-blue-600 border-t-2 border-blue-700 border-l-[2px] border-r-[2px] border-l-gray-200 border-r-gray-200 font-medium px-4 py-2 bg-white bx-gray-200"
                : "text-gray-600 border-t-2 border-transparent font-medium px-4 py-2 hover:text-blue-600 "
            }
          >
            {tab.name}
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default NavTabs;
