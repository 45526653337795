import React from 'react';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import { useState, useEffect } from 'react';
import NavTabs from '../../../../components/NavTabs';
import tabs from './Tab';
import { IVehicleParkedReport, ParkedDataColumn } from '../../../../services/interfaces/IVehicleService';
import { FaEye } from 'react-icons/fa';
import useHttp from '../../../../hooks/useHttp';
import { VehicleTableInterface } from '../../../../utils/interfaces/masterVehicle';

interface VehicleApiResponse {
    message: string;
    data: VehicleTableInterface[];
}
const ParkedReportUI: React.FC = () => {
    const Report_type = 'Parked';
    const [data, setData] = useState<IVehicleParkedReport[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState<CustomFormData | null>(null);
    const [matchingVehicleIds, setMatchingVehicleIds] = useState<number[]>([]);
    const [vehicle, setVehicle] = useState<VehicleTableInterface[]>([]);
    const {request}= useHttp();
    const handleSelectedData = (datafilter: CustomFormData) => {
        
       
        const fullDataNumbers = datafilter.multiSelect?.map((item) => item.value);

        if (Array.isArray(vehicle)) {
            const matchedIds = vehicle.filter((v) => fullDataNumbers?.includes(v.number))
                .map((v) => v.id);

            setMatchingVehicleIds(matchedIds);
            setSelectedData(datafilter);
        } else {
            console.error('Vehicle is not an array:', vehicle);
        }
    };
    const fetchVehicle = async () => {
        setLoading(true);
        try {
            const response = await request({ url: '/vehicles', method: 'GET' });
            if (!response.ok) throw new Error('Failed to fetch vehicles');

            const responseData = await response.json() as VehicleApiResponse;

            if (Array.isArray(responseData.data)) {
                setVehicle(responseData.data);
            } else {
                console.error('Fetched data is not an array:', responseData.data);
                setVehicle([]);
            }
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        if (selectedData) {
            const sdate = selectedData.startDateTime
            ? selectedData.startDateTime.toISOString()
            : '';
            const endDate = selectedData.endDateTime
            ? selectedData.endDateTime.toISOString()
            : '';
        
          const intervalTime:string=selectedData.singleSelect?selectedData.singleSelect?.value:'5m';
            const params = new URLSearchParams({
                start: sdate,
                stop:endDate,
                aggregateWindow: intervalTime,
                page: "1",
                pageSize: "100",
            });
            // const matchingVehicleIds = [3];
            if (matchingVehicleIds && matchingVehicleIds.length > 0) {
                params.append("vehicleIds", matchingVehicleIds.join(","));
            }
            //workHour console.log(`selected data`, selectedData.multiSelect);
            //const url = "/report/movement/?start=2024-10-20T21%3A00%3A00.000Z&stop=2024-10-26T15%3A00%3A00.000Z&aggregateWindow=5m&page=1&pageSize=100";
            const url = `/report/parked?${params.toString()}`;
 
            setLoading(true);

            try {
                const response = await request({
                    method: 'GET',
                    url: url,
                });

                console.log(`response`, response);

                if (!response.ok) throw new Error('Failed to fetch data');
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                const reportData = (await response.json()).data as IVehicleParkedReport[];

                setData(reportData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        void fetchVehicle();
    }, []);

    useEffect(() => {
        void fetchData();
    }, [selectedData]);
    const vehilcleList = vehicle.map((e) => e.number );

    // send data for filter report
    // const options: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    const singleselect: string[] = ['5m', '10m', '15m', '20m'];
    const field_type: string[] = ['Vehicle', 'Interval', 'From', 'To'];
    
   
    const actions = [
        {
            name: 'View',
            path: '/company/report/vehicle-activity/ParkedReportForVehicle',
            icon: FaEye,
            class: 'text-blue-700 text-2xl'
        }
    ];
    return (
        <>
            <NavTabs tabs={tabs} />
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={vehilcleList}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table<IVehicleParkedReport>
                columns={ParkedDataColumn}
                data={data}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                loading={loading}
                tableRowActions={actions}
            />
        </>
    );
};

export default ParkedReportUI;
