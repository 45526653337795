import React, { useState, useEffect } from 'react';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import NavTabs from '../../../../components/NavTabs';
import tabs from './Tab';
import { FaEye } from 'react-icons/fa';
import useHttp from '../../../../hooks/useHttp';
import { IVehicleWorkhourReport, WorkhourDataColumn } from '../../../../services/interfaces/IVehicleService';
import { VehicleTableInterface } from '../../../../utils/interfaces/masterVehicle';

interface VehicleApiResponse {
    message: string;
    data: VehicleTableInterface[];
}
interface IWorkHourApiResponce{
    data:IVehicleWorkhourReport[];
}

const WorkHourReportUI: React.FC = () => {
    const Report_type = 'Work Hour';
    const { request } = useHttp();
    const [data, setData] = useState<IVehicleWorkhourReport[]>([]);
    const [vehicle, setVehicle] = useState<VehicleTableInterface[]>([]);
    const [selectedData, setSelectedData] = useState<CustomFormData | null>(null);

    const [matchingVehicleIds, setMatchingVehicleIds] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);

    const singleselect: string[] = ['5m', '10m', '15m', '20m'];
    const field_type: string[] = ['Vehicle', 'Work Type', 'From', 'To'];

    const handleSelectedData = (datafilter: CustomFormData) => {
        
        const fullDataNumbers = datafilter.multiSelect?.map((item) => item.value);
        if (Array.isArray(vehicle)) {
            const matchedIds = vehicle.filter((v) => fullDataNumbers?.includes(v.number))
                .map((v) => v.id);
            setMatchingVehicleIds(matchedIds);
            setSelectedData(datafilter);
        } else {
            console.error('Vehicle is not an array:', vehicle);
        }
    };

    const fetchVehicle = async () => {
        setLoading(true);
        try {
            const response = await request({ url: '/vehicles', method: 'GET' });
            if (!response.ok) throw new Error('Failed to fetch vehicles');

            const responseData = await response.json() as VehicleApiResponse;

            if (Array.isArray(responseData.data)) {
                setVehicle(responseData.data);
            } else {
                console.error('Fetched data is not an array:', responseData.data);
                setVehicle([]);
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error fetching vehicles:', error.message);
            } else {
                console.error('Unknown error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        if (selectedData) {
            const startdate = selectedData.startDateTime
            ? selectedData.startDateTime.toISOString()
            : '';
            const enddate = selectedData.endDateTime
            ? selectedData.endDateTime.toISOString()
            : '';
            const intervalTime: string = selectedData.singleSelect ? selectedData.singleSelect?.value : '5m';

            const params = new URLSearchParams({
                start:startdate ,
                stop: enddate,
                aggregateWindow: intervalTime,
                page: "1",
                pageSize: "100",
                vehicleIds: `${matchingVehicleIds.join(",")}`

            });

         
            const url = `/report/workHour?${params.toString()}`;
            setLoading(true);

            try {
                const response = await request({ method: 'GET', url });

                if (!response.ok) throw new Error('Failed to fetch data');
                
                const reportData = await response.json() as IWorkHourApiResponce;
                setData(reportData.data);
            } catch (error) {
                if (error instanceof Error) {
                    console.error('Error fetching data:', error.message);
                } else {
                    console.error('Unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        void fetchVehicle();
    }, []);

    useEffect(() => {
        void fetchData();
    }, [selectedData]);

    const actions = [
        {
            name: 'View',
            path: '/company/report/vehicle-activity/WorkHourReportForVehicle',
            icon: FaEye,
            class: 'text-blue-700 text-2xl'
        }
    ];

    const vehilcleList = vehicle.map((e) => e.number);

    return (
        <>
            <NavTabs tabs={tabs} />
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={vehilcleList}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table
                columns={WorkhourDataColumn}
                data={data}
                loading={loading}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                tableRowActions={actions}
            />
        </>
    );
};

export default WorkHourReportUI;
