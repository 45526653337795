import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputField from '../../../components/InputFeild';
import { useAuth } from '../../../context/AuthContext';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';




export interface LoginResponse {
    message: string;
    data: {
        token: string;
        refreshToken: string;
    };
}




function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    };
    const [loginError, setLoginError] = useState('');
    const { request } = useHttp();

    const navigate = useNavigate();
    const { login } = useAuth();
    const validateForm = () => {
        let isValid = true;

        if (!email) {
            setEmailError('Email is required');
            isValid = false;
        }
        // else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        //   setEmailError("Email address is invalid");
        //   isValid = false;
        // }
        else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Password is required');
            isValid = false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await request({
                    url: '/auth/login',
                    method: 'POST',
                    body: { email, password }
                });
                const responseData = await response.json() as LoginResponse;

                toast.success(responseData.message);
                const { token, refreshToken } = responseData.data;

                login(token, refreshToken);




                navigate('/company/dashboard');
            } catch (error) {
                console.error('Error during login:', error);
                setLoginError('Invalid email or password');
            }
        }
    };

    return (
        <div className='fixed inset-0 bg-gray-200 flex items-center justify-center'>
            <div className='bg-white shadow-md w-[330px] md:w-[360px] h-[460px] rounded-md'>
                <div>
                    <img src='/images/login.jpeg' alt='login images' />
                </div>
                <div className='flex items-center justify-center p-4'>
                    <p className='text-gray-500'>Sign in to start your session</p>
                </div>
                <form
                    className='mt-3'
                    onSubmit={e => {
                        e.preventDefault();
                        void handleSubmit(e); // Call the async function correctly
                    }}
                >
                    <div className='grid grid-cols-1 gap-3'>
                        <div className='w-[90%] ml-4'>
                            <InputField
                                type='text'
                                id='email'
                                placeholder='Enter your email'
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                icon={<EmailIcon className='text-gray-500 mr-2' style={{ fontSize: '20px' }} />}
                                className='block w-full p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-3 md:mt-0'
                            />
                            {emailError && <p className='text-red-500 text-sm ml-2'>{emailError}</p>}
                        </div>
                        <div className='w-[90%] ml-4'>
                            <InputField
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                placeholder='Enter your password'
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                icon={
                                    showPassword ? (
                                        <VisibilityOffIcon
                                            className='text-gray-500 mr-2'
                                            style={{ fontSize: '20px', cursor: 'pointer' }}
                                            onClick={handleTogglePassword}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            className='text-gray-500 mr-2'
                                            style={{ fontSize: '20px', cursor: 'pointer' }}
                                            onClick={handleTogglePassword}
                                        />
                                    )
                                }
                                className='block w-full p-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-3 md:mt-0'
                            />
                            {passwordError && <p className='text-red-500 text-sm ml-2'>{passwordError}</p>}
                        </div>
                    </div>
                    <div className='flex justify-between mr-4 ml-4 mt-4'>
                        <label className='flex items-center gap-2'>
                            <input type='checkbox' className='h-6 w-6' />
                            <span className='text-md text-gray-500 font-bold'>Remember me</span>
                        </label>
                        <div>
                            <button type='submit' className='w-[100px] h-[40px] rounded-md bg-blue-600 text-white'>
                                Login
                            </button>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <button className='text-blue-600 hover:text-blue-800 font-semibold'>
                            Forgot Your Password?
                        </button>
                    </div>
                </form>
                {loginError && <p className='text-red-500 text-sm ml-4'>{loginError}</p>}
            </div>
        </div>
    );
}

export default Login;
