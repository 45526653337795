import { TableColumn } from 'react-data-table-component';


export interface IDriverService {
    getDriver(): Promise<IDriver[]>;
}
export type IDriver = {
    id: number;
    driverName: string;
    punchID:string;
    driverEmail: string;
    driverPhoneNumber:string;
    driverDOB: string;
    drivingLicenceNo:string;
    drivingLicenceIssueDate:string;
    drivingLicenceExpireDate:string;
    driverAddress:string;
    employeeDesignation:string;
    tagVia:string;
    shiftGroup:string;
    driverAge:string;
    dateOfJoining:string;
    dateOfLeaving:string;
    drivingExpireSince:string;
    licenceAvailabel:string;
    licenceToDrive:string;
    IFEinsurenceNO:string;
    lifeInsurenceExpireDate:string;
    medicalNo:string;
    medicalExpireDate:string;
    documentName:string;
    issueDate:string;
    expireDate:string;
    status: string;
    lastModified: string;
   
};
export const IDriverDataColumn: TableColumn<IDriver>[] = [
    { name: 'ID', selector: (row: IDriver) => row.id, sortable: true },
    { name: 'Driver Name', selector: (row: IDriver) => row.driverName, sortable: true },
    { name: 'Driver Email', selector: (row: IDriver) => row.driverEmail, sortable: true },
    { name: 'Status', selector: (row: IDriver) => row.status, sortable: true },
    { name: 'Last Modified', selector: (row: IDriver) => row.lastModified, sortable: true }
];
