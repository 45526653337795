import React, { useEffect, useState } from 'react';
import InputField from '../../../../../../components/InputFeild';
import Dropdown from '../../../../../../components/Dropdown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';
import { useNavigate, useParams } from 'react-router-dom';
import { PreventiveMaintainenceService } from '../../../../../../services/PreventiveMaintainenceService';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import Button from '../../../../../../components/Button';

interface FormData {
  reminder_name: string;
  reminder_type_id: number;
  reminder_month: number;
  notify_days: number;
  notify_distance: number;
  engine_hour: number;
  notify_engine: number;
  reminder_notify_to: string;
  reminder_mobile_number: string;
  reminder_email: string;
}

const Preventive_MaintenanceAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    reminder_name: '',
    reminder_type_id: 0,
    reminder_month: 0,
    notify_days: 0,
    notify_distance: 0,
    engine_hour: 0,
    notify_engine: 0,
    reminder_notify_to: '',
    reminder_mobile_number: '',
    reminder_email: '',
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [notifyUsing, setNotifyUsing] = useState({
    notification: false,
    sms: false,
    email: false,
  });

  const reminderTypeOptions = [
    { value: '0', label: 'Select Reminder' },
    { value: '1', label: 'Maintenance' },
    { value: '2', label: 'Inspection' },
    { value: '3', label: 'Service' },
  ];


  const [objectDriverSearch, setObjectDriverSearch] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  const preventiveMaintainenceService = new PreventiveMaintainenceService();

  useEffect(() => {
    if (id) {
      const fetchMaintainence = async () => {
        try {
          const maintenanceRecords = await preventiveMaintainenceService.getPreventiveMaintainence();
          const maintenanceToEdit = maintenanceRecords.find(record => record.id === parseInt(id, 10));

          if (maintenanceToEdit) {
            setFormData(prev => ({
              ...prev,
              ...maintenanceToEdit,
            }));
            setNotifyUsing({
              notification: Boolean(maintenanceToEdit.reminder_notify_to),
              sms: Boolean(maintenanceToEdit.reminder_mobile_number),
              email: Boolean(maintenanceToEdit.reminder_email),
            });
            setIsEditMode(true);
          } else {
            console.error('Maintenance record not found.');
          }
        } catch (error) {
          console.error('Error fetching maintenance records:', error);
        }
      };

      void fetchMaintainence();
    }
  }, [id]);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.reminder_name) {
      newErrors.reminder_name = 'Reminder Name is required';
    }
    if (formData.reminder_type_id === 0) {
      newErrors.reminder_type_id = 'Reminder Type is required';
    }
    if (formData.reminder_month <= 0) {
      newErrors.reminder_month = 'Must be greater than 0';
    }
    if (formData.notify_days <= 0) {
      newErrors.notify_days = 'Must be greater than 0';
    }
    if (formData.notify_distance <= 0) {
      newErrors.notify_distance = 'Must be greater than 0';
    }
    if (formData.engine_hour <= 0) {
      newErrors.engine_hour = 'Must be greater than 0';
    }
    if (formData.notify_engine <= 0) {
      newErrors.notify_engine = 'Must be greater than 0';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (validateForm()) {
      console.log('Form data:', formData);
      navigate('/company/settings/management/Preventive_Maintainence');
    } else {
      console.log('Validation errors:', errors);
    }
  };

  
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    // Clear the specific error message for this field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
    
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Clear the specific error message for this field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
    
    setFormData(prevState => ({
      ...prevState,
      [name]: Number(value),
    }));
  };
  

  const handleSelectChange = (selectedValue: string, name: string) => {
    const newValue = Number(selectedValue);
  
    // Update the form data
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  
    // Clear the specific error related to the updated field if it's valid
    const newErrors = { ...errors };
    if (name === 'reminder_type_id' && newValue !== 0) {
      delete newErrors[name];
    }
  
    // Update errors state
    setErrors(newErrors);
  };
  


  const handleNotifyCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNotifyUsing((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleBack = () => {
    navigate('/company/settings/management/Preventive_Maintainence');
  };

  return (
    <div className="p-1">
      <div className='md:flex justify-between items-center'>
        <div className="text-2xl">Preventive Maintainence</div>
        <div className='flex items-center bg-gray-400 md:bg-gray-300'>
          <ul>
            <li>
              <Link to='/company/dashboard' className='flex items-center space-x-2'>
                <DashboardIcon />
                <span>Home</span>
              </Link>
            </li>
          </ul>
          <ArrowForwardIosIcon style={{ fontSize: 10, marginLeft: '7px' }} />
          <p className='ml-2 text-sm'>Preventive Maintainence</p>
        </div>
      </div>
      <div className="bg-white text-black py-2 px-6 mb-4 mt-2 border-t-4 border-blue-950">
        <h2 className="text-md font-bold">Reminder Rule Detail</h2>
      </div>
      <div className="p-4 bg-white rounded shadow-md">
        <div className="container mx-auto">
          <form className="grid grid-cols-1 lg:grid-cols-3 gap-6" onSubmit={handleSubmit}>
            {/* Left Column */}
            <div className="lg:col-span-1 pr-6">
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Reminder Name:</label>
                <div>
                <InputField
                  type="text"
                  name="reminder_name"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.reminder_name}
                  onChange={handleChange}
                />
                 {errors.reminder_name && <p className="text-red-500 text-sm mt-1">{errors.reminder_name}</p>}
                 </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Reminder Type:</label>
                <div>
                <Dropdown
                  options={reminderTypeOptions}
                  onSelect={(selectedValue) => handleSelectChange(selectedValue, 'reminder_type_id')}
                  selectedValue={String(formData.reminder_type_id)}
                  selectClass=" p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none "
                  selectStyle={{ width: '156px' }}
                />
                {errors.reminder_type_id && <p className="text-red-500 text-sm mt-1">{errors.reminder_type_id}</p>}
                </div>
              </div>

              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Reminder Every (x) Month:</label>
                <div>
                <InputField
                  type="text"
                  name="reminder_month"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.reminder_month}
                  onChange={handleNumberChange}
                />
                {errors.reminder_month && <p className="text-red-500 text-sm mt-1">{errors.reminder_month}</p>}
                </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Notify Before (x) Days:</label>
                <div>
                <InputField
                  type="text"
                  name="notify_days"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.notify_days}
                  onChange={handleNumberChange}
                />
                {errors.notify_days && <p className="text-red-500 text-sm mt-1">{errors.notify_days}</p>}
                </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Every (x) Distance:</label>
                <div>
                <InputField
                  type="text"
                  name="notify_distance"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.notify_distance}
                  onChange={handleNumberChange}
                />
                {errors.notify_distance && <p className="text-red-500 text-sm mt-1">{errors.notify_distance}</p>}
                </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Notify Before (x) Distance:</label>
                <div>
                <InputField
                  type="text"
                  name="engine_hour"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.engine_hour}
                  onChange={handleNumberChange}
                />
                {errors.engine_hour && <p className="text-red-500 text-sm mt-1">{errors.engine_hour}</p>}
                </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Every (x) Engine Hour:</label>
                <div>
                <InputField
                  type="text"
                  name="notify_engine"
                  className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  value={formData.notify_engine}
                  onChange={handleNumberChange}
                />
                {errors.notify_engine && <p className="text-red-500 text-sm mt-1">{errors.notify_engine}</p>}
                </div>
              </div>
              <div className="mb-2 flex items-center">
                <label className="w-1/3 text-sm pr-4">Remind Using:</label>
                <div className="w-2/3 flex items-center space-x-4">
                  <label className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      name="notification"
                      checked={notifyUsing.notification}
                      onChange={handleNotifyCheckboxChange}
                      className="mr-2"
                    />
                    Notification
                  </label>
                  <label className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      name="sms"
                      checked={notifyUsing.sms}
                      onChange={handleNotifyCheckboxChange}
                      className="mr-2"
                    />
                    SMS
                  </label>
                  <label className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      name="email"
                      checked={notifyUsing.email}
                      onChange={handleNotifyCheckboxChange}
                      className="mr-2"
                    />
                    Email
                  </label>
                </div>
              </div>
              {notifyUsing.notification && (
                <div className="mb-2 flex items-center">
                  <label className="w-1/3 text-sm pr-4">Notify To:</label>
                  <InputField
                    type="text"
                    name="reminder_notify_to"
                    className="w-5/6 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                    value={formData.reminder_notify_to}
                    onChange={handleChange}
                  />
                </div>
              )}
              {notifyUsing.sms && (
                <div className="mb-2 flex items-center">
                  <label className="w-1/3 text-sm pr-4">SMS To:</label>
                  <textarea
                    name="reminder_mobile_number"
                    className="w-40 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                    value={formData.reminder_mobile_number}
                    onChange={handleChange}
                  />
                </div>
              )}
              {notifyUsing.email && (
                <div className="mb-2 flex items-center">
                  <label className="w-1/3 text-sm pr-4">Email To:</label>
                  <textarea
                    name="reminder_email"
                    className="w-40 p-2 px-3 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                    value={formData.reminder_email}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>

            {/* Right Column */}
            <div className="lg:col-span-2 pl-3">
              <div className="mb-4 relative flex items-center">
                <SearchOutlinedIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  className="form-control py-2 pl-10 search-input w-full lg:w-2/3 p-2 border border-gray-300 rounded-sm text-sm focus:border-sky-500 focus:outline-none"
                  placeholder="Object/Driver Search"
                  id="example-search-input"
                  value={objectDriverSearch}
                  onChange={(e) => setObjectDriverSearch(e.target.value)}
                />
                <div
                  onClick={() => console.log('Cached icon clicked')}
                  className="ml-3 cursor-pointer flex items-center justify-center w-9 h-9 rounded bg-darkblue"
                  style={{ backgroundColor: 'gray' }}
                >
                  <CachedSharpIcon style={{ color: '#fff' }} />
                </div>
              </div>
              <div className="flex justify-start space-x-4">
                {/* Upload Button */}
                <button
                  className="bg-gray-500 hover:bg-gray-700 w-9 h-9 text-white py-2 px-4 rounded flex items-center  justify-center"
                  onClick={() => console.log('Upload button clicked')}
                >
                <FileDownloadOutlinedIcon />
                </button>

                {/* Download Button */}
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 w-9 h-9 rounded flex items-center  justify-center"
                  onClick={() => console.log('Download button clicked')}
                >
                  <ArrowCircleUpSharpIcon />
                </button>
              </div>


              <div className="mb-4  mt-2 overflow-x-auto">
                <table className="table-auto w-full mb-4 ">
                  <thead>
                    <tr>
                      <th className="px-2 py-3 border w-6 text-sm font-medium">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            className="mr-2"
                          // onChange={handleSelectAllChange}
                          />
                        </label>
                      </th>
                      <th className="px-2 py-3 border w-6 text-sm font-medium">Object/Driver</th>
                      <th className="px-2 py-3 border w-6 text-sm font-medium">
                        <select className="border-none bg-transparent text-sm focus:outline-none">
                          <option value="">Reminder Start Date</option>
                          <option value="Option 1">Option 1</option>
                          <option value="Option 2">Option 2</option>
                          <option value="Option 3">Option 3</option>
                        </select>
                      </th>
                      <th className="py-2 border text-sm font-medium">
                        Distance before Reminder Start Date
                      </th>
                      <th className="py-2 border text-sm font-medium">
                        Engine hour before Reminder Start Date
                      </th>
                      <th className="py-2 border text-sm font-medium">Issue Date</th>
                      <th className="py-2 border text-sm font-medium">Expiry Date</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="mt-4 flex justify-end space-x-4">
                <Button
                  type="submit"
                  className={`bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md `}
                >
                  {isEditMode ? 'Update' : 'Submit'}
                </Button>
                <Button
                  onClick={handleBack}
                  className="bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md"
                >
                  Back
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Preventive_MaintenanceAdd;
