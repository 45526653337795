import React from "react";
import { useNavigate } from "react-router-dom"; // Ensure useNavigate is imported
import FindAddress from "../../../../components/FindAddress";
import Cards from "../../../../components/Card";

// Define the Device interface
interface Device {
  id?: number;
  imei?: string;
  name?: string;
  date: string;
  color?: string;
  speed: number;
  status: string;
  dname: string;
  lat: number;
  lng: number;
}

interface VehicleDetailsProps {
  selectedDevice: Device;
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ selectedDevice }) => {
  const navigate = useNavigate();

  const playback = () => {
    navigate(`/playback/${selectedDevice.name}/${selectedDevice.imei}`);
  };

  return (
    <div className="h-[85vh] flex flex-col overflow-hidden">
      <div className="mt-4 flex-1 overflow-y-auto px-4">
        <div className="border mb-4 bg-white min-w-28">
          <p className="font-bold text-gray-800 text-center whitespace-nowrap overflow-hidden text-ellipsis px-2">
            {selectedDevice.name}
          </p>
          <div className="flex items-center justify-around p-3">
            <div className="w-20 h-5 bg-blue-950 text-white text-center text-xs rounded whitespace-nowrap">
              {selectedDevice.status}
            </div>
            <div className="w-28 h-5 bg-blue-950 text-white text-center text-xs rounded whitespace-nowrap">
              {selectedDevice.date}
            </div>
          </div>
          <hr className="border border-gray-300 mt-3" />

          <div className="p-3">
            <div className="flex items-start justify-between text-xs font-semibold text-gray-700 whitespace-nowrap">
              <p>Vehicle Name:</p>
              <p>{selectedDevice.name}</p>
            </div>
            <div className="flex items-start justify-between text-xs font-semibold text-gray-700 mt-2 whitespace-nowrap">
              <p>Vehicle Number:</p>
              <p>{selectedDevice.name}</p>
            </div>
            <div className="flex items-start justify-between text-xs font-semibold text-gray-700 mt-2 whitespace-nowrap">
              <p>Speed:</p>
              <p>{selectedDevice.speed}</p>
            </div>
            <div className="flex items-start justify-between text-xs font-semibold text-gray-700 mt-2 whitespace-nowrap">
              <p>Driver Name:</p>
              <p>{selectedDevice.dname}</p>
            </div>
            <div className="flex justify-center gap-2 mt-7">
              <button className="w-16 h-7 bg-blue-950 text-white text-center text-xs rounded">
                Reports
              </button>
              <button
                className="w-20 h-7 bg-blue-950 text-white text-center text-xs rounded"
                onClick={playback}
              >
                Playback
              </button>
            </div>
            <div className="flex justify-center mt-3">
              <button className="w-full h-7 bg-blue-950 text-white text-center text-xs rounded">
                Driver Punch Report
              </button>
            </div>
          </div>
        </div>


        {/* Multiple FindAddress Cards */}
        <div className="space-y-4 ">
          {/* {Array(18).fill(0).map((_, index) => ( */}
          <FindAddress
            // key={index}
            latitude={selectedDevice.lat.toString()}
            longitude={selectedDevice.lng.toString()}
            border={true}
            title={true}
          />
          {/* ))} */}
        </div>
        {/* today activity */}
        <div className="p-3 space-y-4 border mt-4 bg-white min-w-28 ">
          <p className="text-sm font-bold">Today Activity</p>
          <div className="text-sm">
            <div className="flex justify-between text-"><p>Running </p> <p>{'hrs'}</p></div>
            <div className="flex justify-between text-"> <p>Idle </p>  <p>{'hrs'}</p></div>
            <div className="flex justify-between text-"><p>Stop </p>  <p>{'hrs'}</p></div>
            <div className="flex justify-between text-"><p>Inactive </p>  <p>{'hrs'}</p></div>
            <div className="flex justify-between text-"><p>Work Hour </p> <p>{'hrs'}</p></div>
            <hr></hr>
            <div className="p">
              <div className="flex justify-between"><p>Last Stop</p><p>17:39 PM</p></div>

              Anakapalle Bypass Tallapalem, Andhra Pradesh (NE)</div>
          </div>
        </div>

        {/* avg speed max-speed */}
        <div className="flex justify-between pt-3">
          {/* avg speed */}
          <Cards title="Avg Speed" className="mr-2">
          </Cards>

          {/* max speed */}
          <Cards title="Max Speed">
          </Cards>
          
        </div>

      </div>
    </div>

  );
};

export default VehicleDetails;
