import React from "react";
import {
  AreaChart as RechartsAreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Define the props interface
interface CustomAreaChartProps {
  data: { duration: number; distance: number }[];
  xAxisLabel: string;
  yAxisLabel: string;
  dataKey: string;
  areaStrokeColor?: string;
  areaFillColor?: string;
  lineStrokeColor?: string;
}

const CustomAreaChart: React.FC<CustomAreaChartProps> = ({
  data,
  xAxisLabel,
  yAxisLabel,
  dataKey,
  areaStrokeColor = "#00BFFF",
  areaFillColor = "rgba(75, 192, 192, 0.2)",
  lineStrokeColor = "#00BFFF",
}) => {
  return (
    <div className="w-full h-40">
      <ResponsiveContainer width="100%" height="80%">
        <RechartsAreaChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="duration"
            label={{
              value: xAxisLabel,
              position: "insideBottom", 
              offset: 3,              
              fontSize: 12,
              textAnchor: "middle",     
            }}
            tick={{ fontSize: 10 }}
            angle={0} 
            domain={[0, 'dataMax']}
          />

          <YAxis
            type="number"
            dataKey="distance"
            label={{
              value: yAxisLabel,
              angle: 270,
              position: "insideLeft",
              offset:20,
              fontSize: 12,
              dy: 20,     
            }}
            tick={{ fontSize: 10 }}
            domain={[0, 'dataMax']}
          />
          <Tooltip contentStyle={{ fontSize: 12 }} />
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={areaStrokeColor}
            fill={areaFillColor}
          />
          <Line type="monotone" dataKey={dataKey} stroke={lineStrokeColor} />
        </RechartsAreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomAreaChart;
