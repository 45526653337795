import { TableColumn } from "react-data-table-component";
export interface IVehicleService {
    getMovementReport(): Promise<IVehicleMovementReport[]>;
    getParkedReport(): Promise<IVehicleParkedReport[]>;
    getIdleReport(): Promise<IVehicleIdleReport[]>;
    getOfflineReport(): Promise<IVehicleOfflineReport[]>;
    getNewdeviceReport(): Promise<IVehicleNewdeviceReport[]>;
    getWorkhourReport(): Promise<IVehicleWorkhourReport[]>;
    // single vehicle
    getMovementReportSingle(): Promise<IVehicleMovementReportSingle[]>;
    getParkedReportSingle(): Promise<IVehicleParkedReportSingle[]>;
    getIdleReportSingle(): Promise<IVehicleIdleReportSingle[]>;
    getWorkhourReportSingle(): Promise<IVehicleWorkhourReportSingle[]>;

}



// interface Location {
//     latitude: number;
//     longitude: number;
// }

// export interface IVehicleMovementReport {
//     vehicleNumber: string;
//     vehicleName: string;
//     IMEI: string;
//     startLocation: Location;
//     endLocation: Location;
//     maxSpeed: number;
//     avgSpeed: number;
//     movement: number;
//     offline: number;
//      idle: number;
//     parked: number;
//     maxParked: number;
//     distance: number;
//     totalTime: number;
//    [ key: string]:string|number|Location;
// }


// export const MovementDataColumn: TableColumn<IVehicleMovementReport>[] = [
//     { name: 'Vehicle Number', selector: (row: IVehicleMovementReport) => row.vehicleNumber ?? 'N/A', sortable: true },
//     { name: 'IMEI', selector: (row: IVehicleMovementReport) => row.IMEI ?? 'N/A', sortable: true },
//     { name: 'Vehicle Name', selector: (row: IVehicleMovementReport) => row.vehicleName ?? 'N/A', sortable: true },
//     { 
//         name: 'Start Location', 
//         selector: (row: IVehicleMovementReport) => 
//             row.startLocation ? `${row.startLocation.latitude ?? 'N/A'}, ${row.startLocation.longitude ?? 'N/A'}` : 'N/A', 
//         sortable: true 
//     },
//     { 
//         name: 'End Location', 
//         selector: (row: IVehicleMovementReport) => 
//             row.endLocation ? `${row.endLocation.latitude ?? 'N/A'}, ${row.endLocation.longitude ?? 'N/A'}` : 'N/A', 
//         sortable: true 
//     },
//     { 
//         name: 'Max Speed', 
//         selector: (row: IVehicleMovementReport) => (row.maxSpeed ?? '0').toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Average Speed (km/h)', 
//         selector: (row: IVehicleMovementReport) => (row.avgSpeed ?? 0).toFixed(2), 
//         sortable: true 
//     },
//     { 
//         name: 'Movement (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.movement ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Offline (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.offline ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Idle (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.idle ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Parked (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.parked ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Max Parked (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.maxParked ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Distance (km)', 
//         selector: (row: IVehicleMovementReport) => (row.distance ?? 0).toString(), 
//         sortable: true 
//     },
//     { 
//         name: 'Total Time (Seconds)', 
//         selector: (row: IVehicleMovementReport) => (row.totalTime ?? 0).toString(), 
//         sortable: true 
//     },
// ];

interface Duration {
    idle: string;
    movement: string;
    offline: string;
    parked: string;
    total: string;
  }
  
 export interface IVehicleMovementReport {
    result: string;
    table: number;
    distance: number;
    duration: Duration;
    startLat: number;
    startLong: number;
    startOdometerReading: string;
    startTime: string;
    stopLat: number;
    stopLong: number;
    stopOdometerReading: string;
    stopTime: string;
    vehicleNumber: string;
    imei: string;
    companyName: string;
    vehicleName: string;
    maxSpeed: number;
    avgSpeed: number;
    [key: string]:string|number|object
  }

  export const MovementDataColumn: TableColumn<IVehicleMovementReport>[] = [
    { name: 'Vehicle Number', selector: row => row.vehicleNumber, sortable: true },
    { name: 'Vehicle Name', selector: row => row.vehicleName, sortable: true },
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'IMEI', selector: row => row.imei, sortable: true },
    { name: 'Max Speed (km/h)', selector: row => row.maxSpeed, sortable: true },
    { name: 'Avg Speed (km/h)', selector: row => row.avgSpeed, sortable: true },
    { name: 'Distance (km)', selector: row => row.distance, sortable: true },
    { name: 'Duration Total', selector: row => row.duration.total },
    { name: 'Idle Time', selector: row => row.duration.idle },
    { name: 'Movement Time', selector: row => row.duration.movement },
    { name: 'Offline Time', selector: row => row.duration.offline },
    { name: 'Parked Time', selector: row => row.duration.parked },
    { name: 'Start Latitude', selector: row => row.startLat },
    { name: 'Start Longitude', selector: row => row.startLong },
    { name: 'Start Odometer', selector: row => row.startOdometerReading },
    { name: 'Start Time', selector: row => new Date(row.startTime).toLocaleString() },
    { name: 'Stop Latitude', selector: row => row.stopLat },
    { name: 'Stop Longitude', selector: row => row.stopLong },
    { name: 'Stop Odometer', selector: row => row.stopOdometerReading },
    { name: 'Stop Time', selector: row => new Date(row.stopTime).toLocaleString() }
  ];
  




// Parked report
// export type IVehicleParkedReport = {
//     company: string;
//     branch: string;
//     vehicleName: string;
//     vehicleBrand: string;
//     vehicleModel: string;
//     driver: string;
//     imeiNumber: number;
//     parked: string;
//     maxparked: string;
//     overstay: number;
   
// };
export interface IVehicleParkedReport {
    imei: number;
    vehicleName: string;
    vehicleNumber: string;
    companyName: string;
    duration: {
        maxParked: string;
        parked: string;
        total: string;
    };
    [key:string]:string|object|number;
}
export const ParkedDataColumn: TableColumn<IVehicleParkedReport>[] = [
    { name: 'IMEI', selector: (row) => row.imei ?? '-', sortable: true },
    { name: 'Vehicle Name', selector: (row) => row.vehicleName ?? '-', sortable: true },
    { name: 'Vehicle Number', selector: (row) => row.vehicleNumber ?? '-', sortable: true },
    { name: 'Company Name', selector: (row) => row.companyName ?? '-', sortable: true },
    {
        name: 'Max Parked Duration',
        selector: (row) => row.duration?.maxParked ?? '-',
        sortable: true
    },
    {
        name: 'Parked Duration',
        selector: (row) => row.duration?.parked ?? '-',
        sortable: true
    },
    {
        name: 'Total Duration',
        selector: (row) => row.duration?.total ?? '-',
        sortable: true
    }
];

// export const ParkedDataColumn:TableColumn<IVehicleParkedReport>[] = [
//     { name: 'Company', selector: (row: IVehicleParkedReport) => row.company, sortable: true },
//     { name: 'Branch', selector: (row: IVehicleParkedReport) => row.branch, sortable: true },
//     { name: 'Vehicle Name', selector: (row: IVehicleParkedReport) => row.vehicleName, sortable: true },
//     { name: 'Vehicle Brand', selector: (row: IVehicleParkedReport) => row.vehicleBrand, sortable: true },
//     { name: 'Vehicle Model', selector: (row: IVehicleParkedReport) => row.vehicleModel, sortable: true },
//     { name: 'Driver', selector: (row: IVehicleParkedReport) => row.driver, sortable: true },
//     { name: 'IMEI Number', selector: (row: IVehicleParkedReport) => row.imeiNumber, sortable: true },
//     { name: 'Parked', selector: (row: IVehicleParkedReport) => row.parked, sortable: true },
//     { name: 'Max Parked', selector: (row: IVehicleParkedReport) => row.maxparked, sortable: true },
//     { name: 'Overstay', selector: (row: IVehicleParkedReport) => row.overstay, sortable: true },
   
// ];

// interface for idele report 
// export type IVehicleIdleReport = {
//     id: number;
//     company: string;
//     branch: string;
//     vehicleName: string;
//     vehicleBrand: string;
//     vehicleModel: string;
//     driver: string;
//     imeiNumber: number;
//     idle: string;
//     longestidle: string;
//     averageidle: string;
// };
// export const IdledDataColumn:TableColumn<IVehicleIdleReport>[] = [
//     { name: 'Id', selector: (row: IVehicleIdleReport) => row.id, sortable: true },
//     { name: 'Company', selector: (row: IVehicleIdleReport) => row.company, sortable: true },
//     { name: 'Branch', selector: (row: IVehicleIdleReport) => row.branch, sortable: true },
//     { name: 'Vehicle Name', selector: (row: IVehicleIdleReport) => row.vehicleName, sortable: true },
//     { name: 'Vehicle Brand', selector: (row: IVehicleIdleReport) => row.vehicleBrand, sortable: true },
//     { name: 'Vehicle Model', selector: (row: IVehicleIdleReport) => row.vehicleModel, sortable: true },
//     { name: 'Driver', selector: (row: IVehicleIdleReport) => row.driver, sortable: true },
//     { name: 'IMEI Number', selector: (row: IVehicleIdleReport) => row.imeiNumber, sortable: true },
//     { name: 'Idle', selector: (row: IVehicleIdleReport) => row.idle, sortable: true },
//     { name: 'Longest Idle', selector: (row: IVehicleIdleReport) => row.longestidle, sortable: true },
//     { name: 'Average Idle', selector: (row: IVehicleIdleReport) => row.averageidle, sortable: true },
   
// ];
interface IdleDuration {
    avgIdle: string;
    maxIdle: string;
    idle: string;
  }
  
 export interface IVehicleIdleReport {
    imei: string;
    vehicleName: string;
    vehicleNumber: string;
    companyName: string;
    duration: IdleDuration;
    [key:string]: string|number|object;
  }

 export  const IdledDataColumn: TableColumn<IVehicleIdleReport>[] = [
    { name: 'IMEI', selector: row => row.imei, sortable: true },
    { name: 'Vehicle Name', selector: row => row.vehicleName, sortable: true },
    { name: 'Vehicle Number', selector: row => row.vehicleNumber, sortable: true },
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'Average Idle Time', selector: row => row.duration.avgIdle },
    { name: 'Max Idle Time', selector: row => row.duration.maxIdle },
    { name: 'Idle Duration', selector: row => row.duration.idle }
  ];

// export interface IVehicleIdleReport {
//     imei: string;
//     vehicleName: string;
//     vehicleNumber: string;
//     companyName: string;
//     duration: Record<string,number>;
//     [key: string]: string|Record<string,number>;
// }

// export const IdledDataColumn: TableColumn<IVehicleIdleReport>[] = [
//     {
//         name: 'IMEI',
//         selector: (row: IVehicleIdleReport) => row.imei ?? '-',
//         sortable: true,
//     },
//     {
//         name: 'Vehicle Name',
//         selector: (row: IVehicleIdleReport) => row.vehicleName ?? '-',
//         sortable: true,
//     },
//     {
//         name: 'Vehicle Number',
//         selector: (row: IVehicleIdleReport) => row.vehicleNumber ?? '-',
//         sortable: true,
//     },
//     {
//         name: 'Company Name',
//         selector: (row: IVehicleIdleReport) => row.companyName ?? '-',
//         sortable: true,
//     },
//     {
//         name: 'Duration', 
//         selector: (row: IVehicleIdleReport) => row.duration ? `No Data`:'noData',
//         sortable: false,
//     },
// ];



// Ivehicle offlie report 
// export type IVehicleOfflineReport = {
//     id: number;
//     company: string;
//     branch: string;
//     vehicleName: string;
//     vehicleBrand: string;
//     vehicleModel: string;
//     driver: string;
//     imeiNumber: number;
//     noofftimesinactiv: string;
//     inactiveduration: string;
//     duration: string;
// };

// export const OfflineDataColumn:TableColumn<IVehicleOfflineReport>[] = [
//     { name: 'Id', selector: (row: IVehicleOfflineReport) => row.id, sortable: true },
//     { name: 'Company', selector: (row: IVehicleOfflineReport) => row.company, sortable: true },
//     { name: 'Branch', selector: (row: IVehicleOfflineReport) => row.branch, sortable: true },
//     { name: 'Vehicle Name', selector: (row: IVehicleOfflineReport) => row.vehicleName, sortable: true },
//     { name: 'Vehicle Brand', selector: (row: IVehicleOfflineReport) => row.vehicleBrand, sortable: true },
//     { name: 'Vehicle Model', selector: (row: IVehicleOfflineReport) => row.vehicleModel, sortable: true },
//     { name: 'Driver', selector: (row: IVehicleOfflineReport) => row.driver, sortable: true },
//     { name: 'IMEI Number', selector: (row: IVehicleOfflineReport) => row.imeiNumber, sortable: true },
//     { name: 'No Off Times Inactive', selector: (row: IVehicleOfflineReport) => row.noofftimesinactiv, sortable: true },
//     { name: 'Inactive Duration', selector: (row: IVehicleOfflineReport) => row.inactiveduration, sortable: true },
//     { name: 'Duration', selector: (row: IVehicleOfflineReport) => row.duration, sortable: true }, 
// ];
export interface IofflineDuration {
    total: string;
    offline: string;
  }
  
  export interface IVehicleOfflineReport {
    imei: string;
    vehicleName: string;
    vehicleNumber: string;
    companyName: string;
    noOfTimes: number;
    duration: IofflineDuration;
    [key: string]: string | object|number;
  }

  export const OfflineDataColumn: TableColumn<IVehicleOfflineReport>[] = [
    { name: 'IMEI', selector: row => row.imei, sortable: true },
    { name: 'Vehicle Name', selector: row => row.vehicleName, sortable: true },
    { name: 'Vehicle Number', selector: row => row.vehicleNumber, sortable: true },
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'No. of Times', selector: row => row.noOfTimes, sortable: true },
    { name: 'Total Duration', selector: row => row.duration.total },
    { name: 'Offline Duration', selector: row => row.duration.offline }
  ];
// export interface IVehicleOfflineReport {
//     imei: string;
//     vehicleName: string;
//     vehicleNumber: string;
//     companyName: string;
//     noOfTimes: number;
//     duration: {
//         total: string;
//         offline: string;
//     };
//     [key: string]: string | object|number;
// }

// export const OfflineDataColumn: TableColumn<IVehicleOfflineReport>[] = [
//     { name: 'IMEI', selector: (row) => row.imei ?? '-', sortable: true },
//     { name: 'Vehicle Name', selector: (row) => row.vehicleName ?? '-', sortable: true },
//     { name: 'Vehicle Number', selector: (row) => row.vehicleNumber ?? '-', sortable: true },
//     { name: 'Company Name', selector: (row) => row.companyName ?? '-', sortable: true },
//     {
//         name: 'Number of Times Offline',
//         selector: (row) => row.noOfTimes !== undefined ? row.noOfTimes.toString() : '-',
//         sortable: true
//     },
//     {
//         name: 'Total Duration',
//         selector: (row) => row.duration?.total ?? '-',
//         sortable: true
//     },
//     {
//         name: 'Offline Duration',
//         selector: (row) => row.duration?.offline ?? '-',
//         sortable: true
//     }
// ];


// new device 
export type IVehicleNewdeviceReport = {
    id: number;
    company: string;
    branch: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    imeiNumber: number;
    created_at: string;
};

export const NewdeviceDataColumn:TableColumn<IVehicleNewdeviceReport>[] = [
    { name: 'Id', selector: (row: IVehicleNewdeviceReport) => row.id, sortable: true },
    { name: 'Company', selector: (row: IVehicleNewdeviceReport) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IVehicleNewdeviceReport) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IVehicleNewdeviceReport) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IVehicleNewdeviceReport) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IVehicleNewdeviceReport) => row.vehicleModel, sortable: true },
    { name: 'IMEI Number', selector: (row: IVehicleNewdeviceReport) => row.imeiNumber, sortable: true },
    { name: 'Created_at', selector: (row: IVehicleNewdeviceReport) => row.created_at, sortable: true },
    ];

// work hour
export  interface dataWorkhour {
    total:string;
}

  export  interface IVehicleWorkhourReport {
    distance: number ;
    duration: dataWorkhour;
    companyName: string;
    imei: string;
    vehicleName: string;
    vehicleNumber: string;
    [key: string]: string | object|number;
  }

   export const WorkhourDataColumn: TableColumn<IVehicleWorkhourReport>[] = [
    { name: 'Distance', selector: row => row.distance ?? 'N/A', sortable: true },
    { name: 'Total Duration', selector: row => row.duration.total, sortable: true },
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'IMEI', selector: row => row.imei, sortable: true },
    { name: 'Vehicle Name', selector: row => row.vehicleName, sortable: true },
    { name: 'Vehicle Number', selector: row => row.vehicleNumber, sortable: true }
  ];


export interface IVehicleWorkhourReportforapiresponce {
    data: Array<{
        distance: number | null;
        duration: {
            total: string;
        };
        companyName: string;
        imei: string;
        vehicleName: string;
        vehicleNumber: string;
    }>;
    currentPage: number;
    totalPages: number;
    totalItems: number;
}
// export const WorkhourDataColumn: TableColumn<IVehicleWorkhourReport['data'][number]>[] = [
//     { name: 'Company Name', selector: (row) => row.companyName, sortable: true },
//     { name: 'IMEI', selector: (row) => row.imei, sortable: true },
//     { name: 'Vehicle Name', selector: (row) => row.vehicleName, sortable: true },
//     { name: 'Vehicle Number', selector: (row) => row.vehicleNumber, sortable: true },
//     { 
//         name: 'Distance', 
//         selector: (row) => row.distance !== null ? row.distance : 'N/A', 
//         sortable: true 
//     },
//     { 
//         name: 'Duration', 
//         selector: (row) => row.duration.total, 
//         sortable: true 
//     }
// ]
// particuler Vehicle reports
export type IVehicleMovementReportSingle = {
    id: number;
    company: string;
    branch: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imeiNumber: number;
    startLocation: string;
    coordinates: string;
    distance: number;
    maxSpeed: number;
    avgSpeed: number;
    type: number;
    duration: number;
    start:string;
    endLocation: string;

};

export const MovementDataColumnSingle: TableColumn<IVehicleMovementReportSingle>[] = [
    { name: 'ID', selector: (row: IVehicleMovementReportSingle) => row.id, sortable: true },
    { name: 'Company', selector: (row: IVehicleMovementReportSingle) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IVehicleMovementReportSingle) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IVehicleMovementReportSingle) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IVehicleMovementReportSingle) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IVehicleMovementReportSingle) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IVehicleMovementReportSingle) => row.driver, sortable: true },
    { name: 'IMEI Number', selector: (row: IVehicleMovementReportSingle) => row.imeiNumber, sortable: true },
    { name: 'Start Location', selector: (row: IVehicleMovementReportSingle) => row.startLocation, sortable: true },
    { name: 'Coordinates', selector: (row: IVehicleMovementReportSingle) => row.coordinates, sortable: true },
    { name: 'Distance (km)', selector: (row: IVehicleMovementReportSingle) => row.distance, sortable: true },
    { name: 'Max Speed (km/h)', selector: (row: IVehicleMovementReportSingle) => row.maxSpeed, sortable: true },
    { name: 'Avg Speed (km/h)', selector: (row: IVehicleMovementReportSingle) => row.avgSpeed, sortable: true },
    { name: 'Type', selector: (row: IVehicleMovementReportSingle) => row.type, sortable: true },
    { name: 'Duration (min)', selector: (row: IVehicleMovementReportSingle) => row.duration, sortable: true },
    { name: 'Start Time', selector: (row: IVehicleMovementReportSingle) => row.start, sortable: true },
    { name: 'End Location', selector: (row: IVehicleMovementReportSingle) => row.endLocation, sortable: true },
];

// Parked report
export type IVehicleParkedReportSingle = {
    company: string;
    branch: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imeiNumber: number;
    arrival: string;
    startLocation: string;
    coordinates: string;
    parked:string;
    departure: string;
    endLocation: string;
    overstay: string;
};

export const ParkedDataColumnSingle: TableColumn<IVehicleParkedReportSingle>[] = [
    { name: 'Company', selector: (row: IVehicleParkedReportSingle) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IVehicleParkedReportSingle) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IVehicleParkedReportSingle) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IVehicleParkedReportSingle) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IVehicleParkedReportSingle) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IVehicleParkedReportSingle) => row.driver, sortable: true },
    { name: 'IMEI Number', selector: (row: IVehicleParkedReportSingle) => row.imeiNumber, sortable: true },
    { name: 'Arrival Time', selector: (row: IVehicleParkedReportSingle) => row.arrival, sortable: true },
    { name: 'Start Location', selector: (row: IVehicleParkedReportSingle) => row.startLocation, sortable: true },
    { name: 'Coordinates', selector: (row: IVehicleParkedReportSingle) => row.coordinates, sortable: true },
    { name: 'Parked Time', selector: (row: IVehicleParkedReportSingle) => row.parked, sortable: true },
    { name: 'Departure Time', selector: (row: IVehicleParkedReportSingle) => row.departure, sortable: true },
    { name: 'End Location', selector: (row: IVehicleParkedReportSingle) => row.endLocation, sortable: true },
    { name: 'Overstay Duration', selector: (row: IVehicleParkedReportSingle) => row.overstay, sortable: true },
];

// interface for idele report 
export type IVehicleIdleReportSingle = {
    id: number;
    company: string;
    branch: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imeiNumber: number;
    startTime: string;
    startLocation: string;
    coordinates: string;
    idle: string;
    endTime: string;
    endLocation: string;
};

export const IdledDataColumnSingle: TableColumn<IVehicleIdleReportSingle>[] = [
    { name: 'ID', selector: (row: IVehicleIdleReportSingle) => row.id, sortable: true },
    { name: 'Company', selector: (row: IVehicleIdleReportSingle) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IVehicleIdleReportSingle) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IVehicleIdleReportSingle) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IVehicleIdleReportSingle) => row.vehicleBrand, sortable: true },
    { name: 'Vehicle Model', selector: (row: IVehicleIdleReportSingle) => row.vehicleModel, sortable: true },
    { name: 'Driver', selector: (row: IVehicleIdleReportSingle) => row.driver, sortable: true },
    { name: 'IMEI Number', selector: (row: IVehicleIdleReportSingle) => row.imeiNumber, sortable: true },
    { name: 'Start Time', selector: (row: IVehicleIdleReportSingle) => row.startTime, sortable: true },
    { name: 'Start Location', selector: (row: IVehicleIdleReportSingle) => row.startLocation, sortable: true },
    { name: 'Coordinates', selector: (row: IVehicleIdleReportSingle) => row.coordinates, sortable: true },
    { name: 'Idle Duration', selector: (row: IVehicleIdleReportSingle) => row.idle, sortable: true },
    { name: 'End Time', selector: (row: IVehicleIdleReportSingle) => row.endTime, sortable: true },
    { name: 'End Location', selector: (row: IVehicleIdleReportSingle) => row.endLocation, sortable: true },
];





// work hour
export type IVehicleWorkhourReportSingle = {
    id: number;
   company: string;
    branch: string;
    vehicleName: string;
    vehicleBrand: string;
    vehicleModel: string;
    driver: string;
    imeiNumber: number;
    startLocation: string;
    coordinates: string;
    distance: number;
    duration: string;
    start: string;
    end: string;
    endLocation: string;
};



export const WorkhourDataColumnSingle: TableColumn<IVehicleWorkhourReportSingle>[] = [
    { name: 'ID', selector: (row: IVehicleWorkhourReportSingle) => row.id, sortable: true },
    { name: 'Company', selector: (row: IVehicleWorkhourReportSingle) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IVehicleWorkhourReportSingle) => row.branch, sortable: true },
    { name: 'Vehicle Name', selector: (row: IVehicleWorkhourReportSingle) => row.vehicleName, sortable: true },
    { name: 'Vehicle Brand', selector: (row: IVehicleWorkhourReportSingle) => row.vehicleBrand, sortable: true },
    { name: 'Driver', selector: (row: IVehicleWorkhourReportSingle) => row.driver, sortable: true },
    { name: 'IMEI Number', selector: (row: IVehicleWorkhourReportSingle) => row.imeiNumber, sortable: true },
    { name: 'Start Location', selector: (row: IVehicleWorkhourReportSingle) => row.startLocation, sortable: true },
    { name: 'Coordinates', selector: (row: IVehicleWorkhourReportSingle) => row.coordinates, sortable: true },
    { name: 'Distance (km)', selector: (row: IVehicleWorkhourReportSingle) => row.distance, sortable: true },
    { name: 'Duration', selector: (row: IVehicleWorkhourReportSingle) => row.duration, sortable: true },
    { name: 'Start Time', selector: (row: IVehicleWorkhourReportSingle) => row.start, sortable: true },
    { name: 'End Time', selector: (row: IVehicleWorkhourReportSingle) => row.end, sortable: true },
    { name: 'End Location', selector: (row: IVehicleWorkhourReportSingle) => row.endLocation, sortable: true },
];