import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useHttp from '../../../../../hooks/useHttp';

interface IDeviceFormValue {
  roleId: string;
  email: string;
  password: string;
}

interface IErrorData {
  message?: string;
}

interface IResponse {
  ok: boolean;
  json: () => Promise<IDeviceFormValue>;
}

const CompanyUserCurdFormUI: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditing = location.pathname.includes('edit');
  const isCreating = location.pathname.includes('create');
  const isViewing = location.pathname.includes('view');
  const readOnlyCondition = isViewing;
  const [initialValues, setInitialValues] = useState<IDeviceFormValue | null>(null);
  const { request } = useHttp();
  const numericId = Number(id);

  const validationSchema = Yup.object({
    roleId: Yup.string().required('Role ID is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  useEffect(() => {
    if (isCreating) {
      setInitialValues({
        roleId: '',
        email: '',
        password: '',
      });
    } else if (numericId && !isCreating) {
      const fetchData = async () => {
        try {
          const response: IResponse = await request({ url: `/device-type/${numericId}` });
          if (!response.ok) {
            const errorData = (await response.json()) as IErrorData;
            throw new Error(errorData.message || 'Failed to fetch data');
          }
          const data = await response.json();
          setInitialValues(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      void fetchData();
    }
  }, [isCreating, numericId, request]);

  const handleSubmit = async (values: IDeviceFormValue) => {
    try {
      let response: IResponse | undefined;

      if (isCreating) {
        response = await request({
          url: '/add-company-user',
          method: 'POST',
          body: values,
          headers: { 'Content-Type': 'application/json' },
        });
      } else if (isEditing && numericId) {
        response = await request({
          url: `/device-type/${numericId}`,
          method: 'PUT',
          body: JSON.stringify(values),
          headers: { 'Content-Type': 'application/json' },
        });
      }

      if (!response || !response.ok) {
        const errorData = (await response!.json()) as IErrorData;
        throw new Error(errorData.message || 'Something went wrong');
      }

      alert(isCreating ? 'Company user added successfully' : 'Company user updated successfully');
      navigate('/companyUser');
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error submitting form:', error);
        alert(`Error: ${error.message}`);
      } else {
        console.error('Unexpected error:', error);
        alert('An unexpected error occurred.');
      }
    }
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-4 text-center">
        {isCreating ? 'Create New User' : isEditing ? 'Edit User' : 'View User'}
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isValid }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Role ID Field */}
            <div className="mb-4">
              <label htmlFor="roleId" className="block text-gray-700 text-sm font-bold mb-2">
                Role ID
              </label>
              <Field
                name="roleId"
                as="select"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                disabled={readOnlyCondition}
              >
                <option value="">Select Role</option>
                <option value="4">Driver</option>
                <option value="5">Other</option>
                {/* Add other role options here */}
              </Field>
              <ErrorMessage name="roleId" component="div" className="text-red-500 text-sm mt-2" />
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                Email
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                readOnly={readOnlyCondition}
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-2" />
            </div>

            {/* Password Field */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                readOnly={readOnlyCondition}
              />
              <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-2" />
            </div>

            {/* Submit Button */}
            {!readOnlyCondition && (
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyUserCurdFormUI;
