import React, { useState, useEffect } from 'react';
import SingleSelectDropdown from '../../../components/SingleSelectDropdown';
import Button from '../../../components/Button';
import DatePicker from 'react-datepicker';
import Dropdown from '../../../components/Dropdown';
import { IViolations, IViolationsDataColumn } from '../../../services/interfaces/IViolation';
import { createViolationService } from '../../../services/ServiceFactory';
import Table from '../../../components/table/Table';
function GeofenceViolations() {
    const options = [
        { value: 'KA-05-2001', label: 'KA-05-2001' },
        { value: 'KA-06-2002', label: 'KA-06-2002' },
        { value: 'KA-04-2003', label: 'KA-04-2003' }
    ];
    const option1 = [
        { value: 'in-area', label: 'In Area' },
        { value: 'out-area', label: 'Out Area' },
        { value: 'parked-area', label: 'Parked Area' }
    ];

    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [geofenceType, setGeofenceType] = useState('');
    const [data, setData] = useState<IViolations[]>([]);
    const ViolationService = createViolationService();

    const handleSelect = (value: string | null) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await ViolationService.getViolation();
            setData(response);
          } catch (error) {
            if (error instanceof Error) {
              console.log(error.message);
            } else {
              console.log('An unknown error occurred');
            }
          }
        };
    
        void fetchData();
      }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simple validation logic
        if (!startDate ||
             !endDate ||
             !selectedValue ||
             !geofenceType
            ) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            selectedValue: selectedValue,
            geofenceType: geofenceType,
            startDate: startDate,
            endDate: endDate
        });
    };
    return (
        <div className='w-full'>
            <div className='w-full h-[200px] rounded-md bg-white'>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <h1 className='text-blue-950  p-2 ml-2 '>Geo Fence Violation</h1>
                <form onSubmit={handleSubmit}>
                    <div className='flex items-start lg:flex-row flex-col  justify-between p-2 ml-2'>
                        <div className='flex items-center lg:gap-6 gap-2'>
                            <div className=' mt-1'>
                                <div className='mb-1'>
                                    <label className='text-black font-semibold text-sm' htmlFor=''>
                                        Select Bus
                                    </label>
                                </div>
                                <SingleSelectDropdown
                                    options={options}
                                    selectedValue={selectedValue}
                                    onSelect={handleSelect}
                                    placeholder='Select Bus'
                                    width='180px'
                                />
                            </div>

                            <div className='mt-1.5'>
                                <div className='text-sm mb-1 font-semibold'>
                                    <label htmlFor='designation'>Select Geofence Type</label>
                                </div>
                                <Dropdown
                                    options={option1}
                                    onSelect={selectedValue => setGeofenceType(selectedValue)}
                                    selectedValue={geofenceType}
                                    placeholder='Geofence Type'
                                    selectClass='rounded-none h-[33px] lg:w-[200px] w-[130px]'
                                    selectStyle={{
                                        backgroundColor: 'white',
                                        fontSize: '15px', // Decrease the font size
                                        padding: '5px' // Adjust padding to make it smaller
                                    }}
                                    optionClass='my-custom-option-class'
                                    optionStyle={{
                                        fontSize: '15px', // Decrease the font size of options
                                        padding: '8px' // Adjust padding of options
                                    }}
                                />
                            </div>

                            <div>
                                <div>
                                    <label htmlFor='' className='text-black font-semibold text-sm'>
                                        From
                                    </label>
                                </div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => setStartDate(date)}
                                    showTimeSelect
                                    dateFormat='Pp'
                                    placeholderText='mm-dd-yyyy  --:--'
                                    className='mt-1 p-1 block w-full border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-center'
                                />
                            </div>
                            <div>
                                <div>
                                    <label htmlFor='' className='text-black font-semibold text-sm'>
                                        To
                                    </label>
                                </div>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => setEndDate(date)}
                                    showTimeSelect
                                    dateFormat='Pp'
                                    placeholderText='mm-dd-yyyy  --:--'
                                    className='mt-1 p-1 block w-full border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-center'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4 p-4'>
                        <Button type='submit' className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[32px] rounded-md'>
                            Check
                        </Button>
                        <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[32px] rounded-md'>
                            Clear
                        </Button>
                    </div>
                </form>
            </div>
            <Table excelExport={true} pdfExport={true} searchAble={true} columns={IViolationsDataColumn} data={data} />
        </div>
    );
}

export default GeofenceViolations;
