import React, { useState, useEffect } from 'react';
import Button from '../../../../../components/Button';
import InputField from '../../../../../components/InputFeild';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';
import Reminder from './components/Reminder';
import Input from '../../../../../components/Input';
import { Link } from 'react-router-dom';

interface DocumentEntry {
    id: number;
}

interface driverdocument {
    documentAction: string;
    documentName: string;
    issueDate: string;
    expireDate: string;
}

interface DriverDocumentsProps {
    data: driverdocument;
}

function DriverDocuments({ data }: DriverDocumentsProps) {
    const [documents, setDocuments] = useState<DocumentEntry[]>([{ id: 0 }]);
    const [nextId, setNextId] = useState(1);
    const [showReminder, setShowReminder] = useState(false); // State for showing reminder
    const [documentName, setDocumentName] = useState('');
    const [issueDate, setIssueDate] = useState<Date | null>(null);
    const [expireDate, setExpireDate] = useState<Date | null>(null);
    const [action, setAction] = useState('');

    const handleAddMore = () => {
        setDocuments([...documents, { id: nextId }]);
        setNextId(nextId + 1);
    };

    const handleDelete = (id: number) => {
        setDocuments(documents.filter(doc => doc.id !== id));
    };

    const openReminder = () => setShowReminder(true); // Function to show reminder
    const closeReminder = () => setShowReminder(false); // Function to hide reminder

    useEffect(() => {
        if (data) {
            setAction(data.documentAction);
            setDocumentName(data.documentName);
            setIssueDate(data.issueDate ? new Date(data.issueDate) : null);
            setExpireDate(data.expireDate ? new Date(data.expireDate) : null);
        } else {
            setAction('');
            setDocumentName('');
            setIssueDate(null);
            setExpireDate(null);
        }
    }, [data]);

    const handleIssueDate = (date: Date | null) => {
        setIssueDate(date);
    };

    const handleExpireDate = (date: Date | null) => {
        setExpireDate(date);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simple validation logic
        if (!documentName || !issueDate || !expireDate) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            documentName: documentName,
            issueDate: issueDate,
            expireDate: expireDate
        });
    };

    return (
        <div>
            <div className='mt-2'>
                <h1 className='text-2xl'>Document Information</h1>
            </div>
            <div>
                <Button
                    onClick={handleAddMore}
                    className='bg-white border border-black text-black w-[90px] h-[35px] rounded-md mt-3'
                >
                    Add More
                </Button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 mt-3 lg:ml-5 ml-2'>
                    <div className='grid grid-cols-2 font-bold gap-3'>
                        <p>Document Name</p>
                        <p>Upload Document</p>
                    </div>
                    <div className='grid grid-cols-3 font-bold gap-3'>
                        <p>Issue Date</p>
                        <p>Expiry Date</p>
                        <p>Actions</p>
                    </div>
                </div>

                {documents.map(doc => (
                    <div key={doc.id} className='grid grid-cols-2 mt-3 lg:ml-5 ml-2'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <InputField
                                    type='text'
                                    id='documentname'
                                    className='block w-full p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    value={documentName}
                                    onChange={e => setDocumentName(e.target.value)}
                                    disabled={action === 'view'}
                                />
                            </div>
                            <div>
                                <input type='file' />
                            </div>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                            <div>
                                <Input
                                    type='date'
                                    id='issuedate'
                                    value={issueDate}
                                    onChange={e => (action === 'view' ? undefined : handleIssueDate(e as Date | null))}
                                    placeholder='Date of Joining:'
                                    error={false}
                                    className='border border-gray-300 px-3 py-1.5'
                                />
                            </div>
                            <div>
                                <Input
                                    type='date'
                                    id='expiredate'
                                    value={expireDate}
                                    onChange={e => (action === 'view' ? undefined : handleExpireDate(e as Date | null))}
                                    placeholder='Date of Joining:'
                                    error={false}
                                    className='border border-gray-300 px-3 py-1.5'
                                />
                            </div>
                            <div className='flex items-center lg:gap-2 gap-0'>
                                <Button onClick={openReminder}>
                                    <NotificationsActiveIcon /> Reminder
                                </Button>
                                <Button
                                    onClick={() => handleDelete(doc.id)}
                                    className='bg-red-500 text-white rounded-md py-1 px-2 lg:mb-0 mb-6'
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}

                <div className='bg-white h-16 mt-4'>
                    <div className='bg-blue-700 h-[2px] rounded-tr-lg rounded-tl-lg'></div>
                    <div className='flex gap-4 justify-end mt-4 mr-2'>
                        <Link to={'/company/settings/master/driver'}>
                        <Button
                            type='button'
                            className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                        >
                            Back
                        </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>

            {/* Conditionally render the Reminder component */}
            {showReminder && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
                    <Reminder onClose={closeReminder} />
                </div>
            )}
        </div>
    );
}

export default DriverDocuments;
