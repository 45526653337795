import { IAllGeofance } from "../interfaces/IAllGeofanceService";
export const geofencesdata: IAllGeofance[] = [
    {
      id: 1,
      geofanceName: "City Center",
      geofancetype: "Polygon",
      created_at: "2024-08-20T10:30:00Z",
      updated_at: "2024-08-22T12:00:00Z"
    },
    {
      id: 2,
      geofanceName: "Warehouse Area",
      geofancetype: "Rectangle",
      created_at: "2024-08-21T09:15:00Z",
      updated_at: "2024-08-22T11:45:00Z"
    },
    {
      id: 3,
      geofanceName: "East Side Park",
      geofancetype: "Circle",
      created_at: "2024-08-22T08:00:00Z",
      updated_at: "2024-08-22T08:30:00Z"
    },
    {
      id: 4,
      geofanceName: "Downtown Perimeter",
      geofancetype: "Polygon",
      created_at: "2024-08-20T15:45:00Z",
      updated_at: "2024-08-23T10:15:00Z"
    },
    {
      id: 5,
      geofanceName: "School Zone",
      geofancetype: "Rectangle",
      created_at: "2024-08-21T14:00:00Z",
      updated_at: "2024-08-23T09:00:00Z"
    },
    {
      id: 6,
      geofanceName: "Industrial Area",
      geofancetype: "Polygon",
      created_at: "2024-08-19T13:25:00Z",
      updated_at: "2024-08-24T16:20:00Z"
    },
    {
      id: 7,
      geofanceName: "Shopping Mall",
      geofancetype: "Circle",
      created_at: "2024-08-20T11:40:00Z",
      updated_at: "2024-08-21T08:50:00Z"
    },
    {
      id: 8,
      geofanceName: "Airport",
      geofancetype: "Polygon",
      created_at: "2024-08-18T07:30:00Z",
      updated_at: "2024-08-22T13:00:00Z"
    },
    {
      id: 9,
      geofanceName: "Residential Area",
      geofancetype: "Rectangle",
      created_at: "2024-08-22T12:15:00Z",
      updated_at: "2024-08-23T11:10:00Z"
    },
    {
      id: 10,
      geofanceName: "Sports Complex",
      geofancetype: "Circle",
      created_at: "2024-08-21T10:55:00Z",
      updated_at: "2024-08-24T14:45:00Z"
    }
  ];
  