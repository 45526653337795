import React from 'react';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
    label?: string;
    className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ label = 'Back', className }) => {
    const navigate = useNavigate();
    const buttonclass =
        'bg-cyan-950 text-white px-2 h-8 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50';
    return (
        <button onClick={() => navigate(-1)} className={`${buttonclass} ${className}`} type='button'>
            {label}
        </button>
    );
};

export default BackButton;
