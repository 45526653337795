import { TableColumn } from "react-data-table-component";

export interface IManageCompanyService {
    getCompanyedata(id: number): Promise<FormValues[]>;
}
export type FormValues = {
    id: number;
    company_name: string;
    email: string;
    user_mobile: string;
    address: string;
    latitude: string;
    longitude: string;
};

export type ICompany = {
    id: number;
    name: string;
    email: string;
    phone: string;
    address: string;
    latitude: string;
    longitude: string;
};

export const CompanyDataColumns : TableColumn<ICompany>[] = [
    { name: 'Id', selector: (row: ICompany) => row.id, sortable: true },
    { name: 'Name', selector: (row: ICompany) => row.name, sortable: true },
    { name: 'Email', selector: (row: ICompany) =>row.email, sortable: true },
    { name: 'Phone', selector: (row: ICompany) => row.phone, sortable: true }
];
