import {IObjectService,IObjectAlertReport,IObjectAlertReportSingle}  from  "./interfaces/IObjectService";
import {objectAlertReportsData ,objectAlertReportSingles} from "./dummyData/ObjectAlertReportData";
export class ObjectAlertService implements IObjectService {
    
        getObjecteAlert(): Promise<IObjectAlertReport[]>{
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(objectAlertReportsData);
                }, 1000);
            });
        }
        getObjecteAlertSingle(): Promise<IObjectAlertReportSingle[]>{
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(objectAlertReportSingles);
                }, 1000);
            });
        }
       
    }
