import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import settings from '../Settings';
import useHttp from '../hooks/useHttp';

export interface IDeviceData {
    imei: string;
    latitude: number;
    longitude: number;
    speed: number;
    ignition: string;
    timestamp: string;
    companyId: number;
    isLive: boolean;
    model: string;
    vehicleNumber: string;
    course: string;
    device_battery: string;
    battery: string;
    moving?: string;
    temperature: string;
    odometer: string;
    state: string;
    color?: string;
    lastIdleTimestamp?: number;
}

export interface VehicleStatusResponse {
    message: string;
    data: {
        vehicles: VehicleStatus[];
        inactiveVehicles: InactiveVehicle[];
        vehicleStatus: VehicleStatusCounts;
    };
}

export interface InactiveVehicle {
    id: number;
    name: string;
    number: string;
    deviceId: number;
    companyId: number;
    userId: number | null;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface VehicleStatus {
    vehicle: Vehicle;
    gpsData?: GpsData;
    deviceStatus: string;
}

export interface Vehicle {
    id: number;
    name: string;
    number: string;
    deviceId: number;
    companyId: number;
    userId: number | null;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
    device: Device;
}

export interface Device {
    id: number;
    companyId: number;
    userId: number | null;
    deviceTypeId: number;
    imei: string;
    name: string;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface GpsData {
    imei: string;
    latitude: number;
    longitude: number;
    speed: number;
    ignition: string;
    timestamp: string;
    companyId: number;
    isLive: boolean;
    device_model: string;
    vehicleNumber: string;
    course: string;
    deviceBattery: string;
    battery: string;
    state: string;
    temperature: string;
    odometer: string;
    moving?: string;
    color?: string;
    lastIdleTimestamp?: number;
}

export interface VehicleStatusCounts {
    running: number;
    idle: number;
    parked: number;
    offline: number;
    newDevice: number;
    inactiveDevice: number;
    total: number;
}

interface LiveLocationContextType {
    locations: Record<string, IDeviceData>;
    statusCounts: VehicleStatusCounts;
}

const LiveLocationContext = createContext<LiveLocationContextType | undefined>(undefined);

export const MultitrackLocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { token } = useAuth();
    const [locations, setLocations] = useState<Record<string, IDeviceData>>({});
    const [statusCounts, setStatusCounts] = useState<VehicleStatusCounts>({
        running: 0,
        idle: 0,
        parked: 0,
        offline: 0,
        newDevice: 0,
        inactiveDevice: 0,
        total: 0
    });

    const { request } = useHttp();

    const fetchData = async () => {
        try {
            const response = await request({ url: '/multi-track' });
            if (!response.ok) throw new Error('Failed to fetch data');
            const data = (await response.json()) as VehicleStatusResponse;

            setLocations(
                data.data.vehicles.reduce((acc, vehicleStatus) => {       
                    acc[vehicleStatus.vehicle.device.imei] = {
                        imei: vehicleStatus.vehicle.device.imei,
                        latitude: vehicleStatus.gpsData?.latitude ?? 1234,
                        longitude: vehicleStatus.gpsData?.longitude ?? 1234,
                        speed: vehicleStatus.gpsData?.speed ?? 0,
                        ignition: vehicleStatus.gpsData?.ignition ?? 'Unknown',
                        timestamp: vehicleStatus.gpsData?.timestamp ?? 'Unknown',
                        companyId: vehicleStatus.vehicle.device.companyId,
                        isLive: vehicleStatus.gpsData?.isLive ?? false,
                        model: vehicleStatus.gpsData?.device_model ?? 'Unknown',
                        vehicleNumber: vehicleStatus.vehicle.number,
                        course: vehicleStatus.gpsData?.course ?? 'Unknown',
                        device_battery: vehicleStatus.gpsData?.deviceBattery ?? 'Unknown',
                        battery: vehicleStatus.gpsData?.battery ?? 'Unknown',
                        moving: vehicleStatus.deviceStatus,
                        temperature: vehicleStatus.gpsData?.temperature ?? 'Unknown',
                        odometer: vehicleStatus.gpsData?.odometer ?? 'Unknown',
                        state: (vehicleStatus.deviceStatus.toUpperCase()==='RUNNING')?'MOVING':vehicleStatus.deviceStatus.toUpperCase(),
                    };
                    return acc;
                }, {} as Record<string, IDeviceData>)
            );

            setStatusCounts(data.data.vehicleStatus);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!token) return;

        void fetchData();

        const socket = new WebSocket(`${settings.WS_API_BASE_PATH}?token=${token}`);

        const handleMessage = (event: MessageEvent) => {
            try {
                const locationData = JSON.parse(event.data as string) as IDeviceData;
                setLocations((prevLocations) => ({
                    ...prevLocations,
                    [locationData.imei]: locationData
                }));
            } catch (error) {
                console.error('Error parsing WebSocket message: ', error);
            }
        };

        socket.onmessage = handleMessage;
        socket.onopen = () => console.log('WebSocket connection established');
        socket.onerror = (error) => console.error('WebSocket error: ', error);
        socket.onclose = () => console.log('WebSocket connection closed');

        return () => {
            socket.close();
        };
    }, [token]);

    return (
        <LiveLocationContext.Provider value={{ locations, statusCounts }}>
            {children}
        </LiveLocationContext.Provider>
    );
};

export const useLiveLocation = () => {
    const context = useContext(LiveLocationContext);
    if (!context) {
        throw new Error('useLiveLocation must be used within a MultitrackLocationProvider');
    }
    return context;
};

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'MOVING':
            return 'green';
        case 'PARKED':
            return 'blue';
        case 'IDLE':
            return '#ffc107';
        case 'OFFLINE':
            return 'red';
        default:
            return 'black';
    }
};
