import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { PlaybackResponse, GpsData } from '../../../services/interfaces/IPlayback';
import useHttp from '../../../hooks/useHttp';
import ControlsContainer, { IDatefilter } from './ControlsContainer';
import { useParams } from 'react-router-dom';

// Define custom icon for marker (optional)
const customMarker = new L.Icon({
  iconUrl: 'https://example.com/custom-marker.png',
  iconSize: [30, 40],
});

const Playback: React.FC = () => {
  const [positions, setPositions] = useState<GpsData[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [playbackInterval, setPlaybackInterval] = useState<NodeJS.Timeout | null>(null);
  const { request } = useHttp();
  const { vehiclenumber, imei } = useParams<{ vehiclenumber: string; imei: string }>();

  const [dateRange, setDateRange] = useState<IDatefilter>({ fromDate: '', toDate: '' });

  const formdata = (fromselecteddate: IDatefilter) => {
    setDateRange(fromselecteddate);
  };

  useEffect(() => {
    const fetchPlaybackData = async (): Promise<void> => {
      if (!dateRange.fromDate || !dateRange.toDate || !imei) return;

      try {
        const response = await request({
          url: `/playback?imei=${imei}&startDate=${dateRange.fromDate}&stopDate=${dateRange.toDate}`,
          method: 'GET',
        });

        if (!response.ok) throw new Error('Network response was not ok');

        const data = (await response.json()) as PlaybackResponse;
        console.log('Fetched GPS data:', data.gpsData);

        setPositions(data.gpsData);
      } catch (error) {
        console.error('Error fetching playback data:', error);
      }
    };

    fetchPlaybackData().catch((error) => console.error(error));
  }, [dateRange, imei, request]);

  // Start playback animation
  const startPlayback = () => {
    if (positions.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < positions.length - 1) {
          return prevIndex + 1;
        } else {
          clearInterval(interval);
          return prevIndex;
        }
      });
    }, 1000);

    setPlaybackInterval(interval);
  };

  // Stop playback animation
  const stopPlayback = () => {
    if (playbackInterval) {
      clearInterval(playbackInterval);
      setPlaybackInterval(null);
    }
  };

  return (
    <div className="h-[100vh] playback">
      <MapContainer center={[20.5937, 78.9629]} zoom={5} style={{ height: '100%', width: '100%' }}>
        <ControlsContainer
          vehicleName={vehiclenumber || 'vehicle'}
          imei={imei || 'imei'}
          startPlayback={startPlayback}
          stopPlayback={stopPlayback}
          formdata={formdata}
        />

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {positions.length > 0 && (
          <>
            <Marker
              position={[positions[currentIndex].latitude, positions[currentIndex].longitude]}
              icon={customMarker}
            />
            <Polyline
              positions={positions.slice(0, currentIndex + 1).map((p) => [p.latitude, p.longitude])}
              color="blue"
            />
          </>
        )}
      </MapContainer>
    </div>
  );
};

export default Playback;
