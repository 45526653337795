import React from 'react';
import { FaEye } from 'react-icons/fa';
import FormReportSearch, { CustomFormData } from '../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../components/table/Table';
import { useState, useEffect } from 'react';
import { createObjectAlertReportService } from '../../../../services/ServiceFactory';
import { IObjectAlertReport, ObjectAlertReportData } from '../../../../services/interfaces/IObjectService';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ObjectAlertReportUI: React.FC = () => {
    const Report_type = 'Filter Options';

    // send data for filter report
    const options: string[] = ['all', 'krishana', 'ram', 'rakehs', 'meash'];
    const singleselect: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    const field_type: string[] = ['Vehicle', 'Select GeoFence Type', 'From', 'To'];
    const [selecteddata, setSelecteddata] = useState<CustomFormData>();
    const [data, setData] = useState<IObjectAlertReport[]>([]);
    const [loading, setLoading] = useState(false);

    const handleSelectedData = (data: CustomFormData) => {
        setSelecteddata(data);
    };
    console.log(selecteddata);
    const geofanceReportService = createObjectAlertReportService();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await geofanceReportService.getObjecteAlert();
                setData(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        void fetchData();
    }, []);

    const actions = [
        { name: 'View', path: '/company/report/object-alertsingle', icon: FaEye, class: 'text-blue-700 text-2xl' }
    ];
    return (
        <>
            <div>
                <div className='md:flex justify-between items-center mb-2'>
                    <div>
                        <h1 className='font-semibold text-2xl text-black'>Object Alert Report</h1>
                    </div>
                    <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                        <ul>
                            <li>
                                <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                    <DashboardIcon />
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                        </ul>
                        <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                        <p className='ml-2'>Object Alert Report</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <FormReportSearch
                    Report_type={Report_type}
                    Multiselectss={options}
                    singleselect={singleselect}
                    field_type={field_type}
                    onSendData={handleSelectedData}
                />
            </div>
            <Table
                data={data}
                columns={ObjectAlertReportData}
                tableRowActions={actions}
                loading={loading}
                excelExport={true}
                pdfExport={true}
                searchAble={true}
            />
        </>
    );
};

export default ObjectAlertReportUI;
