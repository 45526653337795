import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
//import DashboardIcon from '@mui/icons-material/Dashboard';
//import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//import FormReportSearch, { CustomFormData } from '../../../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../../../components/table/Table';
import { useState,useEffect } from 'react';
import {IGeofence,GeofenceTableColumns} from '../../../../../../services/interfaces/IAllGeofanceService';
import useHttp from '../../../../../../hooks/useHttp';


interface ApiResponse {
  message: string;
  data: IGeofence[];
}
const OsmGeofenceUI: React.FC = () => {
    // const Report_type = '';
    // const singleselect: string[] = ['krishana', 'ram', 'rakehs', 'meash'];
    // const field_type: string[] = ['Vehicle', 'Select GeoFence Type', 'from', 'to'];
    // const [selecteddata, setSelecteddata] = useState<CustomFormData>();
    const [data,setData]=  useState<IGeofence[]>([]);
    // const [formShow, setFormShow] = useState<boolean>(true);


//     const handleSelectedData = (data: CustomFormData) => {
//         setSelecteddata(data);
//         console.log(data);
//     };
//     console.log(selecteddata);
  

// const handaleformshow=()=>{
// setFormShow(!formShow);
// }
const { request } = useHttp();

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await request({
                url: '/geofence',
                method: 'GET'
            });

            // if (!response.ok) {
            //     throw new Error('Failed to fetch companies');
                
            // }

            const data=(await response.json()) as ApiResponse;
            setData(data.data);
            //ApiResponse
        } catch (error) {
           console.error('Error fetching geofence data:', error);
        }
    };

    void fetchData();
}, []);
  
  const actions = [
    { name: 'View', path: '/osmGeofence/view', icon: FaEye, class: 'text-blue-700 text-2xl' }
    ,    { name: 'Edit', path: '/osmGeofence/edit', icon: FaEdit, class: 'text-green-700 text-2xl  mx-2' },
    { name: 'delete', path: '/geofence/', icon: FaTrash, class: 'text-red-700 text-2xl' }
     ];
  return (
    <>
    {/* <div className='flex justify-between relative'>
      <h1 className='text-2xl text-gray-800'>All Geofance</h1>
      <Link to='/company/dashboard' className='flex absolute right-2 top-1 text-xl '>
      <span className='mx-3 my-auto hover:bg-blue-500 '><DashboardIcon /></span>Home</Link>
    </div>
    <div className='bg-white w-full h-[30px] relative border border-t-[3px] border-t-blue-700'>
      <p className='absolute  left-2 top-1'>Filter Option</p>
      <button className='absolute  right-2 top-1' onClick={handaleformshow}>{(formShow==true)?<ArrowDownwardIcon/>:<ArrowDownwardIcon/>}</button>
    </div> */}
    {/* {(formShow==true)? <div className='bg-white'><FormReportSearch Report_type={Report_type} singleselect={singleselect}
        field_type={field_type} onSendData={handleSelectedData}/></div>:''} */}
        <div className='relative mb-2 h-[30px]'>  <Link  to="/osmGeofence/create" className='absolute right-2 top-2 bg-blue-950 text-white px-5 border border-green-300 rounded'>Add New Geofance</Link>
        </div>
    <div className='mt-3'>
      <Table<IGeofence>  data={data} columns={GeofenceTableColumns} tableRowActions={actions}/>
        </div>  
    </>
  );
};

export default OsmGeofenceUI;
