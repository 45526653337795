import { TableColumn } from "react-data-table-component";

export type IAdmin={
    id: number;
    // name: string;
    email: string;  
}

export const AdminDataColumn: TableColumn<IAdmin>[] = [
    { name: 'ID', selector: (row: IAdmin) => row.id, sortable: true },
    // { name: 'name ', selector: (row: IAdmin) => row.name, sortable: true },
    { name: 'Alert Name', selector: (row: IAdmin) => row.email, sortable: true },
   
];