import { ReportHandler, getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        void Promise.all([
            getCLS(onPerfEntry),
            getFID(onPerfEntry),
            getLCP(onPerfEntry),
            getFCP(onPerfEntry),
            getTTFB(onPerfEntry)
        ]);
    }
};

export default reportWebVitals;
