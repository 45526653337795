import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';

interface IDeviceFormValue {
    name: string;
    enabled: boolean;
}

interface IErrorData {
    message?: string;
}

interface IResponse {
    ok: boolean;
    json: () => Promise<IDeviceFormValue>;
}

const DeviceType: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const isEditing = location.pathname.includes('edit');
    const isCreating = location.pathname.includes('create');
    const isViewing = location.pathname.includes('view');
    const readOnlyCondition = isViewing;
    const [initialValues, setInitialValues] = useState<IDeviceFormValue | null>(null);
    const { request } = useHttp();
    const numericId = Number(id);

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        enabled: Yup.boolean().required('please is active than  checke checkbox')
    });

    useEffect(() => {
        if (isCreating) {
            setInitialValues({
                name: '',
                enabled: false
            });
        } else if (numericId && !isCreating) {
            const fetchData = async () => {
                try {
                    const response: IResponse = await request({ url: `/device-type/${numericId}` });
                    if (!response.ok) {
                        const errorData = (await response.json()) as IErrorData;
                        throw new Error(errorData.message || 'Failed to fetch data');
                    }
                    const data = await response.json();
                    setInitialValues(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    toast.error(!error);
                }
            };
            void fetchData();
        }
    }, [isCreating, numericId, request]);

    const handleSubmit = async (values: IDeviceFormValue) => {
        try {
            let response: IResponse | undefined;

            if (isCreating) {
                response = await request({
                    url: '/device-type',
                    method: 'POST',
                    body: { name: values.name, enabled: values.enabled }
                });
            } else if (isEditing && numericId) {
                response = await request({
                    url: `/device-type/${numericId}`,
                    method: 'PUT',
                    body: { name: values.name, enabled: values.enabled }
                });
            }

            if (!response || !response.ok) {
                const errorData = (await response!.json()) as IErrorData;
                throw new Error(errorData.message || 'Something went wrong');
            }

            toast.success(isCreating ? 'Device added successfully' : 'Device updated successfully');
            navigate(`/systemAdmin/manageDeviceType`);
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            } else {
                toast.error('Unexpected error');
            }
        }
    };

    if (!initialValues) {
        return <div>Loading...</div>;
    }

    return (
        <div className='max-w-md mx-auto mt-10'>
            <h1 className='text-2xl font-bold mb-4 text-center'>
                {isCreating ? 'Add New DeviceType' : isEditing ? 'Edit DeviceType' : 'View DeviceType'}
            </h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isValid }) => (
                    <Form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
                        <div className='mb-4'>
                            <label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2'>
                                Name
                            </label>
                            <Field
                                type='text'
                                id='name'
                                name='name'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                readOnly={readOnlyCondition}
                            />
                            <ErrorMessage name='name' component='div' className='text-red-500 text-xs mt-2' />
                            {!isValid && <p>please enter device type</p>}
                        </div>

                        <div className='mb-4'>
                            <label htmlFor='enabled' className='block text-gray-700 text-sm font-bold mb-2'>
                                Enabled
                            </label>
                            <div className='flex items-center'>
                                <Field
                                    type='checkbox'
                                    id='enabled'
                                    name='enabled'
                                    className='mr-2 leading-tight'
                                    disabled={readOnlyCondition}
                                />
                                <span className='text-sm'>Enable this option</span>
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>
                            {!readOnlyCondition && (
                                <button
                                    type='submit'
                                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                    disabled={!isValid}
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DeviceType;
