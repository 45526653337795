import { IExpense } from "../interfaces/IExpense";

export const expenseData: IExpense[] = [
    {
        id: 1,
        Company: 'ABC Corp',
        Branch: 'Main Office',
        Object: 'Object A',
        Category: 'Variable',
        Consider_Job: true,
        Job_Allocation: 'In-Progress',
        Completed_Till: '2024-09-30',
        Job_ID: 'Job A',
        Expense_Type_ID: 'Expense B',
        Expense_Date: '2024-09-15',
        Expense_From: '2024-09-01',
        Expense_To: '2024-09-15',
        Expense_Added_By: 'John Doe',
        Amount: 150.75,
        ReferenceNo: 'ref001',
        Odometer: 'odeometer',
        Work_Hour: '8',
        Description: 'Purchase of office supplies including pens and paper.',
        Bill_Attached: 'receipt1.pdf'
    },
    {
        id: 2,
        Company: 'XYZ Inc',
        Branch: 'Branch Office',
        Object: 'Object C',
        Category: 'Fix',
        Consider_Job: false,
        Job_Allocation: 'Completed',
        Completed_Till: '2024-08-20',
        Job_ID: 'Job B',
        Expense_Type_ID: 'Expense C',
        Expense_Date: '2024-08-25',
        Expense_From: '2024-08-15',
        Expense_To: '2024-08-25',
        Expense_Added_By: 'Jane Smith',
        Amount: 275.50,
        ReferenceNo: 'ref002',
        Odometer: '67890',
        Work_Hour: '10',
        Description: 'Reimbursement for travel expenses including hotel and meals.',
        Bill_Attached: 'receipt2.pdf'
    },  
];