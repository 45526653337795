import { TableColumn } from 'react-data-table-component';

export interface IEmergencyAlertService {
    getEmergencyAlert(): Promise<IEmergenctAlert[]>;
}

export type IEmergenctAlert = {
    VehicleNumber: string;
    Title: string;
    Messege: string;
    DriverName: string;
    EmployeeName: string;
    EmployeeId: number;
    Department: string;
    Plant: string;
    G_Map: string;
    IssueRaised: string;
};

export const IEmergencyAlertDataColumn: TableColumn<IEmergenctAlert>[] = [
    { name: 'Vehicle Number', selector: (row: IEmergenctAlert) => row.VehicleNumber, sortable: true },
    { name: 'Title', selector: (row: IEmergenctAlert) => row.Title, sortable: true },
    { name: 'Messege', selector: (row: IEmergenctAlert) => row.Messege, sortable: true },
    { name: 'Driver Name', selector: (row: IEmergenctAlert) => row.DriverName, sortable: true },
    { name: 'Employee Name', selector: (row: IEmergenctAlert) => row.EmployeeName, sortable: true },
    { name: 'Employee Id', selector: (row: IEmergenctAlert) => row.EmployeeId, sortable: true },
    { name: 'Department', selector: (row: IEmergenctAlert) => row.Department, sortable: true },
    { name: 'Plant', selector: (row: IEmergenctAlert) => row.Plant, sortable: true },
    { name: 'Issue Raised', selector: (row: IEmergenctAlert) => row.IssueRaised, sortable: true }
];
