import { TableColumn } from 'react-data-table-component';

export type ICompanyUser= {
    id: number;
    companyId: number;
    email: string;
    role: string;
    roleId: number;
    userType: string;
  }
  


export const ICompanyUserDataColumn: TableColumn<ICompanyUser>[] = [
    { name: 'ID', selector: (row: ICompanyUser) => row.id, sortable: true },
    { name: 'Name', selector: (row: ICompanyUser) => row.email, sortable: true },
    { name: 'Role', selector: (row: ICompanyUser) => row.role, sortable: true },

    { name: 'Company', selector: (row: ICompanyUser) => row.userType, sortable: true },

    { name: 'Name', selector: (row: ICompanyUser) => row.email, sortable: true },

   
];
