import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


export interface IDatefilter{
  fromDate: string;
  toDate: string;
}
interface ControlsContainerProps {
  vehicleName: string;
  imei:string;
  startPlayback: () => void;
  stopPlayback: () => void;
  formdata: (data:IDatefilter) => void;

}

const ControlsContainer: React.FC<ControlsContainerProps> = ({ vehicleName, startPlayback, stopPlayback ,formdata}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const togglePlayPause = () => {
    const fromdateformented = fromDate?.toISOString().slice(0, -5) + "Z";
    const todate = toDate?.toISOString().slice(0, -5) + "Z";

    formdata({fromDate:fromdateformented, toDate:todate})
    if (isPlaying) {
      stopPlayback();
    } else {
      startPlayback();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleOptions = () => setShowOptions(!showOptions);

  return (
    <div
    className="controls-container bg-white p-4 flex flex-col items-center shadow-lg rounded-lg relative"
    style={{ zIndex: 500, maxWidth: '330px', top: '10px', right: '10px' }}
    >
      <div className="device-title mb-4 text-center">
        <b className="text-lg mr-2">{vehicleName}</b>
        <p className="text-sm text-gray-600">{vehicleName}</p>
      </div>

      {/* From Date */}
      <div className="fromDatediv flex items-center mb-4 mx-auto">
        <div className="htext mr-2 text-gray-500">From</div>
        <div className="flex flex-1">
          <DatePicker
            selected={fromDate}
            onChange={(date: Date | null) => setFromDate(date)}  
            showTimeSelect
            dateFormat="Pp"
            className="border-2 border-gray-400 rounded p-2 w-[170px]" 
            popperClassName="custom-datepicker"
          />
        </div>
      </div>

      {/* To Date */}
      <div className="toDatediv flex items-center mb-4">
        <div className="htext mr-2 text-gray-500">To</div>
        <div className="flex flex-1">
          <DatePicker
            selected={toDate}
            onChange={(date: Date | null) => setToDate(date)}  
            showTimeSelect
            dateFormat="Pp"
            className="border-2 border-gray-400 rounded p-2 w-[170px]" 
            popperClassName="custom-datepicker"
          />
        </div>
      </div>

      <div className="playpaushbuttondv text-center flex items-center justify-between mb-4">
        <div className='mx-[20px]'>
          <span
            className="select_box relative cursor-pointer"
            style={{ padding: '5px 10px', border: '1px solid #ccc', borderRadius: '4px' }}
            onClick={toggleOptions}
          >
            <span className="select_txt">Shortcut</span>
            <span className="ml-2">&#9660;</span>
            {showOptions && (
              <div
                className="option absolute mt-2 bg-white border border-gray-300 rounded shadow-lg p-2"
                style={{ zIndex: 100 }}
              >
                <a href="#" className="block px-2 py-1 hover:bg-gray-100">Today</a>
                <a href="#" className="block px-2 py-1 hover:bg-gray-100">Yesterday</a>
              </div>
            )}
          </span>
        </div>

        <button
          className={`btn btn-primary play mr-2 px-4 py-1 text-white bg-blue-500 
            rounded ${isPlaying ? 'hidden' : 'inline-block'}`}
          onClick={togglePlayPause}
        >
          Play
        </button>
        <button
          className={`btn btn-danger stop px-4 py-1 text-white bg-red-500 rounded 
            ${isPlaying ? 'inline-block' : 'hidden'}`}
          onClick={togglePlayPause}
        >
          Pause
        </button>
      </div>
    </div>
  );
};

export default ControlsContainer;
