import { IGeofancetogeofance ,IGeofanceEntryexitone,IGeofanceEntryexit,IGeofanceReportService ,IGeoToGeoParticularVehicle} from "./interfaces/IGeofanceService";
import {geofenceEntriesone,geofenceEntries,geofenceToGeofenceTrips} from './dummyData/GeofanceReportData';
export class GeofanceReportService implements IGeofanceReportService {
    getGeofanceEntryexitoneReport(): Promise<IGeofanceEntryexitone[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(geofenceEntriesone);
            }, 1000);
        });
    }
    getGeofanceEntryexitReport(): Promise<IGeofanceEntryexit[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(geofenceEntries);
            }, 1000);
        });
    }
    getGeofanceToGeofenceReport(): Promise<IGeofancetogeofance[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(geofenceToGeofenceTrips);
            }, 1000);
        });
   }
   getGeofanceToGeofenceparticulerVReport(): Promise<IGeoToGeoParticularVehicle[]> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(geofenceToGeofenceTrips);
        }, 1000);
    });
}
}