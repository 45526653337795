import React from 'react';
import Table from '../../../../components/table/Table';
import BackButton from '../../../../components/BackButton';
import { useState ,useEffect} from 'react';
import {createVehicleService} from '../../../../services/ServiceFactory'
import { IVehicleParkedReportSingle, ParkedDataColumnSingle } from '../../../../services/interfaces/IVehicleService';
const ParkedReportForVehicleUI: React.FC =()=>{
const [data, setData] = useState<IVehicleParkedReportSingle[]>([]);
const[loading,setLoading] = useState(false);
    const vehicleService= createVehicleService();
  // send data for filter report
   
   
    useEffect(() => {
      setLoading(true);

      const fetchdata= async ()=>{
        try {
          const data= await  vehicleService.getParkedReportSingle();
          setData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
   void  fetchdata();
      
    }, []);
    return (
      <>
         <p> 
        <h1 className="text-black-700 text-xl">Parked Vehicle Report</h1>
               <BackButton label="Go Back" className=" absolute float-end  right-9 top-3" />
    </p>
       
        <Table <IVehicleParkedReportSingle> columns={ParkedDataColumnSingle}  
         excelExport={true}
         searchAble={true}
         pdfExport={true} data={data}  loading={loading}/>
      </>
    );
}

 
export default ParkedReportForVehicleUI;
