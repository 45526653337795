import { TableColumn } from "react-data-table-component";
import { MultiValue } from 'react-select';


export interface IDriverRatingService{
    getDriverRatingData(): Promise<IDriverRating[]>;
    getDriverRatingDataCreate(id:number): Promise<FormValues[]>;

}
export type IDriverRating={
    id: number;
    company: string;
    allocated: number;
    speeding: number;
    harshDriving: number;
    idling:string;
    driverRatingName:string;
    driverName:string;
}

export const IDriverRatingColumn:TableColumn<IDriverRating>[] = [
    { name: 'ID', selector: (row: IDriverRating) => row.id, sortable: true, },
    { name: 'Company', selector: (row: IDriverRating) => row.company, sortable: true },
    { name: 'Allocated', selector: (row: IDriverRating) => row.allocated, sortable: true },
    { name: 'Speeding', selector: (row: IDriverRating) => row.speeding, sortable: true },
    { name: 'HarshDriving', selector: (row: IDriverRating) => row.harshDriving, sortable: true },
    { name: 'Idling ', selector: (row: IDriverRating) => row.idling, sortable: true },
    { name: 'DriverRatingName', selector: (row: IDriverRating) => row.driverRatingName, sortable: true },
];

type OptionType = {
    label: string;
    value: string;
  };
  export type FormValues= {
    id:number,
    basedOn: string;
    object: MultiValue<OptionType>;
    driverRatingName: string;
    overspeedDuration: string;
    speedLimit: number;
    speedIncrease: {
      'speedrange1': number;
      'speedrange2': number;
      'speedrange3': number;
      'speedrange4': number;
    };
    harshAcceleration: { acceleration:number,time: number; distance: number };
    harshBraking: { braking:number,time: number; distance: number };
    idlingDuration: string;
    idlingPenalty: number;
  }
 
  
  export type IFormValuesEdit= {
    id: number;
    basedOn: boolean;
    object: MultiValue<OptionType>;
    driverRatingName: string;
    overspeedDuration: string;
    speedLimit: number;
    speedIncrease: {
      'speedrange1': number;
      '76-86': number;
      '86-106': number;
      'MoreThan106': number;
    };
    harshAcceleration: { time: number; distance: number };
    harshBraking: { time: number; distance: number };
    idlingDuration: string;
    idlingPenalty: number;
  }