import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button';
import DatePicker from 'react-datepicker';
import Table from '../../../components/table/Table';
import { createEmergencyAlertService } from '../../../services/ServiceFactory';
import { IEmergencyAlertDataColumn, IEmergenctAlert } from '../../../services/interfaces/IEmergenctAlert';

function EmergencyAlerte() {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [data, setData] = useState<IEmergenctAlert[]>([]);
    const EmergencyAlertService = createEmergencyAlertService();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await EmergencyAlertService.getEmergencyAlert();
                setData(data);
            } catch (error) {
                if (error instanceof Error) {
                    console.log(error.message);
                } else {
                    console.log('An unknown error occurred');
                }
            }
        };
        void fetchData();
    }, []);
    

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simple validation logic
        if (!startDate || !endDate) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            startDate: startDate,
            endDate: endDate
        });
    };

    return (
        <div>
            <div className='w-full h-[140px] rounded-md bg-white'>
                <div className='bg-blue-900 w-full h-1 rounded-tl-md rounded-tr-md'></div>
                <h1 className='text-blue-950  p-2 ml-2 '>Emergency Alerts</h1>
                <form onSubmit={handleSubmit}>
                    <div className='flex items-start lg:flex-row flex-col  justify-between p-2 ml-2'>
                        <div className='flex items-center lg:gap-6 gap-2'>
                            <div>
                                <div>
                                    <label htmlFor='' className='text-black font-semibold'>
                                        From
                                    </label>
                                </div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => setStartDate(date)}
                                    showTimeSelect
                                    dateFormat='Pp'
                                    placeholderText='mm-dd-yyyy  --:--'
                                    className='mt-1 p-1 block w-full border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-center'
                                />
                            </div>
                            <div>
                                <div>
                                    <label htmlFor='' className='text-black font-semibold'>
                                        To
                                    </label>
                                </div>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => setEndDate(date)}
                                    showTimeSelect
                                    dateFormat='Pp'
                                    placeholderText='mm-dd-yyyy  --:--'
                                    className='mt-1 p-1 block w-full border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-center'
                                />
                            </div>

                            <div className='flex gap-4 mt-6'>
                                <Button type='submit' className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[32px] rounded-md'>
                                    Check
                                </Button>
                                <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[32px] rounded-md'>
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Table
                excelExport={true}
                pdfExport={true}
                searchAble={true}
                columns={IEmergencyAlertDataColumn}
                data={data}
            />
        </div>
    );
}

export default EmergencyAlerte;
