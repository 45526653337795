import { IDriver } from "./interfaces/IDriver";
import { driversData } from "./dummyData/DriverData";
import { IDriverService } from "./interfaces/IDriver";

export class DriverService implements IDriverService{
    getDriver(): Promise<IDriver[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(driversData);
            }, 1000);
        });
    }
}
