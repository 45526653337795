import React, { useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Option {
    value: string;
    label: string;
}

export interface CustomFormData {
    multiSelect?: Option[];
    singleSelect: Option | null;
    startDateTime: Date | null;
    endDateTime: Date | null;
}

interface FormReportSearchProps {
    Report_type: string;
    Multiselectss?: string[];
    singleselect: string[];
    field_type: string[] | null | undefined;
    onSendData: (data: CustomFormData) => void;
}

interface FormErrors {
    multiSelect?: string;
    singleSelect?: string;
    startDateTime?: string;
    endDateTime?: string;
}

const FormReportSearch: React.FC<FormReportSearchProps> = ({
    Report_type,
    Multiselectss,
    singleselect,
    field_type,
    onSendData
}) => {
    const [formData, setFormData] = useState<CustomFormData>({
        multiSelect: [],
        singleSelect: null,
        startDateTime: null,
        endDateTime: null
    });

    const [errors, setErrors] = useState<FormErrors>({});

    const MultiselectOptions: Option[] = Multiselectss
        ? Multiselectss.map(item => ({ value: item, label: item }))
        : [];

    const singleselectOptions: Option[] = singleselect.map(item => ({
        value: item,
        label: item
    }));

    const handleMultiSelectChange = (selectedOptions: MultiValue<Option>) => {
        setFormData({ ...formData, multiSelect: selectedOptions as Option[] });
    };

    const handleSingleSelectChange = (selectedOption: SingleValue<Option>) => {
        setFormData({ ...formData, singleSelect: selectedOption });
    };

    const handleStartDateTimeChange = (date: Date | null) => {
        setFormData({ ...formData, startDateTime: date });
    };
    
    const handleEndDateTimeChange = (date: Date | null) => {
        setFormData({ ...formData, endDateTime: date });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
    
        if (validate()) {
            onSendData(formData);
        }
    };

    const validate = (): boolean => {
        const newErrors: FormErrors = {};

        // if (formData.multiSelect?.length === 0 && Multiselectss) {
        //     newErrors.multiSelect = 'This field is required.';
        // }

        if (!formData.startDateTime) {
            newErrors.startDateTime = 'This field is required.';
        }
        if (!formData.endDateTime) {
            newErrors.endDateTime = 'This field is required.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleClear = () => {
        setFormData({
            multiSelect: [],
            singleSelect: null,
            startDateTime: null,
            endDateTime: null
        });
        setErrors({});
    };

    const isPositionEmpty = (index: number): boolean => {
        return !field_type || index >= field_type.length || !field_type[index];
    };

    return (
        <div className='container p-4 bg-white'>
            <h1 className='text-xl mb-4'>{Report_type}</h1>
            <form className='space-y-4 container' onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-5 gap-3'>
                    {Multiselectss && field_type && field_type[0] && (
                        <div className='mx-1'>
                            <label className='block text-sm font-medium text-gray-700'>
                                {isPositionEmpty(0) ? 'Multi-Select Dropdown' : field_type[0]}
                            </label>
                            <Select
                                isMulti
                                value={formData.multiSelect}
                                onChange={handleMultiSelectChange}
                                options={MultiselectOptions}
                                className='mt-1'
                            />
                            {errors.multiSelect && <p className='text-red-500 text-sm mt-1'>{errors.multiSelect}</p>}
                        </div>
                    )}
                    <div className='mx-1'>
                        <label className='block text-sm font-medium text-gray-700'>
                            {isPositionEmpty(1) ? 'Single-Select Dropdown' : field_type![1]}
                        </label>
                        <Select
                            value={formData.singleSelect}
                            onChange={handleSingleSelectChange}
                            options={singleselectOptions}
                            className='mt-1'
                        />
                        {errors.singleSelect && <p className='text-red-500 text-sm mt-1'>{errors.singleSelect}</p>}
                    </div>
                    <div className='mx-1'>
                        <label className='block text-sm font-medium text-gray-700'>
                            {isPositionEmpty(2) ? 'Start Date and Time' : field_type![2]}
                        </label>
                        <DatePicker
                            selected={formData.startDateTime}
                            onChange={handleStartDateTimeChange}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='Pp'
                            className='mt-1 px-2 py-[6px] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                        />
                        {errors.startDateTime && <p className='text-red-500 text-sm mt-1'>{errors.startDateTime}</p>}
                    </div>
                    <div className='md:col-span-2 mx-1'>
                        <label className='block text-sm font-medium text-gray-700'>
                            {isPositionEmpty(3) ? 'End Date and Time' : field_type![3]}
                        </label>
                        <DatePicker
                            selected={formData.endDateTime}
                            onChange={handleEndDateTimeChange}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='Pp'
                            className='mt-1 px-2 py-[6px] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                        />
                        {errors.endDateTime && <p className='text-red-500 text-sm mt-1'>{errors.endDateTime}</p>}
                    </div>
                </div>
                <div className='flex space-x-4 mt-4'>
                    <button
                        type='submit'
                        className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                    >
                        Check
                    </button>
                    <button
                        type='button'
                        onClick={handleClear}
                        className='bg-gray-100 hover:bg-gray-200 text-black w-[90px] h-[35px] rounded-md border border-black'
                    >
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FormReportSearch;
