import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import CloseIcon from '@mui/icons-material/Close';

interface Option {
  value: string;
  label: string;
}

interface SingleSelectDropdownProps {
  options: Option[];
  onSelect: (selectedValue: string | null) => void;
  selectedValue: string | null;
  placeholder?: string;
  selectClass?: string;
  selectStyle?: React.CSSProperties;
  optionClass?: string;
  optionStyle?: React.CSSProperties;
  width?: string;
  disabled?: boolean;
}

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  options,
  onSelect,
  selectedValue,
  placeholder,
  selectClass,
  selectStyle,
  optionClass,
  optionStyle,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      setSearchTerm('');
    }
  };

  const handleSelectOption = (value: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (!disabled) {
      if (selectedValue === value) {
        onSelect(null);
      } else {
        onSelect(value);
      }
      setIsOpen(true);
    }
  };


  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`block w-full p-1 border relative cursor-pointer ${isOpen ? 'border-blue-500' : 'border-gray-300'
          } ${disabled ? 'bg-gray-200 cursor-not-allowed' : ''} ${selectClass}`}
        style={{ ...selectStyle }}
        onClick={handleToggleDropdown}
      >
        {selectedValue ? (
          <div className={`text-gray-800 flex items-center ${disabled ? 'text-gray-500' : ''}`}>

            {options.find(option => option.value === selectedValue)?.label}

          </div>
        ) : (
          <div className={`text-gray-400 ${disabled ? 'text-gray-500' : ''}`}>
            {placeholder || 'Select Option'}
          </div>
        )}
        {/* <KeyboardArrowDownIcon
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${disabled ? 'text-gray-500' : 'text-gray-700'
            } pointer-events-none`}
        /> */}

        {isOpen ? (
          <KeyboardArrowUpIcon
            className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${disabled ? 'text-gray-500' : 'text-gray-700'} pointer-events-none`}
          />
        ) : (
          <KeyboardArrowDownIcon
            className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${disabled ? 'text-gray-500' : 'text-gray-700'} pointer-events-none`}
          />
        )}
      </div>
      {isOpen && !disabled && (
        <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg w-full">
          <input
            type="text"
            className="block w-full px-4 py-2 border-b border-gray-300 focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="max-h-60 overflow-x-auto">
            {filteredOptions.map(option => (
              <div
                key={option.value}
                className={`w-full block px-4 py-1 cursor-pointer text-sm ${selectedValue === option.value ? 'bg-blue-100 font-semibold' : 'hover:bg-gray-100'
                  } ${optionClass}`}
                style={optionStyle}
                onClick={(event) => handleSelectOption(option.value, event)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleSelectDropdown;

