import { TableColumn } from 'react-data-table-component';

export type IDeviceType = {
    id: number;
    name: string;
    enabled: boolean;
}

export const DeviceTypeDataColumn: TableColumn<IDeviceType>[] = [
    { name: 'ID', selector: (row: IDeviceType) => row.id, sortable: true },
    { name: 'Name', selector: (row: IDeviceType) => row.name, sortable: true },
    { 
        name: 'Enabled', 
        selector: (row: IDeviceType) => row.enabled ? 'True' : 'False',  // Convert boolean to string
        sortable: true 
    }
];
