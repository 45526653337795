import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputField from '../../../../../components/InputFeild';
import Button from '../../../../../components/Button';
import SingleSelectDropdown from '../../../../../components/SingleSelectDropdown';
import useHttp from '../../../../../hooks/useHttp';
import { toast } from 'react-toastify';

interface alertypeInfo {
    name: string;
    description: string;
    enabled: string;
}

interface Option {
    value: string;
    label: string;
}

interface AlertTypeResponse {
    name: string;
    description: string;
    enabled: boolean;
}

function AlertTypes() {
    const { id, action } = useParams<{ id: string; action: string }>();
    const { request } = useHttp();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<alertypeInfo>({
        name: '',
        description: '',
        enabled: ''
    });
    const [errors, setErrors] = useState({ name: '' });
    const option1: Option[] = [
        { value: 'true', label: 'Active' },
        { value: 'false', label: 'Inactive' }
    ];

    const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const validationErrors = { name: '' };

        if (!formData.name) validationErrors.name = 'Name is required';

        setErrors(validationErrors);

        const isValid = !validationErrors.name;

        if (!isValid) {
            toast.error('Please fill in all required fields');
            return;
        }

        const data = {
            name: formData.name,
            enabled: formData.enabled === 'false' ? false : true,
            description: formData.description
        };

        if (id) {
            const response = await request({
                url: '/alert-types/' + id,
                method: 'PUT',
                body: data
            });
            toast.success('alert type updated successfully');
            navigate('/systemAdmin/alertType');
            if (!response.ok) {
                toast.error('error while updating alert type');
            }
        } else {
            try {
                const response = await request({
                    url: '/add-alert-type',
                    method: 'POST',
                    body: data
                });

                if (response.ok) {
                    toast.success('alert type created successfully');
                    navigate('/systemAdmin/alertType');
                } else {
                    toast.error('Error while createing Alert Type');
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (id) {
            const fetchAlertType = async () => {
                try {
                    const response = await request({
                        url: `/alert-types/${id}`,
                        method: 'GET'
                    });

                    if (!response.ok) {
                        toast.error('error while getting alert type');
                    }

                    const data = (await response.json()) as AlertTypeResponse;

                    const mappedFormData: alertypeInfo = {
                        name: data.name,
                        description: data.description,
                        enabled: data.enabled === true ? 'true' : 'false'
                    };
                    setFormData(mappedFormData);
                } catch (error) {
                    console.error('Error fetching users:', error);
                }
            };
            void fetchAlertType();
        }
    }, [id]);

    const handleSelect1 = (selectedValue: string | null) => {
        setFormData(prevData => ({
            ...prevData,
            enabled: selectedValue !== null ? selectedValue : prevData.enabled
        }));
    };

    return (
        <div>
            <div className='md:flex justify-between items-center'>
                <div>
                    <h1 className='font-bold text-2xl text-blue-950'>Add Alert Type</h1>
                </div>
                <div className='flex items-center mt-4 md:mt-0 bg-gray-400 md:bg-gray-300'>
                    <ul>
                        <li>
                            <Link to='/company/dashboard' className='flex items-center space-x-2'>
                                <DashboardIcon />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginLeft: '7px' }} />
                    <p className='ml-2'>Alert Type</p>
                </div>
            </div>

            <form>
                <div className='bg-white h-[210px]  border-t-2 border-blue-800 rounded mt-5'>
                    <div className='grid grid-cols-3 mt-4'>
                        <div className='p-4'>
                            <div className='ml-4'>
                                <label htmlFor='name' className='text-sm font-semibold'>
                                    Name
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='name'
                                placeholder='Enter Name'
                                className='w-[85%] border h-9 pl-2 mx-4'
                                value={formData.name}
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                disabled={action === 'view'}
                            />
                            {errors.name && <p className='text-red-500 text-sm ml-4'>{errors.name}</p>}
                        </div>
                        <div className='p-4'>
                            <div className='ml-4'>
                                <label htmlFor='name' className='text-sm font-semibold'>
                                    Description
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='description'
                                placeholder='Enter Description'
                                className='w-[85%] border h-9 pl-2 mx-4'
                                value={formData.description}
                                onChange={e => setFormData({ ...formData, description: e.target.value })}
                                disabled={action === 'view'}
                            />
                        </div>

                        {id && (
                            <div className='p-4'>
                                <div className='mb-1'>
                                    <label className='text-black font-semibold text-sm' htmlFor=''>
                                        Select Alert Type Status
                                    </label>
                                </div>
                                <SingleSelectDropdown
                                    options={option1}
                                    selectedValue={formData.enabled}
                                    onSelect={handleSelect1}
                                    placeholder='Select Alert Type'
                                    selectClass='w-full'
                                    disabled={action === 'view'}
                                />
                            </div>
                        )}
                    </div>

                    <div className='flex justify-between mt-4 mr-2 p-4'>
                        <Link to='/systemAdmin/alertType'>
                            <Button
                                type='button'
                                className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                            >
                                Back
                            </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                                onClick={event => {
                                    void handleButtonClick(event);
                                }}
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AlertTypes;
