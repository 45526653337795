import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import settings from '../Settings'

interface IDeviceData {
    imei: string;
    latitude: number;
    longitude: number;
    speed: number;
    ignition: string;
    timestamp: string;
    companyId: number;
    isLive: boolean;
    model: string;
    vehicleNumber: string;
    course: string;
    device_battery: string;
    battery: string;
    moving: string;
    temperature: string;
    odometer: string;
    state: string;
    color?: string;
    lastIdleTimestamp?: number;
}

interface LiveLocationContextType {
    locations: Record<string, IDeviceData>;
}

const LiveLocationContext = createContext<LiveLocationContextType | undefined>(undefined);

export const LiveLocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { token } = useAuth();
    const [locations, setLocations] = useState<Record<string, IDeviceData>>({});

    const checkForOfflineVehicles = (device: IDeviceData) => {
        const currentTime = Date.now();
        if (device.ignition === '0' && device.speed === 0) {
            if (!device.lastIdleTimestamp) {
                device.lastIdleTimestamp = currentTime; 
            }

            const idleDuration = (currentTime - device.lastIdleTimestamp) / (1000 * 60);
            if (idleDuration >= 60) {
                device.state = 'OFFLINE'; 
                device.color = getStatusColor('OFFLINE');
            }
        } else {
            device.lastIdleTimestamp = undefined; 
        }
    };

    useEffect(() => {
        if (!token) {
            return;
        }

        const socket = new WebSocket(`${settings.WS_API_BASE_PATH}?token=${token}`);

        const handleMessage = (event: MessageEvent) => {
            try {
                const locationData = JSON.parse(event.data as string) as IDeviceData;

                checkForOfflineVehicles(locationData);

                locationData.color = getStatusColor(locationData.state);

                setLocations((prevLocations) => ({
                    ...prevLocations,
                    [locationData.imei]: locationData
                }));
            } catch (error) {
                console.error('Error parsing WebSocket message: ', error);
            }
        };

        socket.onmessage = handleMessage;

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onerror = (error) => {
            console.error('WebSocket error: ', error);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            socket.close();
        };
    }, [token]);

    return <LiveLocationContext.Provider value={{ locations }}>{children}</LiveLocationContext.Provider>;
};

export const useLiveLocation = () => {
    const context = useContext(LiveLocationContext);
    if (!context) {
        throw new Error('useLiveLocation must be used within a LiveLocationProvider');
    }
    return context;
};

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'MOVING':
            return 'green';
        case 'PARKED':
            return 'blue';
        case 'IDLE':
            return '#ffc107';
        case 'OFFLINE':
            return 'red';
        default:
            return 'black';
    }
};
