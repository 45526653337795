import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Table from '../../../../../../components/table/Table';
import { IAlerts, AlertsDataColumn } from '../../../../../../services/interfaces/IAlertsService';
import Button from '../../../../../../components/Button';
import useHttp from '../../../../../../hooks/useHttp';

interface ApiResponse {
    message: string;
    data: IAlerts[];
}
const AlertsTable: React.FC = () => {
    const [data, setData] = useState<IAlerts[]>([]);
    const { request } = useHttp();

    const actions = [
        {
            name: 'View',
            path: '/company/settings/master/alerts/addAlerts/view',
            icon: FaEye,
            class: 'text-blue-700 text-2xl'
        },
        {
            name: 'Edit',
            path: '/company/settings/master/alerts/addAlerts/edit',
            icon: FaEdit,
            class: 'text-green-700 text-2xl mx-1'
        },
        { name: 'Delete', path: '/alert/', icon: FaTrash, class: 'text-red-700 text-2xl' }
    ];

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await request({
                    url: '/alerts',
                    method: 'GET'
                });
                // const data1 = (await response.json()) as [IAlerts];
                // console.log(`my alert data`,data1);
                const result = (await response.json()) as ApiResponse;
                setData(result.data);
                // setData(data1);
            } catch {
                console.log('Error fetching data');
            }
        };
        void fetchdata();
    }, []);
    return (
        <div className='mt-4'>
            <div className='mt-4 text-end'>
                <Link to='/company/settings/master/alerts/addAlerts'>
                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                        New Alert
                    </Button>
                </Link>
            </div>
            <Table
                columns={AlertsDataColumn}
                data={data}
                tableRowActions={actions}
                excelExport={false}
                searchAble={true}
                pdfExport={false}
            />
        </div>
    );
};
export default AlertsTable;
