import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import KeyIcon from '@mui/icons-material/Key';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import { useLiveLocation } from '../../../../context/MultiTrackContext';
import { Device as IDeviceDataparent } from '../MultiTrackosm';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import FindAddress from '../../../../components/FindAddress';

interface IHandleRightSide {
    handleDeviceClick: (data: IDeviceDataparent) => void;
}

const SideTab: React.FC<IHandleRightSide> = ({ handleDeviceClick }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [filter, setFilter] = useState<string>('All');
    const [selectedFilter, setSelectedFilter] = useState<string>('All');
    const { locations } = useLiveLocation();
    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    const handleFilterClick = (status: string) => {
        setFilter(status);
        setSelectedFilter(status);
    };

    const locationDevices = Object.keys(locations).map(key => {
        const device = locations[key];
        return {
            id: device.imei,
            imei: device.imei,
            name: device.vehicleNumber,
            status: device.state,
            date: device.timestamp.split('T')[0],
            color: device.color,
            speed: device.speed,
            battery: device.battery,
            ignition: device.ignition,
            latitude: device.latitude,
            longitude: device.longitude,
            vehicleNumber: device.vehicleNumber,
            timestamp: device.timestamp
        };
    });

    const filteredDevices = locationDevices.filter(device => {
        return filter === 'All' || device.status === filter;
    });

    interface StatusCounts {
        MOVING: number;
        IDLE: number;
        PARKED: number;
        OFFLINE: number;
        NEWDEVICE: number;
        All: number;
    }

    const statusCounts: StatusCounts = locationDevices.reduce(
        (acc, device) => {
            acc[device.status as keyof StatusCounts] = (acc[device.status as keyof StatusCounts] || 0) + 1;
            return acc;
        },
        {
            MOVING: 0,
            IDLE: 0,
            PARKED: 0,
            OFFLINE: 0,
            NEWDEVICE: 0,
            All: locationDevices.length
        }
    );

    return (
        <div
            className={`absolute top-0 left-0 bg-white h-[95%] multitrack cursor-pointer rounded-xl transition duration-75 ease-in-out ${isMinimized ? 'w-[0px]' : 'w-[468px]'}`}
        >
            <button
                onClick={toggleSidebar}
                className={`absolute top-10 h-16 w-7 bg-white rounded-br-xl rounded-tr-xl transition duration-75 ease-in-out ${isMinimized ? 'left-[1px]' : 'left-[468px]'}`}
            >
                {isMinimized ? (
                    <ArrowForwardIosIcon className='text-blue-700' />
                ) : (
                    <ArrowBackIosIcon className='text-blue-700' />
                )}
            </button>

            {!isMinimized && (
                <div className='absolute left-2 top-2 bg-white border-blue-800 border w-[450px] h-[97%] z-20 cursor-pointer rounded-xl'>
                    <div className='flex'>
                        {/* Filter Buttons */}
                        <button
                            onClick={() => handleFilterClick('MOVING')}
                            className={`w-[100px] h-[50px] font-semibold text-center rounded-tl-xl bg-green-100 flex flex-col justify-center items-center ${selectedFilter === 'MOVING' ? 'border-b-4 border-green-700' : ''}`}
                        >
                            <span className='text-green-700 text-xs'>{statusCounts.MOVING}</span>
                            <span className='text-green-700 text-xs'>Running</span>
                        </button>
                        <button
                            onClick={() => handleFilterClick('IDLE')}
                            className={`w-[100px] h-[50px] font-semibold text-center bg-yellow-100 flex flex-col justify-center items-center ${selectedFilter === 'IDLE' ? 'border-b-4 border-yellow-700' : ''}`}
                        >
                            <span className='text-yellow-700 text-xs'>{statusCounts.IDLE}</span>
                            <span className='text-yellow-700 text-xs'>Idle</span>
                        </button>
                        <button
                            onClick={() => handleFilterClick('PARKED')}
                            className={`w-[100px] h-[50px] font-semibold text-center bg-red-100 flex flex-col justify-center items-center ${selectedFilter === 'PARKED' ? 'border-b-4 border-red-700' : ''}`}
                        >
                            <span className='text-red-600 text-xs'>{statusCounts.PARKED}</span>
                            <span className='text-red-600 text-xs'>Parked</span>
                        </button>
                        <button
                            onClick={() => handleFilterClick('OFFLINE')}
                            className={`w-[100px] h-[50px] font-semibold text-center bg-indigo-200 flex flex-col justify-center items-center ${selectedFilter === 'OFFLINE' ? 'border-b-4 border-indigo-700' : ''}`}
                        >
                            <span className='text-indigo-700 text-xs'>{statusCounts.OFFLINE}</span>
                            <span className='text-indigo-700 text-xs'>Offline</span>
                        </button>
                        <button
                            onClick={() => handleFilterClick('NEWDEVICE')}
                            className={`w-[130px] h-[50px] font-semibold text-center bg-gray-200 flex flex-col justify-center items-center ${selectedFilter === 'New device' ? 'border-b-4 border-gray-700' : ''}`}
                        >
                            <span className='text-gray-500 text-xs'>{statusCounts.NEWDEVICE}</span>
                            <span className='text-gray-500 text-xs'>New Device</span>
                        </button>
                        <button
                            onClick={() => handleFilterClick('All')}
                            className={`w-[105px] h-[50px] font-semibold text-center rounded-tr-xl bg-gray-400 flex flex-col justify-center items-center ${selectedFilter === 'All' ? 'border-b-4 border-gray-700' : ''}`}
                        >
                            <span className='text-xs text-black-500'>{statusCounts.All}</span>
                            <span className='text-xs text-black-500'>Total</span>
                        </button>
                    </div>

                    <div className='w-full h-[60px] flex items-center'>
                        <SearchIcon className='w-4 h-4 text-gray-500 ml-4' />
                        <input type='text' placeholder='Search' className='flex-grow h-8 font-bold p-1' />
                    </div>

                    <hr className='border-1 border-gray-300' />
                    <div className='md:h-[85%] lg:h-96 overflow-y-scroll'>
                        {filteredDevices.map(device => {
                            const {
                                id,
                                imei,
                                vehicleNumber: name,
                                timestamp,
                                color,
                                speed,
                                status,
                                latitude,
                                longitude,
                                ignition,
                                battery
                            } = device;

                            return (


                                <React.Fragment key={id}>
                                    <div
                                        className='flex p-1 cursor-pointer items-center'
                                        onClick={() =>
                                            handleDeviceClick({
                                                imei,
                                                name,
                                                date: new Date(timestamp).toLocaleString(),
                                                color,
                                                speed,
                                                status: status || 'Running',
                                                dname: '',
                                                lat: latitude,
                                                lng: longitude
                                            })
                                        }
                                    >
                                        {/* First section (35%) */}
                                        <div className='flex items-center flex-[35]'>
                                            <div
                                                className='w-3 h-3 rounded-full ml-2'
                                                style={{ backgroundColor: color }}
                                            />
                                            <div className='ml-2'>
                                                <p className='font-semibold text-gray-800 text-xs'>{name}</p>
                                                <p className='text-xs'>{new Date(timestamp).toLocaleString()}</p>
                                            </div>
                                        </div>

                                        {/* Second section (30%) */}
                                        <div className='flex items-center gap-3 flex-[30]'>
                                            <div className='relative group mt-1 flex-shrink-0'>
                                                <p className='text-sm min-w-[40px] text-center'>{speed}</p>
                                                <span className='absolute top-6 left-10 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100'>
                                                    {speed} km/h
                                                </span>
                                            </div>
                                            <div className='relative group flex-shrink-0'>
                                                <SatelliteAltIcon
                                                    className='text-gray-500'
                                                    style={{ fontSize: '15px', color: latitude ? 'green' : 'red' }}
                                                />
                                                <span className='absolute top-6 left-10 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100'>
                                                    GPS: {latitude}, {longitude}
                                                </span>
                                            </div>
                                            <div className='relative group flex-shrink-0'>
                                                <KeyIcon
                                                    className='text-gray-500'
                                                    style={{
                                                        fontSize: '15px',
                                                        color: ignition === '1' ? 'green' : 'red'
                                                    }}
                                                />
                                                <span className='absolute top-6 left-10 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100'>
                                                    Ignition: {ignition === '1' ? 'On' : 'Off'}
                                                </span>
                                            </div>
                                            <div className='relative group flex-shrink-0'>
                                                <Battery6BarIcon
                                                    className='text-gray-500'
                                                    style={{
                                                        fontSize: '15px',
                                                        color: battery == null || Number(battery) <= 0 ? 'red' : 'green'
                                                    }}
                                                />
                                                <span className='absolute top-6 left-10 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100'>
                                                    Battery: {battery ?? 'N/A'}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Third section (35%) */}
                                        <div className='flex items-center flex-[35] font-light overflow-hidden text-ellipsis whitespace-nowrap'>
                                            <FindAddress latitude={latitude.toString()} longitude={longitude.toString()} border={false} />
                                        </div>
                                    </div>
                                    <hr className='border border-gray-200' />
                                </React.Fragment>

                            );
                        })}

                    </div>

                </div>
            )}
        </div>
    );
};

export default SideTab;
