import { TableColumn } from 'react-data-table-component';

export interface IExpenseService {
    getExpense(): Promise<IExpense[]>;
}

export type IExpense = {
    id: number;
    Company: string;
    Branch: string;
    Object: string;
    Category: 'Variable' | 'Fix';
    Consider_Job: boolean; 
    Job_Allocation: 'In-Progress' | 'Completed'; 
    Completed_Till: string; 
    Job_ID: string; 
    Expense_Type_ID: string;
    Expense_Date: string; 
    Expense_From: string;
    Expense_To: string;
    Expense_Added_By: string;
    Amount: number;
    ReferenceNo: string;
    Odometer: string; 
    Work_Hour: string;
    Description: string;
    Bill_Attached: string;
    
};

export const IExpenseDataColumn: TableColumn<IExpense>[] = [
    { name: 'ID', selector: (row: IExpense) => row.id, sortable: true },
    { name: 'Company', selector: (row: IExpense) => row.Company, sortable: true },
    { name: 'Branch', selector: (row: IExpense) => row.Branch, sortable: true },
    { name: 'Object', selector: (row: IExpense) => row.Object, sortable: true },
    { name: 'Category', selector: (row: IExpense) => row.Category, sortable: true },
    { name: 'Consider Job', selector: (row: IExpense) => row.Consider_Job, sortable: true },
    { name: 'Type', selector: (row: IExpense) => row.Job_ID, sortable: true },
    { name: 'Expense From', selector: (row: IExpense) => row.Expense_From, sortable: true },
    { name: 'Expense To', selector: (row: IExpense) => row.Expense_To, sortable: true },
    { name: 'Expense Added By', selector: (row: IExpense) => row.Expense_Added_By, sortable: true },
    { name: 'Amount', selector: (row: IExpense) => row.Amount, sortable: true },
    { name: 'Reference No', selector: (row: IExpense) => row.ReferenceNo, sortable: true },
    { name: 'Description', selector: (row: IExpense) => row.Description, sortable: true },
    { name: 'Bill Attached', selector: (row: IExpense) => row.Bill_Attached, sortable: true }

];