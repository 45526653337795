import { IPreventiveMaintainence } from "./interfaces/IPreventiveMaintainenceService";
import { preventiveMaintainenceData } from "./dummyData/PreventiveMaintainenceData";

export class PreventiveMaintainenceService {
     getPreventiveMaintainence(): Promise<IPreventiveMaintainence[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(preventiveMaintainenceData);
            }, 1000);
        });
    }

    updatePreventiveMaintainence(id: number, updatedData: Partial<IPreventiveMaintainence>): Promise<IPreventiveMaintainence[]> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const index = preventiveMaintainenceData.findIndex(item => item.id === id);
                if (index !== -1) {
                    preventiveMaintainenceData[index] = { ...preventiveMaintainenceData[index], ...updatedData };
                    resolve(preventiveMaintainenceData);
                } else {
                    reject(new Error('Preventive Maintenance record not found'));
                }
            }, 1000);
        });
    }
}

