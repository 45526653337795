import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

// Define the props interface
interface HorizontalBarChartProps {
  data: { name: string; }[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  dataKey: string;
  barColor?: string;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  data,
  dataKey,
  barColor = "#00BFFF",
}) => {
  
  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" domain={[0, 2]} tickCount={5} label={{  position: 'insideBottom', offset: -5 }} />
          <YAxis type="category" dataKey="name" fontSize={13} label={{ angle: -90, position: 'insideLeft', offset: 10 }} />
          <Tooltip />
          <Legend />
          <Bar dataKey={dataKey} fill={barColor} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default HorizontalBarChart;
