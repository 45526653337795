import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import InputField from '../../../../../components/InputFeild';
import { useEffect, useState } from 'react';

interface DriverContactInfo {
    profileAction: string;
    driverName: string;
    punchID: string;
    driverEmail: string;
    driverPhoneNumber: string;
    driverDOB: string;
    drivingLicenceNo: string;
    drivingLicenceIssueDate: string;
    drivingLicenceExpireDate: string;
    driverAddress: string;
}

interface DriverProfileProps {
    data: DriverContactInfo;
}

function DriverProfile({ data }: DriverProfileProps) {
    const [name, setName] = useState('');
    const [punchid, setPunchid] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [driverDOB, setDriverDOB] = useState<Date | null>(null);
    const [drivinglicenceNo, setDrivinglicenceNo] = useState('');
    const [drivingLicenceIssueDate, setDrivingLicenceIssueDate] = useState<Date | null>(null);
    const [drivingLicenceExpireDate, setDrivingLicenceExpireDate] = useState<Date | null>(null);
    const [driverAddress, setDriverAddress] = useState('');
    const [action, setAction] = useState('');

    useEffect(() => {
        if (data) {
            setAction(data.profileAction);
            setName(data.driverName);
            setPunchid(data.punchID);
            setEmail(data.driverEmail);
            setPhoneNo(data.driverPhoneNumber);
            setDriverDOB(data.driverDOB ? new Date(data.driverDOB) : null);
            setDrivinglicenceNo(data.drivingLicenceNo);
            setDrivingLicenceIssueDate(data.drivingLicenceIssueDate ? new Date(data.drivingLicenceIssueDate) : null);
            setDrivingLicenceExpireDate(data.drivingLicenceExpireDate ? new Date(data.drivingLicenceIssueDate) : null);
            setDriverAddress(data.driverAddress);
        } else {
            setAction('');
            setName('');
            setPunchid('');
            setEmail('');
            setPhoneNo('');
            setDriverDOB(null);
            setDrivinglicenceNo('');
            setDrivingLicenceIssueDate(null);
            setDrivingLicenceExpireDate(null);
            setDriverAddress('');
        }
    }, [data]);

    const handleDOBChange = (date: Date | null) => {
        setDriverDOB(date);
    };
    const handleLicenceIssueDate = (date: Date | null) => {
        setDrivingLicenceIssueDate(date);
    };
    const handleLicenceExpireDate = (date: Date | null) => {
        setDrivingLicenceExpireDate(date);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simple validation logic
        if (
            !name ||
            !punchid ||
            !email ||
            !phoneNo ||
            !driverDOB ||
            !drivingLicenceIssueDate ||
            !drivingLicenceExpireDate ||
            !drivinglicenceNo ||
            !driverAddress
        ) {
            alert('Please fill in all fields');
            return;
        }

        // Log the data to the console
        console.log({
            driverName: name,
            punchID: punchid,
            driverEmail: email,
            driverPhoneNumber: phoneNo,
            driverDOB: driverDOB,
            drivingLicenceNo: drivinglicenceNo,
            drivingLicenceIssueDate: drivingLicenceIssueDate,
            drivingLicenceExpireDate: drivingLicenceExpireDate,
            driverAddress: driverAddress
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 gap-10'>
                    <div className='bg-white rounded'>
                        <div className='bg-blue-700 h-[2px] rounded-tr-lg rounded-tl-lg'></div>
                        <div>
                            <h1 className='font-semibold text-gray-600 text-lg mt-2 ml-3'>Bus Driver Detail</h1>
                        </div>
                        <hr className='mt-2' />
                        <div className='grid lg:grid-cols-2 grid-cols-1'>
                            <div className='mt-2'>
                                <div className='ml-4'>
                                    <label htmlFor='name' className='text-sm font-semibold'>
                                        Full Name
                                    </label>
                                </div>
                                <InputField
                                    type='text'
                                    id='name'
                                    placeholder='Full Name'
                                    className='w-[85%] border h-9 pl-2 mx-4'
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='mt-2'>
                                <div className='ml-4'>
                                    <label htmlFor='punchId' className='text-sm font-semibold'>
                                        Punch ID
                                    </label>
                                </div>
                                <InputField
                                    type='text'
                                    id='punchId'
                                    placeholder='Punch ID'
                                    className='w-[85%] border h-9 pl-2 mx-4'
                                    value={punchid}
                                    onChange={e => setPunchid(e.target.value)}
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='mt-2'>
                                <div className='ml-4'>
                                    <label htmlFor='email' className='text-sm font-semibold'>
                                        Email
                                    </label>
                                </div>
                                <InputField
                                    type='text'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    id='email'
                                    placeholder='Email'
                                    className='w-[85%] border h-9 pl-2 mx-4'
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='mt-2'>
                                <div className='ml-4'>
                                    <label htmlFor='phoneNumber' className='text-sm font-semibold'>
                                        Phone Number
                                    </label>
                                </div>
                                <InputField
                                    type='text'
                                    value={phoneNo}
                                    onChange={e => setPhoneNo(e.target.value)}
                                    id='phoneNumber'
                                    placeholder='Phone Number'
                                    className='w-[85%] border h-9 pl-2 mx-4'
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='ml-4 mt-3'>
                                <Input
                                    type='date'
                                    id='DOB'
                                    value={driverDOB}
                                    onChange={e => (action === 'view' ? undefined : handleDOBChange(e as Date | null))}
                                    placeholder='Date of birth'
                                    labelChildren='Date of birth'
                                    className='w-[90%] border border-gray-200 px-4 py-1'
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='mt-2'>
                                <div className='ml-4'>
                                    <label htmlFor='drivingLicence' className='text-sm font-semibold'>
                                        Driving Licence No
                                    </label>
                                </div>
                                <InputField
                                    type='text'
                                    id='drivingLicence'
                                    placeholder='Driving Licence No.'
                                    className='w-[85%] border h-9 pl-2 mx-4'
                                    value={drivinglicenceNo}
                                    onChange={e => setDrivinglicenceNo(e.target.value)}
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='ml-4 mt-3'>
                                <Input
                                    type='date'
                                    id='issuedate'
                                    value={drivingLicenceIssueDate}
                                    onChange={e =>
                                        action === 'view' ? undefined : handleLicenceIssueDate(e as Date | null)
                                    }
                                    placeholder='Driving Licence issue date'
                                    labelChildren='Driving Licence issue date'
                                    className='w-[90%] border border-gray-200 px-4 py-1'
                                    disabled={action === 'view'}
                                />
                            </div>

                            <div className='ml-4 mt-3'>
                                <Input
                                    type='date'
                                    id='expiredate'
                                    value={drivingLicenceExpireDate}
                                    onChange={e =>
                                        action === 'view' ? undefined : handleLicenceExpireDate(e as Date | null)
                                    }
                                    placeholder='Driving Licence expiry date'
                                    labelChildren='Driving Licence expiry date'
                                    className='w-[90%] border border-gray-200 px-4 py-1'
                                    disabled={action === 'view'}
                                />
                            </div>
                        </div>

                        <div className='lg:w-[200px] w-[150px] ml-2'>
                            <img src='/images/User-Profile-PNG.png' alt='profile image' />
                        </div>
                        <input type='file' className='ml-2 mt-2 p-3' />
                    </div>

                    <div className='bg-white h-36 rounded'>
                        <div className='bg-blue-700 h-[2px] rounded-tr-lg rounded-tl-lg'></div>
                        <div>
                            <h1 className='font-semibold text-gray-600 text-lg mt-2 ml-3'>Bus Driver Address</h1>
                        </div>
                        <hr className='mt-2' />
                        <div className='mt-2'>
                            <div className='ml-4'>
                                <label htmlFor='address' className='text-sm font-semibold'>
                                    Address
                                </label>
                            </div>
                            <InputField
                                type='text'
                                id='address'
                                placeholder='Type address here'
                                className='w-[90%] border h-9 pl-2 mx-4'
                                value={driverAddress}
                                onChange={e => setDriverAddress(e.target.value)}
                                disabled={action === 'view'}
                            />
                        </div>
                    </div>
                </div>

                <div className='bg-white h-16 mt-4'>
                    <div className='bg-blue-700 h-[2px] rounded-tr-lg rounded-tl-lg'></div>
                    <div className='flex gap-4 justify-end mt-4 mr-2'>
                        <Link to={'/company/settings/master/driver'}>
                        <Button
                            type='button'
                            className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                        >
                            Back
                        </Button>
                        </Link>
                        {action !== 'view' && (
                            <Button
                                type='submit'
                                className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                            >
                                {action === 'edit' ? 'Update' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DriverProfile;
