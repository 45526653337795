import {IVehicleMovementReport,IVehicleParkedReport ,IVehicleIdleReport ,IVehicleWorkhourReport,
  IVehicleMovementReportSingle,IVehicleParkedReportSingle ,IVehicleIdleReportSingle ,IVehicleWorkhourReportSingle,
  IVehicleNewdeviceReport,IVehicleOfflineReport} from '../interfaces/IVehicleService';


export const vehicleMovementReports: IVehicleMovementReport[] = [
 
];


// vehicleParkedReport
export const vehicleParkedReports: IVehicleParkedReport[] = [
  
];

// idel report data
export const vehicleIdleReports: IVehicleIdleReport[] =
 [

   
  ];
  
// data for Offline Report

  
 export const vehicleOfflineReports: IVehicleOfflineReport[] = [
  
  ];
  
  
// data for nwork hours 
export const vehicleWorkhourReports: IVehicleWorkhourReport[] = [
  
  ];
// data for new device
export const vehicleNewdeviceReports: IVehicleNewdeviceReport[] = [
    {
      id: 1,
      company: "ABC Logistics",
      branch: "New York",
      vehicleName: "Truck 001",
      vehicleBrand: "Ford",
      vehicleModel: "F-150",
      imeiNumber: 123456789012345,
      created_at: "2023-01-15"
    },
    {
      id: 2,
      company: "XYZ Transport",
      branch: "Los Angeles",
      vehicleName: "Van 002",
      vehicleBrand: "Mercedes-Benz",
      vehicleModel: "Sprinter",
      imeiNumber: 987654321098765,
      created_at: "2023-02-20"
    },
    {
      id: 3,
      company: "LMN Services",
      branch: "Chicago",
      vehicleName: "Car 003",
      vehicleBrand: "Toyota",
      vehicleModel: "Corolla",
      imeiNumber: 123498765432109,
      created_at: "2023-03-12"
    },
    {
      id: 4,
      company: "DEF Corp",
      branch: "Houston",
      vehicleName: "SUV 004",
      vehicleBrand: "Chevrolet",
      vehicleModel: "Tahoe",
      imeiNumber: 234567890123456,
      created_at: "2023-04-18"
    },
    {
      id: 5,
      company: "GHI Logistics",
      branch: "Phoenix",
      vehicleName: "Truck 005",
      vehicleBrand: "Ram",
      vehicleModel: "1500",
      imeiNumber: 345678901234567,
      created_at: "2023-05-10"
    },
    {
      id: 6,
      company: "JKL Transport",
      branch: "Philadelphia",
      vehicleName: "Van 006",
      vehicleBrand: "Ford",
      vehicleModel: "Transit",
      imeiNumber: 456789012345678,
      created_at: "2023-06-22"
    },
    {
      id: 7,
      company: "MNO Services",
      branch: "San Antonio",
      vehicleName: "Car 007",
      vehicleBrand: "Honda",
      vehicleModel: "Civic",
      imeiNumber: 567890123456789,
      created_at: "2023-07-05"
    },
    {
      id: 8,
      company: "PQR Corp",
      branch: "San Diego",
      vehicleName: "SUV 008",
      vehicleBrand: "GMC",
      vehicleModel: "Yukon",
      imeiNumber: 678901234567890,
      created_at: "2023-08-15"
    },
    {
      id: 9,
      company: "STU Logistics",
      branch: "Dallas",
      vehicleName: "Truck 009",
      vehicleBrand: "Nissan",
      vehicleModel: "Titan",
      imeiNumber: 789012345678901,
      created_at: "2023-09-25"
    },
    {
      id: 10,
      company: "VWX Transport",
      branch: "San Jose",
      vehicleName: "Van 010",
      vehicleBrand: "Mercedes-Benz",
      vehicleModel: "Sprinter",
      imeiNumber: 890123456789012,
      created_at: "2023-10-05"
    }
  ];

// data for particuler vehicle reports
export const vehicleMovementReportsSingleData: IVehicleMovementReportSingle[] = [
  {
    id: 1,
    company: 'Company A',
    branch: 'Branch A',
    vehicleName: 'Truck 01',
    vehicleBrand: 'Brand A',
    vehicleModel: 'Model A1',
    driver: 'John Doe',
    imeiNumber: 123456789012345,
    startLocation: 'Location A',
    coordinates: '51.5074,-0.1278',
    distance: 150,
    maxSpeed: 80,
    avgSpeed: 60,
    type: 1,
    duration: 120,
    start: '2024-07-31T08:00:00',
    endLocation: 'Location B',
  },
  // Add 9 more objects with similar structure and different data
];

export const vehicleParkedReportsSingleData: IVehicleParkedReportSingle[] = [
  {
    company: 'Company B',
    branch: 'Branch B',
    vehicleName: 'Van 02',
    vehicleBrand: 'Brand B',
    vehicleModel: 'Model B1',
    driver: 'Jane Smith',
    imeiNumber: 987654321098765,
    arrival: '2024-07-31T09:00:00',
    startLocation: 'Location C',
    coordinates: '40.7128,-74.0060',
    parked: '3 hours',
    departure: '2024-07-31T12:00:00',
    endLocation: 'Location D',
    overstay: '30 mins',
  },
  // Add 9 more objects with similar structure and different data
];

export const vehicleIdleReportsSingleData: IVehicleIdleReportSingle[] = [
  {
    id: 1,
    company: 'Company C',
    branch: 'Branch C',
    vehicleName: 'Car 03',
    vehicleBrand: 'Brand C',
    vehicleModel: 'Model C1',
    driver: 'Mike Johnson',
    imeiNumber: 192837465019283,
    startTime: '2024-07-31T10:00:00',
    startLocation: 'Location E',
    coordinates: '34.0522,-118.2437',
    idle: '1 hour',
    endTime: '2024-07-31T11:00:00',
    endLocation: 'Location F',
  },
  // Add 9 more objects with similar structure and different data
];

export const vehicleWorkhourReportsSingleData: IVehicleWorkhourReportSingle[] = [
  {
    id: 1,
    company: 'Company D',
    branch: 'Branch D',
    vehicleName: 'Bus 04',
    vehicleBrand: 'Brand D',
    vehicleModel: 'Model D1',
    driver: 'Anna Lee',
    imeiNumber: 564738291012345,
    startLocation: 'Location G',
    coordinates: '48.8566,2.3522',
    distance: 200,
    duration: '4 hours',
    start: '2024-07-31T07:00:00',
    end: '2024-07-31T11:00:00',
    endLocation: 'Location H',
  },
  // Add 9 more objects with similar structure and different data
];

