import { TableColumn } from "react-data-table-component";
export interface IDriverBehaviourService {
    getDriverRatingReport(): Promise<IDeriverRatingReport[]>;
    getDriverWorkReport(): Promise<IDeriverWorkHourReport[]>;
}
export type IDeriverWorkHourReport = {
    id: number;
    company: string;
    branch: string;
    employeeName: string;
    mobileNumber: string;
    employeeId: string;
    dateOfJoining: string;
    designation: string;
    shift: string;
workHourDistance: number;
workHourRunning: number;
workHourIdle: number;
nonWorkingHours: number;
shiftStartTime: string;
shiftEndTime: string;
   
};

export const DriverWorkHourColumn:TableColumn<IDeriverWorkHourReport>[] = [
    { name: 'ID', selector: (row: IDeriverWorkHourReport) => row.id, sortable: true, },
    { name: 'Company', selector: (row: IDeriverWorkHourReport) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IDeriverWorkHourReport) => row.branch, sortable: true },
    { name: 'Employee Name', selector: (row: IDeriverWorkHourReport) => row.employeeName, sortable: true },
    { name: 'Mobile Number', selector: (row: IDeriverWorkHourReport) => row.mobileNumber, sortable: true },
    { name: 'EmployeeId ', selector: (row: IDeriverWorkHourReport) => row.employeeId, sortable: true },
    { name: 'Date Of Joining', selector: (row: IDeriverWorkHourReport) => row.dateOfJoining, sortable: true },
    { name: 'Designation', selector: (row: IDeriverWorkHourReport) => row.designation, sortable: true },
    { name: 'Shift', selector: (row: IDeriverWorkHourReport) => row.shift, sortable: true } ,
     { name: 'WorkHourDistance', selector: (row: IDeriverWorkHourReport) => row.workHourDistance, sortable: true }  , 
     { name: 'WorkHourRunning', selector: (row: IDeriverWorkHourReport) => row.workHourRunning, sortable: true }  ,
     { name: 'WorkHourIdle', selector: (row: IDeriverWorkHourReport) => row.workHourIdle, sortable: true } ,
     { name: 'NonWorkingHours', selector: (row: IDeriverWorkHourReport) => row.nonWorkingHours, sortable: true } ,
     { name: 'ShiftStartTime', selector: (row: IDeriverWorkHourReport) => row.shiftStartTime, sortable: true } ,
     { name: 'ShiftEndTime', selector: (row: IDeriverWorkHourReport) => row.shiftEndTime, sortable: true } 
];

export type IDeriverRatingReport = {
    id: number;
    company: string;
    branch: string;
    employeeName: string;
    mobileNumber: string;
    employeeId: string;
    dateOfJoining: string;
    tagVia:string;
   rfidBeaconTagNo:string;
    designation: string;
    shiftTime: string;
    distance: string;
    running:string;
    idle:string;
    stop:string;
    noOfOverSpeeding:number;
    noOfHarshDriving:number;
    totalViolations:number;
    ratings:string;
};

export const DriverRatingReportColumn:TableColumn<IDeriverRatingReport>[] = [
    { name: 'ID', selector: (row: IDeriverRatingReport) => row.id, sortable: true, },
    { name: 'Company', selector: (row: IDeriverRatingReport) => row.company, sortable: true },
    { name: 'Branch', selector: (row: IDeriverRatingReport) => row.branch, sortable: true },
    { name: 'Employee Name', selector: (row: IDeriverRatingReport) => row.employeeName, sortable: true },
    { name: 'Mobile Number', selector: (row: IDeriverRatingReport) => row.mobileNumber, sortable: true },
    { name: 'EmployeeId ', selector: (row: IDeriverRatingReport) => row.employeeId, sortable: true },
    { name: 'Date Of Joining', selector: (row: IDeriverRatingReport) => row.dateOfJoining, sortable: true },
    { name: 'TagVia', selector: (row: IDeriverRatingReport) => row.tagVia, sortable: true }  , 
    { name: 'RfidBeaconTagNo', selector: (row: IDeriverRatingReport) => row.rfidBeaconTagNo, sortable: true }  , 
    { name: 'Designation', selector: (row: IDeriverRatingReport) => row.designation, sortable: true },
    { name: 'Shift Time', selector: (row: IDeriverRatingReport) => row.shiftTime, sortable: true } ,
     { name: 'Distance', selector: (row: IDeriverRatingReport) => row.distance, sortable: true }  , 
     { name: 'Running', selector: (row: IDeriverRatingReport) => row.running, sortable: true }  , 
     { name: 'Idle', selector: (row: IDeriverRatingReport) => row.idle, sortable: true }  , 
     { name: 'Stop', selector: (row: IDeriverRatingReport) => row.stop, sortable: true }  ,
     { name: 'No. Of OverSpeeding', selector: (row: IDeriverRatingReport) => row.noOfOverSpeeding, sortable: true } ,
     { name: 'No. Of HarshDriving', selector: (row: IDeriverRatingReport) => row.noOfHarshDriving, sortable: true } ,
     { name: 'TotalViolations', selector: (row: IDeriverRatingReport) => row.totalViolations, sortable: true } ,
     { name: 'Ratings', selector: (row: IDeriverRatingReport) => row.ratings, sortable: true } 
];
