import { IDriver } from "../interfaces/IDriver";

export const driversData: IDriver[] = [
    {
        id: 1,
        driverName: 'John Doe',
        punchID:'12345',
        driverEmail: 'john.doe@example.com',
        driverPhoneNumber: '123-456-7890',
        driverDOB: '1990-01-01',
        drivingLicenceNo: 'DL123456',
        drivingLicenceIssueDate: '2010-01-01',
        drivingLicenceExpireDate: '2030-01-01',
        driverAddress: '123 Main St, Cityville',
        employeeDesignation: 'Driver',
        tagVia: 'Beacon',
        shiftGroup: 'Morning',
        driverAge: '34',
        dateOfJoining: '2010-01-01',
        dateOfLeaving: '2020-01-01',
        drivingExpireSince: '2019-01-01',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Car',
        IFEinsurenceNO: 'IFE12345',
        lifeInsurenceExpireDate: '2025-01-01',
        medicalNo: 'MED12345',
        medicalExpireDate: '2025-01-01',
        documentName: 'Driver Licence',
        issueDate: '2010-01-01',
        expireDate: '2030-01-01',
        status: 'Active',
        lastModified: '2024-08-10T12:34:56Z'
    },
    {
        id: 2,
        driverName: 'Jane Smith',
        punchID:'4321',
        driverEmail: 'jane.smith@example.com',
        driverPhoneNumber: '987-654-3210',
        driverDOB: '1985-05-12',
        drivingLicenceNo: 'DL654321',
        drivingLicenceIssueDate: '2005-05-12',
        drivingLicenceExpireDate: '2025-05-12',
        driverAddress: '456 Oak St, Townsville',
        employeeDesignation: 'Employee',
        tagVia: 'RFID/Button',
        shiftGroup: 'Evening',
        driverAge: '39',
        dateOfJoining: '2005-05-12',
        dateOfLeaving: '2015-05-12',
        drivingExpireSince: '2014-05-12',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Truck',
        IFEinsurenceNO: 'IFE67890',
        lifeInsurenceExpireDate: '2026-05-12',
        medicalNo: 'MED67890',
        medicalExpireDate: '2026-05-12',
        documentName: 'Driver Licence',
        issueDate: '2005-05-12',
        expireDate: '2025-05-12',
        status: 'Inactive',
        lastModified: '2024-08-09T09:30:00Z'
    },
    {
        id: 3,
        driverName: 'Alice Johnson',
        punchID:'5678',
        driverEmail: 'alice.johnson@example.com',
        driverPhoneNumber: '555-123-4567',
        driverDOB: '1992-03-22',
        drivingLicenceNo: 'DL987654',
        drivingLicenceIssueDate: '2012-03-22',
        drivingLicenceExpireDate: '2032-03-22',
        driverAddress: '789 Pine St, Villagetown',
        employeeDesignation: 'Engineer',
        tagVia: 'Mobile Application',
        shiftGroup: 'Night',
        driverAge: '32',
        dateOfJoining: '2012-03-22',
        dateOfLeaving: '2022-03-22',
        drivingExpireSince: '2021-03-22l',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Van',
        IFEinsurenceNO: 'IFE11111',
        lifeInsurenceExpireDate: '2027-03-22',
        medicalNo: 'MED11111',
        medicalExpireDate: '2027-03-22',
        documentName: 'Driver Licence',
        issueDate: '2012-03-22',
        expireDate: '2032-03-22',
        status: 'Active',
        lastModified: '2024-08-08T15:45:20Z'
    },
    {
        id: 4,
        driverName: 'Bob Brown',
        punchID:'8765',
        driverEmail: 'bob.brown@example.com',
        driverPhoneNumber: '321-654-9870',
        driverDOB: '1988-07-14',
        drivingLicenceNo: 'DL456789',
        drivingLicenceIssueDate: '2008-07-14',
        drivingLicenceExpireDate: '2028-07-14',
        driverAddress: '234 Cedar St, Hamletville',
        employeeDesignation: 'Superviser',
        tagVia: 'Beacon',
        shiftGroup: 'Morning',
        driverAge: '36',
        dateOfJoining: '2008-07-14',
        dateOfLeaving: '2018-07-14',
        drivingExpireSince: '2017-07-14',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Car',
        IFEinsurenceNO: 'IFE22222',
        lifeInsurenceExpireDate: '2028-07-14',
        medicalNo: 'MED22222',
        medicalExpireDate: '2028-07-14',
        documentName: 'Driver Licence',
        issueDate: '2008-07-14',
        expireDate: '2028-07-14',
        status: 'Active',
        lastModified: '2024-08-07T11:20:10Z'
    },
    {
        id: 5,
        driverName: 'Charlie Davis',
        punchID:'6789',
        driverEmail: 'charlie.davis@example.com',
        driverPhoneNumber: '789-012-3456',
        driverDOB: '1982-09-30',
        drivingLicenceNo: 'DL123789',
        drivingLicenceIssueDate: '2002-09-30',
        drivingLicenceExpireDate: '2022-09-30',
        driverAddress: '567 Birch St, Smalltown',
        employeeDesignation: 'Mechanic',
        tagVia: 'RFID/Button',
        shiftGroup: 'Evening',
        driverAge: '42',
        dateOfJoining: '2002-09-30',
        dateOfLeaving: '2024-07-31',
        drivingExpireSince: '2022-09-30',
        licenceAvailabel: 'No',
        licenceToDrive: 'Truck',
        IFEinsurenceNO: 'IFE33333',
        lifeInsurenceExpireDate: '2022-09-30',
        medicalNo: 'MED33333',
        medicalExpireDate: '2022-09-30',
        documentName: 'Driver Licence',
        issueDate: '2002-09-30',
        expireDate: '2022-09-30',
        status: 'Inactive',
        lastModified: '2024-08-06T08:55:00Z'
    },
    {
        id: 6,
        driverName: 'Diana Wilson',
        punchID:'9876',
        driverEmail: 'diana.wilson@example.com',
        driverPhoneNumber: '234-567-8901',
        driverDOB: '1995-04-18',
        drivingLicenceNo: 'DL456123',
        drivingLicenceIssueDate: '2015-04-18',
        drivingLicenceExpireDate: '2035-04-18',
        driverAddress: '890 Maple St, Middletown',
        employeeDesignation: 'Driver',
        tagVia: 'Mobile Application',
        shiftGroup: 'Night',
        driverAge: '29',
        dateOfJoining: '2015-04-18',
        dateOfLeaving: '2025-04-18',
        drivingExpireSince: '2024-04-18',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Van',
        IFEinsurenceNO: 'IFE44444',
        lifeInsurenceExpireDate: '2035-04-18',
        medicalNo: 'MED44444',
        medicalExpireDate: '2035-04-18',
        documentName: 'Driver Licence',
        issueDate: '2015-04-18',
        expireDate: '2035-04-18',
        status: 'Active',
        lastModified: '2024-08-05T13:40:00Z'
    },
    {
        id: 7,
        driverName: 'Edward Martin',
        punchID:'12345',
        driverEmail: 'edward.martin@example.com',
        driverPhoneNumber: '345-678-9012',
        driverDOB: '1987-11-25',
        drivingLicenceNo: 'DL789123',
        drivingLicenceIssueDate: '2007-11-25',
        drivingLicenceExpireDate: '2027-11-25',
        driverAddress: '678 Elm St, Largetown',
        employeeDesignation: 'Employee',
        tagVia: 'Beacon',
        shiftGroup: 'Morning',
        driverAge: '37',
        dateOfJoining: '2007-11-25',
        dateOfLeaving: '2017-11-25',
        drivingExpireSince: '2016-11-25',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Car',
        IFEinsurenceNO: 'IFE55555',
        lifeInsurenceExpireDate: '2027-11-25',
        medicalNo: 'MED55555',
        medicalExpireDate: '2027-11-25',
        documentName: 'Driver Licence',
        issueDate: '2007-11-25',
        expireDate: '2027-11-25',
        status: 'Inactive',
        lastModified: '2024-08-04T14:30:00Z'
    },
    {
        id: 8,
        driverName: 'Fiona Harris',
        punchID:'54321',
        driverEmail: 'fiona.harris@example.com',
        driverPhoneNumber: '456-789-0123',
        driverDOB: '1993-06-15',
        drivingLicenceNo: 'DL321654',
        drivingLicenceIssueDate: '2013-06-15',
        drivingLicenceExpireDate: '2033-06-15',
        driverAddress: '901 Willow St, Tinyville',
        employeeDesignation: 'Engineer',
        tagVia: 'RFID/Button',
        shiftGroup: 'Evening',
        driverAge: '31',
        dateOfJoining: '2013-06-15',
        dateOfLeaving: '2023-06-15',
        drivingExpireSince: '2022-06-15',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Truck',
        IFEinsurenceNO: 'IFE66666',
        lifeInsurenceExpireDate: '2033-06-15',
        medicalNo: 'MED66666',
        medicalExpireDate: '2033-06-15',
        documentName: 'Driver Licence',
        issueDate: '2013-06-15',
        expireDate: '2033-06-15',
        status: 'Active',
        lastModified: '2024-08-03T16:25:00Z'
    },
    {
        id: 9,
        driverName: 'George Clark',
        punchID:'456789',
        driverEmail: 'george.clark@example.com',
        driverPhoneNumber: '567-890-1234',
        driverDOB: '1980-12-05',
        drivingLicenceNo: 'DL654987',
        drivingLicenceIssueDate: '2000-12-05',
        drivingLicenceExpireDate: '2020-12-05',
        driverAddress: '234 Spruce St, Bigcity',
        employeeDesignation: 'Superviser',
        tagVia: 'Mobile Application',
        shiftGroup: 'Night',
        driverAge: '43',
        dateOfJoining: '2000-12-05',
        dateOfLeaving: '2024-06-30',
        drivingExpireSince: '2020-12-05',
        licenceAvailabel: 'No',
        licenceToDrive: 'Car',
        IFEinsurenceNO: 'IFE77777',
        lifeInsurenceExpireDate: '2020-12-05',
        medicalNo: 'MED77777',
        medicalExpireDate: '2020-12-05',
        documentName: 'Driver Licence',
        issueDate: '2000-12-05',
        expireDate: '2020-12-05',
        status: 'Inactive',
        lastModified: '2024-08-02T10:10:00Z'
    },
    {
        id: 10,
        driverName: 'Hannah Lewis',
        punchID:'98765',
        driverEmail: 'hannah.lewis@example.com',
        driverPhoneNumber: '678-901-2345',
        driverDOB: '1998-02-14',
        drivingLicenceNo: 'DL987321',
        drivingLicenceIssueDate: '2018-02-14',
        drivingLicenceExpireDate: '2038-02-14',
        driverAddress: '345 Poplar St, Cityville',
        employeeDesignation: 'Mechanic',
        tagVia: 'Beacon',
        shiftGroup: 'Morning',
        driverAge: '26',
        dateOfJoining: '2018-02-14',
        dateOfLeaving: '2028-02-14',
        drivingExpireSince: '2027-02-14',
        licenceAvailabel: 'Yes',
        licenceToDrive: 'Van',
        IFEinsurenceNO: 'IFE88888',
        lifeInsurenceExpireDate: '2038-02-14',
        medicalNo: 'MED88888',
        medicalExpireDate: '2038-02-14',
        documentName: 'Driver Licence',
        issueDate: '2018-02-14',
        expireDate: '2038-02-14',
        status: 'Active',
        lastModified: '2024-08-01T12:00:00Z'
    }
];
