import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import Table from '../../../../../components/table/Table';
import useHttp from '../../../../../hooks/useHttp';
import { ICompanyUser, ICompanyUserDataColumn } from '../../../../../services/interfaces/ICompanyUser';
import { useAuth } from '../../../../../context/AuthContext';
import Button from '../../../../../components/Button';


interface ApiResponse {
    message: string;
    data: ICompanyUser[];
  }

const CompanyUserUI: React.FC = () => {
    const [data, setData] = useState<ICompanyUser[]>([]);
    const { request } = useHttp();
    const { id } = useAuth();
    const path = `/users/${id}`;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request({
                    url: path,
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user Type');
                }

                const data=await response.json() as ApiResponse;
                setData(data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        void fetchData();
    }, []);

    const actions = [
        { name: 'View', path: '/companyUser/view', icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: '/companyUser/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-2' },
        {
            name: 'Delete',
            path: '/company-user/',
            icon: FaTrash,
            class: 'text-red-700 text-2xl'
        }
    ];
    return (
        <>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl text-gray-800'>All User</h1>
                <Link to='/company/dashboard' className='flex items-center text-xl hover:bg-blue-500 mb-[30px]'>
                    <AiFillDashboard className='mx-3 ' /> Home
                </Link>
            </div>

            <div className=' flex justify-end'>
                <Link to='/companyUser/create'>
                    <Button className='bg-blue-950 hover:bg-blue-600 text-white w-[140px] h-[35px] rounded-md'>
                        Add New User
                    </Button>
                </Link>
            </div>

            <div className=''>
                <Table<ICompanyUser>
                    data={data}
                    columns={ICompanyUserDataColumn}
                    tableRowActions={actions}
                    searchAble={true}
                />
            </div>
        </>
    );
};

export default CompanyUserUI;
