import React from 'react';
//import { FaSearch } from 'react-icons/fa'; // Example icon

interface InputFieldProps {
  type: string;
  id?: string;
  placeholder?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  errorMessage?: string;
  maxLength?: number;
  inputKey?: string | number;
  readOnly?: boolean;
  labelHtmlFor?: string;
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
  labelChildren?: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  className,
  style,
  name,
  errorMessage,
  inputKey,
  maxLength,
  readOnly,
  labelHtmlFor,
  labelClassName,
  labelStyle,
  labelChildren,
  disabled,
  icon,
  ...rest
}) => {
  return (
    <div className="flex flex-col space-y-1">
      {labelChildren && (
        <label
          htmlFor={labelHtmlFor}
          className={labelClassName || 'text-gray-700 text-sm font-bold'}
          style={labelStyle}
        >
          {labelChildren}
        </label>
      )}
      <div className="relative">
        <input
          key={inputKey}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`${className || 'px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'} ${disabled ? 'bg-white' : ''
            }`}
          style={style}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          {...rest}
        />
          {icon && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center ">
            {icon}
          </div>
        )}
        </div>
        {error && onBlur && (
          <span className="text-red-500 text-xs mt-1">{errorMessage}</span>
        )}
      </div>
      );
};

      export default InputField;
