import React, { useState } from 'react';
import InputField from '../../../../../../components/InputFeild';
import Dropdown from '../../../../../../components/Dropdown';
import Button from '../../../../../../components/Button';

const options4 = [
    { value: "Driver's Licence", label: "Driver's Licence" },
    { value: 'Medical', label: 'Medical' },
    { value: 'Mediclaim', label: 'Mediclaim' },
];

interface ReminderProps {
    onClose: () => void; // Function to handle closing the reminder
}

const Reminder: React.FC<ReminderProps> = ({ onClose }) => {
    const [selectedReminderType, setSelectedReminderType] = useState<string>('');

    const handleSelectReminderType = (selectedValue: string) => {
        setSelectedReminderType(selectedValue);
    };

    const [ReminderName, setReminderName] = useState<string>('');
    const [NotifyDay, setNotifyDay] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReminderName(e.target.value);
    };

    const handleNotifyDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotifyDay(e.target.value);
    };

    return (
        <div className="w-[50%] lg:h-[55%] h-[42%] mx-auto bg-white relative">
            <button onClick={onClose} className="absolute top-2 right-2 text-xl">
                &times;
            </button>
            <div className="p-4">
                <h1 className="font-bold text-2xl">Add Reminder Type</h1>
            </div>
            <hr />
            <div className="mt-4">
                <div className="lg:flex lg:items-center lg:ml-8 ml-0">
                    <label
                        htmlFor="remindername"
                        className="block text-center lg:text-left lg:mb-0 mb-2 lg:w-[150px] w-full text-sm font-semibold"
                    >
                        Reminder Name:
                    </label>
                    <InputField
                        type="text"
                        id="remindername"
                        className="block lg:w-[300px] w-[80%] mx-auto lg:mx-0 p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={ReminderName}
                        onChange={handleInputChange}
                        placeholder="Enter Reminder Name"
                    />
                </div>
                <div className="lg:flex lg:items-center lg:ml-8 ml-0">
                    <label
                        htmlFor="reminderType"
                        className="block text-center lg:text-left lg:mb-0 mb-2 lg:w-[150px] w-full text-sm font-semibold"
                    >
                        Reminder Type:
                    </label>
                    <Dropdown
                        options={options4}
                        onSelect={handleSelectReminderType}
                        selectedValue={selectedReminderType}
                        placeholder="Select Designation"
                        selectClass="rounded-none h-[38px] lg:w-[300px] w-[302px] mx-auto lg:mx-0"
                        selectStyle={{
                            backgroundColor: 'white',
                            fontSize: '12px',
                            padding: '8px',
                        }}
                        optionClass=""
                        optionStyle={{
                            fontSize: '12px',
                            padding: '6px',
                        }}
                    />
                </div>
                <div className="lg:flex lg:items-center lg:ml-8 ml-0">
                    <label
                        htmlFor="notifyBefore"
                        className="block text-center lg:text-left lg:mb-0 mb-2 lg:w-[150px] w-full text-sm font-semibold"
                    >
                        Notify Before (days):
                    </label>
                    <InputField
                        type="text"
                        id="notifyBefore"
                        className="block lg:w-[300px] w-[80%] mx-auto lg:mx-0 p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={NotifyDay}
                        onChange={handleNotifyDayChange}
                        placeholder="Enter Notify Days"
                    />
                </div>
                <div className="lg:flex lg:items-center lg:ml-8 ml-0 mt-2">
                    <label
                        htmlFor="notifyBefore"
                        className="block text-center lg:text-left lg:mb-0 mb-2 lg:w-[150px] w-full text-sm font-semibold"
                    >
                        Remind using:
                    </label>
                    <div className='flex lg:ml-0 ml-4'>
                    <div className='ml-0'>
                    <input type="checkbox" />
                    <label htmlFor="Emails" className='text-sm font-semibold text-gray-700 ml-1'>Emails</label>
                    </div>
                    <div className='ml-2'>
                    <input type="checkbox" />
                    <label htmlFor="sms" className='text-sm font-semibold text-gray-700 ml-1'>SMS</label>
                    </div>
                    <div className='ml-2'>
                    <input type="checkbox" />
                    <label htmlFor="notification" className='text-sm font-semibold text-gray-700 ml-1'>Notification</label>
                    </div>
                    </div>
                </div>
            </div>
            <hr className='mt-4'/>
            <div className='flex gap-4 justify-end mt-4 mr-2'>
            <Button
                            type='submit'
                            className='bg-blue-950 hover:bg-blue-600 text-white w-[90px] h-[35px] rounded-md'
                        >
                            Save
                        </Button>
                        <Button
                            type='button'
                            onClick={onClose}
                            className='bg-gray-200 hover:bg-gray-300 text-black border border-black w-[80px] h-[35px] rounded-md'
                        >
                            Cancel
                        </Button>
                       
                    </div>
        </div>
    );
};

export default Reminder;
