import { IDriverRating, FormValues } from '../interfaces/IDriverRating';

export const driverRatings: IDriverRating[] = [
    {
        id: 1,
        company: 'ABC Logistics',
        allocated: 120,
        speeding: 5,
        harshDriving: 3,
        idling: '20 minutes',
        driverRatingName: 'John Doe',
        driverName: 'rakesh'
    },
    {
        id: 2,
        company: 'XYZ Transport',
        allocated: 150,
        speeding: 2,
        harshDriving: 4,
        idling: '15 minutes',
        driverRatingName: 'Jane Smith',
        driverName: 'rakesh'
    },
    {
        id: 3,
        company: 'QuickMove Inc.',
        allocated: 90,
        speeding: 3,
        harshDriving: 2,
        idling: '30 minutes',
        driverRatingName: 'Bob Johnson',
        driverName: 'rakesh'
    },
    {
        id: 4,
        company: 'FastTrack Ltd.',
        allocated: 200,
        speeding: 1,
        harshDriving: 5,
        idling: '10 minutes',
        driverRatingName: 'Alice Brown',
        driverName: 'rakesh'
    },
    {
        id: 5,
        company: 'Speedy Delivery',
        allocated: 110,
        speeding: 4,
        harshDriving: 3,
        idling: '25 minutes',
        driverRatingName: 'Charlie Green',
        driverName: 'rakesh'
    },
    {
        id: 6,
        company: 'Reliable Haulage',
        allocated: 130,
        speeding: 6,
        harshDriving: 1,
        idling: '12 minutes',
        driverRatingName: 'David White',
        driverName: 'rakesh'
    },
    {
        id: 7,
        company: 'SafeRoads Transport',
        allocated: 140,
        speeding: 3,
        harshDriving: 2,
        idling: '18 minutes',
        driverRatingName: 'Ella Black',
        driverName: 'rakesh'
    },
    {
        id: 8,
        company: 'Prime Movers',
        allocated: 170,
        speeding: 5,
        harshDriving: 4,
        idling: '22 minutes',
        driverRatingName: 'Frank Miller',
        driverName: 'rakesh'
    },
    {
        id: 9,
        company: 'Cargo Express',
        allocated: 100,
        speeding: 2,
        harshDriving: 3,
        idling: '28 minutes',
        driverRatingName: 'Grace Wilson',
        driverName: 'rakesh'
    },
    {
        id: 10,
        company: 'LogiTech Transport',
        allocated: 160,
        speeding: 4,
        harshDriving: 1,
        idling: '16 minutes',
        driverRatingName: 'Harry Lewis',
        driverName: 'rakesh'
    },  {
      id: 5,
      company: 'Speedy Delivery',
      allocated: 110,
      speeding: 4,
      harshDriving: 3,
      idling: '25 minutes',
      driverRatingName: 'Charlie Green',
      driverName: 'rakesh'
  },
  {
      id: 6,
      company: 'Reliable Haulage',
      allocated: 130,
      speeding: 6,
      harshDriving: 1,
      idling: '12 minutes',
      driverRatingName: 'David White',
      driverName: 'rakesh'
  },
  {
      id: 7,
      company: 'SafeRoads Transport',
      allocated: 140,
      speeding: 3,
      harshDriving: 2,
      idling: '18 minutes',
      driverRatingName: 'Ella Black',
      driverName: 'rakesh'
  },
  {
      id: 8,
      company: 'Prime Movers',
      allocated: 170,
      speeding: 5,
      harshDriving: 4,
      idling: '22 minutes',
      driverRatingName: 'Frank Miller',
      driverName: 'rakesh'
  },
  {
      id: 9,
      company: 'Cargo Express',
      allocated: 100,
      speeding: 2,
      harshDriving: 3,
      idling: '28 minutes',
      driverRatingName: 'Grace Wilson',
      driverName: 'rakesh'
  },
  {
      id: 10,
      company: 'LogiTech Transport',
      allocated: 160,
      speeding: 4,
      harshDriving: 1,
      idling: '16 minutes',
      driverRatingName: 'Harry Lewis',
      driverName: 'rakesh'
  }
];

//  data for edit view

export const formValuesData: FormValues[] = [
    {
      id: 1,
      basedOn: 'object',
      object: [{ value: 'car', label: 'Car' }],
      driverRatingName: 'John Doe',
      overspeedDuration: '15m',
      speedLimit: 60,
      speedIncrease: {
        speedrange1: 5,
        speedrange2: 10,
        speedrange3: 15,
        speedrange4: 20,
      },
      harshAcceleration: { acceleration: 3, time: 5, distance: 50 },
      harshBraking: { braking: 4, time: 6, distance: 40 },
      idlingDuration: '10m',
      idlingPenalty: 20,
    },
    {
      id: 2,
      basedOn: 'option2',
      object: [{ value: 'truck', label: 'Truck' }],
      driverRatingName: 'Jane Smith',
      overspeedDuration: '10m',
      speedLimit: 50,
      speedIncrease: {
        speedrange1: 7,
        speedrange2: 12,
        speedrange3: 17,
        speedrange4: 22,
      },
      harshAcceleration: { acceleration: 4, time: 7, distance: 60 },
      harshBraking: { braking: 5, time: 8, distance: 45 },
      idlingDuration: '15m',
      idlingPenalty: 30,
    },
    {
      id: 3,
      basedOn: 'object',
      object: [{ value: 'bus', label: 'Bus' }],
      driverRatingName: 'Alice Johnson',
      overspeedDuration: '5m',
      speedLimit: 70,
      speedIncrease: {
        speedrange1: 3,
        speedrange2: 8,
        speedrange3: 13,
        speedrange4: 18,
      },
      harshAcceleration: { acceleration: 2, time: 4, distance: 30 },
      harshBraking: { braking: 3, time: 5, distance: 25 },
      idlingDuration: '8m',
      idlingPenalty: 15,
    },
    {
      id: 4,
      basedOn: 'option2',
      object: [{ value: 'van', label: 'Van' }],
      driverRatingName: 'Michael Brown',
      overspeedDuration: '20m',
      speedLimit: 55,
      speedIncrease: {
        speedrange1: 6,
        speedrange2: 11,
        speedrange3: 16,
        speedrange4: 21,
      },
      harshAcceleration: { acceleration: 5, time: 9, distance: 65 },
      harshBraking: { braking: 6, time: 10, distance: 50 },
      idlingDuration: '12m',
      idlingPenalty: 25,
    },
    {
      id: 5,
      basedOn: 'object',
      object: [{ value: 'motorcycle', label: 'Motorcycle' }],
      driverRatingName: 'Daniel Lee',
      overspeedDuration: '8m',
      speedLimit: 80,
      speedIncrease: {
        speedrange1: 4,
        speedrange2: 9,
        speedrange3: 14,
        speedrange4: 19,
      },
      harshAcceleration: { acceleration: 6, time: 11, distance: 70 },
      harshBraking: { braking: 7, time: 12, distance: 55 },
      idlingDuration: '9m',
      idlingPenalty: 18,
    },
    {
      id: 6,
      basedOn: 'option2',
      object: [{ value: 'sedan', label: 'Sedan' }],
      driverRatingName: 'Emily Wilson',
      overspeedDuration: '12m',
      speedLimit: 65,
      speedIncrease: {
        speedrange1: 8,
        speedrange2: 13,
        speedrange3: 18,
        speedrange4: 23,
      },
      harshAcceleration: { acceleration: 7, time: 13, distance: 75 },
      harshBraking: { braking: 8, time: 14, distance: 60 },
      idlingDuration: '11m',
      idlingPenalty: 22,
    },
    {
      id: 7,
      basedOn: 'object',
      object: [{ value: 'suv', label: 'SUV' }],
      driverRatingName: 'David Clark',
      overspeedDuration: '18m',
      speedLimit: 75,
      speedIncrease: {
        speedrange1: 9,
        speedrange2: 14,
        speedrange3: 19,
        speedrange4: 24,
      },
      harshAcceleration: { acceleration: 8, time: 15, distance: 80 },
      harshBraking: { braking: 9, time: 16, distance: 65 },
      idlingDuration: '14m',
      idlingPenalty: 28,
    },
    {
      id: 8,
      basedOn: 'option2',
      object: [{ value: 'pickup', label: 'Pickup' }],
      driverRatingName: 'Chris Martin',
      overspeedDuration: '22m',
      speedLimit: 85,
      speedIncrease: {
        speedrange1: 10,
        speedrange2: 15,
        speedrange3: 20,
        speedrange4: 25,
      },
      harshAcceleration: { acceleration: 9, time: 17, distance: 85 },
      harshBraking: { braking: 10, time: 18, distance: 70 },
      idlingDuration: '16m',
      idlingPenalty: 35,
    },
    {
      id: 9,
      basedOn: 'object',
      object: [{ value: 'minivan', label: 'Minivan' }],
      driverRatingName: 'Anna Scott',
      overspeedDuration: '25m',
      speedLimit: 90,
      speedIncrease: {
        speedrange1: 11,
        speedrange2: 16,
        speedrange3: 21,
        speedrange4: 26,
      },
      harshAcceleration: { acceleration: 10, time: 19, distance: 90 },
      harshBraking: { braking: 11, time: 20, distance: 75 },
      idlingDuration: '20m',
      idlingPenalty: 40,
    },
    {
      id: 10,
      basedOn: 'option2',
      object: [{ value: 'tractor', label: 'Tractor' }],
      driverRatingName: 'Jack Harris',
      overspeedDuration: '30m',
      speedLimit: 95,
      speedIncrease: {
        speedrange1: 12,
        speedrange2: 17,
        speedrange3: 22,
        speedrange4: 27,
      },
      harshAcceleration: { acceleration: 11, time: 21, distance: 95 },
      harshBraking: { braking: 12, time: 22, distance: 80 },
      idlingDuration: '25m',
      idlingPenalty: 45,
    },
  ];
