import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import Table from '../../../components/table/Table';
import useHttp from '../../../hooks/useHttp';
import { IIndividualUser, IndividualUserDatacolumn } from '../../../services/interfaces/IIndividualUser';


const IndividualUserUI: React.FC = () => {
    const [data, setData] = useState<IIndividualUser[]>([]);
    const { request } = useHttp();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request({
                    url: '/',
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch companies');
                }

                setData(await response.json() as IIndividualUser[]);
            } catch (error) {
                console.error('Error fetching geofence data:', error);
            }
        };

        void fetchData();
    }, []); 

    const actions = [
        { name: 'View', path: '/systemAdmin/manageAdmin/view', icon: FaEye, class: 'text-blue-700 text-2xl' },
        { name: 'Edit', path: '/systemAdmin/manageAdmin/edit', icon: FaEdit, class: 'text-green-700 text-2xl mx-2' },
        {
            name: 'Delete',
            path: '/systemAdmin/manageAdmin/delete',
            icon: FaTrash,
            class: 'text-red-700 text-2xl'
        }
    ];

    return (
        <>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl text-gray-800'>All Individual User</h1>
                <Link to='' className='flex items-center text-xl hover:bg-blue-500 mb-[30px]'>
                    <AiFillDashboard className='mx-3 ' /> Home
                </Link>
            </div>

            <div className='relative mb-2 mt-[-10px] bg-green-400'>
                <Link
                    to="/indivividualuser/create"
                    className='absolute right-2 top-[-20px] bg-blue-950 text-white px-5 border border-green-300 rounded'
                                    >
                    Add New IndividualUser
                </Link>
            </div>

            <div className=''>
                <Table<IIndividualUser>
                    data={data}
                    columns={IndividualUserDatacolumn}
                    tableRowActions={actions}
                    searchAble={true}
                />
            </div>
        </>
    );
};

export default IndividualUserUI;
