import { IPreventiveMaintainence } from '../interfaces/IPreventiveMaintainenceService';

export const preventiveMaintainenceData: IPreventiveMaintainence[] = [
    {
        id: 1,
        reseller_id: 201,
        company_id: 301,
        reminder_name: 'Oil Change',
        reminder_type_id: 1,
        reminder_month: 6,
        notify_days: 30,
        notify_distance: 5000,
        engine_hour: 150,
        notify_engine: 120,
        reminder_notify_to: 'Manager',
        reminder_mobile_number: '1234567890',
        reminder_email: 'manager@company.com'
    },
    {
        id: 2,
        reseller_id: 202,
        company_id: 302,
        reminder_name: 'Brake Inspection',
        reminder_type_id: 2,
        reminder_month: 12,
        notify_days: 60,
        notify_distance: 10000,
        engine_hour: 300,
        notify_engine: 240,
        reminder_notify_to: 'Supervisor',
        reminder_mobile_number: '2345678901',
        reminder_email: 'supervisor@company.com'
    },
    {
        id: 3,
        reseller_id: 203,
        company_id: 303,
        reminder_name: 'Tire Rotation',
        reminder_type_id: 3,
        reminder_month: 6,
        notify_days: 30,
        notify_distance: 5000,
        engine_hour: 150,
        notify_engine: 120,
        reminder_notify_to: 'Fleet Manager',
        reminder_mobile_number: '3456789012',
        reminder_email: 'fleetmanager@company.com'
    },
    {
        id: 4,
        reseller_id: 204,
        company_id: 304,
        reminder_name: 'Battery Check',
        reminder_type_id: 1,
        reminder_month: 12,
        notify_days: 60,
        notify_distance: 10000,
        engine_hour: 300,
        notify_engine: 240,
        reminder_notify_to: 'Maintenance Lead',
        reminder_mobile_number: '4567890123',
        reminder_email: 'maintenance@company.com'
    },
    ];
