import React, { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import KeyIcon from "@mui/icons-material/Key";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VehicleDetails from "../Multi-track/components/VehicleDetails";
import devices from "../../../devices.json";
import { useMapLoader } from "../../../context/MapLoaderProvider"; // Import the context
//import { useAuth } from "../../../context/AuthContext";

interface Device {
  id: number;
  name: string;
  date: string;
  color: string;
  speed: number;
  status: string;
  dname: string;
  lat: number;
  lng: number;
}

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: 12.97194,
  lng: 77.59369,
};

const Multitrack: React.FC = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [mapOptions, setMapOptions] = useState({});
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [markers, setMarkers] = useState<{ lat: number; lng: number }[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [filter, setFilter] = useState<string>("All");
  const [selectedFilter, setSelectedFilter] = useState<string>("All");
  const { isLoaded } = useMapLoader(); // Use the context to check if the script is loaded
  //const { token } = useAuth(); // Use the context to get the token

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMap(map);
    setMapOptions({
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
  };
  
  const handleDeviceClick = (device: Device) => {
    setSelectedDevice(device);
    const newCenter = {
      lat: device.lat,
      lng: device.lng,
    };
    map?.panTo(newCenter);
    setMarkers([{ lat: device.lat, lng: device.lng }]);
  };

  const handleFilterClick = (status: string) => {
    setFilter(status);
    setSelectedFilter(status);
  };

  const filteredDevices = devices.filter((device) => {
    if (filter === "All") {
      return true;
    }
    return device.status === filter;
  });

  interface StatusCounts {
    Running: number;
    Idle: number;
    Parked: number;
    Offline: number;
    "New device": number;
    All: number;
  }

  const statusCounts: StatusCounts = devices.reduce(
    (acc, device) => {
      acc[device.status as keyof StatusCounts] =
        (acc[device.status as keyof StatusCounts] || 0) + 1;
      return acc;
    },
    {
      Running: 0,
      Idle: 0,
      Parked: 0,
      Offline: 0,
      "New device": 0,
      All: devices.length,
    }
  );

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative flex justify-start items-start">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={mapOptions}
        onLoad={handleMapLoad}
      >
        {markers.map((marker, index) => (
          <Marker key={index} position={marker} />
        ))}
      </GoogleMap>
      <div
        className={`absolute top-0 left-0 bg-white h-[95%] z-20 cursor-pointer rounded-xl transition duration-75 ease-in-out ${
          isMinimized ? "w-[50px]" : "w-[468px]"
        }`}
      >
        <button
          onClick={toggleSidebar}
          className={`absolute top-10 h-16 w-7 bg-white rounded-br-xl rounded-tr-xl transition duration-75 ease-in-out  ${
            isMinimized ? "left-[50px]" : "left-[468px]"
          }`}
        >
          {isMinimized ? (
            <ArrowForwardIosIcon className="text-blue-700" />
          ) : (
            <ArrowBackIosIcon className="text-blue-700" />
          )}
        </button>
        {!isMinimized && (
          <div className="absolute left-2 top-2 bg-white border-blue-800 border w-[450px] h-[97%] z-20 cursor-pointer rounded-xl">
            <div className="flex">
              <button
                onClick={() => handleFilterClick("Running")}
                className={`w-[100px] h-[50px] font-semibold text-center rounded-tl-xl bg-green-100 flex flex-col justify-center items-center ${
                  selectedFilter === "Running" ? "border-b-4 border-green-700" : ""
                }`}
              >
                <span className="text-green-700 text-sm">
                  {statusCounts.Running}
                </span>
                <span className="text-green-700 text-sm">Running</span>
              </button>
              <button
                onClick={() => handleFilterClick("Idle")}
                className={`w-[100px] h-[50px] font-semibold text-center bg-yellow-100 flex flex-col justify-center items-center ${
                  selectedFilter === "Idle" ? "border-b-4 border-yellow-700" : ""
                }`}
              >
                <span className="text-yellow-700 text-sm">
                  {statusCounts.Idle}
                </span>
                <span className="text-yellow-700 text-sm">Idle</span>
              </button>
              <button
                onClick={() => handleFilterClick("Parked")}
                className={`w-[100px] h-[50px] font-semibold text-center bg-red-100 flex flex-col justify-center items-center ${
                  selectedFilter === "Parked" ? "border-b-4 border-red-700" : ""
                }`}
              >
                <span className="text-red-600 text-sm">
                  {statusCounts.Parked}
                </span>
                <span className="text-red-600 text-sm">Parked</span>
              </button>
              <button
                onClick={() => handleFilterClick("Offline")}
                className={`w-[100px] h-[50px] font-semibold text-center bg-indigo-200 flex flex-col justify-center items-center ${
                  selectedFilter === "Offline" ? "border-b-4 border-indigo-700" : ""
                }`}
              >
                <span className="text-indigo-700 text-sm">
                  {statusCounts.Offline}
                </span>
                <span className="text-indigo-700 text-sm">Offline</span>
              </button>
              <button
                onClick={() => handleFilterClick("New device")}
                className={`w-[130px] h-[50px] font-semibold text-center bg-gray-200 flex flex-col justify-center items-center ${
                  selectedFilter === "New device" ? "border-b-4 border-gray-700" : ""
                }`}
              >
                <span className="text-gray-500 text-sm">
                  {statusCounts["New device"]}
                </span>
                <span className="text-gray-500 text-sm">New Device</span>
              </button>
              <button
                onClick={() => handleFilterClick("All")}
                className={`w-[105px] h-[50px] font-semibold text-center rounded-tr-xl bg-gray-400 flex flex-col justify-center items-center ${
                  selectedFilter === "All" ? "border-b-4 border-gray-700" : ""
                }`}
              >
                <span className="text-sm">{statusCounts.All}</span>
                <span className="text-sm">Total</span>
              </button>
            </div>
            <div className="w-full h-[60px] flex items-center">
              <SearchIcon className="w-6 h-6 text-gray-500 ml-4" />
              <input
                type="text"
                placeholder="Search"
                className="flex-grow h-[40px] font-bold p-1"
              />
            </div>
            <hr className="border-1 border-gray-300" />
            <div className="md:h-[85%] lg:h-96 overflow-y-scroll">
              {filteredDevices.map((device) => (
                <React.Fragment key={device.id}>
                  <div
                    className="flex p-1 cursor-pointer"
                    onClick={() => handleDeviceClick(device)}
                  >
                    <div
                      className={`w-3 h-3 rounded-full bg-${device.color} ml-2 mt-1`}
                    />
                    <div>
                      <p className="font-semibold text-gray-800 text-sm ml-2">
                        {device.name}
                      </p>
                      <p className="text-sm ml-2" style={{ fontSize: "12px" }}>
                        {device.date}
                      </p>
                    </div>
                    <div className="flex gap-5  ">
                      <div
                        className="relative group mt-1  flex-shrink-0  "
                        style={{ position: "relative", left: "20px" }}
                      >
                        <p className="text-sm min-w-[50px]  text-center">
                          {device.speed}
                        </p>
                        <span className="absolute top-6 left-10 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          {device.speed},km/h
                        </span>
                      </div>
                      <div className="relative group flex-shrink-0">
                        <SatelliteAltIcon
                          className="text-gray-500"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="absolute top-6 left-10 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          GPS:NA
                        </span>
                      </div>
                      <div className="relative group flex-shrink-0">
                        <KeyIcon
                          className="text-gray-500"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="absolute top-6 left-10 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Ignition:Off
                        </span>
                      </div>
                      <div className="relative group flex-shrink-0">
                        <Battery6BarIcon
                          className="text-gray-500"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="absolute top-6 left-10 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-3 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Power:NA
                        </span>
                      </div>
                    </div>
                    <div className="ml-16">
                      <p>-</p>
                    </div>
                  </div>

                  <hr className="border-1 border-gray-300 w-[96%] ml-2" />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedDevice && (
        <div className="absolute top-20 right-0 bg-white h-[87%] lg:h-[75%] w-[240px] lg:w-[270px] z-30  shadow-lg rounded-md ">
          <div>
            <button
              onClick={() => setSelectedDevice(null)}
              className="absolute top-5 left-[-32px] h-8 w-8 bg-blue-800  rounded-tl-md rounded-bl-md"
            >
              <ArrowForwardIosIcon
                className="text-white mb-1  "
                style={{ fontSize: "15px" }}
              />
            </button>
          </div>
          <VehicleDetails selectedDevice={selectedDevice} />
          {/* Add any other details or components you want to show */}
        </div>
      )}
    </div>
  );
};

export default Multitrack;
