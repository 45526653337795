import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import styles from './ManageCompany.module.css';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import useHttp from '../../../hooks/useHttp';
import { ICompany } from '../../../services/interfaces/IManageCompany';
import AdminUI from '../manageAdmin/AdminUI';
import { toast } from 'react-toastify';
import 'leaflet/dist/leaflet.css';
import { MapContainer, useMapEvents, TileLayer, Marker } from 'react-leaflet';
//import icon from '../../../../assets/images/pin.png'; 
import L from 'leaflet';

import { LatLng } from 'leaflet';


interface ApiResponse{
    message:string,
    data:ICompany
}

const customIcon = new L.Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/pin.png`,
    iconSize: [38, 38], 
    iconAnchor: [22, 94], 
    popupAnchor: [-3, -76], 
  });
type CompanyFormValues = {
    company_name: string;
    email: string;
    user_mobile: string;
    address: string;
    latitude: string;
    longitude: string;
};

const validationSchema = Yup.object({
    company_name: Yup.string().required('Company Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    user_mobile: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    latitude: Yup.string().required('Latitude is required'),
    longitude: Yup.string().required('Longitude is required')
});

const CompanyCurd: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'General' | 'CompanyAdmin'>('General');
    const [markerPosition, setMarkerPosition] = useState<LatLng | null>(null); // Track marker position
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const isEditing = location.pathname.includes('edit');
    const isCreating = location.pathname.includes('create');
    const isViewing = location.pathname.includes('view');
    const readOnlyCondition = isViewing;
    const [initialValues, setInitialValues] = useState<CompanyFormValues | null>(null);
    const [loading, setLoading] = useState(true);
    const { request } = useHttp();
    const numericId = Number(id);

    useEffect(() => {
        if (isCreating) {
            setInitialValues({
                company_name: '',
                email: '',
                user_mobile: '',
                address: '',
                latitude: '',
                longitude: ''
            });
            setLoading(false);
        } else if (numericId) {
            const fetchData = async () => {
                try {
                    const response = await request({ url: '/companies/' + numericId });
                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    const data = (await response.json()) as ApiResponse;
                    console.log(data);
                    const initialValuesEditView: CompanyFormValues = {
                        company_name: data.data.name,
                        email: data.data.email,
                        user_mobile: data.data.phone,
                        address: data.data.address,
                        latitude: data.data.latitude,
                        longitude: data.data.longitude
                    };
                    setInitialValues(initialValuesEditView);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };
            void fetchData();
        }
    }, [isCreating, numericId, request]);

    const handleMapClick = (event: { latlng: LatLng }) => {
        const { lat, lng } = event.latlng;
        setMarkerPosition(event.latlng);
        void formik.setFieldValue('latitude', lat.toFixed(6));
        void formik.setFieldValue('longitude', lng.toFixed(6));
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues || {
            company_name: '',
            email: '',
            user_mobile: '',
            address: '',
            latitude: '',
            longitude: ''
        },
        validationSchema,
        onSubmit: async values => {
            try {
                const response = await request({
                    url: isEditing ? `/companies/${numericId}` : '/companies',
                    method: isEditing ? `PUT` : 'POST',
                    body: {
                        name: values.company_name,
                        email: values.email,
                        phone: values.user_mobile,
                        address: values.address,
                        latitude: values.latitude,
                        longitude: values.longitude
                    }
                });

                if (!response.ok) {
                    throw new Error('Error occurred while saving the company');
                }

                toast.success(isEditing ? 'Updated successfully' : 'Added successfully');
                window.location.href = '/systemAdmin/manageCompany/company';
            } catch (error) {
                toast.error('Error saving company');
            }
        }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    const tabs = isViewing ? ['General', 'CompanyAdmin'] : ['General'];

    return (
        <>
            <div className='w-full'>
                <div className='tabs flex justify-around border-b-2 mb-4 w-1/6 h-[40px]'>
                    {tabs.map(tab => (
                        <button
                            key={tab}
                            className={`py-2 px-3 ${activeTab === tab ? 'border-b-4 border-blue-500 bg-white' : ''}`}
                            onClick={() => setActiveTab(tab as 'General' | 'CompanyAdmin')}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>

            {activeTab === 'CompanyAdmin' && <AdminUI companyId={id} />}

            {activeTab === 'General' && (
                <form onSubmit={formik.handleSubmit} className='w-full' encType='multipart/form-data'>
                    <div className='grid grid-cols-1 md:grid-cols-2 md:h-[500px]' id='details'>
                        <div className='mx-3 border border-t-blue-800 border-t-[3px] bg-white item-center'>
                            <p className='text-lg  text-gray-800   border-b-gray-200 border-b-[3px] '>
                                Company Details
                            </p>

                            <div className={`form-group ${styles.inputdev}`}>
                                <label className='block text-md font-medium text-gray-700' htmlFor='company_name'>
                                    Company Name <span className='text-red-500'>*</span>
                                </label>
                                <input
                                    type='text'
                                    className={styles.input}
                                    id='company_name'
                                    name='company_name'
                                    placeholder='Company Name'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.company_name}
                                    readOnly={readOnlyCondition}
                                />
                                {formik.touched.company_name && formik.errors.company_name ? (
                                    <div className='text-red-600'>{formik.errors.company_name}</div>
                                ) : null}
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className={`form-group ${styles.inputdev}`}>
                                    <label className='block text-sm font-medium text-gray-700' htmlFor='email'>
                                        Email <span className='text-red-500'>*</span>
                                    </label>
                                    <input
                                        type='email'
                                        className={styles.input}
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        readOnly={readOnlyCondition}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-red-600'>{formik.errors.email}</div>
                                    ) : null}
                                </div>

                                <div className={`form-group ${styles.inputdev}`}>
                                    <label className='block text-sm font-medium text-gray-700' htmlFor='user_mobile'>
                                        Phone Number <span className='text-red-500'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className={styles.input}
                                        id='user_mobile'
                                        name='user_mobile'
                                        placeholder='Phone Number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.user_mobile}
                                        readOnly={readOnlyCondition}
                                    />
                                    {formik.touched.user_mobile && formik.errors.user_mobile ? (
                                        <div className='text-red-600'>{formik.errors.user_mobile}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className={`form-group ${styles.inputdev}`}>
                                <label className='block text-sm font-medium text-gray-700' htmlFor='address'>
                                    Address <span className='text-red-500'>*</span>
                                </label>
                                <input
                                    type='text'
                                    className={styles.input}
                                    id='address'
                                    name='address'
                                    placeholder='Address'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    readOnly={readOnlyCondition}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <div className='text-red-600'>{formik.errors.address}</div>
                                ) : null}
                            </div>

                            <div className='grid grid-cols-2'>
                                <div className={`form-group ${styles.inputdev}`}>
                                    <label className='block text-sm font-medium text-gray-700' htmlFor='latitude'>
                                        Latitude <span className='text-red-500'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className={styles.input}
                                        id='latitude'
                                        name='latitude'
                                        placeholder='Latitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.latitude}
                                        readOnly={true}
                                    />
                                    {formik.touched.latitude && formik.errors.latitude ? (
                                        <div className='text-red-600'>{formik.errors.latitude}</div>
                                    ) : null}
                                </div>

                                <div className={`form-group ${styles.inputdev}`}>
                                    <label className='block text-sm font-medium text-gray-700' htmlFor='longitude'>
                                        Longitude <span className='text-red-500'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className={styles.input}
                                        id='longitude'
                                        name='longitude'
                                        placeholder='Longitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.longitude}
                                        readOnly={true}
                                    />
                                    {formik.touched.longitude && formik.errors.longitude ? (
                                        <div className='text-red-600'>{formik.errors.longitude}</div>
                                    ) : null}
                                </div>
                            </div>

                            {/* Map */}
                        </div>
                        <div className=' mx-3 border border-t-blue-800 border-t-[3px] bg-white item-center '>
                            <div className='w-full h-full'>
                            <MapContainer
  center={markerPosition || [20.5937, 78.9629]} // default center
  zoom={13}
  className='h-full w-full'
>
  <TileLayer
    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  />
  {markerPosition && <Marker position={markerPosition} icon={customIcon} />} {/* icon moved here */}
  <MapClickHandler onClick={handleMapClick} />
</MapContainer>

                            </div>
                        </div>
                        <div className='form-group mx-3 md:col-span-2 border-t-blue-800 border-t-[3px] px-4 py-2 bg-white mt-5 h-[60px] flex justify-end items-center'>
                            <BackButton className='mt-4 h-[38px]' />

                            <button
                                type='submit'
                                className='bg-cyan-950 hover:bg-blue-800  text-white px-4 py-2 mt-4  mx-2 rounded'
                                disabled={isViewing}
                            >
                                {isEditing ? 'Update' : 'Create'}
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

const MapClickHandler = ({ onClick }: { onClick: (event: { latlng: LatLng }) => void }) => {
    useMapEvents({
        click: event => {
            onClick(event);
        }
    });
    return null;
};

export default CompanyCurd;
