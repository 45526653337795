import { violationData } from './dummyData/ViolationData';
import { IViolations, IViolationService } from './interfaces/IViolation';

export class ViolationService implements IViolationService {
    getViolation(): Promise<IViolations[]> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(violationData);
            }, 1000);
        });
    }
}
