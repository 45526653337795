import { TableColumn } from 'react-data-table-component';


export type IAlertType = {
    id: number;
    name: string;
    description: string;
    enabled:boolean;
};


export const IAlertTypeDataColumn: TableColumn<IAlertType>[] = [
    { name: 'ID', selector: (row: IAlertType) => row.id, sortable: true },
    { name: 'Alert Name', selector: (row: IAlertType) => row.name, sortable: true },
    { name: 'Description', selector: (row: IAlertType) => row.description, sortable: true },
    { name: 'Status', selector: (row: IAlertType) => row.enabled ? "Active" : "Inactive" , sortable: true }

];