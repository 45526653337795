import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../../components/Button';
import Cards from '../../../components/Card';
// import GeofenceViolationNotification from './GeofenceViolationNotification';
// import EmergencyAlertNotification from './EmergencyAlertNotification';

interface AlertNotificationPageProps {
    togglePopup: () => void;
}

function AlertNotificationPage({ togglePopup }: AlertNotificationPageProps) {
    const [isOpen, setIsOpen] = useState(false);
    // const [value, setValue] = React.useState(0);

    useEffect(() => {
        // Set the popup to open when the component is mounted
        setIsOpen(true);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
        setTimeout(() => togglePopup(), 800); // Wait for the transition to finish before toggling
    };

    return (
        <div
            className={`fixed inset-0  flex justify-start transition-transform duration-800 ml-28 mt-4 z-[999] ${
                isOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <div className='bg-gray-300 p-0 rounded-br-lg rounded-bl-lg shadow-lg w-[350px] h-[80%] relative'>
            <h1 className='bg-[#07163d] w-full h-8 items-end text-center'>
            Notifications
        </h1>
        <div className='p-3'>
        <Cards title='Vehicle name' className='' titleColor='text-black text-slate-400 text-xs font-light' background='bg-white'>
            <div className='flex justify-between'><div><p className='text-slate-400 font-light text-xs'>Time</p>
            <p className='text-slate-800 text-sm '></p>
            </div>
            <p className='text-slate-400 text-xs font-light'>Status</p></div>
        </Cards>
        </div>
                {/* <div className='grid grid-cols-2 gap-1 w-full'>
                    <Button
                        onClick={() => setValue(0)}
                        className={`px-1 py-[8px]  text-white text-sm  w-full ${
                            value === 0 ?  'bg-blue-600' : ' bg-blue-950'
                        }`}
                    >
                        Emergency Alerts
                    </Button>
                    <Button
                        onClick={() => setValue(1)}
                        className={`px-1 py-[8px]  text-white text-sm  w-full ${
                            value === 1 ? ' bg-blue-600' : ' bg-blue-950 '
                        }`}
                    >
                        Geofence Violations
                    </Button>
                </div> */}
{/* 
                {value === 0 && <EmergencyAlertNotification handleClose={handleClose} />}
                {value === 1 && <GeofenceViolationNotification handleClose={handleClose} />} */}

                <Button
                    className='absolute top-10 right-[-32px] px-1 py-[5px] bg-blue-950 text-white rounded-tr-lg rounded-br-lg hover:bg-blue-600'
                    onClick={handleClose}
                >
                    <CloseIcon />
                </Button>
            </div>
        </div>
    );
}

export default AlertNotificationPage;
