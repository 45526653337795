import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../../../components/InputFeild';
import Button from '../../../components/Button';
import useHttp from '../../../hooks/useHttp';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/AuthContext';

interface PasswordUpdateResponse {
    message: string;
}

function Password() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { request } = useHttp();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleChange =
        (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setter(e.target.value);
        };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!currentPassword) newErrors.currentPassword = 'Current password is required.';
        if (!newPassword) newErrors.newPassword = 'New password is required.';
        if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';
        if (newPassword.length < 8) newErrors.newPassword = 'Password must be at least 8 characters long.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await request({
                    url: '/change-password',
                    method: 'PUT',
                    body: { currentPassword, newPassword }
                });
                const data = (await response.json()) as PasswordUpdateResponse;

                if (response.ok) {
                    toast.success(data.message);
                    logout();

                    navigate('/login');
                } else {
                    toast.error(data.message || 'Failed to Change Password');
                }
            } catch (error) {
                toast.error('Failed to Change Password');
            }
        }
    };

    return (
        <div>
            <div className='w-full p-4 bg-white rounded-b shadow-md'>
                <form
                    className='md:ml-10'
                    onSubmit={event => {
                        void handleSubmit(event);
                    }}
                >
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='current'>Current</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <InputField
                                type='password'
                                id='current'
                                placeholder='Current password'
                                value={currentPassword}
                                onChange={handleChange(setCurrentPassword)}
                                className={`block w-full p-2 border ${errors.currentPassword ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {errors.currentPassword && <p className='text-red-500 text-sm'>{errors.currentPassword}</p>}
                        </div>
                    </div>
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='new'>New</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <InputField
                                type='password'
                                id='new'
                                placeholder='New password'
                                value={newPassword}
                                onChange={handleChange(setNewPassword)}
                                className={`block w-full p-2 border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {errors.newPassword && <p className='text-red-500 text-sm'>{errors.newPassword}</p>}
                        </div>
                    </div>
                    <div className='md:flex gap-x-6 mt-4'>
                        <div className='flex items-center md:justify-end font-bold text-gray-700 w-[150px] text-sm'>
                            <label htmlFor='confirm'>Confirm</label>
                        </div>
                        <div className='grid md:grid-cols-1 gap-4 mt-2 md:mt-0' style={{ width: '100%' }}>
                            <InputField
                                type='password'
                                id='confirm'
                                placeholder='Confirm password'
                                value={confirmPassword}
                                onChange={handleChange(setConfirmPassword)}
                                className={`block w-full p-2 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {errors.confirmPassword && <p className='text-red-500 text-sm'>{errors.confirmPassword}</p>}
                        </div>
                    </div>

                    <div className='mt-4 md:ml-[150px]'>
                        <Button
                            type='submit'
                            className='w-[80px] h-[35px] bg-red-500 hover:bg-red-700 text-white rounded'
                        >
                            Update
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Password;
