
import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye,FaEdit,FaTrash ,FaArrowUp ,FaArrowDown} from 'react-icons/fa';
import { AiFillDashboard } from "react-icons/ai";
import FormReportSearch ,{CustomFormData} from '../../../../../../components/tablefilterform/TableFormFilter';
import Table from '../../../../../../components/table/Table';
import { useState,useEffect } from 'react';
import {createAllGeofanceService} from '../../../../../../services/ServiceFactory';
import {IAllGeofance,AllGeofanceDataColumn} from '../../../../../../services/interfaces/IAllGeofanceService';
const AllGeofenceUI: React.FC = () => {
  const Report_type = "All Geofance";
  const singleselect: string[] =['krishana', 'ram', 'rakehs', 'meash'];
  const field_type: string[] =['Vehicle','Select Geofance Type','from','to'];
  const [selecteddata,setSelecteddata] =useState<CustomFormData>();
  const [formShow,setFormShow] =useState<boolean>(false);

  const [data,setData]=  useState<IAllGeofance[]>([]);
  const handleSelectedData = (data: CustomFormData) => {
    setSelecteddata(data);
    console.log(data);
    
  };
 console.log(selecteddata);
  const geofanceReportService = createAllGeofanceService();
  useEffect(() => {
    const fetchdata= async ()=>{
      try {
        const data= await geofanceReportService.getAllgeofance();
        setData(data);
      } catch (error) {
        console.log(error);
      }
    }
 void  fetchdata()  
    
  }, []);

const handaleformshow=()=>{
setFormShow(!formShow);
}
  
  const actions = [
    { name: 'View', path: '/company/settings/management/geofence/view', icon: FaEye, class: 'text-blue-700 text-2xl' }
    ,    { name: 'Edit', path: '/company/settings/management/geofence/edit', icon: FaEdit, class: 'text-green-700 text-2xl  mx-2' },
    { name: 'delete', path: '/company/settings/management/geofence/delete', icon: FaTrash, class: 'text-red-700 text-2xl' }
     ];
  return (
    <>
    <div className='flex justify-between relative'>
      <h1 className='text-2xl text-gray-800'>All Geofance</h1>
      <Link to='/company/dashboard' className='flex absolute right-2 top-1 text-xl '>
      <span className='mx-3 my-auto hover:bg-blue-500 '><AiFillDashboard /></span>Home</Link>
    </div>
    <div className='bg-white w-full h-[30px] relative border border-t-[3px] border-t-blue-700'>
      <p className='absolute  left-2 top-1'>Filter Option</p>
      <button className='absolute  right-2 top-1' onClick={handaleformshow}>{(formShow==true)?<FaArrowUp/>:<FaArrowDown/>}</button>
    </div>
    {(formShow==true)? <div className='bg-white'><FormReportSearch Report_type={Report_type} singleselect={singleselect}
        field_type={field_type} onSendData={handleSelectedData}/></div>:''}
        <div className='relative mb-2 h-[30px]'>  <Link  to="/company/settings/management/geofence/create" className='absolute right-2 top-2 bg-blue-950 text-white px-5 border border-green-300 rounded'>Add New Geofance</Link>
        </div>
    <div className='mt-3'><Table   data={data} columns={AllGeofanceDataColumn} tableRowActions={actions}/></div>  
    </>
  );
};

export default AllGeofenceUI;
